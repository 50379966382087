import { HttpErrorResponse } from "@angular/common/http"
import { createAction, props } from "@ngrx/store"
import { Medicament } from "src/app/models"

export const getFavoritesMedicaments = createAction(
    '[Favorites Medicaments] Get favorites medicaments',
    props<{ professionalId : string | null }>()
)

export const getFavoritesMedicamentsSuccess = createAction(
    '[Favorites Medicaments] Get favorites medicaments success',
    props<{ medicaments : Medicament[] }>()  
)

export const getFavoritesMedicamentsFailure = createAction(
    '[Favorites Medicaments] Get favorites medicaments failure',
    props<{ error : HttpErrorResponse }>()  
)

export const addFavoritesMedicaments = createAction(
    '[Favorites Medicaments] add favorite medicament' , 
    props<{ medicament : Medicament }>()
);

export const addFavoritesMedicamentsSuccess = createAction(
    '[Favorites Medicaments] add favorite medicament success',
    props<{ myMedicamentWithId ? : Medicament }>()
)

export const addFavoritesMedicamentsFailure = createAction(
    '[Favorites Medicaments] add favorite medicament failure',
    props<{ error : HttpErrorResponse }>()
);

export const deleteFavoritesMedicaments = createAction(
    '[Favorites Medicaments] delete favorite medicament' , 
    props<{ medicamentFavoritedId : string }>()
);

export const deleteFavoritesMedicamentsSuccess = createAction(
    '[Favorites Medicaments] delete favorite medicament success',
    props<{ medicamentFavoritedId ? : string }>()
)

export const deleteFavoritesMedicamentsFailure = createAction(
    '[Favorites Medicaments] delete favorite medicament failure',
    props<{ error : HttpErrorResponse }>()
);

export const updateFavoritesMedicaments = createAction(
    '[Favorites Medicaments] update favorite medicament' , 
    props<{ medicamentUpdate : Medicament }>()
);

export const updateFavoritesMedicamentsSuccess = createAction(
    '[Favorites Medicaments] update favorite medicament success',
    props<{ myMedicamentToUpdate ? : Medicament }>()
)

export const updateFavoritesMedicamentsFailure = createAction(
    '[Favorites Medicaments] update favorite medicament failure',
    props<{ error : HttpErrorResponse }>()
);
import { Injectable } from '@angular/core';
import { Medicament, Prescription } from 'src/app/models';

@Injectable()

export class PrescriptionNewValidatorsHelperService {

  CheckEmptyFields(prescriptionDraft : Prescription):string{

    const {medicaments} = prescriptionDraft;

    if(medicaments){
      let /* title = "Campos imcompletos" , */ alert = "Atención campos :" ,
      AllFilled = true , rightDates = true /* , startDateGreaterEndDate = true */;
      
      for (let i = 0; i < medicaments.length; i++) {
  
        const keys = Object.keys(medicaments[i]);
        const values = Object.values(medicaments[i]);
        
        alert += `En medicamento ${medicaments[i].name} : `;
        
        for (let x = 0; x < values.length; x++) {
          
          if(typeof(values[x]) === 'number')
          {
            if(+values[x] < 1){
              alert += `${keys[x]} debe ser mayor que 0 `;
              x < ( values.length - 1 ) ? alert += `,` : alert += ` .`;
              AllFilled = false;
            }
          }
          else 
          {
            if(keys[x] === 'endDate' || keys[x] === 'startDate'){
              if(!this.CheckDates(values[x])){
              
                rightDates = false;
              
              }
            }
            if(!values[x]){
              `${keys[x]} debe estar completo `;
              x < ( values.length - 1 ) ? alert += `,` : alert += ` .`;
              AllFilled = false;
            }
          }
        }
  
        /* if(this.CheckEndDateGreaterThenStartDate(medicaments[i])){
          startDateGreaterEndDate = true;
        } else {
          startDateGreaterEndDate = false;
        } */
      }
   
      //if(!rightDates)title += ` Y Fechas incorrectas`;
      if(AllFilled ){
        return 'allChecksOk';
      } else {
        if(window.location.host !== 'localhost:420'){
          alert = 'Atencíón campos incompletos o con valor 0 , completelos para formalizar receta .';
          if(!rightDates)alert += `Asegurese que las fechas no sean inferiores a hoy y la fecha de fin de tratamiento superior sea a la de comienzo`;
        }
        return alert;
      }
    }else {
      return '';
    }

    
  }

  CheckDates(date:string):boolean{
    const inputDate = new Date(date);
    const todaysDate = new Date();
    inputDate.setHours(0,0,0,0)
    todaysDate.setHours(0,0,0,0)
    if(todaysDate <= inputDate){
      return true;
    }else{
      return false;
    }
  }

  CheckEndDateGreaterThenStartDate(medicament : Medicament):boolean{
    const {startDate , endDate} = medicament; 
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0,0,0,0)
    end.setHours(0,0,0,0)
    if(end < start){
      return false;
    }else{
      return true;
    }
  }

  CheckMedicamentsStartDateLessThanToday(prescription: Prescription):Medicament[]{
    
    const { medicaments } = prescription;

    if( medicaments ){

      const today = new Date();
      const medicamentsWithWrongstartDate = [];
      
      for (let i = 0; i < medicaments.length; i++) {

        const {startDate , endDate} = medicaments[i];
        
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        start.setHours(0,0,0,0)
        end.setHours(0,0,0,0)
        today.setHours(0,0,0,0);

        if(end < start || start < today){
          medicamentsWithWrongstartDate.push(medicaments[i]);
        }
      }
  
      return medicamentsWithWrongstartDate;
    }else{
      return [];
    }
  }
}

import { Injectable } from '@angular/core';
import { AlertContent, Medicament } from 'src/app/models';
import { ComponentToAlertComponentService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root' //provide in root to communicate with alertComponent located in shared module
})

export class PrescriptionsAlertsService {

  constructor(
    private componentToAlertComponentService:                ComponentToAlertComponentService
  ) { }

  BuildPrescriptionsAlert( 
    alertContent:string , title?:string , color? : string ,
    borderColor ? :string , icon?:string , iconColor?:string , 
    navigateTo ?: string 
  ):void{

    const alert : AlertContent = {
      title: title ? title : 'Atención , datos incompletos o incorrectos',
      content: alertContent,
      color: color ? color : 'rgb(252 , 232 , 239)',
      borderColor : borderColor ? borderColor : '1px solid #e74c3c',
      icon: icon ? icon : 'faTriangleExclamation',
      iconColor: iconColor ? iconColor : 'rgb(161 , 18 , 18)',
      navigateTo : navigateTo
    }
    this.componentToAlertComponentService.sendMessage(alert);
  }

  BuildMedicamentsDatesAlert(wrongMedicamentsDate : Medicament[]):void{

    let datesAlert = `Revisar fechas de inicio y fin de los medicamentos : `;
    for (let i = 0; i < wrongMedicamentsDate.length; i++) {
      
      if(wrongMedicamentsDate[i].name){
        datesAlert += `${wrongMedicamentsDate[i].name?.split(' ')[0]}, `;
      }

      
    }
    datesAlert += `inicio no podrá ser una fecha anterior a hoy y fin no podrá ser anterior a inicio`

    const alert : AlertContent = {
      title: 'Atención , fechas incorrectas',
      content: datesAlert,
      color: 'rgb(252 , 232 , 239)',
      borderColor :'1px solid #e74c3c',
      icon:'faTriangleExclamation',
      iconColor: 'rgb(161 , 18 , 18)',
      navigateTo : 'navigateTo'
    }

    this.componentToAlertComponentService.sendMessage(alert);
  }

  BuildPatientAlert(alertContent:string , title?:string , color? : string ,
    borderColor ? :string , icon?:string , iconColor?:string , 
    navigateTo ?: string
  ):void{
    const alert : AlertContent = {
      title: title ? title : 'Atención , paciente no encontrado',
      content: alertContent,
      color: color ? color : 'rgb(252 , 232 , 239)',
      borderColor : borderColor ? borderColor : '1px solid #e74c3c',
      icon: icon ? icon : 'faTriangleExclamation',
      iconColor: iconColor ? iconColor : 'rgb(161 , 18 , 18)',
      navigateTo : navigateTo
    }
    this.componentToAlertComponentService.sendMessage(alert);
  }
}

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { ValueChangedEvent } from 'devextreme/ui/html_editor';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AlertContent, AppState, Medicament, UserInfo } from 'src/app/models';
import { MedicamentValidatorsService, PrescriptionTablaService } from 'src/app/prescriptions/services';
import { PrescriptionNewTableMagistralFormulaPopUpHelperServiceService } from 'src/app/prescriptions/services/customs/prescription-new-table-magistral-formula-pop-up-helper-service.service';
import { ComponentToAlertComponentService, FormatDatesService } from '../../services';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas , faXmark , IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { addFavoritesParapharmacies, deleteFavoritesParapharmacies } from 'src/app/store/actions/medicaments/favorites-parafarmacies.actions';

@Component({
  selector: 'app-parapharmacy-medicament-popup',
  templateUrl: './parapharmacy-medicament-popup.component.html',
  styleUrls: ['./parapharmacy-medicament-popup.component.scss']
})

export class ParapharmacyMedicamentPopupComponent implements OnDestroy {


  @Output() CloseParapharmacyPopUp :                                                           EventEmitter<Medicament> = new EventEmitter;
  parapharmaciesFavoritesDestroyed$:                                                           Subject<void> = new Subject<void>();
  professionalDestroyed$:                                                                      Subject<void> = new Subject<void>();
  professional:                                                                                UserInfo = {};
  endDateMin:                                                                                  Date = new Date();
  today:                                                                                       Date = new Date();
  parapharmaciesFavorites:                                                                     Medicament[];
  startDateEditorOptions = {
    disabled: false , min : new Date()
  }

  faXmark:                                                                                     IconDefinition=faXmark;

  endDateEditorOptions = {
    disabled: false , min : new Date()
  }

  AlertaAbierta=                                                                               false;

  parapharmacyMedicament:                                         Medicament = {
    startDate : new Date(),
    endDate: this.formatDatesService.AddDaysToADate(new Date().toString(),7) ,
    status : "DispensableInFuture",
    patientInstructions: 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta',
    posology: '',
    name: ''
  };
  prescriptionIdSubscription:                            Subscription;

  pharmaceuticalForms:                                   {name:string , formId:number}[] = [
    {"name":"Colirio" ,               "formId":1},
    {"name":"Comprimido" ,            "formId":2},
    {"name":"Cápsulas" ,              "formId":3},
    {"name":"Jarabe" ,                "formId":4},
    {"name":"Ampollas bebibles" ,     "formId":5},
    {"name":"Ampollas Inyectables" ,  "formId":6},
  ];

  unit:                                   {name:string , frecuencyAmountId:number}[] = [
    {"name":"Gotas" ,      "frecuencyAmountId":1},
    {"name":"Comprimido" , "frecuencyAmountId":2},
    {"name":"Mililitros" , "frecuencyAmountId":3},
    {"name":"Miligramos" , "frecuencyAmountId":4},
    {"name":"Microgramos" ,"frecuencyAmountId":5},
    {"name":"Ampollas" ,   "frecuencyAmountId":6},
    {"name":"UI" ,         "frecuencyAmountId":7},
    {"name":"Gramos" ,     "frecuencyAmountId":8},
    {"name":"Cápsulas" ,   "frecuencyAmountId":9}
  ];

  frecuencyUnit:                           {name:string , frecuencyUnitId:number}[] = [
    {"name":'Hora' , "frecuencyUnitId":1},
    {"name":'Día' , "frecuencyUnitId":2},
    {"name":'Semana' , "frecuencyUnitId":3},
    {"name":'Mes' , "frecuencyUnitId":4},
  ];

  durationUnit:                           {name:string , durationUnitId:number}[] = [
    {"name":'Día' , "durationUnitId":2},
    {"name":'Semana' , "durationUnitId":3},
    {"name":'Mes' , "durationUnitId":4},
  ];

  pharmaceuticalFormsgroupedData = new DataSource({
    store: new ArrayStore({
      data: this.pharmaceuticalForms,
      key: 'name',
    }),
    group: 'Category',
  });

  unitgroupedData = new DataSource({
    store: new ArrayStore({
      data: this.unit,
      key: 'name',
    }),
    group: 'Category',
  });

  frecuencyUnitData = new DataSource({
    store: new ArrayStore({
      data: this.frecuencyUnit,
      key: 'name',
    }),
    group: 'Category',
  });

  durationUnitData = new DataSource({
    store: new ArrayStore({
      data: this.durationUnit,
      key: 'name',
    }),
    group: 'Category',
  });

  @Output() CloseFormulaMagistralPopUp :                                                         EventEmitter<Medicament> = new EventEmitter;
  @Input()  whoIsParent ? :                                                                      {from:string , parentId?:string , child : string} ;

  constructor(
    private store:                                                                               Store<AppState>,
    private componentToAlertComponentService:                                                    ComponentToAlertComponentService,
    private prescriptionTablaService:                                                            PrescriptionTablaService,
    private prescriptionNewTableMagistralFormulaPopUpHelperServiceService:                       PrescriptionNewTableMagistralFormulaPopUpHelperServiceService,
    private medicamentValidatorsService:                                                         MedicamentValidatorsService,
    private formatDatesService :                                                                 FormatDatesService,
    library:                                                                                     FaIconLibrary
  ){

    library.addIconPacks(fas);
    library.addIcons(faXmark);

    this.prescriptionIdSubscription = this.prescriptionNewTableMagistralFormulaPopUpHelperServiceService.onMessage()
    .subscribe((message:string) => {
      if( message ){
        this.parapharmacyMedicament.prescriptionId = message;
      }
    })
  }

  ngOnInit(): void {
    this.GetFavoritesParapharmacies();
    this.GetProfessionalInfo();
  }

  GetProfessionalInfo():void{
    this.professionalDestroyed$.next();
    
    this.store.select( AppState => AppState.User ).pipe( takeUntil( this.professionalDestroyed$ ) ).subscribe((professional : UserInfo) => {
      
      this.professional = { ...professional };

    })
  }

  GetFavoritesParapharmacies():void{

    this.store.select( AppState => AppState.FavoritesParapharmacy ).pipe( takeUntil( this.parapharmaciesFavoritesDestroyed$ ) ).subscribe(( parapharmaciesFavorites : Medicament [] ) => {

      if( parapharmaciesFavorites.length > 0 ){

        if( parapharmaciesFavorites[0].name !== 'initial_value' ){

  
          this.parapharmaciesFavorites = [ ...parapharmaciesFavorites ];
         
        }

        this.parapharmaciesFavorites = [ ...parapharmaciesFavorites ];

      } else {

        this.parapharmaciesFavorites = [];
      }
    })
  }

  SelectPharmaForm(e:ValueChangedEvent):void{
    const { value } = e || {};
    const pharmaceuticalFormSelected = this.pharmaceuticalForms.filter(( pf : {name:string , formId:number} ) => pf.name === value );
    this.parapharmacyMedicament.pharmaceuticalForm = pharmaceuticalFormSelected[0].name;
  }

  Selectunit(e:ValueChangedEvent):void{
    const { value } = e || {};
    const unitSelected = this.unit.filter(( pf : {name:string , frecuencyAmountId:number} ) => pf.name === value );
    this.parapharmacyMedicament.takingUnit = unitSelected[0].name;
    if(this.parapharmacyMedicament.administrationWay)this.parapharmacyMedicament.administrationWay.name = unitSelected[0].name;
    this.parapharmacyMedicament.dosage = unitSelected[0].name;
  }

  SelectFrecuencyUnit(e:ValueChangedEvent):void{
    const { value } = e || {};
    const frecuencyUnitSelected = this.frecuencyUnit.filter(( pf : {name:string , frecuencyUnitId:number} ) => pf.name === value );
    this.parapharmacyMedicament.frequencyUnit = frecuencyUnitSelected[0].name;

    this.CalculatePackageAmount();
  }

  SelectDurationUnit(e:ValueChangedEvent):void{
    const { value } = e || {};
    const durationUnitSelected = this.durationUnit.filter(( pf : {name:string , durationUnitId:number} ) => pf.name === value );
    this.parapharmacyMedicament.durationUnit = durationUnitSelected[0].name;

    this.CalculatePackageAmount();
  }

  setEndDateDependingDurationUnit = ( rowData : Medicament , value : string , currentRowData:Medicament ) => {

    rowData.durationUnit = value;
    const { durationAmount } = currentRowData || {};
  }

  FormFieldDataChanged(e:FieldDataChangedEvent){

    const { dataField , value } = e || {};

    if( dataField === 'startDate' ){

      this.endDateMin = new Date(value);
      
      if( !this.formatDatesService.VerifyGreaterThan( this.parapharmacyMedicament.startDate , this.parapharmacyMedicament.endDate ) ){
       
        this.parapharmacyMedicament.endDate = this.parapharmacyMedicament.startDate;
      }
    }

    this.CalculatePackageAmount();
  }

  CalculatePackageAmount():void{
    this.medicamentValidatorsService.CalculatePackagesAmountFromForm(this.parapharmacyMedicament);
  }

  RemoveFavoriteParapharmacyFromStore ( favoriteId : string ):void{
    this.store.dispatch( deleteFavoritesParapharmacies({ parapharmacyFavoritedId : favoriteId }) );
  }

  CheckFavorite( favoriteParapharmacy : Medicament ):void{

    if( this.whoIsParent?.from === 'prescription-new-table' ){

      let parapharmacyFavorite : Medicament | undefined = this.parapharmaciesFavorites.find(( mf : Medicament ) => mf.name === favoriteParapharmacy.name );

      if( parapharmacyFavorite && !favoriteParapharmacy.favorite ){

        const { favoriteId }  = parapharmacyFavorite || {};

        if(favoriteId) this.RemoveFavoriteParapharmacyFromStore( favoriteId );
      }

      if( !parapharmacyFavorite && favoriteParapharmacy.favorite ){

        const myFavoriteMasterFormula = { ...favoriteParapharmacy , professionalId : this.professional.professionalLoggedId };
        
        if( myFavoriteMasterFormula )this.StoreParapharmacyFavorite( myFavoriteMasterFormula );
      }
    }
  }

  StoreParapharmacyFavorite( parapharmacyFavorite : Medicament ):void{
    const favoriteParapharmacy = { ...parapharmacyFavorite , professionalId : this.professional.professionalLoggedId };
    this.store.dispatch( addFavoritesParapharmacies({ parapharmacy : favoriteParapharmacy }) );
  }

  SaveParaMedicament():void{

    if( ( this.parapharmacyMedicament.name && this.parapharmacyMedicament.name.length > 3 ) ){

      this.CheckFavorite( this.parapharmacyMedicament );

      this.CloseParapharmacyPopUp.emit(this.parapharmacyMedicament);

      this.RefreshForm();
    } else {

      this.AlertaAbierta=true;

      setTimeout(() => {
        this.AlertaAbierta=false;
      }, 5000);
    }
  }

  RefreshForm():void{
    this.parapharmacyMedicament = {
      startDate : new Date(),
      endDate: this.formatDatesService.AddDaysToADate(new Date().toString(),7) ,
      status : "DispensableInFuture",
      patientInstructions: 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta',
      posology: '',
      name: ''
    }
  }

  OrderCloseAlert():void{
    this.AlertaAbierta=false;
  }

  ngOnDestroy(): void {
    this.parapharmaciesFavoritesDestroyed$.next(); 
    this.parapharmaciesFavoritesDestroyed$.complete(); 
    this.professionalDestroyed$.next();
    this.professionalDestroyed$.complete();
  }
}

import { Clinic, NgrxPrescriptionsRes, Prescription } from '../../../models';
import { createReducer , on } from '@ngrx/store';
import * as prescriptionsActions from '../../actions/prescriptions/prescriptions.actions';
import * as paraprescriptionsActions from '../../actions/paraPrescription/paraPrescriptions.actions';

export interface PrescriptionsState {
    prescriptions : NgrxPrescriptionsRes
}

let initialState : NgrxPrescriptionsRes = {};

export const PrescriptionsReducer = createReducer(
    
    initialState,

    on(prescriptionsActions.getPrescriptionsSuccess , (state , prescriptions  ) => {

        const { items } = prescriptions || {};

        if( items ){

            const formatedPrescritionsWithProperClinic = items.map(( p : Prescription ) => {

                if( p.patient ){

                    if( p.patient.clinics ){

                        const properClinic = p.patient.clinics.filter(( c : Clinic ) => c.type === 'Patient' )[0];

                        return { ...p , defaultClinicName : properClinic ? properClinic.clinicName : p.patient.clinics[0].clinicName };
                    }
                }

                return { ...p };

            })

            return  { ...state , ...prescriptions , items : formatedPrescritionsWithProperClinic } ;
        
        } else{

            return state;
        }
        
    }),

    on(prescriptionsActions.getPrescriptionsFailure , (state , {error}  ) => {

        state = {
            error : error,
            first: 1,
            size: 10,
            totalPages : 0,
            totalCount: 0,
            items: []
        } ;

        return  state;
        
    }),

    on(prescriptionsActions.updatePrescription , (state , { prescription }) => {

        if(state.items){
            const prescriptions = state.items.map(( p ) => p.id === prescription.id ? prescription : p);
        
            return {...state , items : prescriptions};
        }
 
        return state
        
    }),

    on(prescriptionsActions.addLastPrintPropertyToPrescription , ( state , { prescription } ) => {

        if( state.items ){
            return { ...state , items : state.items.map((i : Prescription) => {
                return i.id === prescription.id ? { ...i , lastPrintDate : new Date() } : i
            })};
        }
     
        return state;
        
    })

    , 

    on( prescriptionsActions.modifyPrescriptionStatusToCanceledSuccess , (state , { message }) => {
      
        if(message){
            if( state.items ){
                return { ...state , items : state.items.map((i : Prescription) => {
                    return i.id === message.id ? { ...i , status : 'Canceled' } : i
                })};
            }
            return state;
        } else {
            return state;
        }
    })

    /*********************************** PARAPRESCRIPTIONS ***************************************/
    ,
    on( paraprescriptionsActions.addParaPrescriptionActionSuccess , ( state , { myParaPrescription } ) => {

        if( myParaPrescription ){

            if( state.items ){

                const myParaPrescriptions : Prescription[] = [ myParaPrescription , ...state.items ];

                return { ...state , items : myParaPrescriptions }

            } else {

                const myParaPrescriptions : Prescription[] = [ myParaPrescription ] ;

                return { ...state , items : myParaPrescriptions };

            }

        }else {
            /* case of error return same state */
            return state;
        }

    })

    ,
    on( paraprescriptionsActions.storeIfIsNotInStore , ( state , { paraPrescription } ) => {

        if(state.items?.find((p : Prescription) => p.id === paraPrescription?.id)){

            return state;

        }else {

            if(state.items){

                if(paraPrescription){

                    const myPrescriptions = [ paraPrescription , ...state.items ];

                    return { ...state , items : myPrescriptions };
                }

                return state;

            } else {

                if( paraPrescription ){

                    const myPrescriptions : Prescription[] = [];
                    myPrescriptions.push(paraPrescription);
                    return { ...state , items : myPrescriptions};

                } else {

                    return state;
                }
            }
        }
    })
    
    
)

import { Component , OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentToSpinnerService } from 'src/app/shared/services/customs/component-to-spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnDestroy {
  
  subscription:                         Subscription;
  showSpinner                           = false;
  
  constructor(
    private componentToSpinnerService:  ComponentToSpinnerService
  ){
    this.subscription = this.componentToSpinnerService.onMessage().subscribe((message)=>{

      if(message){

        this.CheckMessage(message);
      }
    })
  }

  CheckMessage(message:string):void{

    message === 'show' ? this.showSpinner = true : this.showSpinner = false;
  }

  ngOnDestroy(): void {
    
    this.subscription.unsubscribe();
  }
}

import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentToDeletePopUpService } from 'src/app/shared/services';

@Component({
  selector: 'app-delete-warning-pop-up-content',
  templateUrl: './delete-warning-pop-up-content.component.html',
  styleUrls: ['./delete-warning-pop-up-content.component.scss']
})

export class DeleteWarningPopUpContentComponent implements OnDestroy {
  
  messageSubscription :                                          Subscription;
  message:                                                       {name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string , kind?:string , prescriptionStatus?:string};
  deleteMessage:                                                 string;

  @Output() CloseDeletePopUp :                                   EventEmitter<string> = new EventEmitter;
  @Output() CloseDeleteProtocolPopUp :                           EventEmitter<string> = new EventEmitter;
  @Output() CloseDeleteIndicationPopUp :                         EventEmitter<string> = new EventEmitter;
  @Output() CloseDeleteDraftPopUp :                              EventEmitter<string> = new EventEmitter;
  @Output() CloseDeleteMasterFormulaPopUp :                      EventEmitter<{name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string , kind?:string , prescriptionStatus?:string}> = new EventEmitter;
  @Output() CloseDeleteParaMedicamentPopUp :                     EventEmitter<{name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string , kind?:string , prescriptionStatus?:string}> = new EventEmitter;
  @Output() CloseDeleteFormalizedPrescriptionPopUp :             EventEmitter<{name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string , kind?:string , prescriptionStatus?:string}> = new EventEmitter;
  /* CloseDeleteDraftPopUp */
  constructor(
    private componentToDeletePopUpService :                      ComponentToDeletePopUpService
  ){
    this.messageSubscription = this.componentToDeletePopUpService.onMessage()
    .subscribe(( message : {id:string,name:string,action:string , composition ? : string , kind?:string , prescriptionStatus? : string} ) => {
      
      if(message){
        this.message = message;
        this.SetMessage();
      }
      
    })
  }

  SetMessage():void{

    if(this.message.reasonId){
      this.deleteMessage = `Desea borrar esta receta formalizada con ${this.message.id} , el paciente no podrá retirar medicamentos en la farmacia`;
    } else{
      this.deleteMessage = `Desea borrar elemento : ${this.message.name ? this.message.name : this.message.id}` ;
    }
    
  }

  ConfirmDelete():void{

    if(this.message.reasonId){
      this.CloseDeleteFormalizedPrescriptionPopUp.emit(this.message);
    } 
    if ( this.message.kind === 'masterFormula' ){
      this.CloseDeleteMasterFormulaPopUp.emit(this.message);
    } 
    if(this.message.kind === 'paraMedicament'){
      this.CloseDeleteParaMedicamentPopUp.emit(this.message);
    } 
    if(this.message.kind === 'medicament'){
      this.CloseDeletePopUp.emit(this.message.id);
    } 
    if(this.message.kind === 'draft'){
      this.CloseDeleteDraftPopUp.emit(this.message.id);
    }
    if(this.message.kind === 'indication'){
      this.CloseDeleteIndicationPopUp.emit(this.message.id);
    }
    if(this.message.kind === 'protocol'){
      this.CloseDeleteProtocolPopUp.emit(this.message.id);
    }
  }

  CancelDelete():void{
    this.CloseDeletePopUp.emit('cancel');
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }
  
}

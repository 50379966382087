import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of , mergeMap } from 'rxjs';
import { catchError , map, switchMap } from 'rxjs/operators';
import * as FavoritesMasterFormulasActions from 'src/app/store/actions/medicaments/favorites-masterFormulas.actions';
import { MedicamentsFavoritesService } from 'src/app/medicaments-favorites/services';
import { HttpErrorResponse } from '@angular/common/http';
import { Medicament } from 'src/app/models';
import { ComponentToSpinnerService } from 'src/app/shared/services';

@Injectable()

export class favoritesMasterFormulasEffects {

    constructor( 
        private action$ : Actions,
        private medicamentsFavoritesService: MedicamentsFavoritesService ,
        private componentToSpinnerService:  ComponentToSpinnerService
    ){}

    loadMedicamentsFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(FavoritesMasterFormulasActions.getFavoritesMasterFormulas),
            switchMap(action =>
                {
                    const { professionalId } = action || {};
                    return this.medicamentsFavoritesService.LoadFavoritesMasterFormulas( professionalId )
                    .pipe(
                        map(medicaments => {
                            return FavoritesMasterFormulasActions.getFavoritesMasterFormulasSuccess ( {medicaments} )
                        }),
                        catchError((error : HttpErrorResponse) => {
                            this.componentToSpinnerService.sendMessage('hide');
                            return of( FavoritesMasterFormulasActions.getFavoritesMasterFormulasFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })

    addMedicamentsFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( FavoritesMasterFormulasActions.addFavoritesMasterFormulas ),
            switchMap(( action ) => {
                
                const { masterFormula } = action || {}

                return this.medicamentsFavoritesService.SaveFavoriteMasterFormula( masterFormula )
                .pipe(
                    map(( data ) =>{
                        if( data.isOk ){
                            const myMasterFormulatWithId = { ...masterFormula , favoriteId : data.id }

                            return FavoritesMasterFormulasActions.addFavoritesMasterFormulasSuccess({ myMasterFormulatWithId  });
                        }else{
                            return FavoritesMasterFormulasActions.addFavoritesMasterFormulasSuccess({  });
                        }
                    }),

                    catchError(error => {
                        this.componentToSpinnerService.sendMessage('hide');
                        return of( FavoritesMasterFormulasActions.addFavoritesMasterFormulasFailure({ error } ) )
                    })
                )
            })
        )
    })

    deleteMedicamentsFavorites$ = createEffect(() =>
        this.action$.pipe(
            ofType( FavoritesMasterFormulasActions.deleteFavoritesMasterFormulas ),
            mergeMap(( action ) => {
                const { masterFormulaFavoritedId } = action;
                return this.medicamentsFavoritesService.DeleteFavoriteMasterFormula( masterFormulaFavoritedId )
                .pipe(
                    map(( data ) =>{
                        if( data.isOk ){
                            return FavoritesMasterFormulasActions.deleteFavoritesMasterFormulasSuccess({ masterFormulaFavoritedId })
                        } else {
                            return FavoritesMasterFormulasActions.deleteFavoritesMasterFormulasSuccess({  })
                        }
                    }),

                    catchError(error => {
                        this.componentToSpinnerService.sendMessage('hide');
                        return of( FavoritesMasterFormulasActions.deleteFavoritesMasterFormulasFailure({ error } ) )
                    })
                )
            })
        )
    );

    updateMedicamentsFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( FavoritesMasterFormulasActions.updateFavoritesMasterFormulas ),
            mergeMap(( action ) => {

                const { masterFormulaUpdate : { 
                    favoriteId , professionalId , composition , dosage , administrationWay , takingAmount , takingUnit , frequencyAmount ,
                     frequencyUnit , durationAmount , durationUnit , narcotic , psychotropic , patientInstructions , pharmacistWarnings ,
                      startDate , endDate , pharmaceuticalForm , packagesAmount } 
                } = action || {};

                const myMasterFormulaToUpdate : Medicament = {
                    favoriteId : favoriteId ,
                    professionalId : professionalId ,
                    composition : composition ,
                    dosage : dosage ,
                    pharmaceuticalForm : pharmaceuticalForm/* action.masterFormulaUpdate.pharmaForm?.name */ ,
                    administrationWay : pharmaceuticalForm ,
                    takingAmount : takingAmount ,
                    takingUnit : takingUnit ,
                    frequencyAmount : frequencyAmount ,
                    frequencyUnit : frequencyUnit ,
                    durationAmount : durationAmount ,
                    durationUnit : durationUnit , 
                    packagesAmount : packagesAmount,
                    narcotic : narcotic ,
                    psychotropic : psychotropic , 
                    patientInstructions : patientInstructions ,
                    pharmacistWarnings : pharmacistWarnings ,
                    startDate : startDate , 
                    endDate : endDate
                }

                return this.medicamentsFavoritesService.UpdateFavoriteMasterFormula( myMasterFormulaToUpdate )
                .pipe(
                    map(( data ) => {
                        if( data.isOk ){
                            return FavoritesMasterFormulasActions.updateFavoritesMasterFormulasSuccess({ myMasterFormulaToUpdate });
                        } else {
                            return FavoritesMasterFormulasActions.updateFavoritesMasterFormulasSuccess({})
                        }
                    }),

                    catchError(error => {
                        this.componentToSpinnerService.sendMessage('hide');
                        return of( FavoritesMasterFormulasActions.updateFavoritesMasterFormulasFailure({ error } ) )
                    })
                )
            })
        )
    })
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap , exhaustMap, map, switchMap } from 'rxjs/operators';
import { 
    getFilteredPrescriptionsAction , getPrescriptionsFailure, getPrescriptionsSuccess, modifyPrescriptionStatusToCanceled, 
    modifyPrescriptionStatusToCanceledSuccess, modifyPrescriptionStatusToFailure 
} from '../../actions/prescriptions/prescriptions.actions';
import { DynPrescriptionsService } from '../../../prescriptions/services/httpRequests/dyn-prescriptions.service';

import { FormToTableService } from 'src/app/prescriptions/services';
import { getPrescriptionsByParams, getPrescriptionsByParamsFailure, getPrescriptionsByParamsSuccess } from '../../actions/prescriptions/prescriptionsPerPatient.actions';
import { NgrxPrescriptionsRes, Prescription } from 'src/app/models';

@Injectable()

export class prescriptionsEffects {

    constructor( 
        private action$ :                   Actions,
        private dynPrescriptionsService :   DynPrescriptionsService,
        private formToTableService:         FormToTableService
    ){}

    loadPrescriptions$ = createEffect(() => {
        this.formToTableService.sendMessageToForm('http_start');
        return this.action$
        .pipe(
            ofType(getFilteredPrescriptionsAction),
            /* exhaustMap */switchMap(action =>
                {
                    // this.componentToSpinnerService.sendMessage('hide');
                    this.formToTableService.sendMessageToForm('http_done');
                    
                    return this.dynPrescriptionsService.GetPrescriptionByEffects(action.parameters)
                    .pipe(
                        map(( prescriptions : NgrxPrescriptionsRes ) => {

                            return getPrescriptionsSuccess ( prescriptions );
                        }),
                        
                        catchError(error => {
                            // this.componentToSpinnerService.sendMessage('hide');
                            return of( getPrescriptionsFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    loadPrescriptionsByParams$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(getPrescriptionsByParams),
            exhaustMap(action =>
                {

                    return this.dynPrescriptionsService.LoadPrescriptionsByPatientId(action.params)
                    .pipe(
                        map( originalPrescriptions => { 
                            const prescriptions = { ...originalPrescriptions , origin : action.params.origin };
                            return getPrescriptionsByParamsSuccess ({ prescriptions })
                        
                        }),
                        
                        catchError(error => {
                            return of( getPrescriptionsByParamsFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    cancelPrescription$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(modifyPrescriptionStatusToCanceled),
            mergeMap(( action ) => {
                const { message } = action || {};


                return this.dynPrescriptionsService.CancelPrescriptionById( message )
                .pipe(
                    map(( data ) => {
                        const { isOk } = data || {};

                        if( isOk ){
                            return modifyPrescriptionStatusToCanceledSuccess({ message });
                        } else {
                            return modifyPrescriptionStatusToCanceledSuccess({});
                        }

                        
                    }),
                    catchError(error => {
                        return of( modifyPrescriptionStatusToFailure({ error : error }) )
                    })
                )
            })
        )
    })

    
}

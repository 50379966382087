<div class="delete-popup_span_container" >

    <span class="delete-popup_span">
        {{ deleteMessage }}
    </span> 
    
</div>

<div class="delete-popup_buttons_container" >

    <div class="delete-popup_sigle_button_container" (click)="ConfirmDelete()" >
        <button class="delete-protocol" >Eliminar</button>
    </div>

    <div class="delete-popup_sigle_button_container" (click)="CancelDelete()" >
        <button class="delete-popup_button" >Cancelar</button>
    </div>

</div>
import { Component ,Output , EventEmitter , OnDestroy } from '@angular/core';
import { faAngleLeft , faAngleRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TableToTablePaginationService } from '../../services/customs/table-to-table-pagination.service';
import { Subscription } from 'rxjs';
import { PaginationParams } from 'src/app/models';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})

export class TablePaginationComponent implements OnDestroy {

  itemsPerPage :                                                     number[] = [2,5,10,15];
  myCurrentPage                                                      = 1;
  faAngleLeft:                                                       IconDefinition = faAngleLeft;
  faAngleRight:                                                      IconDefinition = faAngleRight;
  first                                                              = 1;
  params:                                                            PaginationParams = {size:10 , first:1};
  size                                                               = 10;
  currentPage                                                        = 1;
  quantity                                                           = 10;
  totalElements                                                      = 0;
  totalPages                                                         = 1;
  isLastPage                                                         = false;
  subscription :                                                     Subscription;
  totalElementosSubscription :                                       Subscription;

  @Output() sendParams :                                             EventEmitter<{first:number , size:number}> = new EventEmitter;

  constructor(
    private tableToTablePaginationService :                          TableToTablePaginationService
  ){
    this.subscription = this.tableToTablePaginationService.onMessage().subscribe(message => {
      if(message){
        this.SetQuantity(message);
      }
    })

    this.totalElementosSubscription = this.tableToTablePaginationService.onTotalElementos().subscribe(message=>{
      if(message){
        this.totalElements = message;
        this.CalculateTotalPages();
      }
    })
  }

  NextPage():void{
    this.currentPage++ ;
    this.params.first = this.GetFirst();
    this.SendEvent()
  }

  PreviousPage():void{
    this.currentPage--;
    this.params.first = this.GetFirst();
    this.SendEvent();  
  }

  ChooseQuantityRows(quantity:number):void{
    this.params.size = quantity;
    this.size = quantity;
    if(this.totalElements>0){

      this.totalPages = Math.ceil(this.totalElements / this.size);
      this.CalculateTotalPages();

    }
    this.params.first = this.GetFirst();
    this.SendEvent();
  }

  GetFirst():number{
    
    if(this.currentPage === 0)this.currentPage = 1;
    const currentPage = this.currentPage;
    const size = this.size;
    return ((currentPage - 1) * size) + 1 ;
  }

  SendEvent():void{
    this.sendParams.emit(this.params);
  }

  SetQuantity(page:number):void{
    this.currentPage = page;
  }

  CalculateTotalPages(){

    if(this.size >= this.totalElements){
      this.currentPage = 1;
      this.totalPages = 1;

    }else {
      this.totalPages = Math.ceil(this.totalElements / this.size);
    }
    
    if(this.currentPage === this.totalPages){
      this.isLastPage = true;
    } else if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    } else {
      this.isLastPage = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.totalElementosSubscription.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Indication } from 'src/app/models';

@Injectable()
export class ProtocolToIndicationTableService {

  subject = new Subject<{ id: string , indications: Indication[] }>();
  refreshOrderSubject = new Subject<string>();
 
  SendMessage(paramsToIndication: { id: string , indications: Indication[] }):void{
    this.subject.next(paramsToIndication);
  }

  OnMessage():Observable<{ id: string , indications: Indication[] }>{
    return this.subject.asObservable();
  }

  ClearMessage():void{
    this.subject.next({ id: '' , indications: [] });
  }

  SendRefreshOrder(order: string):void{
    this.refreshOrderSubject.next( order );
  }

  OnRefreshOrder():Observable<string>{
    return this.refreshOrderSubject.asObservable();
  }

  ClearRefreshOrder():void{
    this.refreshOrderSubject.next('');
  }


}

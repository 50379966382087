import { createReducer, on } from "@ngrx/store";
import { Medicament } from "src/app/models";
import * as FavoritesParapharmacyActions from 'src/app/store/actions/medicaments/favorites-parafarmacies.actions';

export interface FavoriteParapharmacyState {
    parapharmacies : Medicament[]
}

const initialState : Medicament[] = [{name:'initial_value'}];

export const FavoritesParapharmacyReducers = createReducer(
    initialState,

    on( FavoritesParapharmacyActions.getFavoritesParapharmaciesSuccess , ( state , {medicaments} ) => {
        state = medicaments;
        return state;
    }),

    on( FavoritesParapharmacyActions.getFavoritesParapharmaciesFailure , ( state , {error} ) => {
        return [];
    }),

    on( FavoritesParapharmacyActions.addFavoritesParapharmaciesSuccess , ( state , {myparapharmacyWithId} ) =>{
        if( myparapharmacyWithId ){
            return [ ...state , myparapharmacyWithId ];
        }else {
            return state;
        }
    }),

    on( FavoritesParapharmacyActions.addFavoritesParapharmaciesFailure , ( state , { error } ) => {
        return state;
    }),

    on( FavoritesParapharmacyActions.deleteFavoritesParapharmaciesSuccess , ( state , { parapharmacyFavoritedId } ) =>{
        if( parapharmacyFavoritedId ){
            return state.filter( ( mf ) => mf.favoriteId !== parapharmacyFavoritedId );
        } else {
            return state;
        }
    }),

    on( FavoritesParapharmacyActions.updateFavoritesParapharmaciesSuccess , ( state , { myParapharmacyToUpdate }) => {
       
        if( myParapharmacyToUpdate ){
            
            const { favoriteId } = myParapharmacyToUpdate || {};

            return state.map(( mf ) => {
                return mf.favoriteId === favoriteId ? myParapharmacyToUpdate : mf;
            });

        } else {
          return state;
        }
    })
)
import { Component , OnInit , OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
/* fontawsome */
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas , faArrowLeft , faEye , faPlus, IconDefinition , faSearch ,faEdit , faTrash , faEnvelope  , faPen , faCircleChevronLeft , faCheck } from '@fortawesome/free-solid-svg-icons';
/* services */
import { 
  ClinicsService , PatientsService ,PrescriptionDownloadHelperService ,
  PrescriptionNewHelperService , PrescriptionNewValidatorsHelperService  ,
  ProfessionalService , PrescriptionNewToPrescriptionsNewTableService , 
  PrescriptionsNewToMedicamentsTemplateService , DynPrescriptionsService, 
  PrescriptionsAlertsService, PrescriptionNewToPatientsPopUpService, ProtocolsService,
  PrescriptionTablaService
} from 'src/app/prescriptions/services/index';
import { 
  CheckUserRoleService ,
  ComponentToAlertComponentService,
  ComponentToDeletePopUpService,
  ComponentToSpinnerService , FormatDatesService 
} from 'src/app/shared/services/index';
/* models */
import { Clinic, /* ClinicsRes, */ DoclineGetRes , Prescription , 
  Patient ,PrescriptionByProfessionalIdPatientIdParams , Professional , 
  PrescriptionDoclineRes, UserInfo , Medicament, AppState, PrescriptionsFilters, Indication, ProtocolParamsToInsert, NgrxPrescriptionsRes, MagickLinkHolded, 
  AlertContent,
} from 'src/app/models';
/* devextrem */
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { MsalService } from '@azure/msal-angular';
import { locale, loadMessages } from "devextreme/localization";
import esMessages from 'devextreme/localization/messages/es.json';
/* ngrx */
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { getPrescriptionsByParams } from 'src/app/store/actions/prescriptions/prescriptionsPerPatient.actions';
import { ValueChangedEvent } from 'devextreme/ui/html_editor';
import { AccountInfo } from '@azure/msal-browser';
import { AuthComponent } from 'src/app/auth/auth.component';
import { ProfessionalClinics } from 'src/app/models/prescriptions/interfaces/user';
import { removeMagicLinkHolded, storeMagicLinkHolded } from 'src/app/store/actions/prescriptions/magickLinkHolded.actions';
import { storeSinglePrescription } from 'src/app/store/actions/prescriptions/prescription.actions';
import { environment } from 'src/environments/environment';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';
import { getFilteredPrescriptionsAction, modifyPrescriptionStatusToCanceled } from 'src/app/store/actions/prescriptions/prescriptions.actions';

@Component({
  selector: 'app-prescription-new',
  templateUrl: './prescription-new.component.html',
  styleUrls: ['./prescription-new.component.scss'],
  providers: [AuthComponent]
})

export class PrescriptionNewComponent implements OnInit , OnDestroy {

  locale                                                     = "";
  prescriptionDraft:                                         Prescription = { patient : { name : '' } , indi:'' };
  patients :                                                 Patient[];
  faArrowLeft:                                               IconDefinition=faArrowLeft;
  faCircleChevronLeft:                                       IconDefinition=faCircleChevronLeft;
  faEnvelope:                                                IconDefinition=faEnvelope;
  faEdit:                                                    IconDefinition=faEdit;
  faTrash:                                                   IconDefinition=faTrash;
  faEye:                                                     IconDefinition=faEye;
  faCheck:                                                   IconDefinition=faCheck;
  faPen:                                                     IconDefinition=faPen;
  faPlus:                                                    IconDefinition=faPlus;
  faSearch:                                                  IconDefinition=faSearch;
  clinics:                                                   Clinic[];
  professionalClinics:                                       ProfessionalClinics[];
  fromUngroupedData:                                         DataSource;
  clinicsfromUngroupedData:                                  DataSource;
  isDraft                                                    = false;
  patientDoclineIsValid                                      = true;
  professionalDoclineIsValid                                 = true;
  protocolsIdsToInsert:                                      string[] | undefined = [];                                       
  isDoctor:                                                  boolean;
  isNurse:                                                   boolean;
  isAdmin:                                                   boolean;
  isDoctorOnCall:                                            boolean;
  alertMessage                                               = "";
  pageTitle:                                                 string;
  prescriptionCreated:                                       boolean;
  professionalLoggedId:                                      string;
  today:                                                     Date ;
  printClientsTablePopUp                                     = false;
  patientIdSubscription:                                     Subscription;
  userDestroyed$:                                            Subject<void> = new Subject<void>();
  userForClinicsDestroyed$:                                  Subject<void> = new Subject<void>();
  prescriptionsPerPatientDestroyed$:                         Subject<void> = new Subject<void>();
  magickLinkHoldedDestroyed$ :                               Subject<void> = new Subject<void>(); 
  medicamentsSelected =                                      false;
  masterFormulasSelected =                                   false;    
  existsMedicamentOrMasterFormula =                          false; 
  printConfirmManualPrescriptionCreatePopUp=                 false;
  resizeSubscription:                                        Subscription;
  windowWidth=                                               0;
  mobileViewBreakPoint=                                      environment.mobileViewBreakPoint;
  printDeletePopUp                                          = false;
  
  
  constructor(
    
    private authComponent :                                  AuthComponent,
    private store:                                           Store<AppState>,
    private prescriptionNewHelperService:                    PrescriptionNewHelperService,
    private route:                                           ActivatedRoute,
    private router:                                          Router,
    private clinicService :                                  ClinicsService,
    private patientsService :                                PatientsService,
    private professionalService :                            ProfessionalService,
    private prescriptionTablaService:                        PrescriptionTablaService,
    private dynPrescriptionsService :                        DynPrescriptionsService,
    private prescriptionNewToPrescriptionsNewTableService:   PrescriptionNewToPrescriptionsNewTableService,
    private prescriptionsNewToMedicamentsTemplateService:    PrescriptionsNewToMedicamentsTemplateService,
    library:                                                 FaIconLibrary,
    private componentToSpinnerService:                       ComponentToSpinnerService,
    private componentToAlertComponentService:                ComponentToAlertComponentService,
    private prescriptionNewValidatorsHelperService:          PrescriptionNewValidatorsHelperService,
    private prescriptionDownloadHelperService:               PrescriptionDownloadHelperService,
    private checkUserRoleService:                            CheckUserRoleService,
    private authService:                                     MsalService,
    private formatDatesService:                              FormatDatesService,
    private prescriptionsAlertsService:                      PrescriptionsAlertsService,
    private prescriptionNewToPatientsPopUpService:           PrescriptionNewToPatientsPopUpService,
    private protocolsService:                                ProtocolsService,
    private windowResizeService :                            WindowResizeService,
    private componentToDeletePopUpService:                   ComponentToDeletePopUpService
  ){
    this.locale = this.getLocale();

    this.initMessages();
    locale(this.locale);

    library.addIconPacks(fas);
    library.addIcons(faArrowLeft);
    library.addIcons(faEye);
    library.addIcons(faPlus);
    library.addIcons(faSearch);

    this.patientIdSubscription = this.prescriptionNewToPatientsPopUpService.OnPatientIdMessage()
    .subscribe(( patient : {id:string , name:string} ) => {
      if(patient){
        const { id , name } = patient;
        this.prescriptionDraft = { ...this.prescriptionDraft , patient : { name : name  , id : id } , patientId : id };
        this.printClientsTablePopUp = false; 
        this.GetPatientInDocline();
      }
    })

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {

      this.windowWidth = width;
    });
  }

  initMessages() {
    loadMessages(esMessages);
  }

  getLocale() {
    return 'es' ;
  }
  
  ngOnInit(): void {
    this.CheckUserRole();
    this.CheckParams();
  }

  CheckParams():void{

    this.route.paramMap.subscribe(params => {

      if(params.get('professionalId')){

        this.prescriptionCreated = false;
        this.pageTitle = 'Crear Receta';
        this.prescriptionDraft.professionalId = params.get('professionalId');
        this.GetClinicByProfessionalId();
        this.BuildNewPrescriptionDraft();
        this.today = new Date();

      }if(params.get('prescriptionId')){

        this.prescriptionCreated = true;
        this.pageTitle = 'Editar Receta';
        this.prescriptionDraft.prescriptionId = params.get('prescriptionId');
        if(params.get('indi'))this.prescriptionDraft.indi = params.get('indi');
        this.GetPrescriptionById();

      }if(params.get('patientId')){

        this.prescriptionDraft.professionalId = params.get('professionalId');
        this.prescriptionDraft.patientId = params.get('patientId');
        this.GetClinicByProfessionalId();
        this.GetDraftByProfessionalIdAndPatientId();

      }if(params.get('clinicCode') && params.get('patientCode') && params.get('userCode') && params.get('professionalCode') ){

        if( !(this.isDoctor || this.isNurse) ){

          this.router.navigate(['/prescriptions']);
        }else{
          
          this.GetDataByOjoParams( params.get('patientCode') , params.get('professionalCode') , params.get('clinicCode') , params.get('userCode') , params.get('indi') );
        }
      }
    });
  }

  CheckUserRole():void{
    const roles = this.checkUserRoleService.CheckUserRole();
    const {isDoctor , isAdmin , isNurse , isDoctorOnCall} = roles || {};
    if( isDoctor )this.isDoctor = isDoctor;
    if( isNurse )this.isNurse = isNurse;
    if( isAdmin )this.isAdmin = isAdmin;
    if( isDoctorOnCall )this.isDoctorOnCall = isDoctorOnCall;
  }

  GetDataByOjoParams(patientOjo : string | null , professionalOjo : string | null , clinicCode : string | null , userCode : string | null , indi ? : string | null ):void{

    const userInfo: UserInfo | AccountInfo = this.authService.instance.getAllAccounts()[0];

    if( !this.CheckRoleByProfessionalOjo( userInfo , professionalOjo ) ){

      this.authComponent.Logout();

    } else {
      
      if( professionalOjo && userCode ){

        this.componentToSpinnerService.sendMessage('show');
        
        this.prescriptionNewHelperService.GetProfessionalByOjoId(professionalOjo , userCode).subscribe((professionalRes:Professional) => {
          
          const { id } = professionalRes;

          if( id ){
    
            this.store.select( AppState => AppState.User ).pipe(takeUntil( this.userDestroyed$ )).subscribe(( user : UserInfo | undefined ) => {
              const { professionalLoggedId } = user || {};

              if(professionalLoggedId ){
                
                if( professionalLoggedId !== "first_assignement" ){

                  this.prescriptionDraft.professionalId = professionalRes.id;
                  this.GetClinicByProfessionalId();
                    
                  if( patientOjo && clinicCode ){
                    this.prescriptionNewHelperService.GetPatientByOjoId(patientOjo , clinicCode)
                    .subscribe((patientRes:Patient) => {
                      if(patientRes){
        
                        if(!this.isDoctor && this.isNurse){
                          this.router.navigate(['/prescriptions' , {patientId:patientRes.id , userCode : userCode , professionalCode : professionalOjo }]);
        
                        } else if ( this.isAdmin && (!this.isDoctor && !this.isNurse) ){
        
                          this.router.navigate(['/prescriptions' , {role:'justAdmin'}]);
        
                        } else {
    
                          if( patientRes.clinics ){
                            if(patientRes.clinics[0]){
                              this.prescriptionDraft.clinicCode =  patientRes.clinic ? patientRes.clinic.areaCode : patientRes.clinics[0].parentClinicCode ;
                              this.prescriptionDraft.clinicName =  patientRes.clinic ? patientRes.clinic.areaName : patientRes.clinics[0].parentClinicName ;
                            }
                          }
                          
                          if(indi)this.prescriptionDraft.indi = indi;
                          this.prescriptionDraft.patientId = patientRes.id;

                          //TODO , ANTES DEL MÉTODO GETPATIENTiNdOCLINE VERIFICAR QUE NO HAY RECETAS EMITIDAS DE  HOY

                          this.store.select( AppState => AppState.MagicLinkHolded ).pipe(takeUntil( this.magickLinkHoldedDestroyed$ )).subscribe(( magickLiknHolded : MagickLinkHolded ) => {

                            if( magickLiknHolded.action === 'initial' ){

                              
                              if(patientRes.id){
                                this.SearchTodayPrescriptionsEmittedOrRetryDraft(patientRes.id , patientOjo , professionalOjo , clinicCode , userCode );
                              }
                              
                            }else if( magickLiknHolded.action === 'remove' ) {

                              // this.store.dispatch(removeMagicLinkHolded({order : 'remove'}));
                              this.GetPatientInDocline();
                            }
                            
                          })

                        }
                      } else {
                        
                        if(this.isNurse){
                          this.router.navigate(['/prescriptions' , {patientId:'patient_not_found' , userCode : userCode , professionalCode : professionalOjo}])
                        } else if ( this.isDoctor ){
                          
                          const alertMessage = `
                            Paciente no encontrado
                          `;
                          this.prescriptionsAlertsService.BuildPrescriptionsAlert(alertMessage);
                        }
                      }
                    },(error: HttpErrorResponse) => {
                      console.log(error);
                    })
                  }

                }
              } else {
                if( patientOjo && clinicCode ){

                  this.prescriptionNewHelperService.GetPatientByOjoId(patientOjo , clinicCode)
                  .subscribe((patientRes:Patient) => {

                    if(patientRes){
                      this.router.navigate(['/prescriptions' , { patientId:patientRes.id }]);
                    }
                  })
                } 
              }
            }) 
          }
        },(error: HttpErrorResponse) => {
          this.componentToSpinnerService.sendMessage('hide');
          console.log(error);
        })
      }
    }
  }

  SearchTodayPrescriptionsEmittedOrRetryDraft(patientId : string , patientOjo : string , professionalOjo : string  , clinicCode : string , userCode : string ):void{
    
    const params : PrescriptionsFilters = {
      from : this.formatDatesService.ReturnCurDateLastYear() ,
      to : this.formatDatesService.AddDaysToADate(new Date().toString(),1),
      patientId : patientId
    }

    this.store.dispatch( getPrescriptionsByParams({ params : params }) );

    this.store.select( AppState => AppState.PrescriptionsPerPatient ).pipe(takeUntil( this.prescriptionsPerPatientDestroyed$ )).subscribe(( prescriptionsPerPatient : NgrxPrescriptionsRes | undefined ) => {
      
      if(prescriptionsPerPatient?.origin !== 'get_prescription_by_id'){
        
        if( prescriptionsPerPatient?.items ){

          const prescriptionsEmittedOrRetryEmission = prescriptionsPerPatient?.items.filter((p) => p.status === 'Emitted' || p.status === 'RetryDraft');
          
          const today = new Date().toISOString().split('T')[0];
  
          const todayPrescriptionsEmittedOrRetryEmission = prescriptionsEmittedOrRetryEmission.filter((p : Prescription) => {
  
            if(p.createdOn){
  
              const createdToday = p.createdOn.split('T')[0];
              return createdToday === today;
  
            } else {
  
              return [];
  
            }
          });
  
          if(todayPrescriptionsEmittedOrRetryEmission.length > 0){
  
            const magickLiknHolded : MagickLinkHolded = {
              action : 'hold',
              userCode : userCode,
              clinicCode : clinicCode,
              professionalCode : professionalOjo,
              patientCode : patientOjo,
              holdingReasong : 'recover_info_from_homepage',
              indi : this.prescriptionDraft.indi ? this.prescriptionDraft.indi : '' 
            }
  
            this.store.dispatch( storeMagicLinkHolded({ magickLinkHolded : magickLiknHolded }) );
  
            this.router.navigate(['/prescriptions' , { patientId:patientId , professionalId : this.prescriptionDraft.professionalId /* , date : 'curDate' */ }]);
          } else {
            this.GetPatientInDocline();
          }
        }
      }
    })
  }

  CheckRoleByProfessionalOjo( userInfo : UserInfo | AccountInfo , professionalOjo: string | null ):boolean{
    /* 
    * si el professionalOjo es '99999' NO es médico entonces 
    * si el role que viene de ojo no es igual al de azure ad logout
    * si no viene el código '9999' y en los roles no tiene el médico tengo que deslogar 
    */


    /******************************** MOCKEO DE VALORES PARA REPRODUCIR ERROR DE ENFERMERA *********************************/
    /* if(userInfo.idTokenClaims?.roles)userInfo.idTokenClaims.roles = undefined;
    professionalOjo = '99999'

    console.log( userInfo ); */
    /******************************** FIN MOCKEO DE VALORES PARA REPRODUCIR ERROR DE ENFERMERA *********************************/

    
    if(  userInfo.idTokenClaims?.roles && userInfo.idTokenClaims?.roles.length > 0 ){

      if( professionalOjo === '99999' ){
        
        return userInfo.idTokenClaims?.roles.find(( r ) => r === 'doctor') ? false : true; 

      } else {
        
        return userInfo.idTokenClaims?.roles.find(( r ) => r === 'doctor') ? true : false;
      }
    } else {
      
      return professionalOjo === '99999'  ;
    }

  }

  SendIdToTemplate():void{

    this.prescriptionsNewToMedicamentsTemplateService.SendMessage(this.prescriptionDraft);
  }

  GetPrescriptionById():void{
    
    if( this.prescriptionDraft.prescriptionId ){

      this.componentToSpinnerService.sendMessage('show');

      this.dynPrescriptionsService.GetPrescriptionById(this.prescriptionDraft.prescriptionId)
      .subscribe((res:Prescription)=>{
        
        if( res ){
          this.prescriptionDraft.id = res.id;
          const { status } = res || {};
          
          if( status==='Draft' ){
  
            this.isDraft=true ;

            const params : PrescriptionsFilters = {
              from : this.formatDatesService.ReturnCurDateLastYear() ,
              to : this.formatDatesService.AddDaysToADate(new Date().toString(),1),
              patientId : res.patient?.id,
              origin:'get_prescription_by_id'
            }
  
            if(this.prescriptionDraft.indi)this.GetProtocolsByIndi();
            
            this.store.dispatch( getPrescriptionsByParams({ params : params }) );
  
          } else {
            this.isDraft=false;
          } 
  
          this.prescriptionDraft = {...res , indi : this.prescriptionDraft.indi};
          this.SendIdToTemplate();
          this.GetClinicByProfessionalId();
          this.BuildPrescriptionView(res);
        }
      })
    }

    
  }

  SendPrescriptionByEmail():void{
    
    const data = this.prescriptionDraft;

    if(this.CheckIfPrescriptionAlreadyPrinted(data)){
      this.componentToSpinnerService.sendMessage('show');
      const { id } = data;
      if(id){
        this.dynPrescriptionsService.SendPrescriptionByEmail(id)
        .subscribe((res:{isOk?:boolean,error?:string})=>{
          if(res){
            const { isOk } = res;
            if(isOk){
              
              const alert : AlertContent = {
                title: 'Receta enviada por email',
                content: 'En breves momentos el paciente recibirá la receta en su correo electrónico',
                color:'rgb(232 , 252 , 239)',
                borderColor : '1px solid rgb(34 , 195 , 89)',
                icon: 'faCircleCheck',
                iconColor:'rgb(161 , 18 , 18)',
                navigateTo : 'navigateTo'
              }
              this.componentToAlertComponentService.sendMessage(alert);
            }
  
            this.componentToSpinnerService.sendMessage('hide');
          }
        })
      }
    }else{
      this.ShowPrintPrescriptionWarning();
    }
  }

  CheckIfPrescriptionAlreadyPrinted(data:Prescription):boolean{
    const { lastPrintDate } = data;
    return lastPrintDate ? true : false;
  }

  ShowPrintPrescriptionWarning():void{

    const alert : AlertContent = {
      title: 'Atención',
      content: 'Es necesario descargar la receta previamente para poder enviarla por email',
      color:'rgb(252 , 244 , 232)',
      borderColor : '1px solid rgb(240 , 183 , 117)',
      icon: 'faTriangleExclamation',
      iconColor:'rgb(161 , 18 , 18)',
      navigateTo : 'navigateTo'
    }

    this.componentToAlertComponentService.sendMessage(alert);
  }

  SendIfMedicamentOrMasterFormulas(existsMedicamentOrMasterFormula : boolean){
    this.existsMedicamentOrMasterFormula = existsMedicamentOrMasterFormula;
  }

  ReciveRefreshOrder(prescriptionId :string){

    this.prescriptionDraft = { ...this.prescriptionDraft , prescriptionId : prescriptionId };

    if(this.prescriptionDraft.indi)this.prescriptionDraft.indi = null;

    this.GetPrescriptionById();
  }

  BuildNewPrescriptionDraft():void{
    this.prescriptionDraft.createdOn = new Date().toISOString().substring(0, 10);
  }

  BuildPrescriptionView(prescription : Prescription):void{
    
    if(prescription.createdOn)this.FormatDate(prescription.createdOn);

    const {
      patient , professional , isConfidential , masterFormulas , medicaments , status , id
    } = prescription;

    

    /* temporalmente si hay más medicamentos quitamos los psycotrópicos en este momento */
    // let medicamentsWithoutNarcoOrPsyco : Medicament[] = [];

    if( medicaments ){
      // medicaments.length > 1 ? medicamentsWithoutNarcoOrPsyco = medicaments.filter(( m ) => !m.psychotropic && !m.narcotic ) : medicamentsWithoutNarcoOrPsyco = medicaments;

      if( patient?.clinics ){
        if(patient.clinics[0]){
          this.prescriptionDraft = { 
            ...this.prescriptionDraft , patientId : patient?.id , 
            patientFirstName : patient?.firstName , 
            patientLastName : patient?.lastName , 
            professionalId : professional?.id , isConfidential : isConfidential ,
            masterFormulas : masterFormulas , medicaments : medicaments /* medicamentsWithoutNarcoOrPsyco */ , 
            status : status , statusTranslated : status ? this.prescriptionTablaService.FormatPrescriptionStatus(status) : "" , clinicCode : patient.clinics[0].clinicCode,     
            areaCode : patient.clinics[0].parentClinicCode , areaName : patient.clinics[0].parentClinicName, 
            clinicName : patient.clinics[0].clinicName, id : id
          }
        }
      }

      this.prescriptionNewToPrescriptionsNewTableService.sendMessage(this.prescriptionDraft);

      //TODO meter el prescriptionDraft en el estado SinglePrescription;
      this.store.dispatch( storeSinglePrescription({ prescription : this.prescriptionDraft }) );
    }

    this.componentToSpinnerService.sendMessage('hide');
  }

  GetProtocolsByIndi():void{

    const { prescriptionDraft : { indi , id , professionalId }  } = this; 
    
    if(indi && id && professionalId){

      this.protocolsService.GetIndicationIdByOjoId( indi , professionalId ).subscribe(( indications : Indication[] ) => {
  
        const protocolsIdArray : string[] = [];
  
        for (let i = 0; i < indications.length; i++) {
          
          const { protocolId } = indications[i] || {};

          if(protocolId)protocolsIdArray.push(protocolId);
        }
  
        const protocolParamsToInsert : ProtocolParamsToInsert = {
          protocolArrayIds : protocolsIdArray,
          prescriptionId :   id
        }

        if( this.protocolsIdsToInsert?.length === 0 ){

          if( protocolsIdArray.length > 0 ){

            this.protocolsService.InsertProtocols( protocolParamsToInsert ).subscribe(( res ) => {

              this.protocolsIdsToInsert = protocolParamsToInsert.protocolArrayIds;

              if(res)this.router.navigate(['prescriptions/new/' , {prescriptionId:id} ]);

            }, (error : HttpErrorResponse) => {

              this.componentToSpinnerService.sendMessage('hide');
            })

          } else {
            this.router.navigate(['prescriptions/new/' , {prescriptionId:id} ]);
          }
        }
      });
    }
    
  }

  FormatDate(date:string):void{
    if(this.formatDatesService.VerifyLessThanToday(date)){
      this.prescriptionDraft.createdOn = new Date(date).toISOString().substring(0, 10);
    }else{
      this.prescriptionDraft.createdOn = new Date().toISOString().substring(0, 10);
    }
  }

  GetClinicByProfessionalId():void{
    // this.componentToSpinnerService.sendMessage('show');
    this.store.select( AppState => AppState.User ).pipe(takeUntil( this.userForClinicsDestroyed$ )).subscribe(( user : UserInfo | undefined ) => {
      const { clinics , professionalClinics } = user || {};

      if(clinics)this.clinics = clinics;

      if(professionalClinics ){
      
        this.professionalClinics = professionalClinics;
      
        if ( this.isDoctorOnCall ){
          const allClinics : ProfessionalClinics = {
            "clinic": {
              "code": "99999",
              "name": "Todas las clínicas",
              "areaCode": "99999",
              "areaName": "Todas las clínicas"
            },
            "collegiateNumber": ""
          }
          
          this.professionalClinics = [ ...this.professionalClinics , allClinics ];
        }
      }

      this.clinicsfromUngroupedData = new DataSource({
        store: new ArrayStore({
          data: this.professionalClinics,
          key: 'clinic.areaCode',
        }),
        group: 'Category',
      });
    })
  }

  SelectClinic(selectedClinic:ValueChangedEvent):void{
    
    const { value } = selectedClinic || {};
    if(!this.prescriptionDraft.patientId) this.prescriptionNewToPatientsPopUpService.SendMessage( value );
    //this.prescriptionNewToPatientsPopUpService.SendMessage( value );
    this.prescriptionDraft = { ...this.prescriptionDraft };

    this.prescriptionDraft.areaCode = value;
  }

  OpenClientsPopUp():void{
    this.printClientsTablePopUp = true;
  }

  GetPatientInDocline():void{

    this.componentToSpinnerService.sendMessage('show');
    if( this.prescriptionDraft.patientId ) {
      const patient : {patientId:string} = {patientId : this.prescriptionDraft.patientId};
      this.patientsService.LoadPatientDocLineByIdPatient(patient)
      .subscribe((res : DoclineGetRes) => {
  
        this.patientDoclineIsValid=true;
        this.CheckPatientInfo(res);
      },(error: HttpErrorResponse) => {
        console.log(error);
        this.componentToSpinnerService.sendMessage('hide');
        const alertMessage = `
          Datos del cliente No válidos , 
          contacte con el departamento de HelpDesk para solucionar esta incidencia
        `;
        this.prescriptionsAlertsService.BuildPrescriptionsAlert(alertMessage);
        this.patientDoclineIsValid=false;
      })
    }
  }

  CheckPatientInfo(patientRes : DoclineGetRes):void{
    const { isValid } = patientRes;
    if( !isValid ){
      this.patientDoclineIsValid = false;
      this.componentToSpinnerService.sendMessage('hide');
      const alertMessage = `
        Datos del cliente No válidos , 
        contacte con el departamento de HelpDesk para solucionar esta incidencia
      `;
      this.prescriptionsAlertsService.BuildPrescriptionsAlert(alertMessage);
    } else {
      this.prescriptionDraft.patient?.name
      this.GetProfessionalInDocline();
      this.patientDoclineIsValid=true
    }
  }

  GetProfessionalInDocline():void{

    if( this.prescriptionDraft.professionalId ){
      
      const professional : {professionalId:string} = {professionalId : this.prescriptionDraft.professionalId};

      this.professionalService.LoadProfessionalDocLineByIdProfessional(professional)
      .subscribe((res: DoclineGetRes)=>{
  
        this.CheckProfessionalInfo(res);
        this.professionalDoclineIsValid=true;
  
      },(error: HttpErrorResponse) => {

        if( error.status === 424 ){

        }

        this.componentToSpinnerService.sendMessage('hide');
        const alertMessage = `
          Datos del Profesional No válidos , 
          contacte con el departamento de HelpDesk para solucionar esta incidencia
        `;
        this.prescriptionsAlertsService.BuildPrescriptionsAlert(alertMessage);
        this.professionalDoclineIsValid=false;
      })
    }
  }

  CheckProfessionalInfo(professionalRes :  DoclineGetRes):void{

    this.GetDraftByProfessionalIdAndPatientId();
    this.professionalDoclineIsValid=true

    /* const { isValid } = professionalRes;
    if( !isValid ){

      this.componentToSpinnerService.sendMessage('hide');
      const alertMessage = `
        Datos del Profesional No válidos , 
        contacte con el departamento de HelpDesk para solucionar esta incidencia
      `;
      this.prescriptionsAlertsService.BuildPrescriptionsAlert(alertMessage);
      this.professionalDoclineIsValid=false;
      
    } else {

      this.GetDraftByProfessionalIdAndPatientId();
      this.professionalDoclineIsValid=true
    } */
  }
  
  GetDraftByProfessionalIdAndPatientId():void{

    if(!this.prescriptionDraft.prescriptionId){

      const params : PrescriptionByProfessionalIdPatientIdParams={
        professionalId : this.prescriptionDraft.professionalId,
        patientId: this.prescriptionDraft.patientId
      };

      this.dynPrescriptionsService.LoadLastDraftByProfessionalIdAndPatientId(params)
      .subscribe((res:Prescription)=>{
      
        if(res){

          const { id } = res;
         
          if( this.prescriptionDraft.indi ){

            this.prescriptionDraft.id = id;
            this.GetProtocolsByIndi();
          } else {
            this.router.navigate(['prescriptions/new/' , { prescriptionId:id } ]);
          }
        } else{
          
          this.CreateDraftPrescription();//TODO meter la nueva receta en el estado SinglePrescription
        }
      },(error: HttpErrorResponse) => {
        console.log(error);
        this.componentToSpinnerService.sendMessage('hide');
      })
    }
  }

  BackToPrescriptions():void{

    const { prescriptionDraft : { patientId } } = this || {};

    patientId ? this.router.navigate(['prescriptions' , { patientId:patientId }]) : this.router.navigate(['prescriptions']) ;
  }

  CreateDraftPrescription():void{
    this.prescriptionDraft = { ...this.prescriptionDraft ,
      "id": '',
      "professionalId": this.prescriptionDraft.professionalId,
      "patientId": this.prescriptionDraft.patientId,
      "clinicName": this.prescriptionDraft.clinicName,
      "areaName":   this.prescriptionDraft.areaName,
      "areaCode": this.prescriptionDraft.areaCode,
      "isConfidential": true,
      "medicaments": [] ,
      "masterFormulas": [],
      "isDraft": true
    }
    this.SaveChanges();
  }

  SaveChanges():void{
    this.dynPrescriptionsService.CreatePrescriptionDraft(this.prescriptionDraft)
    .subscribe((res:{id?:string})=>{

      const {id} = res;
      this.componentToSpinnerService.sendMessage('hide');
      if(this.prescriptionDraft.indi){

        this.router.navigate(['prescriptions/new/' , {prescriptionId:id , indi:this.prescriptionDraft.indi} ]);
      } else {
        this.router.navigate(['prescriptions/new/' , {prescriptionId:id} ]);
      }

    },(error: HttpErrorResponse) => {

      this.componentToSpinnerService.sendMessage('hide');
      const { error:{errors} } = error;

      if(errors[""][0] === 'A draft prescription already exists for the same patient and professional'){
        const alertMessage = `Ya existe una prescripcción de este doctor para este paciente`;
        this.prescriptionsAlertsService.BuildPrescriptionsAlert(alertMessage);
      }
    })
  }

  CheckProfessionalCollegiateNumberIsValid( ):void{

    this.componentToSpinnerService.sendMessage('show');

    if(this.prescriptionDraft.professional?.id){

      this.professionalService.CheckProfessinalCollegiateNumberIsValid( /* '06d351c7-2996-43e2-95b4-0a7955673d40' */ this.prescriptionDraft.professional?.id ).subscribe((res : {valid:boolean}) => {

        const { valid } = res || {};

        if( valid ){//TODO CORREGIR CONDICIÓN DESPUÉS DE LOS TESTS

          this.FormalizePrescription();
        
        } else {

          /* creamos el popup  */
          
          this.componentToSpinnerService.sendMessage('hide');
          this.printConfirmManualPrescriptionCreatePopUp = true;
        }

      },(error : HttpErrorResponse) => {

        const { error : { InternalCode } } = error || {};

        const title = 'Error!';

        let alertContent : string = "";

        if(InternalCode === 'RESOURCE_NOT_FOUND'){

          alertContent = "Profesional no encontrado en por Docline";

        }

        this.componentToSpinnerService.sendMessage('hide');

        this.prescriptionsAlertsService.BuildPrescriptionsAlert(alertContent , title);

      })
    }
  }

  FormalizePrescription():void{

    if( this.prescriptionDraft.id ){

      const prescriptionIdObject : {prescriptionId:string} = {prescriptionId:this.prescriptionDraft.id};
      
      const wrongMedicamentsDate : Medicament[] | undefined  = this.prescriptionNewValidatorsHelperService.CheckMedicamentsStartDateLessThanToday(this.prescriptionDraft);
      
      if(wrongMedicamentsDate){
        
        if(wrongMedicamentsDate.length < 1){ 
          
          this.dynPrescriptionsService.FormalizePrescription(prescriptionIdObject).subscribe((res:PrescriptionDoclineRes)=>{
            
            this.componentToSpinnerService.sendMessage('hide');

            if(res){
            
              const { prescriptionDraft : { patientId } } = this || {};

              patientId ? this.router.navigate(['prescriptions' , { patientId:patientId }]) : this.router.navigate(['prescriptions']) ;
            }
            
          },(error: HttpErrorResponse) => {

            this.componentToSpinnerService.sendMessage('hide');

            const title = 'Error!';

            let alertContent : string = ""; 

            const { error : { InternalCode } } = error || {};

            if( InternalCode && InternalCode === "DOCLINE_API_INVALID_COLLEGIATE_NUMBER" ){

              alertContent = 'Error Doclinee : Número de colegiado inválido';

              this.prescriptionsAlertsService.BuildPrescriptionsAlert(alertContent , title);

            } else {

              this.router.navigate(['prescriptions']);
            }
          })
        } else {

          this.componentToSpinnerService.sendMessage('hide');

          this.prescriptionsAlertsService.BuildMedicamentsDatesAlert(wrongMedicamentsDate);
        }
      }
    }
  }

  CancelCreateManualPrescription():void{
    this.printConfirmManualPrescriptionCreatePopUp = false;
  }

  CreateManualPrescription():void{

    this.printConfirmManualPrescriptionCreatePopUp = false

    this.componentToSpinnerService.sendMessage('show');

    if(this.prescriptionDraft.id){

      this.dynPrescriptionsService.CreatePrescriptionManual( this.prescriptionDraft.id ).subscribe((res: {StatusCode: number,Message: string,InternalCode: string}) => {

        this.componentToSpinnerService.sendMessage('hide');
        
        if(res)this.router.navigate(['prescriptions']);
      });
    }
  }

  DeletePrescription():void{

    const { prescriptionDraft : { id , status , doclineId } } = this || {};

    if( ( status === 'Draft' || status === 'Manual' || status === 'MasterFormula' ) && id )
    {

      const deleteMessage = {name:'',id:id, action:'open' , kind:'draft'} ;
      this.componentToDeletePopUpService.sendMessage(deleteMessage);
      this.printDeletePopUp = true;
    
    } else if ( ( status === 'Emitida' || status === 'Emitted' ) && id && doclineId ){

      const deleteMessage = {name:'',id:id, action:'open' , prescriptionDoclineId : doclineId , reasonId : '8' , comment : 'Otro motivo' , prescriptionStatus : status  } ;
      this.componentToDeletePopUpService.sendMessage(deleteMessage);
      this.printDeletePopUp = true;
    }
  }

  CloseDeleteDraftPopUp(message:string):void{

    this.printDeletePopUp = false;
    if( message !== 'cancel' ){
      this.ConfirmDeleteDraft( message );
    }
  }

  CloseDeletePopUp(message:string):void{
    this.printDeletePopUp = false;
  }

  ConfirmDeleteDraft(prescriptionId : string):void{
    this.dynPrescriptionsService.DeletePrescriptionById(prescriptionId)
    .subscribe((res:boolean)=>{
      if(res){
        this.router.navigate(['/prescriptions']);
      } 
    },(error: HttpErrorResponse) => {
      console.log(error);
      this.printDeletePopUp = false;
    })
  }

  CloseDeleteFormalizedPrescriptionPopUp(message : {prescriptionDoclineId : string , reasonId : string , comment : string , id:string , prescriptionStatus?:string}):void{

    const paramsToCancelPrescription : { id : string , prescriptionDoclineId : string , reasonId : string , comment : string , prescriptionStatus? : string } = {
      prescriptionDoclineId : message.prescriptionDoclineId , reasonId : '8' , comment : 'Otro motivo' , id : message.id ,prescriptionStatus : message.prescriptionStatus
    }

    this.printDeletePopUp = false;

    this.componentToSpinnerService.sendMessage('show');

    this.store.dispatch( modifyPrescriptionStatusToCanceled({ message : paramsToCancelPrescription }) );

    setTimeout(() => {

      this.router.navigate(['/prescriptions']);

    }, 3000);
  } 

  OrderDownloadPrescription():void{
    this.prescriptionDownloadHelperService.DownloadPrescription(this.prescriptionDraft);
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
    this.patientIdSubscription.unsubscribe();
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
    this.userForClinicsDestroyed$.next();
    this.userForClinicsDestroyed$.complete();
    this.prescriptionsPerPatientDestroyed$.next;
    this.prescriptionsPerPatientDestroyed$.complete();
    this.magickLinkHoldedDestroyed$.next();
    this.magickLinkHoldedDestroyed$.complete();
  }
}


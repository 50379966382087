import { createReducer, on } from "@ngrx/store";
import { MagickLinkHolded } from "src/app/models";
import * as MagickHoldedActions from 'src/app/store/actions/prescriptions/magickLinkHolded.actions';

export interface MagickLinkHoldedState {
    magickLinkHolded : MagickLinkHolded
}

const initialState : MagickLinkHolded = new MagickLinkHolded();

export const MagickLinkHoldedReducers = createReducer(
    initialState,

    on( MagickHoldedActions.storeMagicLinkHolded , ( state , {magickLinkHolded} ) => {
       state = magickLinkHolded;
       return state;
    }),

    on( MagickHoldedActions.removeMagicLinkHolded , ( state , {order} ) => {
        if(order === 'remove'){
            return { ...new MagickLinkHolded() , action : 'remove' };
        } else {
            return state;
        }
        
     })
)
import { HttpErrorResponse } from "@angular/common/http"
import { createAction, props } from "@ngrx/store"
import { Medicament } from "src/app/models"

export const getFavoritesMasterFormulas = createAction(
    '[Favorites MasterFormulas] Get favorites masterFormulas',
    props<{ professionalId : string | null }>()
)

export const getFavoritesMasterFormulasSuccess = createAction(
    '[Favorites MasterFormulas] Get favorites masterFormulas success',
    props<{ medicaments : Medicament[] }>()  
)

export const getFavoritesMasterFormulasFailure = createAction(
    '[Favorites MasterFormulas] Get favorites MasterFormulas failure',
    props<{ error : HttpErrorResponse }>()  
)

export const addFavoritesMasterFormulas = createAction(
    '[Favorites MasterFormulas] add favorite masterFormula' , 
    props<{ masterFormula : Medicament }>()
);

export const addFavoritesMasterFormulasSuccess = createAction(
    '[Favorites MasterFormulas] add favorite masterFormula success',
    props<{ myMasterFormulatWithId ? : Medicament }>()
)

export const addFavoritesMasterFormulasFailure = createAction(
    '[Favorites MasterFormulas] add favorite masterFormula failure',
    props<{ error : HttpErrorResponse }>()
);

export const deleteFavoritesMasterFormulas = createAction(
    '[Favorites MasterFormulas] delete favorite masterFormula' , 
    props<{ masterFormulaFavoritedId : string }>()
);

export const deleteFavoritesMasterFormulasSuccess = createAction(
    '[Favorites MasterFormulas] delete favorite masterFormula success',
    props<{ masterFormulaFavoritedId ? : string }>()
)

export const deleteFavoritesMasterFormulasFailure = createAction(
    '[Favorites MasterFormulas] delete favorite masterFormula failure',
    props<{ error : HttpErrorResponse }>()
);

export const updateFavoritesMasterFormulas = createAction(
    '[Favorites MasterFormulas] update favorite masterFormula' , 
    props<{ masterFormulaUpdate : Medicament }>()
);

export const updateFavoritesMasterFormulasSuccess = createAction(
    '[Favorites MasterFormulas] update favorite masterFormula success',
    props<{ myMasterFormulaToUpdate ? : Medicament }>()
)

export const updateFavoritesMasterFormulasFailure = createAction(
    '[Favorites MasterFormulas] update favorite masterFormula failure',
    props<{ error : HttpErrorResponse }>()
);
import { Component, OnDestroy, OnInit } from '@angular/core';
/* fontawsome */
import { IconDefinition, faEye, fas } from '@fortawesome/free-solid-svg-icons'
import { FaIconLibrary  } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/models';
import { cleanSinglePrescriptionState } from 'src/app/store/actions/prescriptions/prescription.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss']
})

export class PrescriptionsComponent implements OnInit , OnDestroy {

  faEye:                                                    IconDefinition = faEye;
  resizeSubscription:                                       Subscription;
  windowWidth=                                              0;
  mobileViewBreakPoint=                                     environment.mobileViewBreakPoint;
  
  constructor(
    library:                                                FaIconLibrary,  
    private windowResizeService :                           WindowResizeService,
    private store:                                          Store<AppState>
  ){
    library.addIconPacks(fas);
    library.addIcons(faEye);

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {
      
      this.windowWidth = width;

    });
  }

  ngOnInit(): void {
    
    this.CleanSinglePrescriptionState();
  }

  CleanSinglePrescriptionState():void{

    this.store.dispatch( cleanSinglePrescriptionState({ order : 'clean' }) );
  }

  ngOnDestroy(): void {
    
    this.resizeSubscription.unsubscribe();
  }
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Indication } from 'src/app/models';
import { IndicationParams, IndicationsRes } from 'src/app/models/prescriptions/interfaces/indications';

@Injectable()

export class IndicationsService {

  constructor(
    private http :                                        HttpClient
  ) { }

  LoadIndicationsFamilies(){
    return this.http.get(`${environment.masterDataService.url}indicationfamily`);
  }

  LoadIndications( indicationsParams ? : IndicationParams ):Observable<IndicationsRes>{

    const url = `${environment.prescriptionsService.url}protocol/indication/by-filter`;
    const builtUrl = indicationsParams ? this.BuildUrlIndications(url , indicationsParams) : url;

    return this.http.get<IndicationsRes>(`${builtUrl}`);
  }

  AddProtocolToIndication( indicationProtocolBody : Indication ):Observable<boolean>{
    return this.http.post<boolean>(`${environment.prescriptionsService.url}protocol/indication` , indicationProtocolBody);
  }

  RemoveIndication( indicationId : string , protocolId : string ):Observable<boolean>{
    return this.http.delete<boolean>(`${environment.prescriptionsService.url}protocol/indication?indicationId=${indicationId}&ProtocolId=${protocolId}`);
  }

  BuildUrlIndications(url :string , params : IndicationParams):string{
    /* if( Object.values( params ).length > 0 ) */url += `?`;

    const { 
      filter , first , size , filterFamily
    } = params;

    if(filter)url += `filter=${filter}&`;
    if(first){
      url += `first=${first}&`;
    }else {
      url += `first=1&`;
    }
    if(size){
      url += `size=${size}&`;
    }else {
      url += `size=10&`;
    }
    if(filterFamily)url += `filterFamily=${filterFamily}&`;
    

    if(url.slice(-1) === '&')url = url.replace(/.$/,"");
    return url;
  }
}

<div class="mobile_prescriptions_filers-main_container" >

    <div class="mobile_prescriptions_filers-banner_container">
        
        <div>

            <span class="mobile_prescriptions_filers-banner_span"> Recetas </span>
        
        </div>

        <div class="mobile_prescriptions_filers-filters_container">

            <div 
                [ngClass]="{ 
                    'border-blue' : prescriptionsFilters.patientFilter || 
                    prescriptionsFilters.professionalFilter || 
                    prescriptionsFilters.from || 
                    prescriptionsFilters.to 
                }" 
                (click)="OpenMobileFiltersPopUp()" 
                class="mobile_prescriptions_filers-icon_container"
            >
                <fa-icon [icon]="faFilter"></fa-icon>
            </div>

            <div *ngIf="showNewPrescriptionButton" (click)="GoToPrescriptionNew()" class="mobile_prescriptions_filers-icon_container">
                <fa-icon [icon]="faPlus"></fa-icon>
            </div>

        </div>

        <dx-popup
            height="600px"
            minWidth="300px"
            [(visible)]="printMobilePrescriptionsPopUp"
            [hideOnOutsideClick]="true"
        >

            <dxi-toolbar-item
                text="Filtrar recetas"
                location="before">
            </dxi-toolbar-item>

            <app-mobile-prescriptions-filters-pop-up></app-mobile-prescriptions-filters-pop-up>
        </dx-popup>

    </div>

</div>

import { Component , enableProdMode , OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RowDblClickEvent } from 'devextreme/ui/tree_list';
/* fontawesome */
import { faFile, faEnvelope , faPen, fas, faTrash , faReply,
  IconDefinition ,faDownload , faEye , faArrowsRotate , faRepeat
} from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary  } from '@fortawesome/angular-fontawesome';
/* services */
import { 
  DynPrescriptionsService , FormToTableService , HandleHTTPerrorsService, PrescriptionDownloadHelperService , PrescriptionTablaService
} from 'src/app/prescriptions/services/index';
import { 
  TableToTablePaginationService , ComponentToSpinnerService, 
  CheckUserRoleService, FormatDatesService , 
  ComponentToDeletePopUpService, ComponentToAlertComponentService
} from 'src/app/shared/services/index';
/* rxjs */
import { map, takeUntil } from 'rxjs/operators';
/* models */
import { 
  PaginationParams, Prescription , PrescriptionsFilters , AlertContent , Medicament
} from 'src/app/models';
/* ngrx */
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/models/prescriptions/interfaces/appState';
import { getFilteredPrescriptionsAction, modifyPrescriptionStatusToCanceled } from 'src/app/store/actions/prescriptions/prescriptions.actions';
import { modifyPrescriptionsFilters } from 'src/app/store/actions/prescriptions/prescriptionsFilters.actions';
import { Subject, Subscription } from 'rxjs';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { CellClickEvent } from 'devextreme/ui/data_grid';
import { MedicamentsFormalHelperService } from '../../services/helpers/medicaments/medicaments-formal-helper.service';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';
import { environment } from 'src/environments/environment';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

@Component({
  selector: 'app-prescriptions-table',
  templateUrl: './prescriptions-table.component.html',
  styleUrls: ['./prescriptions-table.component.scss']
})
export class PrescriptionsTableComponent implements OnInit , OnDestroy {

  popupPosition/* :                                            {of: Window & typeof globalThis; at: string; my: string; offset: { y: number; }; }; */;
  prescriptions :                                           Prescription[] ;
  medicaments :                                             Medicament[] = [];
  isDoctor:                                                 boolean;
  isNurse:                                                  boolean;
  isAdmin:                                                  boolean;
  faFile:                                                   IconDefinition=faFile;
  faReply:                                                  IconDefinition=faReply;
  faRepeat:                                                 IconDefinition=faRepeat;
  faArrowsRotate:                                           IconDefinition=faArrowsRotate;
  faPen:                                                    IconDefinition=faPen;
  faTrash:                                                  IconDefinition=faTrash;
  faEnvelope:                                               IconDefinition=faEnvelope;
  faDownload:                                               IconDefinition=faDownload;
  faEye:                                                    IconDefinition = faEye;
  patientNotFoundSubscription:                              Subscription;
  printDeletePopUp                                          = false;
  prescriptionToDeleteId :                                  string;
  professionalLoggedId:                                     string;
  prescriptionsFiltersDestroyed$:                           Subject<void> = new Subject<void>();
  prescriptionFiltersDestroyed$:                            Subject<void> = new Subject<void>();
  reemitPrescriptionConfirmPopUpVisible=                    false;
  reemitPrescription :                                      Prescription;
  prescriptionsFilters :                                    PrescriptionsFilters = {
    Filter:'',
    patientId:'',
    professionalId:'',
    first:1,
    size:10 ,
    from : ''
  };
  
  showFilterRow:                                            boolean;
  currentFilter                                             = "";
  applyFilterTypes:                                         {key:string , name:string}[];
  showHeaderFilter:                                         boolean;
  pdfPreviewName:                                           string;
  resizeSubscription:                                       Subscription;
  windowWidth=                                              0;
  mobileViewBreakPoint=                                     environment.mobileViewBreakPoint;

  @ViewChild('pdfViewerOnDemand')                           pdfViewerOnDemand:PdfJsViewerComponent;
  @ViewChild('pdfViewerAutoLoad')                           pdfViewerAutoLoad:PdfJsViewerComponent;

  constructor(
    private store:                                          Store<AppState>,
    private http:                                           HttpClient,
    library:                                                FaIconLibrary,
    private dynPrescriptionsService :                       DynPrescriptionsService,
    private formToTableService :                            FormToTableService,
    private tableToTablePaginationService :                 TableToTablePaginationService,
    private router:                                         Router,
    private prescriptionDownloadHelperService:              PrescriptionDownloadHelperService,
    private componentToSpinnerService:                      ComponentToSpinnerService,
    private checkUserRoleService:                           CheckUserRoleService,
    private PrescriptionTablaService:                       PrescriptionTablaService,
    private formatDatesService:                             FormatDatesService,
    private componentToDeletePopUpService :                 ComponentToDeletePopUpService,
    private componentToAlertComponentService:               ComponentToAlertComponentService,
    private handleHTTPerrorsService:                        HandleHTTPerrorsService,
    private medicamentsFormalHelperService:                 MedicamentsFormalHelperService,
    private windowResizeService :                           WindowResizeService
  ){

    this.patientNotFoundSubscription = this.formToTableService
    .onMessagePatientNotFound().subscribe((message : string) => {
      if(message){
        if(message === 'patient_not_found'){
          const alert : AlertContent = {
            title: 'Atención',
            content: 'Paciente no encontrado , contacte con el departamento de Help Desk para solucionar esta incidencia',
            color:'rgb(252 , 244 , 232)',
            borderColor : '1px solid rgb(240 , 183 , 117)',
            icon: 'faTriangleExclamation',
            iconColor:'rgb(161 , 18 , 18)',
            navigateTo : 'navigateTo'
          }
          this.componentToAlertComponentService.sendMessage(alert);
        }
      }
    })

    library.addIconPacks(fas);
    library.addIcons(faFile);
    library.addIcons(faRepeat);
    library.addIcons(faPen);
    library.addIcons(faTrash);
    library.addIcons(faArrowsRotate);
    library.addIcons(faEnvelope);
    library.addIcons(faDownload);
    library.addIcons(faEye);
    library.addIcons(faReply);

    this.applyFilterTypes = [
      {
        key: 'auto',
        name: 'Immediately',
      }, 
      {
        key: 'onClick',
        name: 'On Button Click',
      }
    ];

    this.currentFilter = this.applyFilterTypes[0].key;
    
    this.popupPosition = {
      of: window, at: 'top', my: 'top', offset: { y: 10 },
    };

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {

      this.windowWidth = width;

      this.showFilterRow = this.windowWidth < this.mobileViewBreakPoint ? false : true;
      
      this.showHeaderFilter = this.windowWidth < this.mobileViewBreakPoint ? false : true;
    });
  }

  ngOnInit(): void {
    this.CheckUserRole();
    this.StorePrescriptions();
    this.GetPrescriptions();
  }

  CheckUserRole():void{
    const roles = this.checkUserRoleService.CheckUserRole();
    const {isDoctor , isAdmin , isNurse} = roles || {};
    if( isDoctor )this.isDoctor = isDoctor;
    if( isNurse )this.isNurse = isNurse;
    if( isAdmin )this.isAdmin = isAdmin;
  }

  StorePrescriptions():void{
    this.store.select( AppState => AppState.PrescriptionsFilters ).pipe(takeUntil( this.prescriptionsFiltersDestroyed$ )).subscribe( prescriptionsFilters => {

      this.prescriptionsFilters = { ...this.prescriptionsFilters , ...prescriptionsFilters};
      this.formToTableService.sendMessageToForm('http_start');
      if(prescriptionsFilters){
        
        this.store.dispatch(getFilteredPrescriptionsAction({ parameters: prescriptionsFilters }))
      }
    })
  }

  GetPrescriptions():void{
    this.store.select( AppState => AppState.Prescription ).pipe(takeUntil( this.prescriptionFiltersDestroyed$ )).subscribe( prescriptions => {
      
      const myPrescriptions = { ...prescriptions };
      
      const { items , totalCount , error } = myPrescriptions || {};
      
      if(error){
        //this.CreateDoclineErrorAlert()
        this.handleHTTPerrorsService.HandleHTTPerrors( error );
      }

      if(items){

        console.log( items );

        this.OrderPrescriptions( items );
        this.SendTotalElements( totalCount );
      }
    })
  }

  OrderPrescriptions(original_prescriptions : Prescription[]):void{
    /* 
    *  para cumplir con el formato de master detail de devextre 
    *  este método separa en 2 arrays distintos las prescriptions 
    *  y los medicamentos uniendo ambos por un id para relacionarlos 
    *  por último elimina el array de medicamentos dentro de la receta
    */

    const newMedicamentsArr : Medicament[] = [];
    
    const prescriptions = original_prescriptions.map(( p : Prescription ) => {

      const myPrescription = {...p}
      const { id , status , createdOn /* , professional:{firstName , lastName} */} = myPrescription ;
      if(status){

        myPrescription.status = this.PrescriptionTablaService.FormatPrescriptionStatus( status /* 'RetryDraft' */);
      
      }
      
      if(createdOn)myPrescription.createdOn = this.formatDatesService.FormatPrescriptionDateDayMonthYear(createdOn);

      if( myPrescription.professional?.firstName && myPrescription.professional?.firstName ){
        const {professional:{firstName , lastName} } = myPrescription;
       myPrescription.Doctor = `${firstName} ${lastName}`;
      }

      const { medicaments , masterFormulas , parapharmacyMedicaments } = myPrescription;

      if(medicaments){

        for (let i = 0; i < medicaments.length; i++) {

          const myMedicament = { ...medicaments[i] };
          myMedicament.id_medicament = id;
  
          const status = myMedicament.status;
          const frecuencyUnit = myMedicament.frequencyUnit;
          const durationUnit = myMedicament.durationUnit;
  
          if( status )myMedicament.status = this.PrescriptionTablaService.TranslateMedicamentStatus(status);
          if( frecuencyUnit )myMedicament.frequencyUnit = this.PrescriptionTablaService.TranslateMedicamentsFrequencyUnit(frecuencyUnit);
          if( durationUnit )myMedicament.durationUnit = this.PrescriptionTablaService.TranslateMedicamentsFrequencyUnit(durationUnit);
          newMedicamentsArr.push( myMedicament );
        }
      } 
      
      if (masterFormulas){
        for (let i = 0; i < masterFormulas.length; i++) {

          const myMasterFormula = { 
            ...masterFormulas[i] , 
            name : masterFormulas[i].composition , 
            fullName : masterFormulas[i].composition,
            unit : { name : masterFormulas[i].administrationWay , id : 0 },
            pharmaForm : { name : masterFormulas[i].pharmaceuticalForm , id : 0 }
          };
          
          myMasterFormula.id_medicament = id;
          const status = myMasterFormula.status;
          const frecuencyUnit = myMasterFormula.frequencyUnit;
          const durationUnit = myMasterFormula.durationUnit;
  
          if( status )myMasterFormula.status = this.PrescriptionTablaService.TranslateMedicamentStatus(status);
          if( frecuencyUnit )myMasterFormula.frequencyUnit = this.PrescriptionTablaService.TranslateMedicamentsFrequencyUnit(frecuencyUnit);
          if( durationUnit )myMasterFormula.durationUnit = this.PrescriptionTablaService.TranslateMedicamentsFrequencyUnit(durationUnit);
          newMedicamentsArr.push( myMasterFormula );
        }
      }

      if (parapharmacyMedicaments){
        for (let i = 0; i < parapharmacyMedicaments.length; i++) {

          const myParapharmacyMedicaments = { 
            ...parapharmacyMedicaments[i] , 
            name : parapharmacyMedicaments[i].name , 
            fullName : parapharmacyMedicaments[i].name,
            unit : { name : parapharmacyMedicaments[i].administrationWay , id : 0 },
            pharmaForm : { name : parapharmacyMedicaments[i].pharmaceuticalForm , id : 0 } 
          };
          
          myParapharmacyMedicaments.id_medicament = id;
          const status = myParapharmacyMedicaments.status;
          const frecuencyUnit = myParapharmacyMedicaments.frequencyUnit;
          const durationUnit = myParapharmacyMedicaments.durationUnit;
  
          if( status )myParapharmacyMedicaments.status = this.PrescriptionTablaService.TranslateMedicamentStatus(status);
          if( frecuencyUnit )myParapharmacyMedicaments.frequencyUnit = this.PrescriptionTablaService.TranslateMedicamentsFrequencyUnit(frecuencyUnit);
          if( durationUnit )myParapharmacyMedicaments.durationUnit = this.PrescriptionTablaService.TranslateMedicamentsFrequencyUnit(durationUnit);
          newMedicamentsArr.push( myParapharmacyMedicaments );
        }
      }
      const prescription_clone = (({ medicaments , ...o }) => o)(myPrescription);

      return prescription_clone;
    })

    this.componentToSpinnerService.sendMessage('hide');

    this.formToTableService.sendMessageToForm('http_done');
    /* rellenamos el campo posology */

    this.medicaments =  this.medicamentsFormalHelperService.BuildPosologyField( newMedicamentsArr ); //merge de medicamentos y master-formulas
    
    this.prescriptions=prescriptions;

    /* var _lsTotal = 0,
    _xLen, _x;
    for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) {
            continue;
        }
        _xLen = ((localStorage[_x].length + _x.length) * 2);
        _lsTotal += _xLen;
        console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
    };
    console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB"); */


    /* setTimeout(() => {
      this.componentToSpinnerService.sendMessage('hide');
      this.formToTableService.sendMessageToForm('http_done');
      this.medicaments = newMedicamentsArr;
      this.prescriptions=prescriptions;
    }, 1500); */
  }

  SendTotalElements(totalElementos:number | undefined):void{
    if( totalElementos )this.tableToTablePaginationService.sendTotalElementos(totalElementos);
  }

  DeletePrescription(linea:CellClickEvent):void{

    const { data : {status , id , doclineId} } = linea;

    if(status === 'Borrador' || status === 'Manual' || status === 'Fórmula Magistral')
    {
      const deleteMessage = {name:'',id:id, action:'open' , kind:'draft'} ;
      this.componentToDeletePopUpService.sendMessage(deleteMessage);
      this.printDeletePopUp = true;
    
    } else if ( status === 'Emitida'){

      const deleteMessage = {name:'',id:id, action:'open' , prescriptionDoclineId : doclineId , reasonId : '8' , comment : 'Otro motivo' , prescriptionStatus : status  } ;
      this.componentToDeletePopUpService.sendMessage(deleteMessage);
      this.printDeletePopUp = true;

    } 
  }

  CloseDeleteDraftPopUp(message:string):void{
    this.printDeletePopUp = false;
    if( message !== 'cancel' ){
      this.ConfirmDeleteDraft( message );
    }
  }

  CloseDeleteFormalizedPrescriptionPopUp(message : {prescriptionDoclineId : string , reasonId : string , comment : string , id:string , prescriptionStatus?:string}):void{

    const paramsToCancelPrescription : { id : string , prescriptionDoclineId : string , reasonId : string , comment : string , prescriptionStatus? : string } = {
      prescriptionDoclineId : message.prescriptionDoclineId , reasonId : '8' , comment : 'Otro motivo' , id : message.id ,prescriptionStatus : message.prescriptionStatus
    }

    this.printDeletePopUp = false;

    this.componentToSpinnerService.sendMessage('show');

    this.formToTableService.sendMessageToForm('http_start');

    this.store.dispatch( modifyPrescriptionStatusToCanceled({ message : paramsToCancelPrescription }) );
  }

  ConfirmDeleteDraft(prescriptionId : string):void{
    this.dynPrescriptionsService.DeletePrescriptionById(prescriptionId)
    .subscribe((res:boolean)=>{
      if(res){
        this.printDeletePopUp = false;
        const myPrescriptionsFilters = Object.assign({} , this.prescriptionsFilters);
        this.componentToSpinnerService.sendMessage('show');
        this.formToTableService.sendMessageToForm('http_start');
        this.store.dispatch(getFilteredPrescriptionsAction({ parameters: myPrescriptionsFilters }));
      } 
    },(error: HttpErrorResponse) => {
      console.log(error);
      this.printDeletePopUp = false;
    })
  }

  CancelDeleteDraft():void{
    this.prescriptionToDeleteId = '';
    this.printDeletePopUp = false;
  }

  public ShowPrescriptionPreview(linea:CellClickEvent):void{

    this.componentToSpinnerService.sendMessage('show');

    const { data : {id} } = linea;

    this.pdfPreviewName = `${id}.pdf`;
    
    this.dynPrescriptionsService.GetDownloadPrescriptionUrlById(id).subscribe((res:string)=>{
      const url = res
      this.downloadFile(url).subscribe(
        (res) => {
          this.componentToSpinnerService.sendMessage('hide');
          this.pdfViewerOnDemand.pdfSrc = res; 
          this.pdfViewerOnDemand.refresh();
        }
      );
    },(error: HttpErrorResponse) => {
      console.log(error);
      this.componentToSpinnerService.sendMessage('hide');
    })
  }

  private downloadFile(url: string){
    return this.http.get(url, { responseType: 'blob' })
    .pipe(
      map((result) => {
        return result;
      })
    );
  }

  SendPrescriptionByEmail(data:Prescription):void{
    
    if(this.CheckIfPrescriptionAlreadyPrinted(data)){
      this.componentToSpinnerService.sendMessage('show');
      const { id } = data;
      if(id){
        this.dynPrescriptionsService.SendPrescriptionByEmail(id)
        .subscribe((res:{isOk?:boolean,error?:string})=>{
          if(res){
            const { isOk } = res;
            if(isOk){
              
              const alert : AlertContent = {
                title: 'Receta enviada por email',
                content: 'En breves momentos el paciente recibirá la receta en su correo electrónico',
                color:'rgb(232 , 252 , 239)',
                borderColor : '1px solid rgb(34 , 195 , 89)',
                icon: 'faCircleCheck',
                iconColor:'rgb(161 , 18 , 18)',
                navigateTo : 'navigateTo'
              }
              this.componentToAlertComponentService.sendMessage(alert);
            }
  
            this.componentToSpinnerService.sendMessage('hide');
          }
        })
      }
    }else{
      this.ShowPrintPrescriptionWarning();
    }
  }

  ForcePrescriptionRefresh(data:Prescription):void{

    const { doclineId } = data || {};

    if( doclineId ){

      this.componentToSpinnerService.sendMessage('show');

      this.dynPrescriptionsService.ForceReloadPrescriptionsPut( doclineId ).subscribe(( res : boolean ) => {

        if( res ){

          this.StorePrescriptions();
        } 
      }, (error) => {
        console.log( error );
        this.componentToSpinnerService.sendMessage('hide');
      })
    }
  }

  ShowReemitPrescriptionPopUp( prescription:Prescription ):void{

    this.reemitPrescription = prescription;
    
    this.reemitPrescriptionConfirmPopUpVisible = true
  }

  ReemitPrescriptionAsDrat():void{

    const { id } = this.reemitPrescription || {};

    this.reemitPrescriptionConfirmPopUpVisible = false;

    this.componentToSpinnerService.sendMessage('show');

    if( id ){

      this.dynPrescriptionsService.ReemitPrescription( { prescriptionId : id , createOnlyDraft : true } ).subscribe((res : { isOk : boolean , error : string , id?:string }) => {
        
        if(res){

          if( res.error ){

            this.componentToSpinnerService.sendMessage('hide');

            const alert : AlertContent = {
              title: 'Atención',
              content: res.error,
              color:'rgb(252 , 244 , 232)',
              borderColor : '1px solid rgb(240 , 183 , 117)',
              icon: 'faTriangleExclamation',
              iconColor:'rgb(161 , 18 , 18)'
            }
            this.componentToAlertComponentService.sendMessage(alert);
          } else {

            this.StorePrescriptions();
            this.router.navigate(['prescriptions/new' , {prescriptionId:res.id} ]);
          }
        }
      }, ( error : HttpErrorResponse ) => {

        this.componentToSpinnerService.sendMessage('hide');

        console.log(error);
      });
    }
  }

  ReemitPrescriptionAsFormalized():void{

    const { id } = this.reemitPrescription || {};

    this.reemitPrescriptionConfirmPopUpVisible = false;

    this.componentToSpinnerService.sendMessage('show');

    if( id ){
      this.dynPrescriptionsService.ReemitPrescription( { prescriptionId : id , createOnlyDraft : false } ).subscribe((res : { isOk : boolean , error : string , id?:string }) => {

        if( res ){

          if( res.error ){

            this.componentToSpinnerService.sendMessage('hide');

            const alert : AlertContent = {
              title: 'Atención',
              content: res.error,
              color:'rgb(252 , 244 , 232)',
              borderColor : '1px solid rgb(240 , 183 , 117)',
              icon: 'faTriangleExclamation',
              iconColor:'rgb(161 , 18 , 18)'
            }
            this.componentToAlertComponentService.sendMessage(alert);
          }

          this.StorePrescriptions();
        }
      }, ( error : HttpErrorResponse ) => {
        console.log(error);
        this.componentToSpinnerService.sendMessage('hide');
      });
    }
  }

  CreateDoclineErrorAlert():void{
    const alert : AlertContent = {
      title: 'Atención',
      content: 'El servicio de docline no está disponible en estos momentos , vuelva a intentarlo más tarde',
      color:'rgb(252 , 244 , 232)',
      borderColor : '1px solid rgb(240 , 183 , 117)',
      icon: 'faTriangleExclamation',
      iconColor:'rgb(161 , 18 , 18)',
      navigateTo : 'navigateTo'
    }
    this.componentToAlertComponentService.sendMessage(alert);
  }

  ReciveParams(paginationParams:PaginationParams):void{
  
    const myPaginationParams = {...paginationParams} ;
    this.prescriptionsFilters = {...this.prescriptionsFilters , ...paginationParams};
    this.componentToSpinnerService.sendMessage('show');
    
    this.store.dispatch( modifyPrescriptionsFilters({ prescriptionsFilters : myPaginationParams }) );
  }

  CheckIfPrescriptionAlreadyPrinted(data:Prescription):boolean{
    const { lastPrintDate } = data;
    return lastPrintDate ? true : false;
  }

  ShowPrintPrescriptionWarning():void{

    const alert : AlertContent = {
      title: 'Atención',
      content: 'Es necesario descargar la receta previamente para poder enviarla por email',
      color:'rgb(252 , 244 , 232)',
      borderColor : '1px solid rgb(240 , 183 , 117)',
      icon: 'faTriangleExclamation',
      iconColor:'rgb(161 , 18 , 18)',
      navigateTo : 'navigateTo'
    }

    this.componentToAlertComponentService.sendMessage(alert);
  }

  EditPrescripcion(linea:CellClickEvent):void{

    const {data : {id}} = linea;

    this.router.navigate(['prescriptions/new' , {prescriptionId:id} ]);
  }

  OrderDownloadPrescription(linea:CellClickEvent):void{

    const {data , data : { id }} = linea;

    const medicaments = this.medicaments.filter(e => e.id_medicament === id);

    data.medicaments = medicaments;

    this.prescriptionDownloadHelperService.DownloadPrescription(data);
  }

  GoToPrescriptionInMobile( prescription : Prescription):void{

    if( this.windowWidth <= this.mobileViewBreakPoint ){

      const { id } = prescription || {};

      this.router.navigate(['prescriptions/new' , {prescriptionId:id} ]);
    }
  }

  OnRowDblClick( e : RowDblClickEvent ):void{

    if( this.windowWidth <= this.mobileViewBreakPoint ){

      const { data : { id } } = e;

      this.router.navigate(['prescriptions/new' , {prescriptionId:id} ]);
    }
  }

  ngOnDestroy() :void{
    
    this.resizeSubscription.unsubscribe();
    this.patientNotFoundSubscription.unsubscribe();
    this.prescriptionsFiltersDestroyed$.next();
    this.prescriptionsFiltersDestroyed$.complete();
    this.prescriptionFiltersDestroyed$.next();
    this.prescriptionFiltersDestroyed$.complete();
  }

}

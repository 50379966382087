import { Component , OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
/* ngrx */
import { Store } from '@ngrx/store';
/* models */
import { AppState, Patient, PatientsPaginationParams } from 'src/app/models';
/* rxjs */
import { Subscription } from 'rxjs';
/* services */
import { PatientsService, PrescriptionNewToPatientsPopUpService } from 'src/app/prescriptions/services';
import { PatientResWithParams } from 'src/app/models/prescriptions/interfaces/patients';
import { ComponentToSpinnerService, TableToTablePaginationService , HandleHTTPerrorsService } from 'src/app/shared/services';
import { addPatientInfoToPrescription } from 'src/app/store/actions/prescriptions/prescription.actions';

@Component({
  selector: 'app-prescription-patients-table-popup',
  templateUrl: './prescription-patients-table-popup.component.html',
  styleUrls: ['./prescription-patients-table-popup.component.scss']
})

export class PrescriptionPatientsTablePopupComponent implements OnDestroy {

  patients:                                                       Patient[] = [];
  typingTimer                                                     = 0;
  doneTypingInterval                                              = 2000;
  patientsTableFilters:                                           PatientsPaginationParams = {
    first: 1,
    size: 10
  }
  clinicsSubscription:                                            Subscription;

  constructor(
    private store:                                                Store<AppState>,
    private prescriptionNewToPatientsPopUpService:                PrescriptionNewToPatientsPopUpService,
    private patientsService:                                      PatientsService,
    private componentToSpinnerService:                            ComponentToSpinnerService,
    private tableToTablePaginationService :                       TableToTablePaginationService,
    private handleHTTPerrorsService:                              HandleHTTPerrorsService
  ){
    this.clinicsSubscription = this.prescriptionNewToPatientsPopUpService.OnMessage()
    .subscribe(( clinicCode : string ) =>{
      if(clinicCode){
        
        this.patientsTableFilters.patientClinicCode = clinicCode;
      }
    })
  }
  
  GetPatientByPatientsFilters():void{

    this.componentToSpinnerService.sendMessage('show');
    this.patientsService.LoadPatientByTableParams( this.patientsTableFilters )
    .subscribe(( res : PatientResWithParams ) => {
      
      const { items , totalCount } = res;

      if(items)this.patients = items;

      if(totalCount)this.SendTotalElements(totalCount);

      this.componentToSpinnerService.sendMessage('hide');

    },(error: HttpErrorResponse) => {

      this.componentToSpinnerService.sendMessage('hide');
      this.handleHTTPerrorsService.HandleHTTPerrors(error);
      
    })
  }

  SendTotalElements(totalElementos:number){
    this.tableToTablePaginationService.sendTotalElementos(totalElementos);
  }

  SelectPatient( data : Patient ):void{

    const { id , name } = data;

    this.patients = this.patients.map((p) => { return p.id === id ? { ...p , selected : true} : { ...p , selected : false}; })

    if(id && name){

      const myPatient : { id: string , name: string } = { id:id , name:name };

      this.store.dispatch( addPatientInfoToPrescription({ patientInfo : myPatient }) );

      this.prescriptionNewToPatientsPopUpService.SendPatientIdMessage( myPatient );
    }
    
  }

  ReciveParams(paginationParams:PatientsPaginationParams){
    const { first , size } = paginationParams;
    this.patientsTableFilters.first = first;
    this.patientsTableFilters.size = size;
    this.GetPatientByPatientsFilters();
  }

  ngOnDestroy(): void {
    this.clinicsSubscription.unsubscribe();
  }
}

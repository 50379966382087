import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
  {
    path: '', redirectTo:'prescriptions' , pathMatch:'full'
  },
  {
    path:'prescriptions',
    loadChildren:()=>import('./prescriptions/prescriptions.module').then(m=>m.PrescriptionsModule),
    canActivate:[MsalGuard]
  },
  {
    path:'paraPrescription',
    loadChildren:()=>import('./para-prescription/para-prescription.module').then(m=>m.ParaPrescriptionModule),
    canActivate:[MsalGuard]
  },
  {
    path:'medicamentsFavorites',
    loadChildren:()=>import('./medicaments-favorites/medicaments-favorites.module').then(m=>m.MedicamentsFavoritesModule),
    canActivate:[MsalGuard]
  },
  {
    path:'serviceErrors',
    loadChildren:()=>import('./http-errors/http-errors.module').then( se => se.HttpErrorsModule ),
    canActivate:[MsalGuard]
  }
];

// const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
   initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' 
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

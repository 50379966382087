import { Component , ViewChild , OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
/* font awesome */
import { fas, faPlus,  IconDefinition , faCheck } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
/* models */
import { MedicinRes, Medicament, PaginationParams, AppState } from 'src/app/models';
/* services */
import { MedicinesService , MedicamentSearchPopUpToPrescriptionNewTableService, MedicamentValidatorsService, PrescriptionTablaService } from 'src/app/prescriptions/services/index';
import { TableToTablePaginationService , ComponentToSpinnerService, HandleHTTPerrorsService } from 'src/app/shared/services/index';
/* rxjs */
import { Subject, Subscription, takeUntil } from 'rxjs';
/* devextrem */
import { RowClickEvent, RowUpdatedEvent } from 'devextreme/ui/data_grid';
import { MedicamentsFormalHelperService } from '../../services/helpers/medicaments/medicaments-formal-helper.service';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';

@Component({
  selector: 'app-medicament-search-pop-up',
  templateUrl: './medicament-search-pop-up.component.html',
  styleUrls: ['./medicament-search-pop-up.component.scss']
})

export class MedicamentSearchPopUpComponent implements OnInit , OnDestroy {

  medicamentsArr:                                                Medicament[] = [];
  params:                                                        PaginationParams = {first:1 , size:10}
  faPlus:                                                        IconDefinition=faPlus;
  faCheck:                                                       IconDefinition=faCheck;
  medicamentSubscription:                                        Subscription;
  resizeSubscription:                                            Subscription;
  medicamentsSelected:                                           Medicament[] = [];
  inputDisabled                                                  = true;
  showPsicoNarcoticAlert                                         = true;
  hiddenRows                                                     = false
  medicamentsFavorites:                                          Medicament[] = []; 
  medicamentdFavoritesDestroyed$:                                Subject<void> = new Subject<void>();
  @Output() SendMedicamentIdArrToDelete :                        EventEmitter<string[]> = new EventEmitter;
  @Output() CloseMedicamentsSearchPopUpEmitter :                 EventEmitter<string> = new EventEmitter;
  windowWidth=                                                   0;
  mobileViewBreakPoint=                                          environment.mobileViewBreakPoint;
  
  timeDuration:                                                  {name:string , key:string}[]=[
    {name:'día' , key:'day'},
    {name:'semana' , key:'week'},
    {name:'mes' , key:'month'},
  ]

  frecuencyUnitTimeDuration:                                     {name:string , key:string}[]=[
    {name:'hora' , key:'hour'},
    {name:'día' , key:'day'},
    {name:'semana' , key:'week'},
    {name:'mes' , key:'month'},
  ]

  constructor(
    private store:                                               Store<AppState>,
    private medicinesService :                                   MedicinesService,
    private tableToTablePaginationService :                      TableToTablePaginationService,
    private MedicamentSearchPopUpToPrescriptionNewTableService : MedicamentSearchPopUpToPrescriptionNewTableService,
    private componentToSpinnerService:                           ComponentToSpinnerService,
    library:                                                     FaIconLibrary,
    private handleHTTPerrorsService:                             HandleHTTPerrorsService,
    private medicamentValidatorsService:                         MedicamentValidatorsService,
    private medicamentsFormalHelperService:                      MedicamentsFormalHelperService,
    private prescriptionTablaService:                            PrescriptionTablaService,
    private windowResizeService :                                WindowResizeService
  ){
    library.addIconPacks(fas);
    library.addIcons(faPlus);
    library.addIcons(faCheck);
    
    this.medicamentSubscription = this.MedicamentSearchPopUpToPrescriptionNewTableService.
    prescriptionNewTableToMedicamentsTableOnMessage().subscribe((message) => {
      if(message){
        this.medicamentsSelected = message;
        this.inputDisabled = false;
        this.CheckIfAlreadySelected();
      }
    })

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {

      this.windowWidth = width;
    });
  }

  @ViewChild('grid') grid ?: string;

  ngOnInit(): void {
    this.GetFavoritesMedicaments();
  }

  GetFavoritesMedicaments():void{
    this.store.select( AppState => AppState.FavoritesMedicaments ).pipe( takeUntil(this.medicamentdFavoritesDestroyed$) ).subscribe(( medicamentsFavorites : Medicament[] ) => {
      
      if( medicamentsFavorites.length > 0 ){
  
        if( medicamentsFavorites[0].name !== 'initial_value' ){
  
          this.medicamentsFavorites = [ ...medicamentsFavorites ];
         
        }
      } else {
  
        this.medicamentsFavorites = [];
      }
    })
  }

  SearchMedicaments(from ? : string):void{
    this.inputDisabled = true;
    this.componentToSpinnerService.sendMessage('show');
    if(from)if(from === 'search_button')this.params.first = 1;
    
    this.medicinesService.LoadMedicinesWithParams(this.params)
    .subscribe((res:MedicinRes)=>{

      this.componentToSpinnerService.sendMessage('hide');
      const {totalCount , items} = res;
     

      const medicamentsWithPsycoProperty = this.AddPsycotropicNarcoticProperty(items); 

      this.medicamentsArr = this.medicamentValidatorsService.ConcatDataInMedicamentName(medicamentsWithPsycoProperty) /* this.AddPsycotropicNarcoticProperty(items) */;
      this.medicamentsArr = this.medicamentsFormalHelperService.AddPosologyFieldsToDoclineMedicines( this.medicamentsArr );
      this.medicamentsArr = this.medicamentsFormalHelperService.BuildPosologyField( this.medicamentsArr );

      this.CheckIfAlreadySelected();
      this.SendTotalElements(totalCount);
      this.inputDisabled = false;

    },(error: HttpErrorResponse) => {
      console.log(error);
      this.componentToSpinnerService.sendMessage('hide');
      //this.handleHTTPerrorsService.HandleHTTPerrors( error );
    })
  }

  OnRowUpdated( e : RowUpdatedEvent ):void{
    
    const { data : { durationAmount , durationUnit , takingAmount , frequencyUnit , frequencyAmount , nationalCode } } = e || {};

    this.medicamentsArr = this.medicamentsArr.map(( m : Medicament ) => {

      if( m.nationalCode === nationalCode ){
      
        return {
          ...m , durationAmount : durationAmount , durationUnit : durationUnit , 
          takingAmount : takingAmount , frequencyUnit : frequencyUnit , frequencyAmount : frequencyAmount 
        };
      
      } else {
      
        return m;
      
      }
    })

    this.medicamentsArr = this.medicamentsFormalHelperService.BuildPosologyField( this.medicamentsArr );
  }

  AddPsycotropicNarcoticProperty(items : Medicament[]){

    const MedicamentsWithPsicotropicOrNarcoticProperty = items.map(
      (m) => {
        return m.psychotropic || m.narcotic ? { ...m , psicotropicOrNarcotic : 'Si' } : { ...m , psicotropicOrNarcotic : 'No' }  
      }
    ) 

    return MedicamentsWithPsicotropicOrNarcoticProperty;
  }

  CheckIfAlreadySelected():void{

    const medicamentsWithSelectedProperty = this.medicamentsArr.map((e) => {

      const { nationalCode } = e || {};
      if(this.medicamentsSelected.find((e:Medicament) => e.code ? +e.code === nationalCode : false)){

        e = { ...e , selected : true };
      }else{

        e = { ...e , selected : false };
      }
      return e;

    })
    
    this.medicamentsArr = medicamentsWithSelectedProperty;
    this.componentToSpinnerService.sendMessage('hide');
  }

  ReciveParams(paginationParams:PaginationParams){
    const { first , size } = paginationParams;
    this.params.first = first;
    this.params.size = size;
    this.SearchMedicaments();
  }

  OnRowClick(e:RowClickEvent):void{
    this.ModifySelection( e.data );
  }

  ModifySelection( medicament : Medicament):void{

    const { selected , medicineId } = medicament || {};

    this.medicamentsFavorites.find(( mf ) => mf.medicineId === medicineId) ? medicament.favorite = true : medicament.favorite = false;
    
    selected ? this.DeselectMedicamentToPrescription(medicament) : this.AddMedicamentToPrescription(medicament);
  }

  SendTotalElements(totalElementos:number){
    this.tableToTablePaginationService.sendTotalElementos(totalElementos);
  }

  DeselectMedicamentToPrescription( medicament : Medicament ):void{
    this.componentToSpinnerService.sendMessage('show');
    this.inputDisabled = true;
    
    const { nationalCode } = medicament || {} ;
    const medicineIdArr = []

    for (let i = 0; i < this.medicamentsSelected.length; i++) {
      const { code } = this.medicamentsSelected[i] || {};
      if( code ){
        if(nationalCode === +code){

          if( this.medicamentsSelected[i].id ) {

            medicineIdArr.push(this.medicamentsSelected[i].id.toString());
          
          } else if( this.medicamentsSelected[i].favoriteId ){
           
            medicineIdArr.push(this.medicamentsSelected[i].favoriteId);
          
          }
        }
      }
    }
    this.SendMedicamentIdArrToDelete.emit(medicineIdArr);
  }

  AddMedicamentToPrescription( medicament : Medicament ):void{
    
    this.componentToSpinnerService.sendMessage('show');
    this.inputDisabled = true;
    medicament = {...medicament , favorite : false};
    const medicamentFilled = this.FillRequiredFields(/* data */ medicament );
    const medicamentArr = [];
    medicamentArr.push(medicamentFilled);
    
    this.MedicamentSearchPopUpToPrescriptionNewTableService.SendMessage(medicamentArr);
  }

  FillRequiredFields(medicament:Medicament):Medicament{

    const {
      administrationWay , laboratoryOriginal , 
      laboratoryProvider , pharmaForm ,
      unit ,
    } = medicament ;
    
    
    if(!administrationWay?.name)(medicament.administrationWay as { name : string , id:number }).name='';
    if(!laboratoryOriginal?.name)(medicament.laboratoryOriginal as { name : string , id:number }).name='';
    if(!laboratoryProvider?.name)(medicament.laboratoryProvider as { name : string , id:number }).name='';
    if(!pharmaForm?.name)(medicament.pharmaForm as { name : string , id:number }).name='';
    if(!unit?.name)(medicament.unit as { name : string , id:number }).name='';

    // medicament = {...medicament , durationUnit:'day' , frecuencyUnit:'hour'}
    return medicament;
  }

  CloseMedicamentsSearchPopUp():void{
    this.CloseMedicamentsSearchPopUpEmitter.emit('close_medicaments_search_pop_up')
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
    this.medicamentSubscription.unsubscribe();
    this.medicamentdFavoritesDestroyed$.next();
    this.medicamentdFavoritesDestroyed$.complete();
  }
}


import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap , exhaustMap, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import * as ParaPrescritionsActions from 'src/app/store/actions/paraPrescription/paraPrescriptions.actions';
import { DynPrescriptionsService, ProtocolsService } from '../../../prescriptions/services';

@Injectable()

export class ParaPrescriptionEffects {

    constructor( 
        private action$ : Actions,
        private dynPrescriptionsService : DynPrescriptionsService
    ){}


    addParaPrescription$ = createEffect(() =>
        this.action$.pipe(
            ofType(ParaPrescritionsActions.addParaPrescriptionAction),
            mergeMap(( action ) => {

                const { paraPrescription } = action || {};

                return this.dynPrescriptionsService.CreatePrescriptionDraft( paraPrescription )
                .pipe(
                    map((data) =>{

                        const { isOk , id , error } = data || {};

                        if( isOk ){
                            const myParaPrescription = { ...paraPrescription , id : id };

                            return ParaPrescritionsActions.addParaPrescriptionActionSuccess({ myParaPrescription });
                        } else {
                            return ParaPrescritionsActions.addParaPrescriptionActionSuccess({  });
                        }
                    })
                )
            })
        )
    );

    /* deleteMedicamentToProtocol$ = createEffect(() =>
        this.action$.pipe(
            ofType(deleteMedicamentToProtocol),
            mergeMap(( action ) => {
                const { protocolId , medicineId } = action;
                return this.protocolsService.DeleteMedicamentFromProtocol(protocolId , medicineId)
                .pipe(
                    map(() =>{
                        return deleteMedicamentToProtocolSuccess({ protocolId : protocolId , medicineId : medicineId })
                    })
                )
            })
        )
    ); */

    
}

import { Injectable } from '@angular/core';
import { Subject , Observable } from 'rxjs';
import { Medicament } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})

export class MedicamentsFavoritesPopUpToPrescriptionNewTableServiceService {

  subject = new Subject<Medicament[]>();
  prescriptionNewTableToMedicamentsTableSubject = new Subject<Medicament[]>();
 
  MedicamentsFavoritesToPrescriptionNewTableSendMessage(message:Medicament[]):void{
    this.subject.next(message);
  }

  MedicamentsFavoritesToPrescriptionNewTableOnMessage():Observable<Medicament[]>{
    return this.subject.asObservable();
  }

  MedicamentsFavoritesToPrescriptionNewTableClearMessage():void{
    this.subject.next([]);
  }

  PrescriptionNewTableToMedicamentsFavoritesSend(message:Medicament[]):void{
    this.prescriptionNewTableToMedicamentsTableSubject.next(message);
  }

  PrescriptionNewTableToMedicamentsFavoritesOnMessage():Observable<Medicament[]>{
    return this.prescriptionNewTableToMedicamentsTableSubject.asObservable();
  }

  PrescriptionNewTableToMedicamentsFavoritesClearMessage():void{
    this.prescriptionNewTableToMedicamentsTableSubject.next([]);
  }
}

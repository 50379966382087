import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Medicament } from 'src/app/models';

@Injectable()

export class MedicamentSearchPopUpToPrescriptionNewTableService {

  subject = new Subject<Medicament[]>();
  prescriptionNewTableToMedicamentsTableSubject = new Subject<Medicament[]>();
 
  SendMessage(message:Medicament[]):void{
    this.subject.next(message);
  }

  OnMessage():Observable<Medicament[]>{
    return this.subject.asObservable();
  }

  ClearMessage():void{
    this.subject.next([]);
  }

  prescriptionNewTableToMedicamentsTableSend(message:Medicament[]):void{
    this.prescriptionNewTableToMedicamentsTableSubject.next(message);
  }

  prescriptionNewTableToMedicamentsTableOnMessage():Observable<Medicament[]>{
    return this.prescriptionNewTableToMedicamentsTableSubject.asObservable();
  }

  prescriptionNewTableToMedicamentsTableClearMessage():void{
    this.prescriptionNewTableToMedicamentsTableSubject.next([]);
  }

}

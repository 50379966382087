import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MedicinRes, PaginationParams } from 'src/app/models';
import { Observable } from 'rxjs';

@Injectable()

export class MedicinesService {

  constructor(private http : HttpClient) {}

  LoadMedicines(name?:string):Observable<MedicinRes>{
    let url = `${environment.prescriptionsService.url}medicin`;
    if(name) url += `?Filter=${name}`;

    return this.http.get<MedicinRes>(url);
  }

  LoadMedicinesWithParams(paginationParams:PaginationParams):Observable<MedicinRes>{
    const {first , size , medicamentoFiltrado} = paginationParams;
    
    let url = `${environment.prescriptionsService.url}medicin?first=${first}&size=${size}`;
    if(medicamentoFiltrado)url += `&filter=${medicamentoFiltrado}`;
    return this.http.get<MedicinRes>(url);
    
  }
}

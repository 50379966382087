<!-- {{ whoIsParent?.from }}
{{ whoIsParent?.parentId }}
{{ whoIsParent?.child }} -->

<dx-scroll-view
    id="scrollview"
    reachBottomText="Updating..."
>

    <dx-form
        id="masterFormulaForm"
        [formData]="medicament" 
        labelLocation="top"
        (onFieldDataChanged)="FormFieldDataChanged($event)"
    >

        <dxi-item *ngIf="whoIsParent?.from !== 'prescription_new_table'" [colCount]="2" itemType="group">
            
            <dxi-item  [colSpan]="1" dataField="composition">
                <dxo-label alignment="left" text="Composición">
                </dxo-label>
            </dxi-item>

            <dxi-item 
                dataField="pharmaceuticalForm" [colSpan]="1" [label]="{text: 'Forma Farmacéutica'}"
            >
                <dx-select-box
                    [dataSource]="pharmaceuticalFormsgroupedData"
                    valueExpr="name"
                    [grouped]="true"
                    displayExpr="name"
                    groupTemplate="pharmaForms"
                    [value]="medicament.pharmaceuticalForm"
                    (onValueChanged)="SelectPharmaForm($event)"
                    >
                    <div *dxTemplate="let pharmaForm of 'pharmaForms'">
                        {{ pharmaForm.name }}
                    </div>
                </dx-select-box> 

            </dxi-item>

        </dxi-item>

        <dxi-item *ngIf="whoIsParent?.from === 'prescription_new_table'" [colCount]="3" itemType="group">
            
            <dxi-item  [colSpan]="1" dataField="composition">
                <dxo-label alignment="left" text="Composición">
                </dxo-label>
            </dxi-item>

            <dxi-item 
                dataField="pharmaceuticalForm" [label]="{text: 'Forma Farmacéutica'}" [colSpan]="1"
            >
                <dx-select-box
                    [dataSource]="pharmaceuticalFormsgroupedData"
                    valueExpr="name"
                    [grouped]="true"
                    displayExpr="name"
                    groupTemplate="pharmaForms"
                    [value]="medicament.pharmaceuticalForm"
                    (onValueChanged)="SelectPharmaForm($event)"
                    >
                    <div *dxTemplate="let pharmaForm of 'pharmaForms'">
                        {{ pharmaForm.name }}
                    </div>
                </dx-select-box> 

            </dxi-item>

            <dxi-item 
                [colSpan]="1"
                editorType="dxCheckBox" 
                dataField="favorite"
            >
                <dxo-label 
                    location="left"
                    alignment="right"
                    [text]="'Favorito'"
                >
                </dxo-label>
            </dxi-item>

        </dxi-item>

        <dxi-item [colCount]="1" itemType="group">

            <dxi-item  
                [colSpan]="1"
                dataField="patientInstructions" 
                [editorOptions]="{ disabled: false }"
                [label]="{text: 'Instrucciones'}"    
            >
                <dxi-validation-rule
                    type="stringLength"
                    [min]="1"
                    message="Especifique instrucciones para el paciente" >
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>

        <dxi-item [colCount]="6" [colSpan]="2" itemType="group" caption="Posología">
            <dxi-item dataField="takingAmount"><dxo-label [visible]="false" ></dxo-label></dxi-item>

            <dxi-item 
            
                dataField="takingUnit"
            >
                <dxo-label [visible]="false" ></dxo-label>
                <dx-select-box
                    [dataSource]="unitgroupedData"
                    valueExpr="name"
                    [grouped]="true"
                    displayExpr="name"
                    groupTemplate="units"
                    [value]="medicament.takingUnit"
                    (onValueChanged)="Selectunit($event)"
                >
                    <div *dxTemplate="let unit of 'units'">
                        {{ unit.name }} 
                    </div>
                </dx-select-box>
            </dxi-item>

            <dxi-item [label]="{ text : 'Cada' , location : 'left' }" dataField="frequencyAmount"></dxi-item>

            <dxi-item 
                
                dataField="medicament.frequencyUnit"
            >
                <dxo-label [visible]="false" ></dxo-label>
                <dx-select-box
                    [dataSource]="frecuencyUnitData"
                    valueExpr="name"
                    [grouped]="true"
                    displayExpr="name"
                    groupTemplate="frecuencyUnits"
                    [value]="medicament.frequencyUnit"
                    (onValueChanged)="SelectFrecuencyUnit($event)"
                >
                    <div *dxTemplate="let frecuencyUnit of 'frecuencyUnits'">
                        {{ frecuencyUnit.name }} 
                    </div>
                </dx-select-box>
            </dxi-item>

            <dxi-item [label]="{ text : 'Durante' , location : 'left' }" dataField="durationAmount"></dxi-item>
            <dxi-item 
                editorType="dxSelectBox"
                [editorOptions]="{
                    items: durationUnit,  
                    displayExpr: 'name',  
                    valueExpr: 'name'
                }"
                dataField="durationUnit"
            >
                <dxo-label [visible]="false" ></dxo-label>
                
            </dxi-item>
        </dxi-item>

        <dxi-item  [colCount]="3" itemType="group" caption="Detalle del medicamento">
            <dxi-item 
                [label]="{text: 'Núm.envases/unidades'}" 
                dataField="packagesAmount"
            ></dxi-item>

            <dxi-item 
                *ngIf="whoIsParent?.from !== 'medicaments_favorites'"
                [editorOptions]="startDateEditorOptions"
                [label]="{text: 'Fecha de Inicio'}" 
                dataField="startDate"
                editorType="dxDateBox"
                format="dd/MM/yyyy"
            ></dxi-item>
            
            <dxi-item 
                *ngIf="whoIsParent?.from !== 'medicaments_favorites'"
                [label]="{text: 'Fecha de Fin'}" 
                dataField="endDate"
                editorType="dxDateBox"
                [editorOptions]="{ min : endDateMin }"
                format="dd/MM/yyyy"
            ></dxi-item> 
        </dxi-item>
        
        <dxi-item>
            <div class="magistral_formula_pop_up-save_button_container" *dxTemplate >

                <div class="magistral_formula_pop_up-save_button" >
                    <dx-button type="default" text="SOME RANDOM" icon="add" (click)="SaveMedicament()">
                        Guardar 
                    </dx-button>
                </div>

                <div class="magistral_formula_pop_up-save_alert_form">
                    <div  *ngIf="AlertaAbierta" >
                        <div 
                        
                            class="alert-main_container" 
                        >
                            <div>
                                <div class="alert-title_span_container" >
                                    <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                                    <span class="alert-title_span" > Complete campos incompletos o valores a 0 </span>
                                </div>
                            </div>
                            
                            <div>
                                <div>
                                    <fa-icon class="alert-x_button" (click)="OrderCloseAlert()" [icon]="faXmark"></fa-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </dxi-item>

    </dx-form>

</dx-scroll-view>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
/* fontawsome */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    SideMenuComponent
  ],
  imports: [
    FontAwesomeModule,
    CommonModule
  ],
  exports: [
    SideMenuComponent
  ]
})
export class SideMenuModule { }

/************************************************************ PRESCRIPTIONS ********************************************************/


 

/* user */
export { UserInfo , UserRes } from './prescriptions/interfaces/user';

/* professionals */
export { ProfessionalsRes } from 'src/app/models/prescriptions/interfaces/professionals';
export { ProfessionalFromJson , ProfessionalsJSONRes } from 'src/app/models/class/professionals/professionalsJsonClass.models';

/* http */
export { Pending , Status } from './prescriptions/interfaces/pendingHttp';
export { HTTPErrors } from 'src/app/models/prescriptions/interfaces/httpErros';

/* patients */
export { Patient , PatientTableFilters , PatientsPaginationParams } from './prescriptions/interfaces/patients';

/* clinics */
export  { Clinic } from './prescriptions/interfaces/clinics';

/* protocols */
export { 
    ProtocolsRes , 
    ProtocolPrescription , 
    ProtocolParamsToInsert,
    ProtocolsFilters,
    ProtocolNew , 
    ProtocolNewRes , 
    ProtocolMedicineUpdateBody
} from 'src/app/models/prescriptions/interfaces/protocols';

/* indications */
export { Indication , IndicationsRes } from 'src/app/models/prescriptions/interfaces/indications';

/* docline responses */
export { 
    DoclineGetRes , 
} from 'src/app/models/prescriptions/interfaces/docline';

/* alert */
export { AlertContent } from 'src/app/models/alerts/interfaces/alert';

/* masterFormula */
export { MasterFormula } from 'src/app/models/class/prescriptions/master-formulas.models';

/* magicLinkHolded */
export { MagickLinkHolded } from './prescriptions/interfaces/magickLiknHolded.models';

/************************* NGRX MODELS ****************************/  
export { 
    NgrxPrescriptionsRes , Prescription , 
    Medicament , Professional ,
    MedicinRes , PrescriptionInsertRes,
    PrescriptionDoclineRes ,
    PaginationParams , PrescriptionByProfessionalIdPatientIdParams , 
    PrescriptionByProfessionalIdPatientIdRes , PrescriptionsFilters , 
    ParamsForPrescriptionsRecord 
} from 'src/app/models/prescriptions/interfaces/prescriptionsRes';

export { AppState } from 'src/app/models/prescriptions/interfaces/appState';





export { MedicamentSearchPopUpComponent } from 'src/app/prescriptions/components/medicament-search-pop-up/medicament-search-pop-up.component';
export { MedicamentsTemplatesPopUpComponent } from 'src/app/prescriptions/components/medicaments-templates-pop-up/medicaments-templates-pop-up.component';
export { PrescriptionNewTableComponent } from 'src/app/prescriptions/components/prescription-new-table/prescription-new-table.component';
export { PrescriptionsDetailComponent } from 'src/app/prescriptions/components/prescriptions-table/prescriptions-detail/prescriptions-detail.component';
export { PrescriptionNewComponent } from 'src/app/prescriptions/pages/prescription-new/prescription-new.component';
export { PrescriptionsComponent } from 'src/app/prescriptions/pages/prescriptions/prescriptions.component';
export { ProtocolComponent } from 'src/app/prescriptions/pages/protocol/protocol.component';
export { ProtocolMedicalIndicationPopUpComponent } from 'src/app/prescriptions/components/protocol-medical-indication-pop-up/protocol-medical-indication-pop-up.component'
export { PrescriptionPatientsTablePopupComponent } from 'src/app/shared/components/prescription-patients-table-popup/prescription-patients-table-popup.component';
export { PrescriptionsFiltersComponent } from 'src/app/prescriptions/components/prescriptions-filters/prescriptions-filters.component';
export { PrescriptionsTableComponent } from 'src/app/prescriptions/components/prescriptions-table/prescriptions-table.component';
export { ProtocolsTableComponent } from 'src/app/prescriptions/components/protocols-table/protocols-table.component';
export { FavoritesMedicamentsPopUpComponent } from 'src/app/prescriptions/components/favorites-medicaments-pop-up/favorites-medicaments-pop-up.component';
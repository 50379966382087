import { Injectable } from '@angular/core';
import { Medicament, NgrxPrescriptionsRes, Prescription } from 'src/app/models';
import { FormatDatesService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root' //injected in effects
})

export class MedicamentValidatorsService {

  constructor(
    private formatDatesService :                                    FormatDatesService
  ) { }

  /* test 
  *  AMOXICILINA /ACIDO CLAVULANICO SALA 500/50 mg
  */

  GetMedicamentsInRecord( medicamentSelected : Medicament , prescriptionsPerPatient : NgrxPrescriptionsRes ):Medicament[]{
    const { items } = prescriptionsPerPatient || {};
    if(items){

      // console.log(items);

      if( items.length > 0 ){
        const { nationalCode , code } = medicamentSelected || {};
  
        const prescriptionsNoDraft = items.filter(( p ) => p.status !== "Draft");
        const medicamentsActive = this.ExtractMedicamentsActive(prescriptionsNoDraft);

        let medicamentFound ;
        if( nationalCode ){
          medicamentFound = medicamentsActive.filter( (m) => {
            
            return m.code ? +m.code === nationalCode : {};
            
          } )
        }else{
          medicamentFound = medicamentsActive.filter( m => m.code === code )
        }
        
        /* PREGUNTAR ESTE PUNTO */
        /* filtramos los medicamentos con status dispensable y dispensableInFuture ya que otro estado NO computará días , por ejemplo un canceled o dispensed no debería sumar */

        /* 
          DispensableInFuture, Dispensable, Locked, Dispensed, Replaced_OnlyMedicament, Expired, VisaPending_OnlyMasterFormula, VisaReject_OnlyMasterFormula, PartialDispensed, 
          ElaboratingMasterFormula_OnlyMasterFormula, PartialDispensedWithReplaced_OnlyMedicament, Deleted 
        */

        // console.log( medicamentFound );

        medicamentFound = medicamentFound.filter(( m ) => m.status === "DispensableInFuture" || m.status === "Dispensable" || m.status === "Dispensed");

        if( medicamentFound.length > 0 ){
          return medicamentFound;
        }else{
          return [];
        }
      } else {
        return [];
      }
    }else{
      return [];
    }
  }

  ExtractMedicamentsActive(prescriptionsPerPatient : Prescription[]):Medicament[]{
    const medicamentsActive : Medicament[] = [];

    for (let i = 0; i < prescriptionsPerPatient.length; i++) {
      
      const { medicaments } = prescriptionsPerPatient[i] || {};

      if(medicaments){

        if( Array.isArray( medicaments ) ){

          if( medicaments.length > 0 ){

            for (let x = 0; x < medicaments.length; x++) {
              
              medicamentsActive.push( medicaments[x] );
              
            }
          }
        }
      }
    }
    return medicamentsActive;
  }


  CalculateAcumulatedDaysWithMedicament( startDate:string , endDate:string ):number{
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(+date2 - +date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  CalculateTotalDays( medicamentFound : Medicament[] ):number{
    let totalDays = 0;
    for (let i = 0; i < medicamentFound.length; i++) {
 
      medicamentFound[i];
      const { startDate , endDate } = medicamentFound[i] || {};

      totalDays = totalDays + this.CalculateAcumulatedDaysWithMedicament( startDate , endDate );
    }

    return 365 - totalDays;
  }

  CheckIfMedicamentAlreadyPrescribed( medicamentSelected : Medicament , prescriptionsPerPatient : NgrxPrescriptionsRes ):Medicament | null{

    const { items } = prescriptionsPerPatient || {};

    if( items ){
    
      /* preguntar si también hay que excluir los draft en la busqueda de medicamento activo */
      const prescriptionsNoDraft = items.filter((p) => p.status !== "Draft" && p.status !== "Canceled" && p.status !== "Expired");

      const medicamentsActive = this.ExtractMedicamentsActive( prescriptionsNoDraft );
      const { nationalCode , code } = medicamentSelected || {};

      //let medicamentFound = nationalCode ? medicamentsActive.filter( m => +m.code === nationalCode ) : medicamentsActive.filter( m => m.code === code );

      let medicamentFound ;

      if( nationalCode ){
        medicamentFound = medicamentsActive.filter( (m) => {
          
          return m.code ? +m.code === nationalCode : {};
          
        } )
      }else{
        medicamentFound = medicamentsActive.filter( m => m.code === code )
      }

      medicamentFound = medicamentFound.filter(( m ) => m.status === "DispensableInFuture" || m.status === "Dispensable");

      // console.log( medicamentFound );
      /* una vez comprobado si el medicamento esta en otras prescriptions vemos cual de todos tiene un endDate mayor */
      return medicamentFound.length > 0 ? this.ReturnBiggestEndDateMedicament( medicamentFound ) : null;

    } else {
      return null;
    }
  }

  ReturnBiggestEndDateMedicament( medicamentFound : Medicament [] ):Medicament{
    /* 
    *  del array de medicamentos activos tenemos que retornar 
    *  aquel que tenga el mayor endDate para que el que se vaya
    *  a dispensar sea como mínimo un startDate = ( endDate + 1 )  
    */
   

    const lastMedicamentDispensed = medicamentFound.reduce((a, b) => a.endDate > b.endDate ? a : b)

    return lastMedicamentDispensed ;
  }

  ConcatDataInMedicamentName( medicaments : Medicament[] ):Medicament[]{
    
    const formattedMedicaments = medicaments.map(( m ) => {
      // if( m.unit )return { ...m , name : m.name + ' ' + m.unitAmount + ' ' + m.unit.name };
      /* if( m.unit ) */return m.unit ? { ...m , name : m.name + ' ' + m.unitAmount + ' ' + m.unit.name } : {};
    })

    return formattedMedicaments

  }

  CalculateEndDateByDurationAndDurationUnitAsMonth( duration : number , startDate : string ):string{

    const myStartDate = new Date(startDate);
    myStartDate.setMonth(myStartDate.getMonth() + +duration);

    const myEndDate = this.formatDatesService.ToMyIsoString( new Date( myStartDate ) ) ;
    return myEndDate;
  }

  CalculateEndDateByDurationAndDurationUnitAsWeek( duration : number , startDate : string ):string {
    
    const daysToAdd = +duration * 7;

    const myEndDate = this.formatDatesService.ToMyIsoString( new Date( this.formatDatesService.SumDaysToDate( startDate , daysToAdd ) ) )  ;
    return myEndDate;
  }

  CalculateEndDateByDurationAndDurationUnitAsDay( duration : number , startDate : string ):string {
    
    const myEndDate = this.formatDatesService.ToMyIsoString( new Date( this.formatDatesService.SumDaysToDate( startDate , +duration ) ) );

    return myEndDate;
  }

  CalculatePackageAmountByHourSelection(rowData:Medicament , currentRowData:Medicament):number | undefined{
   
    const { unitAmount } = currentRowData || {};

    const myfrequencyAmount = rowData.frequencyAmount ? rowData.frequencyAmount : currentRowData.frequencyAmount; 
    const mytakingAmount = rowData.takingAmount ? rowData.takingAmount : currentRowData.takingAmount; 
    const myStartDate = rowData.startDate ? rowData.startDate : currentRowData.startDate; 
    const myEndDate = rowData.endDate ? rowData.endDate : currentRowData.endDate; 

    const daysOfTreatment = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(myStartDate , myEndDate) + 1;

    //en caso de tomas por unidad de frecuencia
    /* const unitsPerDay = mytakingAmount * ( 24 * myfrequencyAmount );

    
    const totalUnitsToTake = unitsPerDay * daysOfTreatment ; */
   

    //en caso de que frecuencia sea a cada 8 horas por ejemplo 
    if(mytakingAmount && myfrequencyAmount && unitAmount){
      const unitsPerDay = mytakingAmount * ( 24 / myfrequencyAmount );
      const totalUnitsToTake = Math.ceil( unitsPerDay * daysOfTreatment ) ;
      const finalPackagesAmount = Math.ceil( totalUnitsToTake / unitAmount );
      return finalPackagesAmount;
    }else {
      return 0;
    }
    

  }

  CalculatePackageAmountByDaySelection( rowData:Medicament , currentRowData:Medicament ):number | undefined{
    const { unitAmount } = currentRowData || {};

    const myfrequencyAmount = rowData.frequencyAmount ? rowData.frequencyAmount : currentRowData.frequencyAmount; 
    const mytakingAmount = rowData.takingAmount ? rowData.takingAmount : currentRowData.takingAmount; 
    const myStartDate = rowData.startDate ? rowData.startDate : currentRowData.startDate; 
    const myEndDate = rowData.endDate ? rowData.endDate : currentRowData.endDate;

    /* const unitsPerDay = mytakingAmount * myfrequencyAmount;
    const daysOfTreatment = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(myStartDate , myEndDate) + 1;
   
    const totalUnitsToTake = unitsPerDay * daysOfTreatment ; */

    /* en caso de que se cada 8 días por ejemplo y no 8 tomas cada día */
    const daysOfTreatment = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(myStartDate , myEndDate) + 1;
    

    if( myfrequencyAmount && mytakingAmount && unitAmount ){
      const totalOfDays = Math.ceil( daysOfTreatment / myfrequencyAmount );
      const totalUnitsToTake = totalOfDays * mytakingAmount;
      const finalPackagesAmount = Math.ceil( totalUnitsToTake / unitAmount );
      return finalPackagesAmount;
    } else {
      return 0;
    }

    
  }

  CalculatePackageAmountByWeekSelection( rowData:Medicament , currentRowData:Medicament ):number | undefined{

    const { unitAmount } = currentRowData || {};

    const myfrequencyAmount = rowData.frequencyAmount ? rowData.frequencyAmount : currentRowData.frequencyAmount; 
    const mytakingAmount = rowData.takingAmount ? rowData.takingAmount : currentRowData.takingAmount; 
    const myStartDate = rowData.startDate ? rowData.startDate : currentRowData.startDate; 
    const myEndDate = rowData.endDate ? rowData.endDate : currentRowData.endDate;

    /* const unitsPerWeek = mytakingAmount * myfrequencyAmount;
        const daysOfTreatment = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(myStartDate , myEndDate) + 1;
        const weeksOfTreatment = Math.ceil( daysOfTreatment / 7 );
        const unitsPerWeek = mytakingAmount * myfrequencyAmount;
        const finalPackagesAmount = Math.ceil( ( unitsPerWeek * weeksOfTreatment ) / unitAmount );
        return finalPackagesAmount;
    */

    const daysOfTreatment = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(myStartDate , myEndDate) + 1;
    const weeksOfTreatment = Math.ceil( daysOfTreatment / 7 );

    if( myfrequencyAmount && mytakingAmount && unitAmount ){
      const totalOfWeeks = Math.ceil( weeksOfTreatment / myfrequencyAmount );
      const totalOfUnits = totalOfWeeks * mytakingAmount;
      const finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
      return finalPackagesAmount;
    } else {
      return 0;
    }
  }

  CalculatePackageAmountByMonthSelection( rowData:Medicament , currentRowData:Medicament ):number | undefined{

    const { unitAmount } = currentRowData || {};

    const myfrequencyAmount = rowData.frequencyAmount ? rowData.frequencyAmount : currentRowData.frequencyAmount; 
    const mytakingAmount = rowData.takingAmount ? rowData.takingAmount : currentRowData.takingAmount;
    const myStartDate = rowData.startDate ? rowData.startDate : currentRowData.startDate; 
    const myEndDate = rowData.endDate ? rowData.endDate : currentRowData.endDate; 

    /* const unitsPerMonth = mytakingAmount * myfrequencyAmount;
    const daysOfTreatment = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(myStartDate , myEndDate) + 1;
    const monthsOfTreatment = Math.ceil( daysOfTreatment / 30 );
    const finalPackagesAmount = Math.ceil( ( unitsPerMonth * monthsOfTreatment ) / unitAmount );
    return finalPackagesAmount ; */ 

    /* en caso de que sean cada 2 meses y no 2 tomas cada mes */
    const daysOfTreatment = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(myStartDate , myEndDate) + 1;
    const monthsOfTreatment = Math.ceil( daysOfTreatment / 30 );

    if( myfrequencyAmount && mytakingAmount && unitAmount ){
      const totalOfMonths = Math.ceil( monthsOfTreatment / myfrequencyAmount );
      const totalOfUnits = totalOfMonths * mytakingAmount;
      const finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
  
  
      return finalPackagesAmount ;
    } else {
      return 0;
    }

    
  }

  CalculatedurationAmountByEndDate(rowData:Medicament , currentRowData:Medicament):number{
    
    const { endDate } = rowData || {};
    const { startDate } = currentRowData || {};

    const daysOfTreatment = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(startDate , endDate) + 1;
    return daysOfTreatment;
  }

  CalculatePackageForProtocols(rowData:Medicament , currentRowData:Medicament):number | undefined{
    
    const { unitAmount } = currentRowData || {};
    
    const myfrequencyAmount = rowData.frequencyAmount ? rowData.frequencyAmount : currentRowData.frequencyAmount; 
    const myFrequencyUnit = rowData.frequencyUnit ? rowData.frequencyUnit : currentRowData.frequencyUnit; 
    const mytakingAmount = rowData.takingAmount ? rowData.takingAmount : currentRowData.takingAmount;
    const myDurationUnit = rowData.durationUnit ? rowData.durationUnit : currentRowData.durationUnit;
    const myDurationAmount = rowData.durationAmount ? rowData.durationAmount : currentRowData.durationAmount;

    let finalPackagesAmount = 0 ;
    
    if( myfrequencyAmount && myFrequencyUnit && mytakingAmount && myDurationUnit && myDurationAmount && unitAmount ){
      if( myFrequencyUnit === 'hour' ){

        const takesForDay = 24 / myfrequencyAmount;
        const unitsPerDay = takesForDay * mytakingAmount;
  
        if( myDurationUnit === 'week' )
        {
         
          const totalOfTreatmentDays = 7 * myDurationAmount;
          
          const totalOfUnits = totalOfTreatmentDays * unitsPerDay;
         
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
  
        }
        else if( myDurationUnit === 'day' )
        {
          const totalOfUnits = myDurationAmount * unitsPerDay;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        }
        else if(myDurationUnit === 'month')
        {
         
          const totalOfTreatmentDays = ( 30 * myDurationAmount );
          const totalOfUnits = totalOfTreatmentDays * unitsPerDay;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        
        }
  
      }
      else if ( myFrequencyUnit === 'day' )
      {
        if( myDurationUnit === 'week' )
        {
          /* En este caso multiplicamos la duración por 7 días para obtener el número total de días en el tratamiento */
          const totalOfTreatmentDays = 7 * myDurationAmount;
          const daysOfTaking = totalOfTreatmentDays / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * daysOfTaking;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        }
        else if( myDurationUnit === 'day' )
        {
          /* total de tomas será cantidad de duración myDurationAmount partido entre myfrequencyAmount */
          const totalOfTakes = myDurationAmount / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * totalOfTakes;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
  
        }
        else if(myDurationUnit === 'month')
        {
          const totalOfTreatmentDays = ( 30 * myDurationAmount );
          const totalOfTakes = totalOfTreatmentDays / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * totalOfTakes;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        }
      }
      else if ( myFrequencyUnit === 'week' )
      {
        if( myDurationUnit === 'week' )
        {
          const totalOfTakes = myDurationAmount / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * totalOfTakes;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        }
        else if( myDurationUnit === 'day' )
        {
  
          const totalOfWeeksInTreatment = myDurationAmount / 7;
          const totalOfTakes = totalOfWeeksInTreatment / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * totalOfTakes;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
  
        }
        else if(myDurationUnit === 'month')
        {
          const totalOfWeeksInTreatment = myDurationAmount * 4;
          const totalOfTakes = totalOfWeeksInTreatment / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * totalOfTakes;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        }
      }
      else if ( myFrequencyUnit === 'month' )
      {
        if( myDurationUnit === 'week' )
        {
          const totalOfMonthsInTreatment = myDurationAmount / 4;
          const totalOfTakes = totalOfMonthsInTreatment / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * totalOfTakes;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        }
        else if( myDurationUnit === 'day' )
        {
          const totalOfMonthsInTreatment = myDurationAmount / 30;
          const totalOfTakes = totalOfMonthsInTreatment / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * totalOfTakes;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        }
        else if(myDurationUnit === 'month')
        {
          const totalOfTakes = myDurationAmount / myfrequencyAmount;
          const totalOfUnits = mytakingAmount * totalOfTakes;
          finalPackagesAmount = Math.ceil( ( totalOfUnits ) / unitAmount );
        }
      }
  
      return finalPackagesAmount ;
    } else {
      return 0;
    }
  }

  CalculatePackagesAmountFromForm(medicament : Medicament):void{
    // console.log(medicament);
  }

  
}



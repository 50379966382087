import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* msal */
import { 
  MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor, MSAL_INSTANCE, 
  MSAL_INTERCEPTOR_CONFIG, MsalService, MSAL_GUARD_CONFIG, MsalBroadcastService 
} from '@azure/msal-angular';

/* components */
import { AuthComponent } from './auth/auth.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* fontawsome */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/* ngrx */
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaEffects } from './store/MetaEffects'; 
import { metaReducers, reducers } from './store/Metareducers';
import { EffectsModule } from '@ngrx/effects';

/* modules */
import { SharedModule } from './shared/shared.module';

/* msal configs */
import * as msalConfigs from './msal.configs';
import { SideMenuModule } from './side-menu/side-menu.module';
import { NgOptimizedImage } from '@angular/common';

@NgModule({

  declarations: [
    AppComponent,
    AuthComponent
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule, 
    MsalModule,
    /* ReactiveFormsModule, */
    SharedModule,
    MsalModule,
    StoreModule.forRoot( reducers , {metaReducers}  ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    EffectsModule.forRoot(metaEffects),
    StoreRouterConnectingModule.forRoot(),
    HttpClientModule,
    FontAwesomeModule,
    SideMenuModule
  ],

  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalConfigs.MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalConfigs.MSALGuardConfigFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalConfigs.UsersMSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalConfigs.MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],

  bootstrap: [AppComponent , MsalRedirectComponent]
})
export class AppModule { }

















/* old config */
/* 

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      allowNativeBroker: false,
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function UsersMSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.apiUsersClientId,
      authority: environment.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      allowNativeBroker: false,
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(environment.url , environment.scopes);
  protectedResourceMap.set(environment.usersUrl , environment.apiUsersScopes);
 
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.scopes 
    }
  };
}

export function UsersMSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.apiUsersScopes 
    }
  };
}


providers: [

  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: UsersMSALGuardConfigFactory
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService
], */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap , exhaustMap, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { 
    addMedicamentToProtocol, addMedicamentToProtocolSuccess, deleteMedicamentToProtocol, deleteMedicamentToProtocolSuccess, 
    getFilteredProtocolAction , getFilteredProtocolSuccess, getProtocolsFailure, storeProtocolAction, storeProtocolFailure, storeProtocolSuccess 
} from '../../actions/prescriptions/protocols.actions';
import { ProtocolsService } from '../../../prescriptions/services';
import { ProtocolNewRes } from 'src/app/models';

@Injectable()

export class protocolsEffects {

    constructor( 
        private action$ : Actions,
        private protocolsService : ProtocolsService 
    ){}

    loadProtocols$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(getFilteredProtocolAction),
            exhaustMap(action =>
                {
                    return this.protocolsService.LoadProtocols(action.parameters)
                    .pipe(
                        map(protocols => getFilteredProtocolSuccess ( {protocols} )),
                        catchError((error : HttpErrorResponse) => {
                            return of( getProtocolsFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    storeSingleProtocols$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(storeProtocolAction),
            exhaustMap(action =>
                {
                    const { protocol } = action || {};

                    return this.protocolsService.CreateProtocol( protocol ).pipe(
                        map((protocolRes : ProtocolNewRes) => {

                            const { id } = protocolRes || {};

                            const myProtocol = { ...protocol , id : id };

                            return storeProtocolSuccess ( {myProtocol} )
                        
                        }),
                        catchError((error : HttpErrorResponse) => {
                            return of( storeProtocolFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    addMedicamentToProtocol$ = createEffect(() =>
        this.action$.pipe(
            ofType(addMedicamentToProtocol),
            mergeMap(( action ) => {
                return this.protocolsService.AddProtocolMedicament( action.medicament )
                .pipe(
                    map(() =>{

                        const { medicament } = action;
                        return addMedicamentToProtocolSuccess({ medicament })
                    })
                )
            })
        )
    );

    deleteMedicamentToProtocol$ = createEffect(() =>
        this.action$.pipe(
            ofType(deleteMedicamentToProtocol),
            mergeMap(( action ) => {
                const { protocolId , medicineId } = action;
                return this.protocolsService.DeleteMedicamentFromProtocol(protocolId , medicineId)
                .pipe(
                    map(() =>{
                        return deleteMedicamentToProtocolSuccess({ protocolId : protocolId , medicineId : medicineId })
                    })
                )
            })
        )
    );

    
}

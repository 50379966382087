import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Prescription } from 'src/app/models';

@Injectable()

export class PresTemplateToNewPresTableService {

  private subject = new Subject<Prescription>();
  private openModalsubject = new Subject<string>();

  sendMessage( message:Prescription) {
    this.subject.next(message);
  }

  clearMessages() {
    this.subject.next({});
  }

  onMessage(): Observable<Prescription> {
    return this.subject.asObservable();
  }

  sendOpenModalMessage( message : string ):void{
    this.openModalsubject.next( message );
  }

  sendOpenModalClearMessages():void{
    this.openModalsubject.next('');
  }

  sendOpenModalOnMessage():Observable<string>{
    return this.openModalsubject.asObservable();
  }


}

import { Injectable } from '@angular/core';
import { Indication } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})

export class OrderResultsService {

  OrderResultsByABC<T extends Indication>(results: T[], propertyToOrder: keyof T): T[] {
    const orderedResults = results.sort((a, b) => {
      const textA = (a[propertyToOrder] as string | undefined)?.toUpperCase() || '';
      const textB = (b[propertyToOrder] as string | undefined)?.toUpperCase() || '';
  
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  
    return orderedResults;
  }
}

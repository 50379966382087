import { createReducer, on } from "@ngrx/store";
import { NgrxPrescriptionsRes } from "src/app/models";
import * as prescriptionsFiltersActions from 'src/app/store/actions/prescriptions/prescriptionsPerPatient.actions';

export interface PrescriptionsPerPatient {
    prescriptionsPerPatient : NgrxPrescriptionsRes
}

const initialState : NgrxPrescriptionsRes = {};

export const PrescriptionsPerPatientReducer = createReducer(
    initialState,

    on( prescriptionsFiltersActions.getPrescriptionsByParamsSuccess , ( state , { prescriptions } ) =>{
        //si queremos meter en el estado una copia de prescriptions con el array items filtrando excluyendo status draft
        //state = { ...prescriptions , items : prescriptions.items.filter(p => p.status !== 'Draft' ) };
        state = prescriptions;
        return state;
    }),

    on( prescriptionsFiltersActions.getPrescriptionsByParamsFailure , ( state , { error } ) => {

        return  {
            error : error,
            first: 1,
            size: 10,
            totalPages : 0,
            totalCount: 0,
            items: []
        } ;
    })
)
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Patient, /* PatientsResOld , */ DoclineGetRes } from 'src/app/models';
import { PatientResWithParams, PatientsPaginationParams } from 'src/app/models/prescriptions/interfaces/patients';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PatientsService {

  constructor(
    private http : HttpClient
  ) { }

  LoadPatients():Observable<Patient>{
    return this.http.get<Patient>(environment.usersService.url + 'patient/search');
  }

  LoadPatientDocLineByIdPatient(body:{patientId:string}):Observable<DoclineGetRes>{

    return this.http.post<DoclineGetRes>(`${environment.prescriptionsService.url}patientdocline` , body);
  }

  LoadPatientByFilter(SelectedClinic:string):Observable<Patient[]>{
    return this.http.get<Patient[]>(`
      ${environment.usersService.url}patient/get-patients-by-areacode?areaCode=${SelectedClinic}`
    );
  }

  LoadPatientById(id:string | null):Observable<Patient>{
    
    return this.http.get<Patient>(`${environment.patientsService.url}patient/${id}`);
  }

  LoadPatientByParams():Observable<Patient>{
    return this.http.get<Patient>(environment.usersService.url + 'patient');
  }

  LoadPatientByTableParams( patientsTableFilters : PatientsPaginationParams ):Observable<PatientResWithParams>{


    const url = `${environment.patientsService.url}patient/by-parent-clinic-code-paged`;
    const builtUrl = this.BuildPatientsTableUrl( url , patientsTableFilters );

    return this.http.get<PatientResWithParams>( builtUrl );
  }

  /*__________________________________________________________________ API ________________________________________________________ */
  LoadPatientByClinicCode( SelectedClinic : string ):Observable<Patient[]>{
    return this.http.get<Patient[]>(`
      ${environment.patientsService.url}patient/by-parent-clinic-code?patientClinicCode=${SelectedClinic}`
    );
  }

  BuildPatientsTableUrl(url : string , patientsTableFilters : PatientsPaginationParams):string{
    
    if( Object.values( patientsTableFilters ).length > 0 )url += `?`;
    const { first , size , patientClinicCode , filter } = patientsTableFilters;
    if(first)url += `first=${first}&`;
    if(size)url += `size=${size}&`;
    if(patientClinicCode)url += `patientClinicCode=${patientClinicCode}&`;
    if(filter)url += `filter=${filter}&`;

    if(url.slice(-1) === '&')url = url.replace(/.$/,"");

    return url;
  }

  /* LoadPatientByClinicCode( parameters ):Observable<Patient[]>{
    const { selectedClinic } = parameters;
    return this.http.get<Patient[]>(`${environment.apiPatientsUrl}patient/by-parent-clinic-code?patientClinicCode=${selectedClinic}`);
  } */
}

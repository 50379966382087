import { Component , OnInit , OnDestroy , Output , EventEmitter , ViewChild, ChangeDetectorRef } from '@angular/core';
/* services */
import { 
  CheckUserRoleService , ComponentToDeletePopUpService, 
  ComponentToSpinnerService, FormatDatesService 
} from 'src/app/shared/services/index';
import { 
  MedicamentSearchPopUpToPrescriptionNewTableService , DynPrescriptionsService , 
  PrescriptionNewToPrescriptionsNewTableService , MedicinesService , 
  PresTemplateToNewPresTableService, HandleHTTPerrorsService , PrescriptionsAlertsService, MedicamentsFavoritesPopUpToPrescriptionNewTableServiceService, PrescriptionNewHelperService,
  PrescriptionsNewToMedicamentsTemplateService
} from 'src/app/prescriptions/services/index';
/* rxjs */
import { Subject, Subscription, takeUntil} from 'rxjs';
/* models */
import { MedicinRes, Medicament, Prescription, AppState, NgrxPrescriptionsRes } from 'src/app/models';
/* devextrem */
import { loadMessages } from "devextreme/localization";
import esMessages from 'devextreme/localization/messages/es.json';
/* fontawsome */
import { faPlus, IconDefinition , faTrash , faStar , faPrescriptionBottleMedical , faTriangleExclamation , faFlaskVial , faCapsules , faFloppyDisk , faXmark } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
/* ngrx state */
import { DxDataGridComponent } from 'devextreme-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { MedicamentValidatorsService } from '../../services/helpers/medicaments/medicament-validators.service';
import { CellClickEvent,EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { ValueChangedEvent } from 'devextreme/ui/html_editor';
import { AdministrationWay } from 'src/app/models/prescriptions/interfaces/protocols';
import { HidingEvent } from 'devextreme/ui/popup';
import { PrescriptionNewTableMagistralFormulaPopUpHelperServiceService } from '../../services/customs/prescription-new-table-magistral-formula-pop-up-helper-service.service';
import { MedicamentsFormalHelperService } from '../../services/helpers/medicaments/medicaments-formal-helper.service';
import { addFavoritesMedicaments, deleteFavoritesMedicaments } from 'src/app/store/actions/medicaments/favorites-medicaments.actions';
import { Router } from '@angular/router';
import { addFavoritesMasterFormulas, deleteFavoritesMasterFormulas } from 'src/app/store/actions/medicaments/favorites-masterFormulas.actions';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { addFavoritesParapharmacies, deleteFavoritesParapharmacies } from 'src/app/store/actions/medicaments/favorites-parafarmacies.actions';
import { environment } from 'src/environments/environment';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';

@Component({
  selector: 'app-prescription-new-table',
  templateUrl: './prescription-new-table.component.html',
  styleUrls: ['./prescription-new-table.component.scss']
})

export class PrescriptionNewTableComponent implements OnInit , OnDestroy {

  prescriptionsPerPatientTest:                                  NgrxPrescriptionsRes;
  testMessage = 'hola';
  medicamentsArr:                                               Medicament[] = [];
  dataSource:                                                   Medicament[];
  medicamentToInsert:                                           Medicament;
  subscription :                                                Subscription;
  medicamentsSubscription :                                     Subscription;
  masterFormulaSubscription :                                   Subscription;
  medicineSelected:                                             Medicament | undefined = {code:'' , name:''};
  prescriptionId:                                               string ;
  isDraft:                                                      boolean;
  medicamentFromPopUpSubscription:                              Subscription;
  medicamentFavoriteFromPopUpSubscription:                      Subscription;
  isDoctor:                                                     boolean;
  isNurse:                                                      boolean;
  isAdmin:                                                      boolean;
  printMedicamentSearchPopUp                                    = false;
  printPopUp                                                    = false;
  showMagistralFormulaPopUp                                     = false;
  favoritesMedicamentsPopUp                                     = false;
  faPlus:                                                       IconDefinition=faPlus;
  faXmark:                                                      IconDefinition=faXmark;
  faFloppyDisk:                                                 IconDefinition=faFloppyDisk;
  faStar:                                                       IconDefinition=faStar;
  faFlaskVial:                                                  IconDefinition=faFlaskVial;
  faPrescriptionBottleMedical:                                  IconDefinition=faPrescriptionBottleMedical;
  faTriangleExclamation:                                        IconDefinition=faTriangleExclamation;
  faCapsules:                                                   IconDefinition=faCapsules;
  faTrash:                                                      IconDefinition=faTrash;
  today:                                                        Date = new Date();
  selectedStartDate:                                            Date;
  printDeletePopUp                                              = false;
  prescriptionStatus:                                           string;
  limitEndDate:                                                 Date;
  patientPrescriptionsRecord:                                   NgrxPrescriptionsRes;
  limitEndDateMessage                                           = "";
  limitDaysMessage                                              = "";
  existsPsycotropic                                             = false;
  deleteWidthColumn                                             = 140;
  locale                                                        = "es";
  patientPrescriptionsRecordLoaded                              = false;
  medicamentKindfromUngroupedData:                              DataSource;
  medicamentKindSelected=                                       "";
  ShowWrongEndDateAlert                                         = false;
  prescriptionDraft:                                            Prescription;

  prescriptionsPerPatient$:                                     Subject<void> = new Subject<void>();
  medicamentdFavoritesDestroyed$:                               Subject<void> = new Subject<void>();
  masterFormulasFavoritesDestroyed$:                            Subject<void> = new Subject<void>();
  parapharmaciesFavoritesDestroyed$:                            Subject<void> = new Subject<void>();
  medicamentsFavorites:                                         Medicament[];
  masterFormulasFavorites:                                      Medicament[];
  parapharmaciesFavorites:                                      Medicament[];
  hiddenRows                                                    = false;
  whoIsParent                                                   = {from:'prescription_new_table' , child : 'master_formulas_form'};
  iAmParent:                                                    {from:string , parentId? : string | null , child : string} = { from : 'prescription-new-table' , child:'paraMedicament'}
  lastMedicamentSelected:                                       Medicament;  
  showPopUpMedicamentEdition                                    = false;  
  prescriptionForMedicaments                                    = true;       
  prescriptionForMagistralFormulas                              = true; 
  medicamentAction                                              = '';
  isEditingRow                                                  = false;
  showParapharmacyPopUp                                         = false;     
  showUnitAmountEditorField                                     = false 
  professionalId:                                               string = "";  
  isMedicament                                                  = false;
  isParaMedicament                                              = false;
  isMasterFormula                                               = false;
  favoriteMedicamentsLoaded                                     = false;             
  favoriteParapharmaciesLoaded                                  = false;             
  favoriteMasterFormulasLoaded                                  = false;
  showMedicamentSearchButton                                    = false;  
  showFavoriteSearchButton                                      = false;  
  showParaPharmacyCreateMobileForm                              = false;           
  showMasterFormulaCreateMobileForm                             = false;
  showProtocolSearchButton                                      = false;
  masterFormulaValidatorOpen                                    = false;
  paraPharmacyMobile:                                           Medicament = {
    startDate : new Date(),
    endDate: this.formatDatesService.AddDaysToADate(new Date().toString(),7) ,
    status : "DispensableInFuture",
    patientInstructions: 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta',
    posology: '',
    name: ''
  };
  masterFormulaMobile:                                          Medicament = {
    pharmaceuticalForm:'Colirio',
    takingUnit:'Gotas',
    dosage:'Gotas',
    administrationWay : { name : 'Gotas' , id:1 },
    packagesAmount:1,
    frequencyAmount:1,
    frequencyUnit:'day',
    durationAmount:1,
    endDate: this.formatDatesService.AddDaysToADate(new Date().toString(),7) ,
    startDate: this.formatDatesService.AddDaysToADate(new Date().toString(),0) ,
    durationUnit:'week', 
    narcotic: false,
    psychotropic:false,
    takingAmount:1,
    patientInstructions: 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta',
    pharmacistWarnings: 'Avisos de carácter farmacéutico',
    //composition : 'Composición de la fórmula',
    name: 'Nombre del medicamento'
  };
  windowWidth=                                                  0;
  mobileViewBreakPoint=                                         environment.mobileViewBreakPoint; 
  resizeSubscription:                                           Subscription;

  timeDuration:                                                                            {name:string , key:string}[]=[
    {"name":'día' , "key":'day'},
    {"name":'semana' , "key":'week'},
    {"name":'mes' , "key":'month'},
  ]

  frecuencyUnitTimeDuration:                                                               {name:string , key:string}[]=[
    {"name":'hora' , "key":'hour'},
    {"name":'día' , "key":'day'},
    {"name":'semana' , "key":'week'},
    {"name":'mes' , "key":'month'},
  ]

  pharmaceuticalForms:                                   {name:string , formId:number}[] = [
    {"name":"Colirio" ,               "formId":1},
    {"name":"Comprimido" ,            "formId":2},
    {"name":"Cápsulas" ,              "formId":3},
    {"name":"Jarabe" ,                "formId":4},
    {"name":"Ampollas bebibles" ,     "formId":5},
    {"name":"Ampollas Inyectables" ,  "formId":6},
  ];

  unitForMagistralFormulas:                                   {name:string , frecuencyAmountId:number}[] = [
    {"name":"Gotas" ,      "frecuencyAmountId":1},
    {"name":"Comprimido" , "frecuencyAmountId":2},
    {"name":"Mililitros" , "frecuencyAmountId":3},
    {"name":"Miligramos" , "frecuencyAmountId":4},
    {"name":"Microgramos" ,"frecuencyAmountId":5},
    {"name":"Ampollas" ,   "frecuencyAmountId":6},
    {"name":"UI" ,         "frecuencyAmountId":7},
    {"name":"Gramos" ,     "frecuencyAmountId":8},
    {"name":"Cápsulas" ,   "frecuencyAmountId":9}
  ];

  pharmaceuticalFormsgroupedData = new DataSource({
    store: new ArrayStore({
      data: this.pharmaceuticalForms,
      key: 'name',
    }),
    group: 'Category',
  });

  frecuencyUnitTimeDurationGroupedData = new DataSource({
    store: new ArrayStore({
      data: this.frecuencyUnitTimeDuration,
      key: 'name',
    }),
    group: 'Category',
  });

  timeDurationGroupedData = new DataSource({
    store: new ArrayStore({
      data: this.timeDuration,
      key: 'name',
    }),
    group: 'Category',
  });

  unitgroupedData = new DataSource({
    store: new ArrayStore({
      data: this.unitForMagistralFormulas,
      key: 'name',
    }),
    group: 'Category',
  });

  @Output() SendNewsMedicaments :                                                          EventEmitter<Medicament[]> = new EventEmitter;
  @Output() SendRefreshOrder:                                                              EventEmitter<string> = new EventEmitter;
  @Output() SendIfMedicamentOrMasterFormulas:                                              EventEmitter<boolean> = new EventEmitter;
  @ViewChild(DxDataGridComponent, { static: false }) prescriptionNewGridContainer:         DxDataGridComponent;

  constructor(
    private router:                                                                        Router,
    private store:                                                                         Store<AppState>,
    private presTemplateToNewPresTableService :                                            PresTemplateToNewPresTableService,
    private prescriptionNewToPrescriptionsNewTableService :                                PrescriptionNewToPrescriptionsNewTableService,
    private medicinesService :                                                             MedicinesService,
    private dynPrescriptionsService :                                                      DynPrescriptionsService,
    private medicamentSearchPopUpToPrescriptionNewTableService :                           MedicamentSearchPopUpToPrescriptionNewTableService,
    private medicamentsFavoritesPopUpToPrescriptionNewTableServiceService:                 MedicamentsFavoritesPopUpToPrescriptionNewTableServiceService,
    private checkUserRoleService:                                                          CheckUserRoleService,
    private formatDatesService:                                                            FormatDatesService,
    private componentToDeletePopUpService :                                                ComponentToDeletePopUpService,
    private componentToSpinnerService:                                                     ComponentToSpinnerService,
    private handleHTTPerrorsService:                                                       HandleHTTPerrorsService,
    private medicamentValidatorsService:                                                   MedicamentValidatorsService,
    private prescriptionsAlertsService:                                                    PrescriptionsAlertsService,
    private prescriptionNewTableMagistralFormulaPopUpHelperServiceService :                PrescriptionNewTableMagistralFormulaPopUpHelperServiceService,
    library:                                                                               FaIconLibrary,
    private prescriptionNewHelperService:                                                  PrescriptionNewHelperService,
    private medicamentsFormalHelperService:                                                MedicamentsFormalHelperService,
    private prescriptionsNewToMedicamentsTemplateService:                                  PrescriptionsNewToMedicamentsTemplateService,
    private windowResizeService :                                                          WindowResizeService,
    private cdr:                                                                           ChangeDetectorRef

  ) {

    this.initMessages();
    library.addIcons(faPlus);
    library.addIcons(faTrash);
    library.addIcons(faFlaskVial);
    library.addIcons(faPrescriptionBottleMedical);
    library.addIcons(faCapsules);
    library.addIcons(faStar);

    this.medicamentFromPopUpSubscription = this.medicamentSearchPopUpToPrescriptionNewTableService.OnMessage().subscribe((message:Medicament[]) => {
      if(message){

        this.lastMedicamentSelected = message[0];
        this.ReciveMedicamentFromPopUp(message);
      }
    })

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {

      this.windowWidth = width;
    });
  }

  ngOnInit(): void {
    this.ReciveMessages();
    this.CheckUserRole();
    this.GetPatientPrescriptionsRecord();
    this.SetMedicamentsKindCombo();
  }

  initMessages() { 
    loadMessages(esMessages);
  }

  SetMedicamentsKindCombo():void{

    this.medicamentKindfromUngroupedData = new DataSource({

      store: new ArrayStore({

        data: [
          {
            key:'medicament',name:'Medicamento Oficial',
          },
          {
            key:'parapharmacy',name:'Parafarmacia'
          },
          {
            key:'masterFormula',name:'Fórmula Magistral'
          },{
            key:'protocol',name:'Protocolos'
          },{
            key:'favorite',name:'Favoritos'
          }
        ],
        key: 'key',
      }),

      group: 'Category',
    });
  }

  CheckUserRole():void{
    const roles = this.checkUserRoleService.CheckUserRole();
    const {isDoctor , isAdmin , isNurse} = roles || {};
    if( isDoctor )this.isDoctor = isDoctor;
    if( isNurse )this.isNurse = isNurse;
    if( isAdmin )this.isAdmin = isAdmin;
  }

  ReciveMessages():void{

    this.medicamentsSubscription = this.prescriptionNewToPrescriptionsNewTableService.onMessage()

    .subscribe((message)=>{

      if(message){
        
        const {id , medicaments , status , masterFormulas , parapharmacyMedicaments , professionalId} = message;

        this.prescriptionDraft = message;

        if(professionalId)this.professionalId = professionalId;

        if(status)this.prescriptionStatus = status;

        this.prescriptionStatus !== 'Draft' ? this.isDraft = false : this.isDraft = true;

        this.isDraft ? this.deleteWidthColumn=40 : this.deleteWidthColumn=140;

        if(id)this.prescriptionId = id;
        
        this.prescriptionNewTableMagistralFormulaPopUpHelperServiceService.sendMessage(this.prescriptionId);

        if(medicaments){

          const myMedicaments = medicaments.map((m:Medicament) => {

            return { ...m , kind : 'medicament'  } 

          });

          this.dataSource = this.medicamentValidatorsService.ConcatDataInMedicamentName( myMedicaments );
          
          this.GetFavoritesMedicaments()
        }  
        
        if( masterFormulas ){

          const myMasterFormulas = masterFormulas.map((m:Medicament) => { 
            return { 
              ...m , kind : 'masterFormula' , 
              unit : { name : m.takingUnit}
            }});
          this.dataSource = [ ...this.dataSource , ...myMasterFormulas ];

          this.GetFavoritesMasterFormulas();
        }

        if( parapharmacyMedicaments ){

          const myParapharmacyMedicaments = parapharmacyMedicaments.map((pr) => { 
            return { 
              ...pr , fullName : pr.name , 
              kind : 'paraMedicament' , unit : { name :  pr.takingUnit }} });
          this.dataSource = [ ...this.dataSource , ...myParapharmacyMedicaments ];

          this.GetFavoritesParapharmacy();
        }

        this.dataSource = this.dataSource.map(( m : Medicament ) => {

          if(m.composition && m.pharmaceuticalForm){
            return { 
              ...m , 
              name : m.composition , 
              fullName : m.composition , 
              pharmaForm : { name : m.pharmaceuticalForm , id : 0 }/* ,
              unit : { name : m.administrationWay , id : 0 } */
            }
          } else {
            return { ...m };
          }

        })

        this.dataSource = this.medicamentsFormalHelperService.BuildPosologyField( this.dataSource );

        this.dataSource.length > 0 ? this.SendIfMedicamentOrMasterFormulas.emit(true) : this.SendIfMedicamentOrMasterFormulas.emit(false);

        this.SearchPsycotropicInPrescription();

        this.SendMedicamentsSelectedToMedicamentsTable();

        for (let i = 0; i < this.dataSource.length; i++) {
        
          this.medicamentsArr.push({name:this.dataSource[i].name,code:''});
        }

        this.SelectMedicamentsOrMasterFormulaView();
      }
    })

    this.subscription = this.presTemplateToNewPresTableService.onMessage().subscribe((message)=>{
      if(message){
        this.AddMedicaments(message.medicines)
      }
    })
  }

  SelectMedicamentKind( selectedMedicamentKind:ValueChangedEvent ):void{

    const { value } = selectedMedicamentKind || {};

    this.showMedicamentSearchButton = false;
    this.showParaPharmacyCreateMobileForm = false;
    this.showMasterFormulaCreateMobileForm = false;
    this.showFavoriteSearchButton = false;
    this.showProtocolSearchButton = false;

    switch (value) {
      case 'medicament':

        this.showMedicamentSearchButton = true;
      break;
      case 'parapharmacy':

        this.showParaPharmacyCreateMobileForm = true;
      break;
      case 'masterFormula':

        this.showMasterFormulaCreateMobileForm = true;
      break;
      case 'protocol':

        // this.printPopUp = true;
        this.showProtocolSearchButton = true;
      break;
      case 'favorite':
        this.showFavoriteSearchButton = true;
      // this.favoritesMedicamentsPopUp = true;
      break;

      default:
        break;
    }
  }

  OpenFavoriteSearchPopUp():void{

    this.favoritesMedicamentsPopUp = true
  }

  OpenProtocolSearchPopUp():void{

    this.printPopUp = true;
  }

  ReturnDataSourceWithAvailableDays( medicaments : Medicament[] ): Medicament[]{

    if( medicaments ){

      return medicaments.map(( m : Medicament ) => {

        const medicamentFounds = this.medicamentValidatorsService.GetMedicamentsInRecord( m , this.patientPrescriptionsRecord );
  
        if(medicamentFounds){
          
          if(medicamentFounds.length > 0){
  
            m.availableDays = this.medicamentValidatorsService.CalculateTotalDays(medicamentFounds);
  
          }else {
  
            m.availableDays = 365;
  
          }
  
        }else{
  
          m.availableDays=365;
        }
  
        return m;
      })
    } else {

      return medicaments;
    }
  }

  SearchPsycotropicInPrescription():void{
    const psycotropics = this.dataSource.filter(( m ) => m.narcotic || m.psychotropic );
    if( psycotropics.length > 0 ){
      this.existsPsycotropic = true;
      this.SetPsycotropicEndDateLimit( this.dataSource[0].startDate );
    }else{
      this.existsPsycotropic = false;
    }
  }

  SetPsycotropicEndDateLimit(date : string):void{
    this.limitEndDate = new Date( this.formatDatesService.AddDaysToADate( date , 90) );
  }

  GetPatientPrescriptionsRecord():void{

    this.store.select( AppState => AppState.PrescriptionsPerPatient ).pipe(takeUntil( this.prescriptionsPerPatient$ )).subscribe(( prescriptionsPerPatient : NgrxPrescriptionsRes | undefined ) => {
      
      if( prescriptionsPerPatient?.items ){

        this.prescriptionsPerPatientTest = prescriptionsPerPatient;
        this.patientPrescriptionsRecord = { ...prescriptionsPerPatient , items : prescriptionsPerPatient.items.filter(p => p.status !== 'Draft') };
        this.patientPrescriptionsRecordLoaded = true;

        if( this.windowWidth < this.mobileViewBreakPoint ){
          
          this.dataSource = this.ReturnDataSourceWithAvailableDays( this.dataSource );
        }
      } 
    })
  }

  GetFavoritesMasterFormulas():void{

    this.store.select( AppState => AppState.FavoritesMasterFormulas ).pipe( takeUntil( this.masterFormulasFavoritesDestroyed$ ) ).subscribe(( masterFormulasFavorites : Medicament [] ) => {

      if( masterFormulasFavorites.length > 0 ){

        if( masterFormulasFavorites[0].name !== 'initial_value' ){
  
          this.masterFormulasFavorites = [ ...masterFormulasFavorites ];
         
          this.dataSource = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.dataSource , this.masterFormulasFavorites , 'masterFormula' );
        }

        this.masterFormulasFavorites = [ ...masterFormulasFavorites ];

      } else {

        this.masterFormulasFavorites = [];

        this.dataSource = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.dataSource , this.masterFormulasFavorites , 'masterFormula' );
      }

      this.favoriteMasterFormulasLoaded = true;
    })
  }

  GetFavoritesParapharmacy():void{

    this.store.select( AppState => AppState.FavoritesParapharmacy ).pipe( takeUntil( this.parapharmaciesFavoritesDestroyed$ ) ).subscribe(( parapharmaciesFavorites : Medicament [] ) => {

      if( parapharmaciesFavorites.length > 0 ){

        if( parapharmaciesFavorites[0].name !== 'initial_value' ){
  
          this.parapharmaciesFavorites = [ ...parapharmaciesFavorites ];

          this.dataSource = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.dataSource , this.parapharmaciesFavorites , 'paraMedicament' );
         
        }

        this.parapharmaciesFavorites = [ ...parapharmaciesFavorites ];

      } else {

        this.parapharmaciesFavorites = [];

        this.dataSource = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.dataSource , this.parapharmaciesFavorites , 'paraMedicament' );
      }

      this.favoriteParapharmaciesLoaded = true;
    })
  }

  GetFavoritesMedicaments():void{

    this.store.select( AppState => AppState.FavoritesMedicaments ).pipe( takeUntil(this.medicamentdFavoritesDestroyed$) ).subscribe(( medicamentsFavorites : Medicament[] ) => {
      
      if( medicamentsFavorites.length > 0 ){

        if( medicamentsFavorites[0].name !== 'initial_value' ){
  
          this.medicamentsFavorites = [ ...medicamentsFavorites ];
          
          this.dataSource = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.dataSource , this.medicamentsFavorites , 'medicament' );
          
        }

        this.medicamentsFavorites = [ ...medicamentsFavorites ];

      } else {

        this.medicamentsFavorites = [];

        this.dataSource = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.dataSource , this.medicamentsFavorites , 'medicament' );
      }

      this.favoriteMedicamentsLoaded = true;
    })

  }

  OpenMyFavoritesPopUp():void{
    this.favoritesMedicamentsPopUp = true;
  }

  SendMedicamentsSelectedToMedicamentsTable():void{

    this.medicamentSearchPopUpToPrescriptionNewTableService.prescriptionNewTableToMedicamentsTableSend(this.dataSource);
    
    this.medicamentsFavoritesPopUpToPrescriptionNewTableServiceService.PrescriptionNewTableToMedicamentsFavoritesSend(this.dataSource);
  }

  GetMedicines(name?:string):void{
    this.medicinesService.LoadMedicines(name).subscribe((res:MedicinRes)=>{
      const {items} = res;
      if(items){
        if(items.length > 0){
          this.medicamentsArr = items;
          for (let x = 0; x < this.dataSource.length; x++) {
            const {name} = this.dataSource[x];
            this.medicamentsArr.push({name:name,code:''});
          }
        } else {
          this.dataSource = [];
          this.dataSource.length > 0 ? this.SendIfMedicamentOrMasterFormulas.emit(true) : this.SendIfMedicamentOrMasterFormulas.emit(false);
        }
      }
    },(error: HttpErrorResponse) => {
      this.handleHTTPerrorsService.HandleHTTPerrors( error );
    })
  }

  AddMedicaments(medicines:Medicament[] | undefined):void{
    this.SendNewsMedicaments.emit(medicines);
  }

  logEvent(e:any,action?:string) {

    if(action === 'onRowUpdated'){
      const {data} = e;

      const medicamentUpdated : Medicament = {
        ...data , 
        prescriptionId : this.prescriptionId , 
        medicineId : data.id
      };

      if(e.data.kind === 'paraMedicament'){
        
        const { favorite } = data || {};

        let parapharmacyFavorite : Medicament | undefined = this.parapharmaciesFavorites.find(( mf : Medicament ) => mf.name === data.name );

        if( parapharmacyFavorite && !favorite ){

          const { favoriteId }  = parapharmacyFavorite || {};

          if(favoriteId) this.RemoveFavoriteParapharmacyFromStore( favoriteId );
        }

        if( !parapharmacyFavorite && favorite ){
          
          this.StoreParapharmacyFavorite( data );
        }

        this.UpdateParaMedicament(medicamentUpdated);
      }

      if(e.data.kind === 'masterFormula'){

        const { favorite } = data || {};

        let masterFormulaFavorite : Medicament | undefined = this.masterFormulasFavorites.find(( mf : Medicament ) => mf.composition === data.composition );

        const myMasterFormulaToUpdate = this.prescriptionNewHelperService.BuildMasterFormulaToUpdate( {
          ...data , 
          prescriptionId : this.prescriptionId , 
          id : data.id , composition : data.fullName
        } );

        if( masterFormulaFavorite && !favorite ){

          const { favoriteId }  = masterFormulaFavorite || {};

          if(favoriteId) this.RemoveFavoriteMasterFormulaFromStore( favoriteId );
        }

        if( !masterFormulaFavorite && favorite ){
          
          this.StoreMasterFormulaFavorite( myMasterFormulaToUpdate );
        }

        this.UpdateMasterFormula( myMasterFormulaToUpdate );
      }

      if(e.data.kind === 'medicament'){

        const { favorite } = data || {};

        let medicamentFavorite : Medicament | undefined = this.medicamentsFavorites.find(( mf : Medicament ) => mf.medicineId === data.id );

        if( medicamentFavorite && !favorite ){

          const { favoriteId }  = medicamentFavorite || {};

          if(favoriteId) this.RemoveFavoriteFromStore( favoriteId );
        }

        if( !medicamentFavorite && favorite ){
          
          this.StoreFavoriteMedicine( data );
        }

        const medicamentUpdated : Medicament = {
          ...data , 
          prescriptionId : this.prescriptionId , 
          medicineId : data.id
        };

        this.UpdateMedicament(medicamentUpdated);
      }

    }else if(action === 'onCellPrepared'){

      if (e.rowType === "data" && e.column.command == "edit") {
        if( e.data.status !== 'DispensableInFuture'){

          e.cellElement.childNodes[0].classList.remove("dx-link-delete");
          e.cellElement.childNodes[0].classList.remove("dx-icon-trash");
          e.cellElement.childNodes[0].classList.remove("dx-link-icon");
          e.cellElement.childNodes[0].classList.remove("dx-link");
        }
      }
    }else if(action === "onEditorPreparing"){

      const {  row : { data : { kind } } } = e || {};

      if(kind === 'medicament'){
        this.isMedicament=true;
        this.isParaMedicament=false;
        this.isMasterFormula = false;
      }

      if(kind === 'masterFormula'){
        this.isMasterFormula = true;
        this.isMedicament=false;
        this.isParaMedicament=false;
      }

      if(kind === 'paraMedicament'){
        this.isMedicament=false;
        this.isParaMedicament=true;
        this.isMasterFormula = false;
      }

      if(e.dataField === "fullName"){
        
        if(kind === 'medicament') e.editorOptions.disabled = true;
        if(kind === 'paraMedicament') e.editorOptions.disabled = false;
        if(kind === 'masterFormula') e.editorOptions.disabled = false;

      }

      if(e.dataField === "unit.name"){
        
        if(kind === 'medicament') e.editorOptions.disabled = true;
        if(kind === 'paraMedicament') e.editorOptions.disabled = false;
        if(kind === 'masterFormula') e.editorOptions.disabled = false;

      }

      if(e.dataField === "unitAmount"){

        if(kind === 'medicament') e.editorOptions.disabled = true;
        if(kind === 'paraMedicament') e.editorOptions.disabled = false;
        if(kind === 'masterFormula') e.editorOptions.disabled = false;
      }

      if(e.dataField === "pharmacistWarnings"){
        if(kind === 'medicament') e.editorOptions.disabled = true;
        if(kind === 'paraMedicament') e.editorOptions.disabled = false;
        if(kind === 'masterFormula') e.editorOptions.disabled = false;
      }

      if(e.dataField === "endDate"){

        e.editorOptions.min = e.row.data.startDate;
        const { row : { data : { narcotic , psychotropic } } } = e || {};
        if( this.limitDaysMessage ){

          if( !(psychotropic || narcotic) ){
            e.editorOptions.max = this.formatDatesService.AddDaysToADate( e.row.data.startDate , +this.limitDaysMessage );
          }
        }

      } else if( e.dataField === "startDate" ){

        const lastMedicamentPrescribed = this.medicamentValidatorsService.CheckIfMedicamentAlreadyPrescribed( e.row.data , this.patientPrescriptionsRecord );

        if(lastMedicamentPrescribed){
          e.editorOptions.min = this.formatDatesService.AddDaysToADate( lastMedicamentPrescribed.endDate , 1 );
        }
      }

    }else if( action === 'onEditingStart' ){

      const { data } = e;

      const medicamentFounds = this.medicamentValidatorsService.GetMedicamentsInRecord( data , this.patientPrescriptionsRecord );
      
      if( this.existsPsycotropic ){

        this.limitDaysMessage = '90';

      }else{
        
        if(medicamentFounds){
        
          if(medicamentFounds.length > 0){

            const availableDays = this.medicamentValidatorsService.CalculateTotalDays(medicamentFounds);
            this.limitDaysMessage = availableDays.toString();
          }else {
            this.limitDaysMessage = '365';
          }
        }else{
          this.limitDaysMessage='365';
        }
      }
    }
  }

  RemoveFavoriteFromStore( favoriteId : string ){
    this.store.dispatch( deleteFavoritesMedicaments({ medicamentFavoritedId : favoriteId }) );
  }

  RemoveFavoriteMasterFormulaFromStore ( favoriteId : string ):void{
    this.store.dispatch( deleteFavoritesMasterFormulas({ masterFormulaFavoritedId : favoriteId }) );
  }

  RemoveFavoriteParapharmacyFromStore ( favoriteId : string ):void{
    this.store.dispatch( deleteFavoritesParapharmacies({ parapharmacyFavoritedId : favoriteId }) );
  }

  StoreFavoriteMedicine( medicament : Medicament ):void{
    
    const favoriteMedicamentToStore : Medicament = { ...medicament , medicineId : medicament.id , professionalId : this.professionalId };
    this.store.dispatch( addFavoritesMedicaments({ medicament : favoriteMedicamentToStore }) );
  }

  StoreMasterFormulaFavorite( masterFormulaFavorite : Medicament ):void{
    
    const favoriteMasterFormula = { ...masterFormulaFavorite , professionalId : this.professionalId };
    this.store.dispatch( addFavoritesMasterFormulas({ masterFormula : favoriteMasterFormula }) );
  }

  StoreParapharmacyFavorite( parapharmacyFavorite : Medicament ):void{
    
    const favoriteParapharmacy = { ...parapharmacyFavorite , professionalId : this.professionalId };
    this.store.dispatch( addFavoritesParapharmacies({ parapharmacy : favoriteParapharmacy }) );
  }

  UpdateMedicament(medicament:Medicament):void{

    if(!medicament.prescriptionId)medicament.prescriptionId = this.prescriptionId;

    if(!medicament.medicineId)medicament.medicineId = medicament.id;

    this.componentToSpinnerService.sendMessage('show');

    this.dynPrescriptionsService.UpdateMedicine(medicament).subscribe((res:{ isOk: boolean , error:string })=>{
      
      const { isOk } = res || {};
      if(isOk){
        this.medicamentAction = 'UPDATE'
        this.SendRefreshOrder.emit(medicament.prescriptionId);
      }
    },(error: HttpErrorResponse) => {
      this.handleHTTPerrorsService.HandleHTTPerrors( error );
    }) 
  }

  ValidateParamedicament( medicament : Medicament , action : string ):void{

    const { name , fullName } = medicament || {};


    if( action === 'create' ? ( name && name.length > 3 ) : ( fullName && fullName.length > 3 ) ){
 
      this.masterFormulaValidatorOpen=false;

      action === 'create' ? this.AddMobileParapharmacy( medicament ) : this.UpdateParaMedicament( medicament );
    
    } else {


      this.masterFormulaValidatorOpen=true;

      setTimeout(() => {
        this.masterFormulaValidatorOpen=false;
      }, 5000);
    }
  }

  UpdateParaMedicament( medicament : Medicament ):void{

    const { 
      status , patientInstructions , startDate , endDate , posology , 
      id , fullName , prescriptionId
    } = medicament || {};

    const myParaMedicament = {
      status: status,
      posology: posology,
      patientInstructions: patientInstructions,
      startDate: startDate,
      endDate: endDate,
      id: id,
      name: fullName,
      prescriptionId: prescriptionId ? prescriptionId : this.prescriptionId
    } 

    this.dynPrescriptionsService.UpdateParaMedicamentInPrescription(myParaMedicament).subscribe((res:{isOk: boolean,error: string}) => {

      const { isOk } = res || {};

      if(isOk){

        this.medicamentAction = 'UPDATE'
        this.SendRefreshOrder.emit(medicament.prescriptionId);
      }
    } ,(error: HttpErrorResponse) => {
      this.handleHTTPerrorsService.HandleHTTPerrors( error );
    }) 
  }

  BuildMasterFormulaToUpdate( masterFormula : Medicament ):void{

    const myMasterFormulaToUpdate = this.prescriptionNewHelperService.BuildMasterFormulaToUpdate( {
      ...masterFormula , 
      prescriptionId : this.prescriptionId , 
      id : masterFormula.id , composition : masterFormula.fullName
    } );

    if( this.ValidateMobileMasterFormula( masterFormula , 'update' )){

      this.UpdateMasterFormula( myMasterFormulaToUpdate );
    }
  }

  UpdateMasterFormula( masterFormulaUpdated : Medicament ):void{

    this.dynPrescriptionsService.UpdateMasterFormula( masterFormulaUpdated ).subscribe((res:{ isOk: boolean , error:string }) => {
      const { isOk } = res || {};
      if(isOk)this.SendRefreshOrder.emit(masterFormulaUpdated.prescriptionId);
    })
  }

  DeletePrescriptionMedicament( linea : CellClickEvent ):void{

    const { data : { id , name , composition , kind } } = linea;

    const message = { id: id , name : name , action : 'show' , composition : composition , kind : kind};

    this.componentToDeletePopUpService.sendMessage(message);

    this.printDeletePopUp = true;
  }

  DeletePrescriptionMedicamentMobileView( medicament : Medicament ):void{

    const { id , name , composition , kind }  = medicament;

    if( name ){

      const message = { id: id , name : name , action : 'show' , composition : composition , kind : kind};
      this.componentToDeletePopUpService.sendMessage(message);
      this.printDeletePopUp = true;
    }
  }

  CloseDeletePopUp(message:string):void{
    this.printDeletePopUp = false
    if(message !== 'cancel'){
      this.DeleteMedicine(this.prescriptionId , message);    
    } 
  }

  CloseDeleteParaMedicamentPopUp(paramedicament : Medicament):void{

    this.componentToSpinnerService.sendMessage('show');
    
    this.printDeletePopUp = false;
  
    this.DeleteParapharmacy( paramedicament.id );
  }

  DeleteParapharmacy( paramedicamentId : string ):void{
    
    this.dynPrescriptionsService.DeleteParaMedicament(this.prescriptionId , paramedicamentId).subscribe((res:boolean) => {

      if(res){

        if( this.prescriptionStatus === 'Manual' && this.dataSource.length === 1 ){

          this.DeletePrescription();        

        } else {

          this.GetMedicinesByPrescriptionId();
        }
      }
    })
  }

  DeleteMedicine(prescriptionId:string , medicineId:string):void{

    this.componentToSpinnerService.sendMessage('show');

    this.dynPrescriptionsService.DeleteMedicine(prescriptionId === "" ? this.prescriptionId : prescriptionId , medicineId).subscribe((res:boolean)=>{

      this.componentToSpinnerService.sendMessage('hide');
      if(res){

        if( this.prescriptionStatus === 'Manual' ){

          if(this.dataSource.length === 1){

            this.DeletePrescription();

          } else {

            this.GetMedicinesByPrescriptionId();
          }

        } else {

          if( this.isDraft ){

            this.SendRefreshOrder.emit( prescriptionId === "" ? this.prescriptionId : prescriptionId );

          }else{

            this.SetMedicamentDisabled(medicineId);
          }
        }
      }
    },(error: HttpErrorResponse) => {
      this.componentToSpinnerService.sendMessage('hide');
      const message : {name:string,id:string,action:string} = {name:'',id:'',action:'hide'} ;
      this.componentToDeletePopUpService.sendMessage(message);
      this.handleHTTPerrorsService.HandleHTTPerrors(error);
    })
  }

  CloseDeleteMasterFormulaPopUp( e : {name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string} ):void{
    const { id } = e || {};
    this.DeleteMasterFormula(id);  
  }

  CloseDeleteParaPharmacyPopUp( e : {name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string} ):void{
    const { id } = e || {};
    
  } 

  DeleteMasterFormula( masterFormulaId : string ):void{
    
    this.componentToSpinnerService.sendMessage('show');
    this.medicamentAction = 'DELETE';
    this.printDeletePopUp = false;
    this.dynPrescriptionsService.DeleteMasterFormula( masterFormulaId ).subscribe((res : boolean) => {
      
      if(res){

        if( this.prescriptionStatus === 'MasterFormula' && this.dataSource.length === 1 ){

          this.DeletePrescription();

        }else {

          this.GetMedicinesByPrescriptionId();
        }
      }
    })
  }

  CloseMedicamentsSearchPopUp(message:string):void{
    if(message === 'close_medicaments_search_pop_up'){
      this.printMedicamentSearchPopUp = false;
    }
  }

  OpenCreateMasterFormula():void{
    this.showMagistralFormulaPopUp = true;
  }

  OpenParaMedicamentSearchPopUp():void{
    this.showParapharmacyPopUp = true;
  }

  SetMedicamentDisabled(medicineId : string ):void{

    this.dataSource = this.dataSource.map(( m ) => {
      return m.id === medicineId ? { ...m , status : 'Deleted' } : m ;
    })
  }

  ReciveMedicamentFromPopUp(medicamentsSelected:Medicament[]):void{ 
    
    for (let i = 0; i < medicamentsSelected.length; i++) {

      if( this.CheckIfPsycotropicOrNarcoticMedicament( medicamentsSelected[i] ) && this.dataSource.length > 0){

        this.printMedicamentSearchPopUp = false;
        this.favoritesMedicamentsPopUp = false;
        if(medicamentsSelected[i].favoriteId)this.SendMedicamentsSelectedToMedicamentsTable();
        this.componentToSpinnerService.sendMessage('hide');
        this.prescriptionsAlertsService.BuildPrescriptionsAlert(`No es posible añadir un medicamento psycotrópico o estupefaciente junto a otros medicamentos`,`ATENCIÓN , cree una nueva receta para este medicamento`);

      }else{

        if(this.CheckIfPsycotropicOrNarcoticMedicament( medicamentsSelected[i] )) this.printMedicamentSearchPopUp = false;
        
        const medicamentFounds = this.medicamentValidatorsService.GetMedicamentsInRecord( medicamentsSelected[i] , this.patientPrescriptionsRecord );

        if(medicamentFounds){

          if(medicamentFounds.length > 0){

            const availableDays = this.medicamentValidatorsService.CalculateTotalDays(medicamentFounds);

            if( this.CheckMedicamentAvailableDays( availableDays ) ){

              this.GetLastMedicamentPrescribed( medicamentsSelected[i] , this.patientPrescriptionsRecord );
            }
          }else{

            this.SetMedicamentFirstTime( medicamentsSelected[i] );
          }
        }
      }
    }
  }

  CheckIfPsycotropicOrNarcoticMedicament( medicamentSelected : Medicament ):boolean{
    const { psychotropic , narcotic } = medicamentSelected;
    return psychotropic || narcotic ? true : false;
  }

  SetMedicamentFirstTime(medicamentSelected : Medicament):void{

    medicamentSelected.startDate = new Date();
    medicamentSelected.endDate = this.formatDatesService.AddDaysToADate(new Date().toString() , 7);
    medicamentSelected.favoriteId ? this.InsertMedicineInDraft({ ...medicamentSelected , prescriptionId:this.prescriptionId }) : this.AddNewMedicament(medicamentSelected);
  }

  CheckMedicamentAvailableDays( availableDays : number ):boolean{
    if( availableDays > 0 ){
      return true;
    }else{
      this.componentToSpinnerService.sendMessage('hide');
      this.printMedicamentSearchPopUp = false;
      this.prescriptionsAlertsService.BuildPrescriptionsAlert( `Este medicamento no puede exceder el número de días de tratamiento , revise otras recetas en los que este medicamento haya sido prescrito`,`ATENCIÓN este medicamento alcanzó el limite de tiempo`);
      return false;
    }
  }

  GetLastMedicamentPrescribed( medicamentSelected : Medicament , patientPrescriptionsRecord : NgrxPrescriptionsRes ):void{
    
    const lastMedicamentPrescribed = this.medicamentValidatorsService
    .CheckIfMedicamentAlreadyPrescribed( medicamentSelected , patientPrescriptionsRecord );

    if( lastMedicamentPrescribed ){
      this.SetMedicamentDates( medicamentSelected , lastMedicamentPrescribed );
    }else{

      medicamentSelected.startDate = new Date();
      medicamentSelected.endDate = new Date();
    }
    medicamentSelected.favoriteId ? this.InsertMedicineInDraft({ ...medicamentSelected , prescriptionId:this.prescriptionId }) : this.AddNewMedicament(medicamentSelected);
    // this.SendNewMedicament.emit(medicamentSelected);
  }

  SetMedicamentDates( medicamentSelected : Medicament , lastMedicamentPrescribed : Medicament ):void{
    const { endDate } = lastMedicamentPrescribed;
    const myDate = new Date( endDate );

    if( this.formatDatesService.VerifyLessThanToday( myDate.toString() ) ){

      myDate.setDate( myDate.getDate() + 1 )
      medicamentSelected.startDate = myDate;
      medicamentSelected.endDate = myDate;

    }else{

      medicamentSelected.startDate = new Date();
      medicamentSelected.endDate = new Date();
    }
  }

  onEditorPreparing = (e:EditorPreparingEvent) => {
    if (e.parentType === "dataRow" && e.dataField === "CustomerID") {
      e.editorOptions.onValueChanged = function (ev : ValueChangedEvent) {
        const selectedItem = ev.component.option("selectedItem");
        e.setValue(selectedItem);
      };
    }
  };

  InsertMedicineDDBB(medicament : Medicament):void{
    this.dynPrescriptionsService.InsertMedicineDDBB(medicament).subscribe((res :{error?:string , id?:string , isOk?:boolean})=>{
      const {id} = res;
      medicament.medicineId = id;
      this.InsertMedicineInDraft(medicament);
    },(error: HttpErrorResponse) => {
      this.handleHTTPerrorsService.HandleHTTPerrors( error );
    })
  }

  AddNewMedicament(medicament : Medicament):void{
    const filledMedicament = { 
      ...medicament , prescriptionId : this.prescriptionId , takingAmount:1 , packagesAmount:1 , frequencyAmount:1 , 
      frequencyUnit: 'day' , durationAmount: 1 , durationUnit: 'week' , patientInstructions: 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta' , 
      pharmacistWarnings:'avisos farmaceuticos'
    };
    this.InsertMedicineDDBB(filledMedicament);
  }

  InsertMedicineInDraft( medicament : Medicament ):void{

    this.dynPrescriptionsService.InsertNewMedicineToDraft(medicament).subscribe((res)=>{
      if(res){

        const { favoriteId } = medicament || {};

        if( favoriteId ){

          this.medicamentAction = 'INSERT_FAVORITE';
        
        } else {

          this.medicamentAction = 'INSERT';
        }

        this.medicamentToInsert = medicament;
        this.GetMedicinesByPrescriptionId();
      }
    })
  }

  CloseFormulaMagistralPopUp( masterFormula : Medicament ):void{
    this.showMagistralFormulaPopUp = false;
    this.InsertMasterFormulaInDraft( masterFormula );
  }

  SendMedicamentFromPopUpFavoritetoAdd( medicament : Medicament[] ):void{

    const { kind } = medicament[0] || {};

    if( kind === 'masterFormula' ){

      this.InsertMasterFormulaInDraft( medicament[0] );
    }

    if( kind === 'medicament' ){

      this.ReciveMedicamentFromPopUp(medicament);
    }

    if( kind === 'paraMedicament' ){

      this.InsertParapharmacyInDraft( medicament[0] );
    }
  }

  ValidateMobileMasterFormula( masterFormula : Medicament , action:string ):boolean{

    if( 
      ( action === 'insert' ? masterFormula.composition : masterFormula.fullName ) &&
      masterFormula.dosage &&
      masterFormula.pharmaceuticalForm &&
      ( masterFormula.takingAmount && masterFormula.takingAmount > 0 ) &&
      ( masterFormula.frequencyAmount && masterFormula.frequencyAmount > 0 ) && 
      ( masterFormula.durationAmount && masterFormula.durationAmount > 0 ) &&
      ( masterFormula.packagesAmount && masterFormula.packagesAmount > 0 )
    ){

      this.masterFormulaValidatorOpen=false;

      return true;
      
    } else {

      this.masterFormulaValidatorOpen=true;

      setTimeout(() => {
        this.masterFormulaValidatorOpen=false;
      }, 5000);

      return false;
    }
  }

  AddMobileMasterFormula( masterFormula : Medicament ):void{
  
    const masterFormulaToUpdate = { 
      ...masterFormula , 
      startDate : this.formatDatesService.AddDaysToADate(new Date(masterFormula.startDate).toString(),0),
      endDate : this.formatDatesService.AddDaysToADate(new Date(masterFormula.endDate).toString(),0)
    } ;

    if( this.ValidateMobileMasterFormula( masterFormulaToUpdate , 'insert' ) ){

      this.showMasterFormulaCreateMobileForm = false;

      this.InsertMasterFormulaInDraft( masterFormulaToUpdate );
    }
  }

  OrderCloseAlert():void{
    this.masterFormulaValidatorOpen=false;
  }

  InsertMasterFormulaInDraft( masterFormula : Medicament ):void{
      
    let masterFormulaMapped : Medicament = this.prescriptionNewHelperService.MasterFormulaMapper( masterFormula );

    this.medicamentAction = 'INSERT_MASTERFORMULA';

    masterFormulaMapped = { ...masterFormulaMapped , prescriptionId : this.prescriptionId };

    this.dynPrescriptionsService.InsertMasterFormula(masterFormulaMapped).subscribe((res:{ isOk:boolean , error?:'' }) => {

      if(res){

        this.GetMedicinesByPrescriptionId();
        
      }
    })
  }

  AddMobileParapharmacy( parapharmacy : Medicament ):void{

    this.paraPharmacyMobile = {};

    this.showParaPharmacyCreateMobileForm = false;
  
    this.InsertParapharmacyInDraft( parapharmacy );
  }

  InsertParapharmacyInDraft( parapharmacy : Medicament ):void{
      
    this.medicamentAction = 'INSERT_PARAMEDICAMENT';

    parapharmacy = { ...parapharmacy , prescriptionId : this.prescriptionId };

    this.dynPrescriptionsService.InsertParamedicament(parapharmacy).subscribe((res:{ isOk:boolean , error?:'' }) => {

      if(res){

        this.GetMedicinesByPrescriptionId();
        
      }
    })
  }

  CheckIfMasterFormulaAlreadyExists( masterFormula : Medicament ):boolean{

    const { composition } = masterFormula || {};

    return this.dataSource.find(( m : Medicament ) => m.composition === composition) ? true : false;
  }

  setEndDateValue = ( rowData : Medicament , value : string , currentRowData : Medicament ) => {

    rowData.startDate = value;

    const { durationUnit , durationAmount } = currentRowData || {};

    if( this.CheckIfPsycotropicOrNarcoticMedicament(currentRowData) ){

      this.limitEndDate = new Date( this.formatDatesService.AddDaysToADate( rowData.startDate , 90) )
    }

    this.CalculateEndDate(rowData , durationAmount , rowData , 'startDate' , durationUnit)
  }

  setEndDateValueMobileForm = ( rowData : Medicament , event : ValueChangedEvent , currentRowData : Medicament ) => {

    const { kind } = rowData || {};

    if( kind === 'medicament' ){

      const value = event.value

      rowData.startDate = value;
  
      const { durationUnit , durationAmount } = currentRowData || {};
  
      if( this.CheckIfPsycotropicOrNarcoticMedicament(currentRowData) ){
  
        this.limitEndDate = new Date( this.formatDatesService.AddDaysToADate( rowData.startDate , 90) );
  
      }
  
      this.CalculateEndDateForMobileForm(rowData , durationAmount , currentRowData , 'startDate' , durationUnit);
    }
  } 

  setEndDateDependingAmount = ( rowData : Medicament , value : number, currentRowData : Medicament ) => {

    rowData.durationAmount = value;

    if(currentRowData.kind === "medicament" || currentRowData.kind === 'masterFormula'){
      const { durationUnit } = currentRowData || {};
      this.CalculateEndDate(rowData , value , currentRowData , 'durationAmount' , durationUnit);
    }
  }

  SetEndDateDependingAmountMobileForm = ( medicament : Medicament , value : string ) => {

    if(medicament.kind === "medicament" || medicament.kind === 'masterFormula'){
      const { durationUnit } = medicament || {};

      value = value === "" ? "1" : value;

      this.CalculateEndDateForMobileForm({durationAmount : +value} , +value , medicament , 'durationAmount' , durationUnit);
    }
  }

  SetEndDateDependingDurationUnitMobileForm = ( medicament : Medicament , event : ValueChangedEvent ) => {

    const { kind } = medicament || {};

    if( kind === 'medicament' ){

      const { value } = event || {};

      if(medicament.kind === "medicament" || medicament.kind === "masterFormula"){
        
        this.CalculateEndDateForMobileForm({durationUnit : value} , medicament.durationAmount , medicament , 'durationUnit' , medicament.durationUnit);
      }
    }
  }


  setEndDateDependingDurationUnit = ( rowData : Medicament , value : string , currentRowData:Medicament ) => {

    rowData.durationUnit = value;

    if(currentRowData.kind === "medicament" || currentRowData.kind === "masterFormula"){
      const { durationAmount } = currentRowData || {};
      this.CalculateEndDate(rowData , durationAmount , currentRowData , 'durationUnit' , rowData.durationUnit);
    }
  }

  CalculateEndDateForMobileForm( rowData : Medicament , value : number | undefined  , currentRowData : Medicament , from:string , durationUnit : string | undefined ):void{

    const { startDate , durationAmount } = currentRowData || {};

    let calculatedEndDate  = '' ;

    const medicament = this.dataSource.find((m: Medicament) => m.id === currentRowData.id);

    if (medicament) {

      if(this.existsPsycotropic){

        if( value ){

          if( ( durationUnit === 'day' && value > 90 ) || ( durationUnit === 'week' && value > 12 ) || ( durationUnit === 'month' && value > 3 ) ){
  
            this.ShowWrongEndDateAlert = true;
            this.RemoveWrongEndDateAlert();
           
            if(from === 'durationUnit'){

              medicament.durationUnit = currentRowData.durationUnit;
            }else if( from === 'durationAmount' ){
              
              medicament.durationAmount = durationAmount;
            }else if(from === 'startDate'){
              medicament.startDate = startDate;
            }
    
          }else{
    
            if(durationUnit === 'month')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsMonth(value  , startDate);
            if(durationUnit === 'week')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsWeek(value  , startDate);
            if(durationUnit === 'day')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsDay(value  , startDate);

            medicament.endDate = calculatedEndDate;

            this.SetFinalPackageAmountForMobileForm(medicament, calculatedEndDate , 'endDate');

          }
        }
  
      }else{
  
        if(value){
  
          if(durationUnit === 'month')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsMonth(value  , startDate);
          if(durationUnit === 'week')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsWeek(value  , startDate);
          if(durationUnit === 'day')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsDay(value  , startDate);
        }

        const daysToPrescribe = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(startDate , calculatedEndDate);

        const medicamentFounds = this.medicamentValidatorsService.GetMedicamentsInRecord( medicament , this.patientPrescriptionsRecord );

        const availableDays = medicamentFounds && medicamentFounds.length > 0 ? this.medicamentValidatorsService.CalculateTotalDays(medicamentFounds) : '365';
  
        if( daysToPrescribe >= + availableDays ){
  
          this.ShowWrongEndDateAlert = true;

          this.RemoveWrongEndDateAlert();

          if(from === 'durationUnit'){
            
            medicament.durationUnit = currentRowData.durationUnit;
  
          }else if( from === 'durationAmount' ){
            
            medicament.durationAmount = durationAmount;

          }else if(from === 'startDate'){

            medicament.startDate = startDate;
  
          }

          medicament.endDate = medicament.startDate;
          
        }else{ 

          medicament.endDate = calculatedEndDate;

          this.SetFinalPackageAmountForMobileForm( medicament , calculatedEndDate , 'endDate');
        }
      }

      Object.assign(medicament, { 
        durationUnit: rowData.durationUnit ? rowData.durationUnit : medicament.durationUnit , 
        durationAmount : !medicament.durationAmount ? "" : medicament.durationAmount , 
        startDate : medicament.startDate , 
        endDate : calculatedEndDate });

      this.cdr.detectChanges();
    }
  } 

  CalculateEndDate( rowData : Medicament , value : number | undefined  , currentRowData : Medicament , from:string , durationUnit : string | undefined ):void{
    
    const { startDate , durationAmount } = currentRowData || {};

    let calculatedEndDate  = '' ;

    if(this.existsPsycotropic){
      if( value ){
        if( ( durationUnit === 'day' && value > 90 ) || ( durationUnit === 'week' && value > 12 ) || ( durationUnit === 'month' && value > 3 ) ){

          this.ShowWrongEndDateAlert = true;
          this.RemoveWrongEndDateAlert();
         
          if(from === 'durationUnit'){
            rowData.durationUnit = currentRowData.durationUnit;
          }else if( from === 'durationAmount' ){
            
            rowData.durationAmount = durationAmount;
          }else if(from === 'startDate'){
            rowData.startDate = startDate;
          }
  
        }else{
  
          if(durationUnit === 'month')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsMonth(value  , startDate);
          if(durationUnit === 'week')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsWeek(value  , startDate);
          if(durationUnit === 'day')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsDay(value  , startDate);
          rowData.endDate = calculatedEndDate;
          this.SetFinalPackageAmount(rowData, currentRowData);
        }
      }

    }else{

      if(value){

        if(durationUnit === 'month')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsMonth(value  , startDate);
        if(durationUnit === 'week')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsWeek(value  , startDate);
        if(durationUnit === 'day')calculatedEndDate=this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsDay(value  , startDate);
      }
      const daysToPrescribe = this.formatDatesService.CalculateNumberOfDaysBetweenTwoDates(startDate , calculatedEndDate);

      if( daysToPrescribe >= +this.limitDaysMessage ){

        this.ShowWrongEndDateAlert = true;
        this.RemoveWrongEndDateAlert();
        if(from === 'durationUnit'){
          
          rowData.durationUnit = currentRowData.durationUnit;

        }else if( from === 'durationAmount' ){
          
          rowData.durationAmount = durationAmount;
        }else if(from === 'startDate'){
          rowData.startDate = startDate;

        }
        rowData.endDate = rowData.startDate;
      }else{
        rowData.endDate = calculatedEndDate;
        this.SetFinalPackageAmount(rowData, currentRowData);
      }
    }
  }
  
  RemoveWrongEndDateAlert():void {
    setTimeout(() => {
      this.ShowWrongEndDateAlert = false;
    }, 4000);
  }

  /************************************************* CALCULOS DE PACKAGEAMOUNT ************************************************/
  setPackageAmountFromTakinAmount = ( rowData : Medicament , value : number , currentRowData : Medicament ) => {

    rowData.takingAmount = value;

    if(currentRowData.kind === "medicament" || currentRowData.kind === "masterFormula"){

      this.SetFinalPackageAmount(rowData , currentRowData);
    }
  }

  setPackageAmountFromFrecuencyAmount  = ( rowData : Medicament , value : number , currentRowData : Medicament ) => {

    rowData.frequencyAmount = value;

    if(currentRowData.kind === "medicament" || currentRowData.kind === "masterFormula"){
      
      this.SetFinalPackageAmount(rowData , currentRowData);
    }
  }

  setPackageAmountFromFrecuencyUnit = ( rowData : Medicament , value : string , currentRowData : Medicament ) => {

    rowData.frequencyUnit = value;

    if(currentRowData.kind === "medicament" || currentRowData.kind === "masterFormula"){
      
      this.SetFinalPackageAmount(rowData , currentRowData);
    }
  }

  setPackageAmountAndDurationAmountFromEndDate = ( rowData : Medicament , value : string , currentRowData : Medicament ) => {

    rowData.endDate = value;
    
    if(currentRowData.kind === "medicament" || currentRowData.kind === 'masterFormula'){
      
      this.SetFinalPackageAmount(rowData , currentRowData);

      rowData.durationAmount = this.medicamentValidatorsService.CalculatedurationAmountByEndDate( rowData , currentRowData );

      rowData.durationUnit = 'day';
    }
  }

  setPackageAmountAndDurationAmountFromEndDateFormMobile( rowData : Medicament , event : ValueChangedEvent , currentRowData : Medicament ):void{

    const { kind , id } = currentRowData || {};

    const { value } = event || {};

    if( kind === "medicament" ){
      
      this.SetFinalPackageAmountForMobileForm(rowData , value , 'endDate');

      const medicament = this.dataSource.find((m: Medicament) => m.id === id);

      if( medicament ){

        if( event.event ){

          medicament.durationAmount = this.medicamentValidatorsService.CalculatedurationAmountByEndDate( { endDate : value } , currentRowData );

        } else {

          medicament.durationAmount = currentRowData.durationAmount;
        }

        medicament.durationUnit = 'day';

        Object.assign(medicament, { packagesAmount: medicament.packagesAmount , durationUnit : medicament.durationUnit });

        this.cdr.detectChanges();
      }
    } 
  }

  SetFinalPackageAmount(rowData : Medicament , currentRowData : Medicament):void{

    const myFrequencyUnit = rowData.frequencyUnit ? rowData.frequencyUnit : currentRowData.frequencyUnit; 

    if (myFrequencyUnit === 'hour' ){

      const finalPackagesAmount = this.medicamentValidatorsService.CalculatePackageAmountByHourSelection(rowData , currentRowData);
      rowData.packagesAmount = finalPackagesAmount;

    } else if ( myFrequencyUnit === 'day' ){

      const finalPackagesAmount = this.medicamentValidatorsService.CalculatePackageAmountByDaySelection(rowData , currentRowData);
      rowData.packagesAmount = finalPackagesAmount;

    }else if ( myFrequencyUnit === 'week' ){

      const finalPackagesAmount = this.medicamentValidatorsService.CalculatePackageAmountByWeekSelection(rowData , currentRowData);
      rowData.packagesAmount = finalPackagesAmount;

    }else if ( myFrequencyUnit === 'month' ){
      
      const finalPackagesAmount = this.medicamentValidatorsService.CalculatePackageAmountByMonthSelection(rowData , currentRowData);
      rowData.packagesAmount = finalPackagesAmount;
    }
  }


  SetFinalPackageAmountForMobileFormFromSelector( rowData : Medicament , event: ValueChangedEvent , key : string ):void{

    const { kind } = rowData || {};
    
    if( kind === 'medicament' ){

      const { value } = event || {};

      this.SetFinalPackageAmountForMobileForm( rowData , value , key  )
    }
  }

  SetFinalPackageAmountForMobileForm( rowData : Medicament , value: string , key : string ):void{

    const { id , frequencyUnit } = rowData || {};

    const medicament = this.dataSource.find((m: Medicament) => m.id === id);

    if (medicament) {

      const myRowData = { [key] : key === 'frequencyUnit' || key === 'durationUnit' ? value : + value };

      if (frequencyUnit === 'hour') {

        const finalPackagesAmount = this.medicamentValidatorsService.CalculatePackageAmountByHourSelection(myRowData, rowData);

        medicament.packagesAmount = finalPackagesAmount;

      } else if (frequencyUnit === 'day') {

        const finalPackagesAmount = this.medicamentValidatorsService.CalculatePackageAmountByDaySelection(myRowData, rowData);

        medicament.packagesAmount = finalPackagesAmount;

      } else if (frequencyUnit === 'week') {

        const finalPackagesAmount = this.medicamentValidatorsService.CalculatePackageAmountByWeekSelection(myRowData, rowData);

        medicament.packagesAmount = finalPackagesAmount;

      } else if (frequencyUnit === 'month') {

        const finalPackagesAmount = this.medicamentValidatorsService.CalculatePackageAmountByMonthSelection(myRowData, rowData);

        medicament.packagesAmount = finalPackagesAmount;
      }

      Object.assign(medicament, { packagesAmount: !medicament.packagesAmount ? 1 : medicament.packagesAmount });

      this.cdr.detectChanges();
    }
  }

  /************************************************* FINAL CALCULOS DE PACKAGEAMOUNT ************************************************/

  setCellValue = (rowData : Medicament , value :string) => {

    if(rowData){
      rowData.name = value;
      this.medicineSelected = this.medicamentsArr.find(item => item.name === value);
      const { dosage , unitAmount , administrationWay , pharmaForm , name } = this.medicineSelected || {};
      if( dosage )rowData.Cantidad = dosage;
      rowData.takingAmount = unitAmount
      if(administrationWay?.name)rowData.patientInstructions = administrationWay.name;
      if(name)rowData.name = name;
      rowData.packagesAmount = 1;
      rowData.frequencyAmount = 1;
      rowData.frequencyUnit = 'dia';
      rowData.durationAmount = 1;
      rowData.durationUnit = 'dia';
      if( pharmaForm?.name )( rowData.pharmaForm as AdministrationWay ).name = pharmaForm.name;
      rowData.pharmacistWarnings = 'avisos farmaceuticos'; 
    }
  };

  OpenMedicamentSearchPopUp():void{
    this.printMedicamentSearchPopUp=true;
  }

  ShowMedicamentTemplate():void{

    if ( this.windowWidth <= this.mobileViewBreakPoint ){

      this.prescriptionsNewToMedicamentsTemplateService.SendMessage(this.prescriptionDraft);
    }  
    this.printPopUp=true;
    this.presTemplateToNewPresTableService.sendOpenModalMessage('open');
  }

  ClosePopUp(e:string):void{
    if(e === 'close_pop_up'){
      this.printPopUp = false;
      this.GetMedicinesByPrescriptionId();
    }
  }

  DeletePrescription():void{
    this.dynPrescriptionsService.DeletePrescriptionById(this.prescriptionId)
    .subscribe((res:boolean)=>{
      if(res){
        this.router.navigate(['prescriptions']);
      } 
    },(error: HttpErrorResponse) => {
      
      console.log(error);
      this.printDeletePopUp = false;
    })
  }

  CloseParapharmacyPopUp(medicament:Medicament):void{

    if( this.CheckIfParamedicamentAlreadyInPrescription(medicament) ){

      this.componentToSpinnerService.sendMessage('show');

      const myParaMedicament = { ...medicament , prescriptionId : this.prescriptionId };
  
      this.dynPrescriptionsService.InsertParaMedicamentInDraft(myParaMedicament).subscribe((res:{isOk:string , error:null}) => {
  
        const { isOk } = res || {};
  
        if( isOk ){
          this.medicamentToInsert = medicament;
          this.GetMedicinesByPrescriptionId();
        }
      })
    }

    this.showParapharmacyPopUp = false;
  }

  CheckIfParamedicamentAlreadyInPrescription(medicament : Medicament):boolean{

    return this.dataSource.find(( ms : Medicament ) => ms.name === medicament.name ) ? false : true ;
  }

  ShowPsicotropicAlert():void{
    this.prescriptionsAlertsService.BuildPrescriptionsAlert(`No es posible añadir un medicamento en la misma receta junto a un medicamento psycotrópico o estupefaciente`,`ATENCIÓN , medicamento psycotrópico o estupefaciente`);
  }

  GetMedicinesByPrescriptionId():void{

    this.dynPrescriptionsService.GetPrescriptionById(this.prescriptionId)
    .subscribe(( res:Prescription ) =>{

      const { medicaments , masterFormulas , parapharmacyMedicaments } = res;

      if(medicaments && masterFormulas && parapharmacyMedicaments){

        if(medicaments){
          const myMedicaments = medicaments.map((m:Medicament) => { return { ...m , kind : 'medicament'} });
          this.dataSource = this.medicamentValidatorsService.ConcatDataInMedicamentName( myMedicaments );

          this.GetFavoritesMedicaments();
        }  
        
        if( masterFormulas ){

          const myMasterFormulas = masterFormulas.map((m:Medicament) => { 
            return { 
              ...m , kind : 'masterFormula' ,
              unit : { name :  m.takingUnit }
            } });
          this.dataSource = [ ...this.dataSource , ...myMasterFormulas ];

          this.GetFavoritesMasterFormulas();
        }

        if( parapharmacyMedicaments ){
          const myParapharmacyMedicaments = parapharmacyMedicaments.map((pr) => { 
            return { 
              ...pr , fullName : pr.name , 
              kind : 'paraMedicament' , unit : { name :  pr.takingUnit } } });
          this.dataSource = [ ...this.dataSource , ...myParapharmacyMedicaments ];

          this.GetFavoritesParapharmacy();
        }

        this.dataSource = this.dataSource.map(( m : Medicament ) => {

          if(m.composition && m.pharmaceuticalForm){
            return { 
              ...m , 
              name : m.composition , 
              fullName : m.composition , 
              pharmaForm : { name : m.pharmaceuticalForm , id : 0 }
            }
          } else {
            return { ...m };
          }

        })
      }

      this.dataSource = this.medicamentsFormalHelperService.BuildPosologyField( this.dataSource );

      if(this.medicamentToInsert){

        if( this.medicamentToInsert.nationalCode || this.medicamentToInsert.code ){

          const indexFound = this.medicamentToInsert.nationalCode ? this.dataSource.findIndex(m => m.code == this.medicamentToInsert.nationalCode) : this.dataSource.findIndex(m => m.code == this.medicamentToInsert.code);

          if(indexFound >= 0){

            if(this.medicamentAction === 'INSERT'){

              setTimeout(() => {
                this.printMedicamentSearchPopUp = false;
                this.favoritesMedicamentsPopUp = false;
                this.prescriptionNewGridContainer.instance.editRow( indexFound );
              }, 1000);
            
            }
          }
        }
      }

      this.dataSource.length > 0 ? this.SendIfMedicamentOrMasterFormulas.emit(true) : this.SendIfMedicamentOrMasterFormulas.emit(false);
      
      this.SearchPsycotropicInPrescription();
      this.SendMedicamentsSelectedToMedicamentsTable();

    },(error: HttpErrorResponse) => {
      this.handleHTTPerrorsService.HandleHTTPerrors( error );
    })
  }

  SelectMedicamentsOrMasterFormulaView():void{

    if(this.dataSource.length > 0){
      if(this.dataSource[0].composition) {
        this.prescriptionForMagistralFormulas = true ;
        this.prescriptionForMedicaments = false;
      } else {
        this.prescriptionForMagistralFormulas = false ;
        this.prescriptionForMedicaments = true;
      }
    } else {
      this.prescriptionForMagistralFormulas = true ;
      this.prescriptionForMedicaments = true;
    }
  }

  ReciveMedicamentIdArrToDelete(medicamentsIdArr : string[]){
    
    for (let i = 0; i < medicamentsIdArr.length; i++) {
      this.dynPrescriptionsService.DeleteMedicine(this.prescriptionId , medicamentsIdArr[i])
      .subscribe((res:boolean)=>{
        if(res){
          this.medicamentAction = 'DELETE';
          this.GetMedicinesByPrescriptionId();
        }
      },(error: HttpErrorResponse) => {
        this.handleHTTPerrorsService.HandleHTTPerrors( error );
      })
    }
  }

  SendMedicamentFavoriteIdArrToDelete(medicamentsIdArr : string[]):void{
    for (let i = 0; i < medicamentsIdArr.length; i++) {
      this.medicamentAction = 'DELETE';
      this.dynPrescriptionsService.DeleteMedicine(this.prescriptionId , medicamentsIdArr[i])
      .subscribe((res:boolean)=>{
        if(res)this.GetMedicinesByPrescriptionId();
      },(error: HttpErrorResponse) => {
        this.handleHTTPerrorsService.HandleHTTPerrors( error );
      })
    }
  }

  onHiding(e:HidingEvent):void{
    //this.GetMedicinesByPrescriptionId();
  }

  ngOnDestroy(): void {
    this.medicamentsSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.medicamentFromPopUpSubscription.unsubscribe();
    this.prescriptionsPerPatient$.next();
    this.prescriptionsPerPatient$.complete();
    this.medicamentdFavoritesDestroyed$.next();
    this.medicamentdFavoritesDestroyed$.complete();
    this.masterFormulasFavoritesDestroyed$.next();
    this.masterFormulasFavoritesDestroyed$.complete();
    this.parapharmaciesFavoritesDestroyed$.next();
    this.parapharmaciesFavoritesDestroyed$.complete();
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PrescriptionsFilters } from 'src/app/models';

@Injectable({
  providedIn: 'root' //injected in effects
})

export class FormToTableService {
  
  private tableSubject =            new Subject<PrescriptionsFilters>();
  private formSubject =             new Subject<string>();
  private patientNotFoundSubject =  new Subject<string>();

  sendMessage( message:PrescriptionsFilters) {
    this.tableSubject.next( message );
  }

  clearMessages() {
    this.tableSubject.next({});
  }

  onMessage(): Observable<PrescriptionsFilters> {
    return this.tableSubject.asObservable();
  }

  sendMessageToForm(message:string):void{
    this.formSubject.next(message)
  }

  clearMessageToForm():void{
    this.formSubject.next('');
  }

  onMessageToForm():Observable<string>{
    return this.formSubject.asObservable();
  }

  sendMessagePatientNotFound(message:string):void{
    this.patientNotFoundSubject.next(message)
  }

  clearMessagePatientNotFound():void{
    this.patientNotFoundSubject.next('');
  }

  onMessagePatientNotFound():Observable<string>{
    return this.patientNotFoundSubject.asObservable();
  }


}

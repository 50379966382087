import { createAction, props } from '@ngrx/store';
import { 
    ProtocolsRes , ProtocolsFilters, 
    ProtocolPrescription, Medicament 
} from 'src/app/models';

import { HttpErrorResponse } from '@angular/common/http';

export const getFilteredProtocolAction = createAction(
    '[Protocols] Get protocols' , 
    props<{ parameters:ProtocolsFilters }>()
);

export const getFilteredProtocolSuccess = createAction(
    '[Protocols] Get protocols success',
    // ( protocols : ProtocolsRes ) => ( protocols )
    props<{ protocols : ProtocolsRes }>()
)

export const getProtocolsFailure = createAction('[Protocols] Get protocols Failure',
props<{ error: HttpErrorResponse  }>());

export const AddSingleProtocol = createAction(
    '[Protocols] Add single protocol',
    props<{ protocol : ProtocolPrescription }>()
)

export const storeProtocolAction = createAction(
    '[Protocols] store protocols' , 
    props<{ protocol : Object }>()
);

export const storeProtocolSuccess = createAction(
    '[Protocols] store protocols success',
    // ( protocols : ProtocolsRes ) => ( protocols )
    props<{ myProtocol : ProtocolPrescription }>()
)

export const storeProtocolFailure = createAction('[Protocols] store protocols Failure',
props<{ error: HttpErrorResponse  }>());



export const addMedicamentToProtocol = createAction(
    '[Protocols] Add medicament to protocol',
    props<{ medicament : Medicament }>()
)

export const addMedicamentToProtocolSuccess = createAction(
    '[Protocols] Add medicament to protocol success',
    props<{ medicament : Medicament }>()
)

export const deleteMedicamentToProtocol = createAction(
    '[Protocols] delete medicament in protocol',
    props<{ protocolId : string , medicineId : string }>()
)

export const deleteMedicamentToProtocolSuccess = createAction(
    '[Protocols] delete medicament in protocol success',
    props<{ protocolId : string , medicineId : string }>()
)






import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DoclineGetRes } from 'src/app/models';

@Injectable()
export class PrescriptionNewToPatientInfoErrorsPopUpService {

  subject = new Subject<DoclineGetRes>();
 
  SendMessage(message:DoclineGetRes):void{
    this.subject.next(message);
  }

  OnMessage():Observable<DoclineGetRes>{
    return this.subject.asObservable();
  }

  ClearMessage():void{
    this.subject.next({});
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { AppState, Medicament, UserInfo, UserRes } from 'src/app/models';
import { ValueChangedEvent } from 'devextreme/ui/html_editor';
import { MedicamentValidatorsService, PrescriptionNewHelperService, PrescriptionTablaService } from '../../../prescriptions/services';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { PrescriptionNewTableMagistralFormulaPopUpHelperServiceService } from '../../../prescriptions/services/customs/prescription-new-table-magistral-formula-pop-up-helper-service.service';
import { FormatDatesService } from '../../services';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import { Store } from '@ngrx/store';
import { addFavoritesMasterFormulas, deleteFavoritesMasterFormulas } from 'src/app/store/actions/medicaments/favorites-masterFormulas.actions';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas , faXmark , IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-magistral-formula-pop-up',
  templateUrl: './magistral-formula-pop-up.component.html',
  styleUrls: ['./magistral-formula-pop-up.component.scss']
})
export class MagistralFormulaPopUpComponent implements OnInit , OnDestroy{

  masterFormulasFavoritesDestroyed$:                            Subject<void> = new Subject<void>();
  professionalDestroyed$:                                       Subject<void> = new Subject<void>();
  masterFormulasFavorites:                                      Medicament[];
  professional:                                                 UserInfo = {};
  AlertaAbierta=                                                false;
  faXmark:                                                      IconDefinition=faXmark;

  medicament:                                                   Medicament = {
    pharmaceuticalForm:'Colirio',
    takingUnit:'Gotas',
    dosage:'Gotas',
    administrationWay : { name : 'Gotas' , id:1 },
    packagesAmount:1,
    frequencyAmount:1,
    frequencyUnit:'Día',
    durationAmount:1,
    endDate: this.formatDatesService.AddDaysToADate(new Date().toString(),7) ,
    startDate: this.formatDatesService.AddDaysToADate(new Date().toString(),0) ,
    durationUnit:'Semana', 
    narcotic: false,
    psychotropic:false,
    takingAmount:1,
    patientInstructions: 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta',
    pharmacistWarnings: 'Avisos de carácter farmacéutico',
    //composition : 'Composición de la fórmula',
    name: 'Nombre del medicamento'
  };

  endDateMin:                                                                                  Date = new Date();

  startDateEditorOptions = {
    disabled: false , min : new Date()
  }

  prescriptionIdSubscription:                            Subscription;

  pharmaceuticalForms:                                   {name:string , formId:number}[] = [
    {"name":"Colirio" ,               "formId":1},
    {"name":"Comprimido" ,            "formId":2},
    {"name":"Cápsulas" ,              "formId":3},
    {"name":"Jarabe" ,                "formId":4},
    {"name":"Ampollas bebibles" ,     "formId":5},
    {"name":"Ampollas Inyectables" ,  "formId":6},
  ];

  unit:                                   {name:string , frecuencyAmountId:number}[] = [
    {"name":"Gotas" ,      "frecuencyAmountId":1},
    {"name":"Comprimido" , "frecuencyAmountId":2},
    {"name":"Mililitros" , "frecuencyAmountId":3},
    {"name":"Miligramos" , "frecuencyAmountId":4},
    {"name":"Microgramos" ,"frecuencyAmountId":5},
    {"name":"Ampollas" ,   "frecuencyAmountId":6},
    {"name":"UI" ,         "frecuencyAmountId":7},
    {"name":"Gramos" ,     "frecuencyAmountId":8},
    {"name":"Cápsulas" ,   "frecuencyAmountId":9}
  ];

  frecuencyUnit:                           {name:string , frecuencyUnitId:number}[] = [
    {"name":'Hora' , "frecuencyUnitId":1},
    {"name":'Día' , "frecuencyUnitId":2},
    {"name":'Semana' , "frecuencyUnitId":3},
    {"name":'Mes' , "frecuencyUnitId":4},
  ];

  durationUnit:                           {name:string , durationUnitId:number}[] = [
    {"name":'Día' , "durationUnitId":2},
    {"name":'Semana' , "durationUnitId":3},
    {"name":'Mes' , "durationUnitId":4},
  ];

  pharmaceuticalFormsgroupedData = new DataSource({
    store: new ArrayStore({
      data: this.pharmaceuticalForms,
      key: 'name',
    }),
    group: 'Category',
  });

  unitgroupedData = new DataSource({
    store: new ArrayStore({
      data: this.unit,
      key: 'name',
    }),
    group: 'Category',
  });

  frecuencyUnitData = new DataSource({
    store: new ArrayStore({
      data: this.frecuencyUnit,
      key: 'name',
    }),
    group: 'Category',
  });

  durationUnitData = new DataSource({
    store: new ArrayStore({
      data: this.durationUnit,
      key: 'name',
    }),
    group: 'Category',
  });

  @Output() CloseFormulaMagistralPopUp :                                                         EventEmitter<Medicament> = new EventEmitter;
  @Output() CloseParaMedicamentPopUp :                                                           EventEmitter<Medicament> = new EventEmitter;
  @Input()  whoIsParent ? :                                                                      {from:string , parentId?:string , child : string} ;

  constructor(
    private prescriptionNewHelperService:                                                        PrescriptionNewHelperService,
    private store :                                                                              Store<AppState>,
    private prescriptionTablaService:                                                            PrescriptionTablaService,
    private medicamentValidatorsService:                                                         MedicamentValidatorsService,
    // private recibeMagistralFormulaFromMagistralPopUpService:                                     RecibeMagistralFormulaFromMagistralPopUpService,
    private prescriptionNewTableMagistralFormulaPopUpHelperServiceService:                       PrescriptionNewTableMagistralFormulaPopUpHelperServiceService,
    private formatDatesService :                                                                 FormatDatesService,
    library:                                                                                     FaIconLibrary
  ){

    library.addIconPacks(fas);
    library.addIcons(faXmark);

    this.prescriptionIdSubscription = this.prescriptionNewTableMagistralFormulaPopUpHelperServiceService.onMessage()
    .subscribe((message:string) => {
      if( message ){
        this.medicament.prescriptionId = message;
      }
    })
  }

  ngOnInit(): void {
    this.SetObjectDependParent();
    this.GetFavoritesMasterFormulas();
    this.GetProfessionalInfo();
  }

  GetProfessionalInfo():void{
    this.professionalDestroyed$.next();
    
    this.store.select( AppState => AppState.User ).pipe( takeUntil( this.professionalDestroyed$ ) ).subscribe((professional : UserInfo) => {
      
      this.professional = { ...professional };

    })
  }

  SetObjectDependParent():void{

    if(this.whoIsParent?.child === 'master_formula'){
      this.medicament.composition = 'Composición de la fórmula';
    }
  }

  GetFavoritesMasterFormulas():void{

    this.store.select( AppState => AppState.FavoritesMasterFormulas ).pipe( takeUntil( this.masterFormulasFavoritesDestroyed$ ) ).subscribe(( masterFormulasFavorites : Medicament [] ) => {

      if( masterFormulasFavorites.length > 0 ){

        if( masterFormulasFavorites[0].name !== 'initial_value' ){
  
          this.masterFormulasFavorites = [ ...masterFormulasFavorites ];
         
        }

        this.masterFormulasFavorites = [ ...masterFormulasFavorites ];

      } else {

        this.masterFormulasFavorites = [];
      }
    })

  }

  FormFieldDataChanged(e:FieldDataChangedEvent){

    const { dataField , value } = e || {};

    if( dataField === 'startDate' ){

      this.endDateMin = new Date(value);
      this.medicament.startDate = this.formatDatesService.AddDaysToADate(new Date( value ).toString(),0);
      
    }

    /* si no cambia manualmente la fecha fin se la calculamos nosotros */

    if( dataField !== 'endDate' ){
      if( this.medicament.durationAmount ){

        switch (this.medicament.durationUnit) {
          case 'Día':
            this.medicament.endDate = this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsDay(this.medicament.durationAmount  , this.medicament.startDate);
            break;
          case 'Semana':
            this.medicament.endDate = this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsWeek(this.medicament.durationAmount  , this.medicament.startDate);
            break;
          case 'Mes':
            this.medicament.endDate = this.medicamentValidatorsService.CalculateEndDateByDurationAndDurationUnitAsMonth(this.medicament.durationAmount  , this.medicament.startDate);
            break;
        
          default:
            break;
        }
      }
    } else {
      this.medicament.endDate = this.formatDatesService.AddDaysToADate(new Date( value ).toString(),0);
    }
  }

  SelectPharmaForm(e:ValueChangedEvent):void{
    const { value } = e || {};
    const pharmaceuticalFormSelected = this.pharmaceuticalForms.filter(( pf : {name:string , formId:number} ) => pf.name === value );
    this.medicament.pharmaceuticalForm = pharmaceuticalFormSelected[0].name;
  }

  Selectunit(e:ValueChangedEvent):void{
    const { value } = e || {};
    const unitSelected = this.unit.filter(( pf : {name:string , frecuencyAmountId:number} ) => pf.name === value );
    this.medicament.takingUnit = unitSelected[0].name;
    if(this.medicament.administrationWay)this.medicament.administrationWay.name = unitSelected[0].name;
    this.medicament.dosage = unitSelected[0].name;
  }

  SelectFrecuencyUnit(e:ValueChangedEvent):void{
    const { value } = e || {};
    const frecuencyUnitSelected = this.frecuencyUnit.filter(( pf : {name:string , frecuencyUnitId:number} ) => pf.name === value );
    this.medicament.frequencyUnit = frecuencyUnitSelected[0].name;
  }

  SelectDurationUnit(e:ValueChangedEvent):void{
    const { value } = e || {};
    const durationUnitSelected = this.durationUnit.filter(( pf : {name:string , durationUnitId:number} ) => pf.name === value );
    this.medicament.durationUnit = durationUnitSelected[0].name;
  }

  RemoveFavoriteMasterFormulaFromStore ( favoriteId : string ):void{
    this.store.dispatch( deleteFavoritesMasterFormulas({ masterFormulaFavoritedId : favoriteId }) );
  }

  StoreMasterFormulaFavorite( masterFormulaFavorite : Medicament ):void{
    const favoriteMasterFormula = { ...masterFormulaFavorite , professionalId : this.professional.professionalLoggedId };
    this.store.dispatch( addFavoritesMasterFormulas({ masterFormula : favoriteMasterFormula }) );
  }

  CheckFavorite( favoriteMasterFormula : Medicament ):void{

    if( this.whoIsParent?.from === 'prescription_new_table' ){

      let masterFormulaFavorite : Medicament | undefined = this.masterFormulasFavorites.find(( mf : Medicament ) => mf.composition === favoriteMasterFormula.composition );

      if( masterFormulaFavorite && !favoriteMasterFormula.favorite ){

        const { favoriteId }  = masterFormulaFavorite || {};

        if(favoriteId) this.RemoveFavoriteMasterFormulaFromStore( favoriteId );
      }

      if( !masterFormulaFavorite && favoriteMasterFormula.favorite ){

        const myFavoriteMasterFormula = this.prescriptionNewHelperService.MasterFormulaFavoriteMapper( { ...favoriteMasterFormula , professionalId : this.professional.professionalLoggedId } );
        
        if( myFavoriteMasterFormula )this.StoreMasterFormulaFavorite( myFavoriteMasterFormula );
      }
    }
  }

  SaveMedicament():void{

    if( this.medicament.frequencyUnit && this.medicament.durationUnit ){
      const translatedFrecuencyUnit = this.prescriptionTablaService.TranslateToEnglishMedicamentsFrequencyUnit( this.medicament.frequencyUnit );
      const translatedDurationUnit = this.prescriptionTablaService.TranslateToEnglishMedicamentsDurationUnit( this.medicament.durationUnit );
  
      const myMedicament = { 
        ...this.medicament , 
        frequencyUnit: translatedFrecuencyUnit , 
        durationUnit : translatedDurationUnit ,
        administrationWay : this.medicament.administrationWay?.name , 
        takingAmount : Number(this.medicament.takingAmount),
        frequencyAmount : Number(this.medicament.frequencyAmount),
        durationAmount : Number(this.medicament.durationAmount),
      };

      if( 
        myMedicament.composition &&
        myMedicament.dosage &&
        myMedicament.pharmaceuticalForm &&
        ( myMedicament.takingAmount && myMedicament.takingAmount > 0 ) &&
        ( myMedicament.frequencyAmount && myMedicament.frequencyAmount > 0 ) && 
        ( myMedicament.durationAmount && myMedicament.durationAmount > 0 ) &&
        ( myMedicament.packagesAmount && myMedicament.packagesAmount > 0 )
      ){
        this.CheckFavorite( myMedicament );

        this.CloseFormulaMagistralPopUp.emit( myMedicament );

        this.RefreshFrom();
        
      } else {

        this.AlertaAbierta=true;

        setTimeout(() => {
          this.AlertaAbierta=false;
        }, 5000);

      }
    }
  }

  RefreshFrom():void{
    this.medicament = {
      pharmaceuticalForm:'Colirio',
      takingUnit:'Gotas',
      dosage:'Gotas',
      administrationWay : { name : 'Gotas' , id:1 },
      packagesAmount:1,
      frequencyAmount:1,
      frequencyUnit:'Día',
      durationAmount:1,
      endDate: this.formatDatesService.AddDaysToADate(new Date().toString(),7) ,
      startDate: this.formatDatesService.AddDaysToADate(new Date().toString(),0) ,
      durationUnit:'Semana', 
      narcotic: false,
      psychotropic:false,
      takingAmount:1,
      favorite : false,
      patientInstructions: 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta',
      pharmacistWarnings: 'Avisos de carácter farmacéutico',
      //composition : 'Composición de la fórmula',
      name: 'Nombre del medicamento'
    };
  }

  OrderCloseAlert():void{
    this.AlertaAbierta=false;
  }

  ngOnDestroy(): void {
    this.masterFormulasFavoritesDestroyed$.next();
    this.masterFormulasFavoritesDestroyed$.complete();
    this.professionalDestroyed$.next();
    this.professionalDestroyed$.complete();
  }
}

<div class="videos_table-page_navigation_buttons" >

    <div class="videos_table-icons_angles_containers"  *ngIf="currentPage > 1" >
        <fa-icon class="videos_table-page_icons" (click)="PreviousPage()" [icon]="faAngleLeft"></fa-icon>
    </div>

    <div class="videos_table-page_indicator_container" >
        <span> Página {{ currentPage }} de {{ totalPages }}</span>
    </div>

    <div *ngIf="!isLastPage"  class="videos_table-icons_angles_containers" >
        <fa-icon class="videos_table-page_icons" (click)="NextPage()" [icon]="faAngleRight"></fa-icon>
    </div>

    <div class="videos_table-total_elementos_span" >
        <span> Total : {{ totalElements }} </span>
    </div>
    
</div>

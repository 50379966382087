import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { UserInfo } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})

export class CheckUserRoleService {

  constructor(
    private authService:                                     MsalService
  ) { }

  CheckUserRole():{isAdmin:boolean,isDoctor:boolean,isNurse:boolean,isDoctorOnCall:boolean} | undefined{
    
    const userInfo: UserInfo | AccountInfo = this.authService.instance.getAllAccounts()[0];
    
    let userRoles : {isAdmin:boolean,isDoctor:boolean,isNurse:boolean,isDoctorOnCall:boolean} = {
      isAdmin : false,
      isNurse : false,
      isDoctor : false,
      isDoctorOnCall : false
    }

    if(userInfo){

      const roles = userInfo.idTokenClaims?.roles;

      if( roles ){
        
        /* si no se encuentran los roles de doctor pusheamos el role de enfermero */
        if( !roles.find((e) => e === 'doctor') )roles.push('nurse');

        //const {idTokenClaims : { roles } } = userInfo || {};
        //let testGroups : string[] = [];
    
        /* test solo doctor.on.call sacamos role de enfermero y admin */
        let testGroups = roles.filter((e)=>e!=='nurse' && e!=='admin' && e!=='doctor');
        if( !testGroups.find((e) => e === 'doctor') )testGroups.push('doctor');

        /* test solo doctor sacamos role de enfermero y admin */
        /* let testGroups = roles.filter((e)=>e!=='nurse' && e!=='admin');
        if( !testGroups.find((e) => e === 'doctor') )testGroups.push('doctor'); */
    
        //test solo admin , del array role doctor y enfermero
        /* let testGroups = roles.filter((e)=>e!=='nurse' && e!=='doctor');
        if( !testGroups.find((e) => e === 'admin') )testGroups.push('admin'); */
        
        /* test solo enfermero , del array role doctor y admin */
        /* let testGroups = roles.filter((e)=>e!=='doctor' && e!=='admin');
        if( !testGroups.find((e) => e === 'nurse') )testGroups.push('nurse'); */

        /* test enfermero y admin , del array role doctor */
        /* let testGroups = roles.filter((e)=>e!=='doctor');
        if( !testGroups.find((e) => e === 'nurse') )testGroups.push('nurse');
        if( !testGroups.find((e) => e === 'admin') )testGroups.push('admin'); */
    
        //test doctor y admin , saco del array enfermero */
        /* let testGroups = roles.filter((e)=>e!=='nurse');
        if( !testGroups.find((e) => e === 'doctor') )testGroups.push('doctor');
        if( !testGroups.find((e) => e === 'admin') )testGroups.push('admin'); */

        //test doctor , admin y nurse */
        /* let testGroups : string[] = []; 
        if( !testGroups.find((e) => e === 'doctor') )testGroups.push('doctor');
        if( !testGroups.find((e) => e === 'admin') )testGroups.push('admin');
        if( !testGroups.find((e) => e === 'nurse') )testGroups.push('nurse'); */ 
        
    
        (roles as string[])/* testGroups */.find((e : string) => e === 'admin' || e === 'protocol' ) ? userRoles.isAdmin = true : userRoles.isAdmin = false;
        (roles as string[])/* testGroups */.find((e : string) => e === 'doctor') ? userRoles.isDoctor = true : userRoles.isDoctor = false;
        (roles as string[])/* testGroups */.find((e : string) => e === 'nurse') ? userRoles.isNurse = true : userRoles.isNurse = false;
        (roles as string[])/* testGroups */.find((e : string) => e === 'doctor.on.call') ? userRoles.isDoctorOnCall = true : userRoles.isDoctorOnCall = false;
      
        return userRoles;
      }else {
        userRoles = { ...userRoles , isAdmin : false , isDoctor : false , isDoctorOnCall : false , isNurse : true };
        return userRoles
      }

      
    } else {
      return undefined;
    }
    
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
/* fontawsome */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './components/alert/alert.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
/* devexpress */
import { DxButtonModule, DxDataGridModule, DxFormModule, DxLoadIndicatorModule, DxScrollViewModule, DxSelectBoxModule, DxTemplateModule } from 'devextreme-angular';
import { DeleteWarningPopUpContentComponent } from './components/delete-warning-pop-up-content/delete-warning-pop-up-content.component';
/* directives */
import { InputTrimDirective } from './directives';
import { PrescriptionPatientsTablePopupComponent } from '../prescriptions/components';
import { MagistralFormulaPopUpComponent } from './components/magistral-formula-pop-up/magistral-formula-pop-up.component';
import { ParapharmacyMedicamentPopupComponent } from './components/parapharmacy-medicament-popup/parapharmacy-medicament-popup.component';
// import { PrescriptionPatientsTablePopupComponent } from '../prescriptions/components';


@NgModule({
  declarations: [
    TablePaginationComponent,
    AlertComponent,
    SpinnerComponent,
    DeleteWarningPopUpContentComponent,
    MagistralFormulaPopUpComponent,
    PrescriptionPatientsTablePopupComponent,
    /* directives */
    InputTrimDirective,
    ParapharmacyMedicamentPopupComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    /* forms */
    FormsModule,
    ReactiveFormsModule,
    /* devexpress */
    DxButtonModule,
    DxLoadIndicatorModule,
    DxTemplateModule,
    DxDataGridModule,
    DxFormModule,
    DxSelectBoxModule,
    DxScrollViewModule
  ],

  exports: [
    ParapharmacyMedicamentPopupComponent,
    TablePaginationComponent,
    AlertComponent,
    SpinnerComponent,
    DeleteWarningPopUpContentComponent,
    InputTrimDirective,
    PrescriptionPatientsTablePopupComponent,
    MagistralFormulaPopUpComponent
  ]
})
export class SharedModule { }

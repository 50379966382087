import { createAction, props } from "@ngrx/store";
import { MagickLinkHolded } from "src/app/models";

export const storeMagicLinkHolded = createAction(
    '[MagickLinkHolded] Store magickLink holded',
    props<{ magickLinkHolded : MagickLinkHolded }>()
)

export const removeMagicLinkHolded = createAction(
    '[MagickLinkHolded] Remove magickLink holded',
    props<{ order : string }>()
)
<div  class="app_documents-banner " >
  <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-4 app_documents-logo_container" ><!-- ../assets/logos/logo_baviera_optim.jpg" -->
    <img *ngIf="logged" class="app_documents-logo" src="../assets/logos/iris.png" alt="logo clinica baviera">
    
  </div>
  <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-4 app_documents-auth-container " >
    
    <app-auth (isLogged)="isLogged($event)"  (iframe)="RecibeFrame($event)"></app-auth>

  </div>
</div>

<div *ngIf="logged" class="app-main-first-container" ><!-- annotation -->

  <div *ngIf="windowWidth > mobileViewBreakPoint" class="app_main-side_menu_container" >

    <app-side-menu></app-side-menu>
    
  </div>

  <div  class="app-main-container">

    <div class="app_documents-top_container bg-white" *ngIf="windowWidth > mobileViewBreakPoint" >
      <div class="documents-player_tittle_container" >
        
        <fa-icon class="app_documents-eye_icon" [icon]="faEye"></fa-icon>
        <span class="app_documents-player_span" >
          Recetas Electrónicas
        </span>

      </div>
      
    </div>
  
    <div class="app-router-container">
      
      <div>
        <router-outlet *ngIf="!isIframe"></router-outlet>
      </div>
    </div>
  </div>
</div>








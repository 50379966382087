export class ProfessionalsJSONRes {

    payload: ProfessionalFromJson[] = [] ;
    success  = false;
}

export class ProfessionalFromJson {
    id             = 0;
    username       = '';
    nombre         = '';
    apellidos      = '';
    apellidos_dos: null | string = '';
    dni:           null | string = '';
    email          = '';
    units:         Unit[] = [];
    clinics:       GoingUpClinic[] = [];
    code:          string[] = [];
    //goingup_type:  GoingupType[] = [];
    bip_id:        null | string = '';
    // genero:        GoingUpUserGenero;
    avatar:        null | string = '';
    // role:          Role | null;
}

export class GoingUpClinic {
    name:    string;
    key_cli: null | string;
}

export class Unit {
    id:   number;
    name: string;
}


/* export enum GoingUpUserGenero {
    Female = "female",
    Male = "male",
} */

/* export enum GoingupType {
    ConsultingDoctor = "CONSULTING_DOCTOR",
    ManagementGoingup = "MANAGEMENT_GOINGUP",
    MedicalReader = "MEDICAL_READER",
    RemoteAssistant = "REMOTE_ASSISTANT",
    Scorecard = "SCORECARD",
} */

/* export enum Role {
    Medico = "medico",
    Optico = "optico",
} */




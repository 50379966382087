import { Component , Output , EventEmitter , OnInit , OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, Medicament } from 'src/app/models';
import { Subject , takeUntil , Subscription } from 'rxjs';
import { getFavoritesMasterFormulas } from 'src/app/store/actions/medicaments/favorites-masterFormulas.actions';
import { getFavoritesMedicaments } from 'src/app/store/actions/medicaments/favorites-medicaments.actions';
import { RowClickEvent } from 'devextreme/ui/data_grid';
import { CheckUserRoleService, ComponentToSpinnerService } from 'src/app/shared/services';
import { MedicamentsFavoritesPopUpToPrescriptionNewTableServiceService, PrescriptionTablaService } from '../../services';
import { MedicamentsFormalHelperService } from '../../services/helpers/medicaments/medicaments-formal-helper.service';
/* fontawsome */
import { IconDefinition , faPrescriptionBottleMedical , faFlaskVial , faCapsules } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { getFavoritesParapharmacies } from 'src/app/store/actions/medicaments/favorites-parafarmacies.actions';
import { environment } from 'src/environments/environment';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';

@Component({
  selector: 'app-favorites-medicaments-pop-up',
  templateUrl: './favorites-medicaments-pop-up.component.html',
  styleUrls: ['./favorites-medicaments-pop-up.component.scss']
})

export class FavoritesMedicamentsPopUpComponent implements OnInit , OnDestroy {

  @Output() CloseFavoritesPopUp :                                                          EventEmitter<Medicament> = new EventEmitter;
  @Output() SendMedicamentFavoriteIdArrToDelete :                                          EventEmitter<string[]> = new EventEmitter;
  @Output() SendMedicamentFromPopUpFavoritetoAdd :                                         EventEmitter<Medicament[]> = new EventEmitter;
  @Output() SendMasterFormulaFavoriteIdArrToDelete :                                       EventEmitter<string[]> = new EventEmitter;
  @Output() SendParapharmacyFavoriteIdArrToDelete :                                        EventEmitter<string[]> = new EventEmitter;
  @Input()  whoIsParent ? :                                                                {from:string , parentId?:string , child : string} ;

  professionalLoggedId:                                                                    string | null = '';
  medicamentSubscription:                                                                  Subscription;
  inputDisabled                                                                            = false;
  favoritesMedicamentsDestroyed$:                                                          Subject<void> = new Subject<void>;
  professionalLoggedIdDestroyed$:                                                          Subject<void> = new Subject<void>;
  favoriteMasterFormulasDestroyed$:                                                        Subject<void> = new Subject<void>;
  favoriteParapharmaciesDestroyed$:                                                        Subject<void> = new Subject<void>;
  favoritesMedicaments:                                                                    Medicament[] = [];
  favoritesMasterFormulas:                                                                 Medicament[] = [];
  favoritesParapharmacies:                                                                 Medicament[] = [];
  mixFavoritesMedicamentsMasterFormulas:                                                   Medicament[] = [];
  medicamentsSelected:                                                                     Medicament[] = [];
  showFilterRow                                                                            : boolean;
  currentFilter                                                                            = "";     
  applyFilterTypes:                                                                        {key:string , name:string}[];   
  showHeaderFilter:                                                                        boolean;   
  isDoctor =                                                                               false;
  isNurse =                                                                                false;
  isAdmin =                                                                                false;
  faFlaskVial:                                                                             IconDefinition=faFlaskVial;
  faPrescriptionBottleMedical:                                                             IconDefinition=faPrescriptionBottleMedical;
  faCapsules:                                                                              IconDefinition=faCapsules;
  windowWidth=                                                                             0;
  mobileViewBreakPoint=                                                                    environment.mobileViewBreakPoint;
  resizeSubscription:                                                                      Subscription;

  constructor(
    private store :                                                                        Store<AppState>,
    private componentToSpinnerService:                                                     ComponentToSpinnerService,
    private medicamentsFavoritesPopUpToPrescriptionNewTableServiceService:                 MedicamentsFavoritesPopUpToPrescriptionNewTableServiceService,
    private medicamentsFormalHelperService:                                                MedicamentsFormalHelperService,
    private checkUserRoleService:                                                          CheckUserRoleService,
    private windowResizeService :                                                          WindowResizeService,
    library:                                                                               FaIconLibrary,
  ){

    library.addIcons(faFlaskVial);
    library.addIcons(faPrescriptionBottleMedical);
    library.addIcons(faCapsules);

    this.medicamentSubscription = this.medicamentsFavoritesPopUpToPrescriptionNewTableServiceService.PrescriptionNewTableToMedicamentsFavoritesOnMessage().subscribe((message) => {
      if( message ){

        this.medicamentsSelected = message;

        if(this.medicamentsSelected.length > 0){

          this.CheckIfMedicamentOrMagistralFormula();

        } else {

          this.GetFavoritesMedicaments();
        }
        
        if(this.mixFavoritesMedicamentsMasterFormulas.length > 0/*  && this.medicamentsSelected.length > 0 */){
          this.CheckMedicamentsFavoritesAlreadySelected();
        }
      }
    })

    this.showFilterRow = true;
    this.showHeaderFilter = true;

    this.applyFilterTypes = [
      {
        key: 'auto',
        name: 'Immediately',
      }, 
      {
        key: 'onClick',
        name: 'On Button Click',
      }
    ];

    this.currentFilter = this.applyFilterTypes[0].key;

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {

      this.windowWidth = width;
    });
  }

  ngOnInit(): void {
    this.CheckUserRole();
    this.GetProfessionalLoggedInfo();
  }

  CheckUserRole():void{
    const roles = this.checkUserRoleService.CheckUserRole();
    const {isDoctor , isAdmin , isNurse} = roles || {};
    if( isDoctor )this.isDoctor = isDoctor;
    if( isNurse )this.isNurse = isNurse;
    if( isAdmin )this.isAdmin = isAdmin;
  }

  CheckIfMedicamentOrMagistralFormula():void{
    /* if (this.medicamentsSelected[0].composition) {
      this.mixFavoritesMedicamentsMasterFormulas = this.mixFavoritesMedicamentsMasterFormulas.filter((m) => m.composition);
    } else {
      this.mixFavoritesMedicamentsMasterFormulas = this.mixFavoritesMedicamentsMasterFormulas.filter((m) => !m.composition);
    } */
  }

  GetProfessionalLoggedInfo():void{

    this.store.select( AppState => AppState.User.professionalLoggedId ).pipe( takeUntil( this.professionalLoggedIdDestroyed$ ) ).subscribe(( professionalLoggedId : string | undefined ) => {
      
      if(professionalLoggedId){
      
        this.professionalLoggedId = professionalLoggedId;
      
        this.GetFavoritesMedicaments();
      
        this.GetFavoritesMasterFormulas();
      
        this.GetFavoritesParapharmacies();
      }
    })
  }

  GetFavoritesMedicaments():void{

    this.componentToSpinnerService.sendMessage('show');
    
    this.store.select( AppState => AppState.FavoritesMedicaments ).pipe( takeUntil( this.favoritesMedicamentsDestroyed$ ) ).subscribe(( medicamentsFavorites : Medicament[] ) => {
      
      if(medicamentsFavorites.length > 0){
       
        if(medicamentsFavorites[0].name !== 'initial_value'){
          
          this.favoritesMedicaments = medicamentsFavorites.map((m : Medicament) => { 
  
            if(m.unit.name){
              return { ...m , fullName : m.name + ' ' + m.unitAmount + ' ' + m.unit.name , kind : 'medicament' };
            } else {
              return { ...m , fullName : m.name + ' ' + m.unitAmount + ' ' + m.unit , unit : {name : m.unit} , kind : 'medicament' };
            }
          } );
          
          this.MergingFavoritesMedicamentsMasterFormulas();
        } else {
          this.StoreFavoritesMedicaments();
        }
      } else {
        this.favoritesMedicaments = [];
        this.MergingFavoritesMedicamentsMasterFormulas();
      }
    })
  }

  StoreFavoritesMedicaments():void{

    if(this.professionalLoggedId && this.professionalLoggedId !== "first_assignement" && this.isDoctor){

      this.store.dispatch( getFavoritesMedicaments({ professionalId : this.professionalLoggedId }) );
      
    }
  }

  GetFavoritesMasterFormulas():void{
    
    this.store.select( AppState => AppState.FavoritesMasterFormulas ).pipe( takeUntil( this.favoriteMasterFormulasDestroyed$ ) ).subscribe(( favoritesMasterFormulas : Medicament[] ) => {
      
      if(favoritesMasterFormulas.length > 0){
        
        if( favoritesMasterFormulas[0].name !== 'initial_value' ){
          
          this.favoritesMasterFormulas = favoritesMasterFormulas.map((m) => { return { ...m , kind : 'masterFormula' }  } );

          this.MergingFavoritesMedicamentsMasterFormulas();
        } else {
          this.StoreFavoritesMasterFormulas();
        }
      } else {
        this.favoritesMasterFormulas = [];
        this.MergingFavoritesMedicamentsMasterFormulas();
      }
    })
  }

  StoreFavoritesMasterFormulas():void{

    if(this.professionalLoggedId && this.professionalLoggedId !== "first_assignement" && this.isDoctor){
      this.store.dispatch( getFavoritesMasterFormulas({ professionalId : this.professionalLoggedId }) );
    }
  }

  GetFavoritesParapharmacies():void{

    this.store.select( AppState => AppState.FavoritesParapharmacy ).pipe( takeUntil( this.favoriteParapharmaciesDestroyed$ ) ).subscribe(( favoritesParapharmacies : Medicament[] ) => {

      if(favoritesParapharmacies.length > 0){

        if( favoritesParapharmacies[0].name !== 'initial_value' ){

          this.favoritesParapharmacies = favoritesParapharmacies.map((m) => { return { ...m , kind : 'paraMedicament' , fullName : m.name }  } );

          this.MergingFavoritesMedicamentsMasterFormulas();
        
        } else {
          
          this.StoreFavoritesParapharmacies();
        }
      
      } else {
        
        this.favoritesParapharmacies = [];
       
        this.MergingFavoritesMedicamentsMasterFormulas();
      }
    })
  }

  StoreFavoritesParapharmacies():void{

    if(this.professionalLoggedId && this.professionalLoggedId !== "first_assignement" && this.isDoctor){

      this.store.dispatch( getFavoritesParapharmacies({ professionalId : this.professionalLoggedId }) );
    }
  }

  MergingFavoritesMedicamentsMasterFormulas():void{

    this.componentToSpinnerService.sendMessage('hide');

    this.favoritesMasterFormulas = this.favoritesMasterFormulas.map((m) => {
      
      if(m.composition && m.pharmaceuticalForm){

        return { 
          ...m , 
          name : m.composition , 
          fullName : m.composition ,
          pharmaForm : { name : m.pharmaceuticalForm , id : 0 },
          unit : { name : m.takingUnit , id : 0 }
        }
      } else {
        return { ...m };
      }
    })

    this.mixFavoritesMedicamentsMasterFormulas = [ ...this.favoritesMedicaments , ...this.favoritesMasterFormulas , ...this.favoritesParapharmacies ];

    this.mixFavoritesMedicamentsMasterFormulas = this.medicamentsFormalHelperService.BuildPosologyField(this.mixFavoritesMedicamentsMasterFormulas);

    if(this.mixFavoritesMedicamentsMasterFormulas.length > 0 && this.medicamentsSelected.length > 0){

      this.CheckMedicamentsFavoritesAlreadySelected();
    } else {
      this.componentToSpinnerService.sendMessage('hide');
    }

  }

  CheckMedicamentsFavoritesAlreadySelected():void{

    const mixFavoritesMedicamentsMasterFormulas = this.mixFavoritesMedicamentsMasterFormulas.map(( fm : Medicament ) => {

      
      if( fm.kind === 'masterFormula' ){

        return this.medicamentsSelected.find((ms) => fm.composition === ms.composition) ? { ...fm , selected : true } : { ...fm , selected : false };
      
      } else if( fm.kind === 'medicament' ){

        return this.medicamentsSelected.find((ms) => fm.medicineId === ms.id) ? { ...fm , selected : true } : { ...fm , selected : false };
      
      }else if( fm.kind === 'paraMedicament' ){

        return this.medicamentsSelected.find((ms) => fm.name === ms.name) ? { ...fm , selected : true } : { ...fm , selected : false };
      
      }else { return fm };
    })

    this.mixFavoritesMedicamentsMasterFormulas = mixFavoritesMedicamentsMasterFormulas;
    this.componentToSpinnerService.sendMessage('hide');
    
    setTimeout(() => {
      this.inputDisabled = false;  
    }, 500);
    
  }

  OnRowClick(e:RowClickEvent):void{
    if(!this.inputDisabled){
      this.inputDisabled = true;
      this.ModifySelection( e.data );
    }
  }

  ModifySelection( medicament : Medicament ):void{
    
    const { selected } = medicament || {};
    
    selected ? this.DeselectMedicamentToPrescription(medicament) : this.AddMedicamentToPrescription(medicament);
  }

  AddMedicamentToPrescription( medicament : Medicament ):void{
    
    this.componentToSpinnerService.sendMessage('show');
    
    let medicamentArr = [];

    medicamentArr.push(medicament);

    medicamentArr = this.medicamentsFormalHelperService.FormatMedicamentFrequencyUnitToEnglish(medicamentArr);
    
    medicamentArr = this.medicamentsFormalHelperService.FormatMedicamentDurationUnitToEnglish(medicamentArr);
    
    this.SendMedicamentFromPopUpFavoritetoAdd.emit(medicamentArr)
    
    this.inputDisabled = true;
  }

  DeselectMedicamentToPrescription( medicament : Medicament ):void{
    
    this.componentToSpinnerService.sendMessage('show');
    const { medicineId , kind } = medicament || {};

    if( kind === 'medicament' && medicineId){
      const medicineIdArr = [medicineId];
      this.SendMedicamentFavoriteIdArrToDelete.emit(medicineIdArr);
    }
    
    if( kind === 'masterFormula' ){
      const id = this.medicamentsSelected.filter((ms) => ms.composition === medicament.composition)[0].id;
      this.SendMasterFormulaFavoriteIdArrToDelete.emit(id);
    }

    if( kind === 'paraMedicament' ){

      const id = this.medicamentsSelected.filter((ms) => ms.name === medicament.name)[0].id
      
      
      this.SendParapharmacyFavoriteIdArrToDelete.emit(id);
    }
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
    this.medicamentSubscription.unsubscribe();
    this.favoritesMedicamentsDestroyed$.next();
    this.favoritesMedicamentsDestroyed$.complete();
    this.favoriteMasterFormulasDestroyed$.next();
    this.favoriteMasterFormulasDestroyed$.complete();
    this.professionalLoggedIdDestroyed$.next();
    this.professionalLoggedIdDestroyed$.complete();
    this.favoriteParapharmaciesDestroyed$.next();
    this.favoriteParapharmaciesDestroyed$.complete();
  }
}

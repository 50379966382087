import { Injectable } from "@angular/core";/* 
import { Actions } from "@ngrx/effects";
import { PatientsService } from "src/app/prescriptions/services"; */

@Injectable()

export class PatientsEffects {

    /* constructor(
        private action$ :                             Actions,
        private patientsService:                      PatientsService
    ){} */

}


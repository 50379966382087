import { createAction , props } from '@ngrx/store';
import { UserInfo } from 'src/app/models';

export const addUser = createAction('Add User' , props<UserInfo>());
export const removeUser = createAction('Remove User' , props<UserInfo>())
export const clearUser = createAction('Clear User');
export const returnUsers = createAction('Return User');

export const addProfessionalAvatar = createAction(
    '[User] add professional avatar to user',
    props<{ avatar : string }>()
)

export const removeFirstAssignementId = createAction(
    '[User] remove first professionalLoggedId assignement',
    props<{ order : string }>()
)

/* pendiente de cobrar el reducers de doctores */
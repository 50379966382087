<div class="prescriptions_details-main_container" >

    <dx-data-grid 
      [dataSource]="medicamentDataSource"
      [showBorders]="true"
      [columnAutoWidth]="true"
    >
      <dxo-scrolling
        [useNative]="false"
        [scrollByContent]="true"
        [scrollByThumb]="true"
        showScrollbar="onHover"
        columnRenderingMode="virtual"
      ></dxo-scrolling>
  
      <div *dxTemplate="let data of 'estados'">
        <div class="cell-estado" 
            [ngClass]="{
              'cell-primary': 
                data.text === 'Dispensable a futuro' ||
                data.text === 'Dispensable' ||
                data.text === 'Dispensado parcialmente'
              ,
              'cell-success':
                data.text === 'Dispensado'
              ,
              'cell-danger' : 
                data.text === 'Bloqueado Cautelarmente' ||
                data.text === 'Visado Rechazado' ||
                data.text === 'Pendiente de Visado' ||
                data.text === 'Caducado' ||
                data.text === 'Anulado'
              ,
              'cell-warning': 
                data.text === 'Tan solo medicamento reemplazado' ||
                data.text === 'Pendiente Solo En MasterFormula' ||
                data.text === 'Dispensado parcialmente con sustitucion' ||
                data.text === 'Fórmula Magistral / Vacuna Individualizada en elaboración' ||
                data.text === 'Dispensado con Sustitucion'
            }"
          >{{data.value}}
        </div>
      </div>
  
      <dxi-column
        alignment='left' 
        dataField="packagesAmount" 
        caption="Número de cajas"
      ></dxi-column>
      
      <dxi-column
        alignment='left' 
        width="500"
        dataField="name" 
        caption="Medicamento" 
        dataType="string"
      ></dxi-column>
  
      

      <dxi-column
        alignment='left' 
        dataField="posology" 
        caption="Posologia" 
        width="500"
        dataType="string"
      ></dxi-column>
  
      <!-- <dxi-column
        alignment='left' 
        dataField="takingAmount"
        width="80" 
        caption="Cantidad">
      </dxi-column>
  
      <dxi-column
        alignment='left' 
        dataField="pharmaForm.name" 
        caption="Unidad"
        width="300"
      >
      </dxi-column>
      
      <dxi-column
        alignment='left' 
        dataField="frequencyUnit" 
        width="100"
        caption="Unidad de frecuencia"
      ></dxi-column>
  
      <dxi-column
        alignment='left' 
        dataField="durationAmount" 
        caption="Duración"
      ></dxi-column>
  
      <dxi-column
        alignment='left' 
        dataField="durationUnit" 
        caption="Unidad de Duración"
      ></dxi-column> -->
  
      <dxi-column
        alignment='left' 
        dataField="patientInstructions" 
        caption="Instrucciones"
        width="130"
      ></dxi-column>

      <dxi-column
        alignment='left' 
        dataField="pharmacistWarnings" 
        caption="Aviso farmacéutico" 
        width="130"
        dataType="string"
      ></dxi-column>
  
      <dxi-column
        alignment='left' 
        dataField="status" 
        cellTemplate="estados" 
        caption="Estado" 
        dataType="string" 
        [allowFixing]="true"
        fixedPosition="left"
      ></dxi-column>
  
    </dx-data-grid>
  </div>
  
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Medicament, Patient, Professional  } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable()

export class PrescriptionNewHelperService {

  constructor(
    private http :                                           HttpClient
  ) { }

  GetProfessionalByOjoId(professionalCode:string , userCode:string):Observable<Professional>{
    return this.http.get<Professional>(
      `${environment.usersService.url}professional/get-professional-by-code?ProfessionalCode=${professionalCode}&UserCode=${userCode}`
    );
  }

  

  GetPatientByOjoId(patientCode:string , clinicCode:string):Observable<Patient>{
    return this.http.get<Patient>(`${environment.patientsService.url}patient/${clinicCode}/${patientCode}`);
  }

  /* GetPatientByOjoId(patientCode:string , clinicCode:string):Observable<Patient>{
    return this.http.get<Patient>(
      `${environment.usersUrl}patient/get-patient-by-code?PatientCode=${patientCode}&ClinicCode=${clinicCode}`
    );
  } */

  MasterFormulaMapper(masterFormula : Medicament):Medicament{

    const { 
      professionalId , composition , dosage , administrationWay , takingAmount , takingUnit , frequencyAmount , frequencyUnit ,
      durationAmount , durationUnit , patientInstructions , pharmacistWarnings , pharmaceuticalForm , packagesAmount , startDate , endDate
    } = masterFormula || {};

    const options = { timeZone: 'Europe/Madrid' };

    const myStartDate = startDate.toLocaleString('es-ES', options);
    const myEndDate = endDate.toLocaleString('es-ES', options);

    const masterFormulaMapped : Medicament = {
      professionalId: professionalId,
      composition: composition,
      dosage: dosage,
      pharmaceuticalForm: pharmaceuticalForm,
      administrationWay: pharmaceuticalForm,
      takingAmount: takingAmount,
      takingUnit: takingUnit,
      frequencyAmount: frequencyAmount,
      frequencyUnit: frequencyUnit,
      durationAmount: durationAmount,
      durationUnit: durationUnit,
      narcotic: false,
      psychotropic: false,
      patientInstructions: patientInstructions,
      pharmacistWarnings: pharmacistWarnings,
      packagesAmount : packagesAmount,
      startDate: new Date( myStartDate ),
      endDate: new Date( myEndDate ),
      status: "DispensableInFuture",
      unitAmount:10
    }
    

    return masterFormulaMapped;
  }

  BuildMasterFormulaToUpdate( originalMasterFormula : Medicament ):Medicament{

    const { 
      administrationWay , composition , dosage , durationAmount ,
      durationUnit , endDate , startDate , frequencyAmount , frequencyUnit , unit , 
      id , narcotic , psychotropic , patientInstructions , pharmaceuticalForm ,  
      pharmacistWarnings , prescriptionId , takingAmount , packagesAmount
    } = originalMasterFormula || {};

    return {
      packagesAmount : packagesAmount,
      administrationWay: pharmaceuticalForm,
      composition: composition,
      dosage: dosage,
      durationAmount: durationAmount,
      durationUnit: durationUnit,
      endDate: endDate,
      frequencyAmount: frequencyAmount,
      frequencyUnit: frequencyUnit,
      id: id,
      isNarcotic: narcotic,
      isPsychotropic: psychotropic,
      patientInstructions: patientInstructions,
      pharmaceuticalForm: pharmaceuticalForm,
      pharmacistWarnings: pharmacistWarnings,
      prescriptionId: prescriptionId,
      startDate: startDate,
      takingAmount: takingAmount,
      takingUnit: unit.name 
    }
  }

  MasterFormulaFavoriteMapper( favoriteMasterFormula : Medicament | undefined ):Medicament | undefined{

    if( favoriteMasterFormula ){

      const { 
        composition , dosage , durationAmount , 
        durationUnit , endDate , frequencyAmount , frequencyUnit ,patientInstructions ,
         pharmaceuticalForm , pharmacistWarnings ,
        startDate , takingAmount , takingUnit , professionalId
      } = favoriteMasterFormula || {};
      
      return {
        professionalId: professionalId,
        composition: composition,
        dosage: dosage,
        pharmaceuticalForm: pharmaceuticalForm,
        administrationWay: pharmaceuticalForm,
        takingAmount: takingAmount,
        takingUnit: takingUnit,
        frequencyAmount: frequencyAmount,
        frequencyUnit: frequencyUnit,
        durationAmount: durationAmount,
        durationUnit: durationUnit,
        isNarcotic: false,
        isPsychotropic: false,
        patientInstructions: patientInstructions,
        pharmacistWarnings: pharmacistWarnings,
        startDate: startDate,
        endDate: endDate
      }
    } else {return undefined};
  }

  /* patientTest if 204 no content ffdd6d9c-8925-4665-8b61-9099e4c11eec */
}

/* old professionalId 4a3d00c3-eccb-4961-946f-60f891447e85 */

/* 

  CON EL DOCTOR HECTOR MASCAROS
  http://localhost:4201/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46A01;patientCode=MRTR07805070;indi=TFD,

  test url
  http://localhost:4201/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C07;patientCode=BDBR08505250;indi=LAS2,LAS8,QU21,
  http://localhost:4201/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C07;patientCode=BDBR08505250;indi=,

  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C07;patientCode=BDBR08505250;indi=QU22,PT01,

  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170;indi=LAS2,LAS8,0002,
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170;indi=LAS2,LAS8,0002,
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=0000000975;professionalCode=99999;clinicCode=46C13;patientCode=CSMD14907170;indi=LAS2,LAS8,0002,
  http://localhost:4201/prescriptions/new;userCode=0000000975;professionalCode=99999;clinicCode=46C13;patientCode=CSMD14907170;indi=LAS2,LAS8,0002,

  clinicCode=46A01;patientCode=MRTR07805070
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=12A01;patientCode=BDLN05109170;indi=LAS2,LAS8,0002,
  https://prescriptions-pre.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=BDLN05109170;indi=LAS2,LAS8,0002,
  http://localhost:4201/prescriptions/new;userCode=0000000975;professionalCode=99999;clinicCode=46C13;patientCode=CSMD14907170;indi=LAS2,LAS8,0002,


  https://prescriptions-pre.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD1490717;indi=LAS2,LAS8,0002,


  https://wonderful-field-0ef6fb003.3.azurestaticapps.net/prescriptions/new;userCode=BAVIERA;professionalCode=99999;clinicCode=46C01;patientCode=SPNG17105100
  https://wonderful-field-0ef6fb003.3.azurestaticapps.net/prescriptions/new;userCode=ALB;professionalCode=ALB;clinicCode=46C01;patientCode=SPNG17105100 
  https://wonderful-field-0ef6fb003.3.azurestaticapps.net/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46C02;patientCode=PLCR08801090
  https://wonderful-field-0ef6fb003.3.azurestaticapps.net/prescriptions/new;userCode=0000000662;professionalCode=99999;clinicCode=46C02;patientCode=PLCR08801090


  PACIENTE Y PROFESSIONAL ENCONTRADOS
  http://localhost:4200/prescriptions/new;userCode=0000003082;professionalCode=99999;clinicCode=15A01;patientCode=LVGN05910170 //valido
  http://localhost:4200/prescriptions/new;userCode=0000003082;professionalCode=99999;clinicCode=15C01;patientCode=LVGN07703060 //paciente no activo
  
  http://localhost:4200/prescriptions/new;userCode=0000003258;professionalCode=JJLAD;clinicCode=15A01;patientCode=LVGR17412010 //paciente no activo

  http://localhost:4200/prescriptions/new;userCode=0000003082;professionalCode=99999;clinicCode=15A01;patientCode=LVGN07703060
  http://localhost:4200/prescriptions/new;userCode=0000003082;professionalCode=99999;clinicCode=15A01;patientCode=LVGN07703060
  http://localhost:4200/prescriptions/new;userCode=0000003258;professionalCode=99999;clinicCode=15A01;patientCode=LVGN07703060
  
  
  
  http://localhost:4200/prescriptions/new;userCode=ALB;professionalCode=ALB;clinicCode=46C01;patientCode=LVGN07703060 

  http://localhost:4200/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46C02;patientCode=PLCR08801090
                                            KEYUSR                    ojoId

  http://localhost:4200/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=15C01;patientCode=LVGN07703060

  http://localhost:4200/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=15C01;patientCode=PLCR08801090
  http://localhost:4200/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46C02;patientCode=PLCR08801090
  http://localhost:4200/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46C02;patientCode=PLCR08801090
  http://localhost:4200/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46C02;patientCode=PLCR08801090
  http://localhost:4200/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46C02;patientCode=PLCR08801090
  http://localhost:4200/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46C02;patientCode=PLCR08801090

  professionalCode=MASCH&userCode=0000007984

  VALIDOS
  http://localhost:4200/prescriptions/new;userCode=0000003258;professionalCode=JJLAD;clinicCode=15A01;patientCode=LVGR17412010 //valido
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=0000003258;professionalCode=JJLAD;clinicCode=15A01;patientCode=LVGR17412010 //valido
  http://localhost:4200/prescriptions/new;userCode=0000008363;professionalCode=LOREV;clinicCode=15A01;patientCode=LVGN05910170&indi=LA13,LAS1,LAS3, //valido



  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C05;patientCode=NDGR16203050;

  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170;indi=MED2,QUI3,
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C05;patientCode=NDGR16203050;


  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C05;patientCode=NDGR16203050;
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170;

  
  https://prescriptions-pre.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46A01;patientCode=MRTR07805070;indi=FBMD,
  https://prescriptions-pre.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C05;patientCode=NDGR16203050;indi=FBMD,





  
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C05;patientCode=NDGR16203050;indi=QUI9, //activo
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C05;patientCode=NDGR16203050;indi=LA13,LAS1,LAS3, //activo
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170;indi=LAZT, //activo
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170;indi=LA13,LAS9, //activo




  NUEVOS VALIDOS 

  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170;indi=MED2,QUI3, //activo
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170;indi=LA13,LAS9, //activo

  PACIENTE NO ENCONTRADO
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD1490717;indi=LA13,LAS9, //activo
  https://prescriptions-pre.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD1490717 //activo

  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C13;patientCode=CSMD14907170


  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46A01;patientCode=MRTR07805070;indi=LA11,MED1,ALT, //activo
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46A01;patientCode=MRTR07805070;indi=LA11,MED1,ALT, //activo

  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46A01;patientCode=MRTR07805070;indi=LA11,MED1,ALT, //activo


  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46A01;patientCode=MRTR07805070;indi=LAS2, //activo

  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=MRMN09501240;indi=LA11,MED1,EN01,ALT,
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=MRMN09501240;indi=CP01,QUDF,

  
  
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=TJLC06510050

  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=PDCL08207260;indi=EDOF,//VALIDO
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=MRPR06011041;indi=CRLN
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=HRMR07707010
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=SLRC08301110
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=DSGR09002211
  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=SNCL19604140

  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=PNSC05403280
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=MRPR06011041
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=VLVL19509260
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=HRMR07707010
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=SLRC08301110
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=DSGR09002211
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=SNCL19604140

  http://localhost:4200/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=PDCL08207260
  https://prescriptions-dev.clinicabaviera.com/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=PDCL08207260
  https://wonderful-field-0ef6fb003.3.azurestaticapps.net/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C01;patientCode=PDCL08207260

















  https://wonderful-field-0ef6fb003.3.azurestaticapps.net/prescriptions/new;userCode=0000003258;professionalCode=JJLAD;clinicCode=46A01;patientCode=LVGR17412010 //valido
  https://wonderful-field-0ef6fb003.3.azurestaticapps.net/prescriptions/new;userCode=0000008363;professionalCode=LOREV;clinicCode=46A01;patientCode=LVGN05910170 //valido
  http://localhost/prescriptions/new;userCode=0000008363;professionalCode=LOREV;clinicCode=46A01;patientCode=LVGN05910170

  https://wonderful-field-0ef6fb003.3.azurestaticapps.net/prescriptions/new;userCode=0000007984;professionalCode=MASCH;clinicCode=46C02;patientCode=PLCR08801090

  http://localhost:4200/prescriptions/new;userCode=0000003258;professionalCode=JJLAD;clinicCode=15A01;patientCode=LVGR17412010
*/
import { Injectable } from '@angular/core';
import { Medicament } from 'src/app/models';
import { PrescriptionTablaService } from '../translations/prescriptions-translation.service';

@Injectable({
  providedIn: 'root'
})
export class MedicamentsFormalHelperService {

  constructor(
    private prescriptionTablaService:                                                      PrescriptionTablaService,
  ){}

  FormatMedicamentName( medicaments : Medicament[] ):Medicament[]{
    
    const formattedMedicaments = medicaments.map((m : Medicament) => { 
      if(m.unit.name){
        return { ...m , fullName : m.name + ' ' + m.unitAmount + ' ' + m.unit.name };
      } else {
        return { ...m , fullName : m.name + ' ' + m.unitAmount + ' ' + m.unit , unit : {name : m.unit}};
      }
    } );
    
    return formattedMedicaments;
  }

  FormatMedicamentFrequencyUnit( medicaments : Medicament[] ):Medicament[]{
    
    const formattedMedicaments = medicaments.map((m : Medicament) => {
      if(m.frequencyUnit){
        return { ...m , frequencyUnit : this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.frequencyUnit) }
      } else {
        return m;
      }
    })

    return formattedMedicaments;
  }

  FormatMedicamentDurationUnit( medicaments : Medicament[] ):Medicament[]{
    
    const formattedMedicaments = medicaments.map((m : Medicament) => {
      if(m.durationUnit){
        return { ...m , durationUnit : this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.durationUnit) }
      } else {
        return m;
      }
    })

    return formattedMedicaments;
  }

  FormatMedicamentFrequencyUnitToEnglish( medicaments : Medicament[] ):Medicament[]{
    
    const formattedMedicaments = medicaments.map((m : Medicament) => {
      if(m.frequencyUnit){
        return { ...m , frequencyUnit : this.prescriptionTablaService.TranslateToEnglishMedicamentsFrequencyUnit(m.frequencyUnit) }
      } else {
        return m;
      }
    })

    return formattedMedicaments;
  }

  FormatMedicamentDurationUnitToEnglish( medicaments : Medicament[] ):Medicament[]{
    
    const formattedMedicaments = medicaments.map((m : Medicament) => {
      if(m.durationUnit){
        return { ...m , durationUnit : this.prescriptionTablaService.TranslateToEnglishMedicamentsFrequencyUnit(m.durationUnit) }
      } else {
        return m;
      }
    })

    return formattedMedicaments;
  }

  BuildPosologyField( medicaments : Medicament[] ):Medicament[]{

    console.log(medicaments);


    const myMedicamentsWithPosology : Medicament[] = medicaments.map(( m : Medicament ) => {
      
      if(m.frequencyUnit && m.durationUnit && m.frequencyAmount && m.durationAmount){

        let myFrequencyUnit = "";
        let myFrequencyAmount = "";
        let myDurationAmount = "";
        let myDurationUnit = "";

        if(m.frequencyAmount > 1){

          myFrequencyAmount = m.frequencyAmount.toString();

          if(

            this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.frequencyUnit) === 'Hora' ||
            this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.frequencyUnit) === 'Día' ||
            this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.frequencyUnit) === 'Semana'

          ){

            myFrequencyUnit = this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.frequencyUnit) + 's';
            myFrequencyAmount = m.frequencyAmount.toString();

          } else if ( 
              this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.frequencyUnit) === 'Mes' 
            ){
            myFrequencyUnit = this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.frequencyUnit) + 'es';
            myFrequencyAmount = m.frequencyAmount.toString();
          } 
        } else {
          myFrequencyUnit = m.frequencyUnit;
        }

        if(m.durationAmount > 1){
          if(
            this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.durationUnit) === 'Hora' ||
            this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.durationUnit) === 'Día' ||
            this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.durationUnit) === 'Semana'
          ){
            myDurationUnit = this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.durationUnit) + 's';
            myDurationAmount = m.durationAmount.toString();
          }else if ( this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.durationUnit) === 'Mes' ){
            myDurationUnit = this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(m.durationUnit) + 'es';
            myDurationAmount = m.durationAmount.toString();
          }
        }else {
          myDurationUnit = m.durationUnit;
          myDurationAmount = "1";
        }

        

        let myUnitName = m.unit ? m.unit.name : m.takingUnit

        return { 
          ...m , 
          posology : `${m.takingAmount?.toString()} ${myUnitName} cada ${myFrequencyAmount} ${this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(myFrequencyUnit)} durante ${myDurationAmount} ${this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(myDurationUnit)}`.toLowerCase()
        }
      } else {
        return m
      }
    })

    return myMedicamentsWithPosology;
  }

  AddPosologyFieldsToDoclineMedicines( medicaments : Medicament[] ):Medicament[]{
    
    
    /* se trata de configurar inicialmente los valores takingAmount , frequencyAmount , frequencyUnit , durationAmount , durationUnit */

    return medicaments.map(( m : Medicament ) => {
      return { ...m , takingAmount : 1 , frequencyAmount : 1 , frequencyUnit : 'hour' , durationAmount : 1 , durationUnit : 'day' };
    })
  }

  AddFavoritePropertyToMedicament( medicaments : Medicament[] , favorites : Medicament[] , kind : string ):Medicament[]{

    if( kind === 'medicament' ){

      return medicaments.map((m : Medicament) => {
        
        if( m.kind === kind ){

          return { ...m , favorite : favorites.find((mf : Medicament) => m.code === mf.code && m) ? m.favorite = true : m.favorite = false }
        
        } else {

          return m;
        }
      })

    } else if ( kind === 'paraMedicament' ){

      return medicaments.map((m : Medicament) => {

        if( m.kind === kind ){

          return { ...m , favorite : favorites.find((pf : Medicament) => m.name === pf.name) ? m.favorite = true : m.favorite = false }
        
        } else {

          return m;
        }
       
      })

    } else if ( kind === 'masterFormula' ) {

      return medicaments.map((m : Medicament) => {

        if( m.kind === kind ){

          return { ...m , favorite : favorites.find((mf : Medicament) => m.composition === mf.composition) ? m.favorite = true : m.favorite = false }
        
        }else {

          return m;
        }
      })

    } else {

      return [];
    }
  }
}


<div 
    *ngIf="isDoctor || isNurse" 
    class="prescriptions_table-main_container" 
>
    
    <div>
        <app-alert></app-alert>
    </div>

    <dx-data-grid  
        [dataSource]="prescriptions"
        keyExpr="id"
        id="prescriptiosGridContainer"
        [showRowLines]="true"
        [showColumnLines]="false"
        [showBorders]="false"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        [columns]="['Id','fecha','paciente','doctor','centro','estado']"
        [loadPanel]="false"
        class="prescription_table-table"
        
    ><!-- (onRowDblClick)="OnRowDblClick($event)" -->

        <dxo-scrolling
            [useNative]="false"
            [scrollByContent]="true"
            [scrollByThumb]="true"
            showScrollbar="onHover"
            columnRenderingMode="virtual"
        >
        </dxo-scrolling>

        <dxo-filter-builder
            [allowHierarchicalFields]="true"
        ></dxo-filter-builder>

        <dxo-filter-builder-popup [position]="popupPosition"></dxo-filter-builder-popup>

        <dxo-filter-row 
            [visible]="showFilterRow"
            [applyFilter]="currentFilter"
        >
        </dxo-filter-row>

        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>

        <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
        
        <dxi-column 
            class="prescriptions_table-columns_header" 
            caption="Codigo" 
            [allowFiltering]="false" 
            dataField="friendlyId"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 120"
        ></dxi-column>
        
        <dxi-column 
            class="prescriptions_table-columns_header" 
            caption="Fecha" 
            dataField="createdOn" 
            [calculateFilterExpression]="CalculateFilterExpression"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 120"
        >
            Fecha
        </dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header prescriptions_table-patient_column" 
            [caption]="windowWidth < mobileViewBreakPoint ? '' : 'Paciente'" 
            [cellTemplate]="windowWidth < mobileViewBreakPoint ? 'mobileNamesDates' : null"
            dataField="patient.name"
            [allowFiltering]="false"
            [width]="windowWidth < mobileViewBreakPoint ? 150 : 300"
        ></dxi-column>

        <div *dxTemplate="let name of 'mobileNamesDates'" >
            <div class="prescriptions_table-mobile_firstName"> {{ name.data.patient.lastName }} , {{ name.data.patient.firstName }} </div>
            <div class="prescriptions_table-mobile_Date"> {{ name.data.createdOn }} </div>
        </div>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            caption="Doctor" 
            dataField="Doctor"
            [allowFiltering]="false"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 300"
        ></dxi-column>

        <!-- patient.clinics[0].clinicName -->
        <dxi-column 
            class="prescriptions_table-columns_header" 
            caption="Centro" 
            dataField="defaultClinicName"
            [allowFiltering]="true"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 300"
        ></dxi-column>
        
        <dxi-column 
            class="prescriptions_table-columns_header" 
            [caption]="windowWidth < mobileViewBreakPoint ? '' : 'Estado'" 
            dataField="status" 
            cellTemplate="estados"
            [allowFiltering]="true"
            [width]="windowWidth < mobileViewBreakPoint ? 110 : 220"
        ></dxi-column>

        <div *dxTemplate="let data of 'estados'" class="prescriptions_table-mobile_estado_main_container">
            <div class="cell-estado" 
                *ngIf="windowWidth > mobileViewBreakPoint"
                [ngClass]="{
                    'cell-danger':  data.text === 'Cancelada' || data.text === 'Expirada' , 
                    'cell-grey':  data.text === 'Manual' , 
                    'cell-dark-masterFormula':  data.text === 'Fórmula Magistral' , 
                    'cell-orange':  data.text === 'Reintentando emisión' , 
                    'cell-warning': data.text === 'Borrador', 
                    'cell-success': data.text === 'Emitida' || data.text === 'Dispensada', 
                    'cell-primary': data.text === 'Pendiente de Emisión', 
                    'cell-dark-success': data.text === 'Dispensada', 
                    'cell-grey-grey': data.text === 'F.Magistral elaboración' || data.text === 'D.parcial con sustitucion', 
                    'third': false
                }">{{data.value}}
            </div>

            <div class="cell-estado_mobile" 
                *ngIf="windowWidth <= mobileViewBreakPoint"
                [ngClass]="{
                    'cell-danger':  data.text === 'Cancelada' || data.text === 'Expirada' , 
                    'cell-grey':  data.text === 'Manual' , 
                    'cell-dark-masterFormula':  data.text === 'Fórmula Magistral' , 
                    'cell-orange':  data.text === 'Reintentando emisión' , 
                    'cell-warning': data.text === 'Borrador', 
                    'cell-success': data.text === 'Emitida' || data.text === 'Dispensada', 
                    'cell-primary': data.text === 'Pendiente de Emisión', 
                    'cell-dark-success': data.text === 'Dispensada', 
                    'cell-grey-grey': data.text === 'F.Magistral elaboración' || data.text === 'D.parcial con sustitucion', 
                    'third': false
                }"
            >
            </div>
        </div>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="reemisions" 
            dataField="reemisions_actions"
            [allowFiltering]="false"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 30"
        ></dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="status_refresh" 
            dataField="status_refresh_action"
            [allowFiltering]="false"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 30"
        ></dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="email" 
            dataField="email_action"
            [allowFiltering]="false"
            
        ><!-- [width]="windowWidth < mobileViewBreakPoint ? 0 : 30" -->
        </dxi-column>
        
        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="download" 
            dataField="download_action"
            [allowFiltering]="false"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 30"
        ></dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="preview" 
            dataField="preview_action"
            [allowFiltering]="false"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 30"
        ></dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="preview_movile" 
            dataField="preview_action_mobile"
            [allowFiltering]="false"
            [width]="windowWidth > mobileViewBreakPoint ? 0 : 30"
        ></dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="edit" 
            dataField="edit_action"
            [allowFiltering]="false"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 30"
        ></dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="delete" 
            dataField="delete_action"
            [allowFiltering]="false"
            [width]="windowWidth < mobileViewBreakPoint ? 0 : 30"
        ></dxi-column>

        <div *dxTemplate="let acciones of 'reemisions'">
            <div class="prescriptions_table-acciones_container" >
                <div 
                    *ngIf="
                        ( acciones.data.status === 'Dispensada' || acciones.data.status === 'Cancelada' || acciones.data.status === 'Expirada' ) && 
                        ( isDoctor ) && 
                        ( acciones.data.doclineId )
                    "
                    (click)="ShowReemitPrescriptionPopUp(acciones.data)" 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        id="reemision{{ acciones.data.id }}"
                        class="prescriptions_table-acciones-icons" 
                        [icon]="faReply"
                    ></fa-icon>
                </div>

                <dx-popover
                    target="#reemision{{ acciones.data.id }}"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="top"
                    [width]="150"
                >
                    <div *dxTemplate="let data = model; of: 'content'">
                        Volver a crear 
                    </div>

                </dx-popover>

                <div 
                    *ngIf="
                        !( acciones.data.status === 'Dispensada' || acciones.data.status === 'Cancelada' || acciones.data.status === 'Expirada' ) || 
                        !( isDoctor ) || 
                        !acciones.data.doclineId
                    " 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        class="prescriptions_table-acciones-icons_disable" 
                        [icon]="faReply"
                    ></fa-icon>
                </div>
            </div>
        </div>

        <div *dxTemplate="let acciones of 'status_refresh'">
            <div class="prescriptions_table-acciones_container" >
                <div 
                    *ngIf="
                        ( acciones.data.status === 'Emitida' || acciones.data.status === 'Dispensada' || acciones.data.status === 'Pendiente de Emisión' ) &&
                        ( isNurse || isDoctor )
                    "
                    (click)="ForcePrescriptionRefresh(acciones.data)" 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        id="forcerefresh{{ acciones.data.id }}"
                        class="prescriptions_table-acciones-icons" 
                        [icon]="faArrowsRotate"
                    ></fa-icon>
                </div>

                <dx-popover
                    target="#forcerefresh{{ acciones.data.id }}"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="top"
                    [width]="155"
                >
                    <div *dxTemplate="let data = model; of: 'content'">
                        Refrescar estado
                    </div>

                </dx-popover>

                <div 
                    *ngIf="
                        !( 
                            acciones.data.status === 'Emitida' || 
                            acciones.data.status === 'Dispensada' || 
                            acciones.data.status === 'Pendiente de Emisión' 
                        ) ||
                        !( isNurse || isDoctor )
                    " 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        class="prescriptions_table-acciones-icons_disable" 
                        [icon]="faArrowsRotate"
                    ></fa-icon>
                </div>
            </div>
        </div>

        <div *dxTemplate="let acciones of 'email'">
            <div class="prescriptions_table-acciones_container" >
                <div 
                    *ngIf="
                        ( 
                          acciones.data.status === 'Emitida' || 
                          acciones.data.status === 'Manual' || 
                          acciones.data.status === 'Fórmula Magistral' || 
                          acciones.data.status === 'Expirada' || 
                          acciones.data.status === 'Dispensada' 
                        ) && 
                        ( isNurse || isDoctor )
                    " 
                    (click)="SendPrescriptionByEmail(acciones.data)" 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        id="email{{ acciones.data.id }}"
                        class="prescriptions_table-acciones-icons" 
                        [icon]="faEnvelope"
                    ></fa-icon>
                </div>

                <dx-popover
                    target="#email{{ acciones.data.id }}"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="top"
                    [width]="150"
                >
                    <div *dxTemplate="let data = model; of: 'content'">
                        Enviar por email
                    </div>

                </dx-popover>

                <div 
                    *ngIf="
                        !( 
                            acciones.data.status === 'Emitida' || 
                            acciones.data.status === 'Manual' || 
                            acciones.data.status === 'Fórmula Magistral' || 
                            acciones.data.status === 'Expirada' || 
                            acciones.data.status === 'Dispensada' 
                        ) || 
                        !( isNurse || isDoctor )
                    " 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        class="prescriptions_table-acciones-icons_disable" 
                        [icon]="faEnvelope"
                    ></fa-icon>
                </div>
            </div>
        </div>

        <div *dxTemplate="let acciones of 'download'">
            <div class="prescriptions_table-acciones_container" >
                <div 
                    *ngIf=" 
                        (
                            acciones.data.status === 'Emitida' ||
                            acciones.data.status === 'Manual' || 
                            acciones.data.status === 'Fórmula Magistral' || 
                            acciones.data.status === 'Expirada' || 
                            acciones.data.status === 'Dispensada'
                        ) && 
                        ( isDoctor || isNurse )
                    " 
                    (click)="OrderDownloadPrescription(acciones)" 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        id="download{{ acciones.data.id }}"
                        class="prescriptions_table-acciones-icons" 
                        [icon]="faDownload"
                    ></fa-icon>
                </div>

                <dx-popover
                    target="#download{{ acciones.data.id }}"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="top"
                    [width]="110"
                >
                    <div *dxTemplate="let data = model; of: 'content'">
                        Descargar
                    </div>

                </dx-popover>

                <div 
                    *ngIf=" 
                        !( 
                            acciones.data.status === 'Emitida' ||
                            acciones.data.status === 'Manual' || 
                            acciones.data.status === 'Fórmula Magistral' || 
                            acciones.data.status === 'Expirada' || 
                            acciones.data.status === 'Dispensada'
                        ) || 
                        !( isDoctor || isNurse ) 
                    " 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        class="prescriptions_table-acciones-icons_disable" 
                        [icon]="faDownload"
                    ></fa-icon>
                </div>
            </div>
        </div>

        <div *dxTemplate="let acciones of 'preview_movile'">

            <div class="prescriptions_table-acciones_container" >
                <div  
                    (click)="GoToPrescriptionInMobile(acciones.data)" 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        class="prescriptions_table-acciones-icons" 
                        [icon]="faEye"
                    ></fa-icon>
                </div>
            </div>

        </div>

        <div *dxTemplate="let acciones of 'preview'">
            <div class="prescriptions_table-acciones_container" >
                <div 
                    *ngIf="
                        ( 
                            acciones.data.status === 'Emitida' ||
                            acciones.data.status === 'Manual' || 
                            acciones.data.status === 'Fórmula Magistral' || 
                            acciones.data.status === 'Expirada' || 
                            acciones.data.status === 'Dispensada'
                        ) && 
                        ( isNurse || isDoctor )
                    " 
                    (click)="ShowPrescriptionPreview(acciones)" 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        id="preview{{ acciones.data.id }}"
                        class="prescriptions_table-acciones-icons" 
                        [icon]="faEye"
                    ></fa-icon>
                </div>

                <dx-popover
                    target="#preview{{ acciones.data.id }}"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="top"
                    [width]="130"
                >
                    <div *dxTemplate="let data = model; of: 'content'">
                        Previsualizar
                    </div>

                </dx-popover>

                <div 
                    *ngIf="
                        !( 
                            acciones.data.status === 'Emitida' || 
                            acciones.data.status === 'Manual' || 
                            acciones.data.status === 'Fórmula Magistral' || 
                            acciones.data.status === 'Expirada' || 
                            acciones.data.status === 'Dispensada' 
                        ) || 
                        !( isNurse || isDoctor )
                    " 
                    class="prescriptions_table-acciones_icons_container" >
                    <fa-icon 
                        class="prescriptions_table-acciones-icons_disable" 
                        [icon]="faEye"
                    ></fa-icon>
                </div>
            </div>
        </div>

        <div *dxTemplate="let acciones of 'edit'">
            <div *ngIf="isDoctor" (click)="EditPrescripcion(acciones)" class="prescriptions_table-acciones_icons_container" >
                <fa-icon id="edit{{ acciones.data.id }}" class="prescriptions_table-acciones-icons" [icon]="faPen"></fa-icon>
            </div>

            <dx-popover
                target="#edit{{ acciones.data.id }}"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                position="top"
                [width]="80"
            >
                <div *dxTemplate="let data = model; of: 'content'">
                    Editar
                </div>

            </dx-popover>

            
            <div *ngIf="!isDoctor " class="prescriptions_table-acciones_icons_container" >
                <fa-icon class="prescriptions_table-acciones-icons_disable" [icon]="faPen"></fa-icon>
            </div>
        </div>

        <div *dxTemplate="let acciones of 'delete'">
            <!-- acciones.data.status == 'Borrador' -->

            <div *ngIf="isDoctor && ( acciones.data.status === 'Borrador' || acciones.data.status === 'Manual' || acciones.data.status === 'Fórmula Magistral' || acciones.data.status === 'Emitida' ) " 
                (click)="DeletePrescription(acciones)" 
                class="prescriptions_table-acciones_icons_container" 
            >
                <fa-icon id="delete{{ acciones.data.id }}" class="prescriptions_table-acciones-icons" [icon]="faTrash"></fa-icon>
            </div>

            <dx-popover
                target="#delete{{ acciones.data.id }}"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                position="top"
                [width]="90"
            >
                <div *dxTemplate="let data = model; of: 'content'">
                    Eliminar
                </div>

            </dx-popover>

            <div *ngIf="!isDoctor || !( acciones.data.status === 'Borrador' || acciones.data.status === 'Manual' || acciones.data.status === 'Fórmula Magistral' || acciones.data.status === 'Emitida' ) " 
                class="prescriptions_table-acciones_icons_container" 
            >
                <fa-icon class="prescriptions_table-acciones-icons_disable" [icon]="faTrash"></fa-icon>
            </div>

        </div>

        <dxo-master-detail [enabled]=" windowWidth < mobileViewBreakPoint ? false : true" template="detail"></dxo-master-detail>

        <div *dxTemplate="let medicamento of 'detail'">
            <div class="master-detail-caption"></div>
            <app-prescriptions-detail [Detalles]="medicaments" [key]="medicamento.key" ></app-prescriptions-detail>
        </div>
        
    </dx-data-grid>
    <div>
        <app-table-pagination (sendParams)="ReciveParams($event)"> </app-table-pagination>
    </div>

    <app-spinner></app-spinner>
</div>

<dx-popup
    height="300px"
    width="30%"
    minWidth="300px"
    [(visible)]="printDeletePopUp"
    [hideOnOutsideClick]="true"
>
    <app-delete-warning-pop-up-content 
        (CloseDeleteDraftPopUp)="CloseDeleteDraftPopUp($event)" (CloseDeleteFormalizedPrescriptionPopUp)="CloseDeleteFormalizedPrescriptionPopUp($event)">
    </app-delete-warning-pop-up-content>
</dx-popup>

<dx-popup
    height="300px"
    width="30%"
    minWidth="300px"
    [(visible)]="reemitPrescriptionConfirmPopUpVisible"
    [hideOnOutsideClick]="true"
>
    <div>
        <span> ¿ Desea crear la receta como un Borrador o como receta ya Formalizada ? </span>
    </div>

    <div class="prescriptions_table-reemit_popup_buttons_container" >
        <div class="prescriptions_table-reemit_popup_buttons" (click)="ReemitPrescriptionAsDrat()" >Borrador</div>
        <div class="prescriptions_table-reemit_popup_buttons" (click)="ReemitPrescriptionAsFormalized()">Formalizada</div>
    </div>
</dx-popup>

<div *ngIf="!isDoctor && !isNurse" >
    <app-protocols-table></app-protocols-table>
</div>

<div >
    <ng2-pdfjs-viewer 
    #pdfViewerOnDemand
        [externalWindow]="true"
        [downloadFileName]="pdfPreviewName"
        [openFile]="true"
        [viewBookmark]="false"
        [download]="false">
    </ng2-pdfjs-viewer>
</div>
<!-- <div style="width: 0px; height: 0px">
    <ng2-pdfjs-viewer #pdfViewerAutoLoad></ng2-pdfjs-viewer>
</div>
<div style="height: 0px">
    <ng2-pdfjs-viewer 
        pdfSrc="gre_research_validity_data.pdf" 
        viewerId="MyUniqueID" 
    >
    </ng2-pdfjs-viewer>
</div> -->

<!-- <embed src="https://stirisdevwesteu01.blob.core.windows.net/prescriptions/8360a294fab0412.pdf?sv=2021-10-04&se=2023-05-29T12%3A22%3A49Z&sr=b&sp=r&sig=X22tz7CoMmw93KTVb8klFEVfmeHYLWrkt7k7SJ%2FTRPA%3D" type=""> -->

import { PrescriptionsFilters } from '../../../models';
import { createAction, props } from '@ngrx/store';

export const modifyPrescriptionsFilters = createAction(
    '[PrescriptionsFilters] modify PrescriptionsFilters' , 
    props<{ prescriptionsFilters:PrescriptionsFilters }>()
);



//export const getPrescriptionsFailure = createAction('[Data] Fetch Data Failure', props<{ error: string }>());
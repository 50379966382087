import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatDatesService {

  VerifyGreaterThan(startDate : string , endDate : string):boolean{


    if(Date.parse(startDate) <= Date.parse(endDate)){
      return true;
    }else{
      return false;
    }
  }

  VerifyLessThanToday(date:string):boolean{
    const mydate = new Date(date);
    const today = new Date();
    if (mydate.getTime() < today.setHours(0,0,0,0)) {
      return false
    } else {
      return true;
    }
  }

  FormatPrescriptionDateYearMonthDay(date?:string):string{

    if(date){
      const dateObj  = new Date(date);
      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate();
      const year = dateObj.getFullYear();
      return `${year}-${month}-${day}`;
    }else  {
      return '';
    }

    
  }

  FormatPrescriptionDateDayMonthYear(date:string):string{
    const dateObj  = new Date(date);
    const month = dateObj.getMonth() + 1;
   
    const day = dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();
    
    const year = dateObj.getFullYear();
    return day + "/" + month + "/" + year;
  }

  FormatDateDayMonthYear(date:string):string{
    const dateObj  = new Date(date);
    const month = dateObj.getMonth();
   
    const day = dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();
    
    const year = dateObj.getFullYear();
    return day + "/" + month + "/" + year;
  }

  SumDaysToDate(date:string , days:number):string{
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate.toString();
  }

  ReturnCurDateLastYear():string{
    const d = new Date();
    d.setDate(d.getDate() - 365); // subtract 5 days
    return d.toISOString().split("T")[0]; // 2020-01-19
  }

  AddDaysToADate( date : string , days : number):string{
    const d = new Date( date );
    d.setDate(d.getDate() + days); 
    return d.toISOString().split("T")[0];
  }

  ToMyIsoString(date : Date) {
    const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',pad = function(num:number) {return (num < 10 ? '0' : '') + num;};
    console.log(dif);
    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + '00:00:01' /*+ pad(date.getHours()) +
      ':' + '00' + pad(date.getMinutes())+
      ':' + '00' + pad(date.getSeconds())  +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60); */
  }

  /* CalculateNumberOfDaysInNextCommingsXmonths( numberOfCommingsMonths : string , startDate:string ):void{

    const myStartDate = new Date( startDate );
    const yearOfStartDate = myStartDate.getFullYear();
    const monthOfStartDate = myStartDate.getMonth() + 1;

    let months = [0,1,2,3,4,5,6,7,8,9,10,11];
    let nextMonths = [];

    for (let i = monthOfStartDate ; i < +numberOfCommingsMonths; i++) {
      
      
    }

  } */

  daysInMonth(month:number, year:number) {
    return new Date(year, month, 0).getDate();
  }

  CalculateNumberOfDaysBetweenTwoDates(startDate : string , endDate:string):number{
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
      
    // To calculate the time difference of two dates
    const Difference_In_Time = date2.getTime() - date1.getTime();
      
    // To calculate the no. of days between two dates
    const Difference_In_Days = Math.floor( Difference_In_Time / (1000 * 3600 * 24) );
      
    return Difference_In_Days;
  }
}

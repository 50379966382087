<div class="prescription_form-filter_main_cotainer" >
    <div *ngIf="showAlert" class="prescription_form-patient_not_found_alert" >
        <div  
             
            class="alert-main_container" 
        >
            <div>
                <div class="alert-title_span_container" >
                    <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                    <span class="alert-title_span" > ATENCIÓN! </span>
                </div>
                <div class="alert-content_span_container">
                    <span class="alert-content_span"> Paciente no encontrado , contacte con el departamento de Help Desk para solucionar esta incidencia </span>
                </div>
            </div>
            
            <div>
                <div>
                    <fa-icon class="alert-x_button" (click)="OrderCloseAlert()" [icon]="faXmark"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    
    <div  class="prescription-form">
        <div class="prescription_form-inputs_container" >
                
            <div class="prescription_form-filter_input_container" >
                <input 
                    appInputTrim
                    [(ngModel)]="prescriptionsFilters.patientFilter"
                    class="input_nosubmit_white prescriptions_pacientes_input" 
                    type="text"
                    [disabled]="inputsDisabled"
                    (keyup)="StartTyping('patientFilter')"
                    placeholder="Buscar por paciente"
                    [ngClass]="{ 
                        'border-blue': prescriptionsFilters.patientFilter && prescriptionsFilters.patientFilter !== '' 
                    }"
                >
                <fa-icon 
                    *ngIf="prescriptionsFilters.patientFilter && prescriptionsFilters.patientFilter.length > 0  && !( inputsDisabled )"
                    (click)="ClearPatientFilter()" 
                    class="prescription_form-x-icons" 
                    [icon]="faCircleXmark"
                ></fa-icon>
            
            </div>
    
            <div *ngIf="!isDoctor" class="prescription_form-filter_input_container" >
                
                <input 
                    appInputTrim
                    [(ngModel)]="prescriptionsFilters.professionalFilter"
                    class="input_nosubmit_white prescriptions_pacientes_input" 
                    type="text" 
                    [disabled]="inputsDisabled"
                    (keyup)="StartTyping()"
                    placeholder="Buscar por profesional"
                    [ngClass]="{ 
                        'border-blue': prescriptionsFilters.professionalFilter && prescriptionsFilters.professionalFilter.length > 0 
                    }"
                >
    
                <fa-icon 
                    *ngIf="prescriptionsFilters.professionalFilter && prescriptionsFilters.professionalFilter.length > 0  && !( inputsDisabled )"
                    (click)="ClearProffesionalFilter()" 
                    class="prescription_form-x-icons" 
                    [icon]="faCircleXmark"
                ></fa-icon>
            </div>

            <!-- <div class="prescription_form-filter_input_container" >
                <input 
                    appInputTrim
                    [(ngModel)]="prescriptionsFilters.clinic"
                    class="input_nosubmit_white prescriptions_pacientes_input" 
                    type="text" 
                    [disabled]="inputsDisabled"
                    (keyup)="StartTyping()"
                    placeholder="Buscar por clínica"
                    [ngClass]="{ 
                        'border-blue': prescriptionsFilters.clinic && prescriptionsFilters.clinic.length > 0 
                    }"
                >
    
                <fa-icon 
                    *ngIf="prescriptionsFilters.clinic && prescriptionsFilters.clinic.length > 0  && !( inputsDisabled )"
                    (click)="ClearClinicFilter()" 
                    class="prescription_form-x-icons" 
                    [icon]="faCircleXmark"
                ></fa-icon>
            </div> -->

            <!-- <div class="dx-fieldset prescriptions_filters-inputs_datafield_containers" >
                <div class="dx-field"  >
                  
                    <div class="prescriptions_filters-selector_paciente_container">
    
                        <dx-select-box
                            [disabled]="inputsDisabled"
                            class="prescriptions_filters-dx_select_box"
                            [dataSource]="statusUngroupedData"
                            valueExpr="key"
                            [grouped]="true"
                            displayExpr="name"
                            [searchEnabled]="true"
                            groupTemplate="statuses"
                            [value]="prescriptionsFilters.status"
                            (onValueChanged)="SelectStatus($event)"
    
                        >
                        
                            <div *dxTemplate="let status of 'statuses'">
                                {{ status.name }} 
                            </div>
                        </dx-select-box>
    
                    </div>
                </div>
            </div> -->
    
            <div 
                class="prescription_form-from_input_container" 
                [ngClass]="{ 
                    'border-blue': prescriptionsFilters.from && prescriptionsFilters.from !== '' 
                }"
            >
                
                <dx-date-box 
                    class="form-date_box_from_input"
                    [showClearButton]="true"
                    [value]="prescriptionsFilters.from"
                    [(ngModel)]="prescriptionsFilters.from" 
                    [disabled]="inputsDisabled"
                    max="{{ maxDate }}"
                    type="date"
                    displayFormat="dd/MM/yyyy"
                    width="200px"
                    placeholder="Desde"
                    (onValueChanged)="CheckFilters($event , 'from')"
                >
                </dx-date-box>
            </div>
        
            <div 
                class="prescription_form-to_input_container" 
                [ngClass]="{ 
                    'border-blue': prescriptionsFilters.to && prescriptionsFilters.to !== '' 
                }"    
            >
                <dx-date-box 
                    class="form-date_box_to_input"
                    [value]="prescriptionsFilters.to"
                    [(ngModel)]="prescriptionsFilters.to"
                    [disabled]="inputsDisabled"
                    min="{{ minDate }}" 
                    type="date"
                    [showClearButton]="true"
                    placeholder="Hasta"
                    displayFormat="dd/MM/yyyy"
                    width="200px"
                    (onValueChanged)="CheckFilters($event , 'to')"
                    
                >
                </dx-date-box>
            </div>
        </div>
    </div>
    
    <div class="prescription-form_main-container" >
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-8 col-xs-8 prescription-filters-aplicar-container "></div>
    
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 prescription-form_righ_container">
                
                <div 
                    *ngIf="isDoctor && showNewPrescriptionButton" 
                    class="prescriptions_forms-nueva_receta_main_container" 
                >
                    <div 
                        (click)="GoNewPrescription()" 
                        class="prescriptions_forms-nueva_receta_container" 
                    >
                        <div>
                            <fa-icon [icon]="faPrescriptionBottleMedical"></fa-icon>
                        </div>
                        <div>
                            <span>NUEVA RECETA</span>
                        </div>
                    </div>
                </div>
                
                <div 
                    *ngIf="isAdmin || isDoctor" 
                    class="prescriptions_forms-nueva_receta_main_container prescriptions_forms-protocol_button_container" 
                >
                    <div 
                        (click)="OpenProtocolPopUp()" 
                        class="prescriptions_forms-nueva_receta_container" 
                    >
                        <div>
                            <fa-icon [icon]="faNewspaper"></fa-icon>
                        </div>
                        <div>
                            <span>PROTOCOLOS</span>
                        </div>
                    </div>
                </div>
                
                <div 
                    *ngIf="isDoctor" 
                    class="prescriptions_forms-nueva_receta_main_container" 
                >
                    <div 
                        (click)="GoToFavoritesPage()" 
                        class="prescriptions_forms-nueva_receta_container" 
                    >
                        <div>
                            <fa-icon [icon]="faStar"></fa-icon>
                        </div>
                        <div>
                            <span>FAVORITOS</span>
                        </div>
                    </div>
                </div>
    
                <dx-popup
                    height="98%"
                    width="30%"
                    minWidth="300px"
                    id="protocolPopUp"
                    [(visible)]="printPopUpProtocol"
                    [hideOnOutsideClick]="true"
                >
                    <dxo-position
                        my="right top"
                        at="right top"
                    >
                    </dxo-position>
                    <app-medicaments-templates-pop-up  ></app-medicaments-templates-pop-up>  
                </dx-popup>
            </div>
        </div>
    </div>

    <dx-popup
        height="50%"
        width="30%"
        minWidth="300px"
        id="prescriptionEmittedAlertPopUp"
        [(visible)]="prescriptionEmittedAlertPopUp"
        [hideOnOutsideClick]="true"
    >

        <h3>Hoy ya creó recetas para este paciente , ¿desea crear nuevo borrador con los datos seleccionados en ojo?</h3>
        <div class="prescriptions_forms-popup_buttons_container" >
            <div>
                <button class="prescriptions_forms-popup_buttons_create prescriptions_forms-popup_buttons" (click)="CreatePrescriptionWithMagickLinkHolded()" >Crear</button>
            </div>
            <div>
                <button class="prescriptions_forms-popup_buttons_cancel prescriptions_forms-popup_buttons" (click)="CancelCreatePrescriptionWithMagickLinkHolded()">Cancelar</button>
            </div>
        </div>
        <!-- <dxo-position
            my="right top"
            at="right top"
        >
        </dxo-position> -->
        <!-- <app-medicaments-templates-pop-up  ></app-medicaments-templates-pop-up> -->  
    </dx-popup>

</div>
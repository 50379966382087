import { Injectable } from '@angular/core';
import { ProfessionalFromJson } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalHelperService {

  AddAvatarByEmail( userEmail : string , professionalsJson : ProfessionalFromJson[] ): string {
    //test
    // const email = 'alvaro@optretina.com';

    const doctor = professionalsJson.filter((pj) => pj.email === userEmail);
    if(doctor){
      if(doctor?.length > 0){
        return doctor[0].avatar ? doctor[0].avatar : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}

<div class="side_menu-main-container" >
    <div class="side_menu-first_menu" >

        <div class="side_menu-items_container c-menu__link side_menu-active_path" 
            (click)="GoToPage('prescriptions')"
        >
            <fa-icon [icon]="faPrescriptionBottleMedical"  ></fa-icon>
        </div>
        
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('goingUp')"
        >
            <fa-icon [icon]="faGraduationCap"  ></fa-icon>
        </div>
        
        <div class="side_menu-items_container c-menu__link" routerLink="/documents" 
            (click)="GoToPage('player')"
        >
            <img class="side_menu-youtube_image" src="../../../../assets/icons/youtube_black_png.png" alt="">
        </div>
    </div>

</div>
<!-- {{ whoIsParent?.from }}
{{ whoIsParent?.parentId }}
{{ whoIsParent?.child }} -->

<dx-form
    id="parapharmacyForm"
    [formData]="parapharmacyMedicament" 
    labelLocation="top"
    [formData]="parapharmacyMedicament"
    (onFieldDataChanged)="FormFieldDataChanged($event)"
>
    <dxi-item *ngIf="whoIsParent?.from === 'medicaments_favorites'" [colCount]="1" itemType="group">

        <dxi-item [colSpan]="1" dataField="name" >
            <dxo-label alignment="left" text="Nombre">
            </dxo-label>
        </dxi-item>

    </dxi-item>

    <dxi-item *ngIf="whoIsParent?.from === 'prescription-new-table' || whoIsParent?.from === 'protocol'" [colCount]="3" itemType="group">

        <dxi-item [colSpan]="2" dataField="name" >
            <dxo-label alignment="left" text="Nombre">
            </dxo-label>
        </dxi-item>

        <dxi-item 
            [colSpan]="1"
            editorType="dxCheckBox" 
            dataField="favorite"
        >
            <dxo-label 
                location="left"
                alignment="right"
                [text]="'Favorito'"
            >
            </dxo-label>
        </dxi-item>

    </dxi-item>

    <dxi-item [colCount]="1" itemType="group">
        
        <dxi-item  
            [colSpan]="1"
            dataField="patientInstructions" 
            [editorOptions]="{ disabled: false }"
            [label]="{text: 'Instrucciones'}"    
        >
        </dxi-item>

    </dxi-item>

    <dxi-item [colCount]="1" [colSpan]="2" itemType="group" caption="Posología">
        <dxi-item dataField="posology"><dxo-label [visible]="false" ></dxo-label></dxi-item>
    </dxi-item>

    <dxi-item *ngIf="whoIsParent?.from === 'prescription-new-table'" [colCount]="2" [colSpan]="2" itemType="group" caption="Detalle del medicamento">

        <dxi-item 
            
            [editorOptions]="startDateEditorOptions"
            [label]="{text: 'Fecha de Inicio'}" 
            dataField="startDate"
            editorType="dxDateBox"
            format="dd/MM/yyyy"
        ></dxi-item>

        <dxi-item 
            
            [label]="{text: 'Fecha de Fin'}" 
            dataField="endDate"
            editorType="dxDateBox"
            [editorOptions]="{ min : endDateMin }"
            format="dd/MM/yyyy"
        ></dxi-item>

    </dxi-item>
    
    <dxi-item>
        <div class="magistral_formula_pop_up-save_button_container" *dxTemplate >
            <div class="magistral_formula_pop_up-save_button" >
                <dx-button type="default" text="SOME RANDOM" icon="add" (click)="SaveParaMedicament()">
                    Guardar 
                </dx-button>
            </div>
            <div class="magistral_formula_pop_up-save_alert_form">
                <div  *ngIf="AlertaAbierta" >
                    <div 
                       
                        class="alert-main_container" 
                    >
                        <div>
                            <div class="alert-title_span_container" >
                                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                                <span class="alert-title_span" > Complete campos incompletos o valores a 0 </span>
                            </div>
                        </div>
                        
                        <div>
                            <div>
                                <fa-icon class="alert-x_button" (click)="OrderCloseAlert()" [icon]="faXmark"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </dxi-item>

</dx-form>

import { HttpErrorResponse } from "@angular/common/http"
import { createAction, props } from "@ngrx/store"
import { Medicament } from "src/app/models"

export const getFavoritesParapharmacies = createAction(
    '[Favorites Parapharmacies] Get favorites parapharmacies',
    props<{ professionalId : string | null }>()
)

export const getFavoritesParapharmaciesSuccess = createAction(
    '[Favorites Parapharmacies] Get favorites parapharmacies success',
    props<{ medicaments : Medicament[] }>()  
)

export const getFavoritesParapharmaciesFailure = createAction(
    '[Favorites Parapharmacies] Get favorites parpharrmacies failure',
    props<{ error : HttpErrorResponse }>()  
)

export const addFavoritesParapharmacies = createAction(
    '[Favorites Parapharmacies] add favorite parapharmacy' , 
    props<{ parapharmacy : Medicament }>()
);

export const addFavoritesParapharmaciesSuccess = createAction(
    '[Favorites Parapharmacies] add favorite parpharrmacies success',
    props<{ myparapharmacyWithId ? : Medicament }>()
)

export const addFavoritesParapharmaciesFailure = createAction(
    '[Favorites Parapharmacies] add favorite parpharrmacy failure',
    props<{ error : HttpErrorResponse }>()
);

export const deleteFavoritesParapharmacies = createAction(
    '[Favorites Parapharmacies] delete favorite parapharmacy' , 
    props<{ parapharmacyFavoritedId : string }>()
);

export const deleteFavoritesParapharmaciesSuccess = createAction(
    '[Favorites Parapharmacies] delete favorite parapharmacy success',
    props<{ parapharmacyFavoritedId ? : string }>()
)

export const deleteFavoritesParapharmaciesFailure = createAction(
    '[Favorites Parpharmacies] delete favorite parapharmacy failure',
    props<{ error : HttpErrorResponse }>()
);

export const updateFavoritesParapharmacies = createAction(
    '[Favorites Parapharmacies] update favorite parapharmacy' , 
    props<{ parapharmacyUpdate : Medicament }>()
);

export const updateFavoritesParapharmaciesSuccess = createAction(
    '[Favorites Parapharmacies] update favorite parapharmacy success',
    props<{ myParapharmacyToUpdate ? : Medicament }>()
)

export const updateFavoritesParapharmaciesFailure = createAction(
    '[Favorites Parafarmacies] update favorite parafarmacy failure',
    props<{ error : HttpErrorResponse }>()
);
import { HttpErrorResponse } from "@angular/common/http"
import { createAction, props } from "@ngrx/store"
import { NgrxPrescriptionsRes , PrescriptionsFilters } from "src/app/models"

export const getPrescriptionsByParams = createAction(
    '[Prescriptions] Get prescriptions by patientId',
    props<{ params : PrescriptionsFilters }>()
)

export const getPrescriptionsByParamsSuccess = createAction(
    '[Prescriptions] Get prescriptions by patientId success',
    props<{ prescriptions : NgrxPrescriptionsRes }>()
)

export const getPrescriptionsByParamsFailure = createAction(
    '[Prescriptions] Get prescriptions by patientId failure',
    props<{ error: HttpErrorResponse }>()
)
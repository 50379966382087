import { createReducer, on } from "@ngrx/store";
import { Medicament } from "src/app/models";
import * as FavoritesMedicamentsActions from 'src/app/store/actions/medicaments/favorites-medicaments.actions';

export interface FavoriteMedicamentsState {
    medicaments : Medicament[]
}

const initialState : Medicament[] = [{name:'initial_value'}];

export const FavoritesMedicamentsReducers = createReducer(
    initialState,

    on( FavoritesMedicamentsActions.getFavoritesMedicamentsSuccess , ( state , {medicaments} ) => {
       state = medicaments;
       return state;
    }),

    on( FavoritesMedicamentsActions.getFavoritesMedicamentsFailure , ( state , {error} ) => {
       
        return [];
    }),

    on( FavoritesMedicamentsActions.addFavoritesMedicamentsSuccess , ( state , {myMedicamentWithId} ) =>{
        if( myMedicamentWithId ){
            return [ ...state , myMedicamentWithId ];
        }else {
            return state;
        }
    }),

    on( FavoritesMedicamentsActions.addFavoritesMedicamentsFailure , ( state , { error } ) => {
        
        return state;
    }),

    on( FavoritesMedicamentsActions.deleteFavoritesMedicamentsSuccess , ( state , { medicamentFavoritedId } ) =>{
     
        if( medicamentFavoritedId ){
          return state.filter( ( mf ) => mf.favoriteId !== medicamentFavoritedId );
        } else {
            return state;
        }
    }),

    on( FavoritesMedicamentsActions.updateFavoritesMedicamentsSuccess , ( state , { myMedicamentToUpdate }) => {
       
        if( myMedicamentToUpdate ){
            
            const { favoriteId } = myMedicamentToUpdate || {};
            return state.map(( mf ) => {return mf.favoriteId === favoriteId ? myMedicamentToUpdate : mf;});
        
        } else {
          return state;
        }
        
    })
)
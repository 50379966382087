import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentToDeletePopUpService {

  private subject = new Subject<{name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string , kind?:string , prescriptionStatus?:string}>();

  sendMessage( message : {name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string , kind?:string , prescriptionStatus?:string} ) {
    this.subject.next(message);
  }

  clearMessages() {
    this.subject.next({name:'' , id:'' , action:''});
  }

  onMessage(): Observable<{name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string ,kind?:string , prescriptionStatus?:string}> {
    return this.subject.asObservable();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Professional } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable()

export class ClinicsService {

  constructor(private http : HttpClient) { }

  LoadClinincsByProfessionalId(id:string):Observable<Professional>{

    return this.http.get<Professional>(environment.usersService.url + 'professional' + '?Id=' + id);
  }
}

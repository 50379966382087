import { createReducer, on } from "@ngrx/store";
import { Patient, Prescription } from "src/app/models";
import { getPatientsFailure, getPatientsSuccess } from "../../actions/prescriptions/patients.actions";
import { addClinicToPrescription, addPatientInfoToPrescription, cleanSinglePrescriptionState, storeSinglePrescription } from "../../actions/prescriptions/prescription.actions";

export interface PrescriptionState {
    prescription : Prescription
}

const initialState : Prescription = {};

export const PrescriptionReducers = createReducer(
    initialState,

    on( addClinicToPrescription , ( state , {areaCode} ) => {
       
       return { ...state , clinicCode : areaCode };
    }),

    on( addPatientInfoToPrescription , ( state , { patientInfo } ) => {

        const { id , name } = patientInfo || {};

        return { ...state , patient : { id : id , name : name } , patientId : id };
    }),

    on( cleanSinglePrescriptionState , ( state , { order } ) => {

        state = {};

        return state;
    }), 

    on( storeSinglePrescription , ( state , {prescription} ) => {

        state = prescription;

        return state;
    })
)
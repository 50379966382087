import { PrescriptionsFilters } from '../../../models';
import { createReducer , on } from '@ngrx/store';
import { modifyPrescriptionsFilters } from '../../actions/prescriptions/prescriptionsFilters.actions';

export interface PrescriptionsFiltersState {
    prescriptionsFilters : PrescriptionsFilters
}

const initialState : PrescriptionsFilters = { first:1 , size:10 , status:"All" /* , from: new Date().toString() */ /*  , to: SetFromDate( new Date().toString() ) */ };

export const PrescriptionsFiltersReducer = createReducer(
    initialState,
    
    on(modifyPrescriptionsFilters , (state , {prescriptionsFilters}   ) => {
        
        return { ...state , ...prescriptionsFilters };
    })
)

/* function SetFromDate (date : string):string {
    let dateObj  = new Date(date);
    let month = dateObj.getMonth() + 1;
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    return day + "/" + month + "/" + year;
} */


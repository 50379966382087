<div  class="medicaments_templates_popup-main_container">
    <div class="medicaments_templates_popup-protocol_banner_container">
       <!--  <div class="medicaments_templates_popup-protocol_title_container">
            <span class="medicaments_templates_popup-protocol_title_span">
                PROTOCOLOS
            </span>
        </div> -->
    
        <div class="medicaments_templates_popup-new_protocol_button_container">
            
            <div 
                *ngIf="( isAdmin || isDoctor ) && windowWidth > mobileViewBreakPoint" 
                (click)="GoToProtocol()" 
                class="medicaments_templates_popup-new_protocol_button" 
            >
                <div>
                    <fa-icon [icon]="faPlus"></fa-icon>
                </div>
                <div>
                    <span>Crear Protocolo</span>
                </div>
            </div>

            <div class="medicaments_templates_popup-input_search_container" >
                <input 
                    appInputTrim
                    class="input_nosubmit_white medicaments_templates_popup-search_input" 
                    type="text" 
                    placeholder="Buscar Protocolo" 
                    [(ngModel)]="params.filter" 
                    (input)="StartTyping()"
                >
            </div>

        </div>
        
    </div>

    <div class="medicaments_templates_popup-protocol_main_container" >
    
        <div class="dx-fieldset">

            <dx-scroll-view
                id="scrollviewMedicamentsTemplates"
                reachBottomText="Updating..."
                class="medicaments_templates_popup-scroll_view_container"
            >

                <div 
                    *ngFor="let protocolPrescription of protocolsPrescriptions" 
                    class="medicaments_templates_popup-protocol_container" 
                >
                    <div 
                        class="dx-field-value medicaments_templates-medicines_dropdowns" 
                        style="width:60%;" 
                    >
                        <dx-drop-down-button
                            width="100%"
                            textAlign="start"
                            [splitButton]="true"
                            [useSelectMode]="false"
                            [items]="protocolPrescription.medicaments"
                            text="{{ protocolPrescription.name }}"
                            displayExpr="name"
                            keyExpr="id"
                        ></dx-drop-down-button>
                    </div>
                    <div  class="medicaments_templates-acciones_icons_container" >
                        <div 
                            *ngIf=" (windowWidth > mobileViewBreakPoint) && (isAdmin || ( isDoctor && protocolPrescription.professionalId ))"
                            (click)="EditProtocol(protocolPrescription.id)" 
                            class="medicaments_templates-action_container" 
                        >
                            <fa-icon 
                                
                                class="medicaments_templates-acciones-icons" 
                                [icon]="faPen"
                            ></fa-icon>
                        </div>
                        <div *ngIf="isDraft && isDoctor" (click)="SelectProtocol(protocolPrescription)" class="medicaments_templates-action_container" >
                            <fa-icon 
                                class="medicaments_templates-acciones-icons" 
                                [icon]="faPlus"
                            ></fa-icon>
                        </div>
                        <div class="medicaments_templates_popup-star_img_container">
                            <img 
                                *ngIf="protocolPrescription.professionalId"
                                class="videos_table-starts" 
                                [ngSrc]="'assets/icons/starok.png'" 
                                alt="means this protocol was marked as favorite" 
                                width="20" 
                                height="20"
                            >
                        </div>
                    </div>

                </div>

            </dx-scroll-view>
        </div>
        
        
        
    </div>

    <app-spinner></app-spinner>

</div>
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentToSpinnerService } from 'src/app/shared/services/customs/component-to-spinner.service';
import { DynPrescriptionsService } from '../../httpRequests/dyn-prescriptions.service';
import { AppState, Prescription } from 'src/app/models';
import { Store } from '@ngrx/store';
import { addLastPrintPropertyToPrescription } from 'src/app/store/actions/prescriptions/prescriptions.actions';


@Injectable()

export class PrescriptionDownloadHelperService {

  constructor(
    private store:                                                   Store<AppState>,
    private componentToSpinnerService:                               ComponentToSpinnerService,                               
    private dynPrescriptionsService :                                DynPrescriptionsService                              
  ) { }

  DownloadPrescription( prescription : Prescription ):boolean | undefined{

    const prescriptionId = prescription.id ? prescription.id : prescription.prescriptionId;

    if( prescriptionId ){
      this.componentToSpinnerService.sendMessage('show');
      this.dynPrescriptionsService.GetDownloadPrescriptionUrlById(prescriptionId).subscribe((res:string)=>{

        this.dynPrescriptionsService.DownloadPrescriptionByUrl(res).subscribe((response)=>{
  
          const filename = `${prescriptionId}-receta.pdf`;
          const binaryData :  BlobPart[] = [];
          binaryData.push((response as BlobPart));
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData));
  
          if (filename){
            downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
          }
            
          downloadLink.click();
          this.componentToSpinnerService.sendMessage('hide');
  
          this.store.dispatch( addLastPrintPropertyToPrescription({ prescription : prescription }) )
          
          return true;
        },(error: HttpErrorResponse) => {

          this.componentToSpinnerService.sendMessage('hide');
          return false;
        })
  
      },(error: HttpErrorResponse) => {

        this.componentToSpinnerService.sendMessage('hide');
        return false;
      })
  
      return false;
      
    }else {
      return false;
    }
  }
  
  /* PreviewPdf(prescription : Prescription):void{
    const { id } = prescription;
    
    if( id ){
      this.dynPrescriptionsService.GetDownloadPrescriptionUrlById(id).subscribe((res:string)=>{
      
      },(error: HttpErrorResponse) => {
      })
    }
  } */

  /* https://stirisdevwesteu01.blob.core.windows.net/prescriptions/16285664c3064d7.pdf?sv=2021-10-04&se=2023-05-29T14%3A14%3A59Z&sr=b&sp=r&sig=H40apBjT6JQkUZBmStWRAdPcCsClHi6FgeZWETE2PN8%3D */
}

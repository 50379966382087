<div class="prescription_form-mobile_filter_input_container" >

    <input 
        appInputTrim
        [(ngModel)]="prescriptionsFilters.patientFilter"
        class="input_nosubmit_white prescription_form-mobile_pacientes_input" 
        type="text"
        [disabled]="inputsDisabled"
        (keyup)="StartTyping('patientFilter')"
        placeholder="Buscar por paciente"
        [ngClass]="{ 
            'border-blue': prescriptionsFilters.patientFilter && prescriptionsFilters.patientFilter !== '' 
        }"
    >

    <div class="prescription_form-mobile_filter_icon_container">

        <fa-icon 
            *ngIf="prescriptionsFilters.patientFilter && prescriptionsFilters.patientFilter.length > 0  && !( inputsDisabled )"
            (click)="ClearPatientFilter()" 
            class="prescription_form-mobile_form-x-icons" 
            [icon]="faCircleXmark"
        ></fa-icon>

    </div>

</div>

<!-- <div class="prescription_form-mobile_filter_input_container" >

    <input 
        appInputTrim
        [(ngModel)]="prescriptionsFilters.clinic"
        class="input_nosubmit_white prescription_form-mobile_pacientes_input" 
        type="text"
        [disabled]="inputsDisabled"
        (keyup)="StartTyping('clinic')"
        placeholder="Buscar por clínica"
        [ngClass]="{ 
            'border-blue': prescriptionsFilters.clinic && prescriptionsFilters.clinic !== '' 
        }"
    >

    <div class="prescription_form-mobile_filter_icon_container">

        <fa-icon 
            *ngIf="prescriptionsFilters.clinic && prescriptionsFilters.clinic.length > 0  && !( inputsDisabled )"
            (click)="ClearClinicFilter()" 
            class="prescription_form-mobile_form-x-icons" 
            [icon]="faCircleXmark"
        ></fa-icon>

    </div>

</div> -->

<!-- <div class="dx-fieldset mobile_prescriptions_filters-inputs_datafield_containers" >

    <div class="dx-field"  >
      
        <div class="mobile_prescriptions_filters-selector_paciente_container">

            <dx-select-box
                [disabled]="inputsDisabled"
                class="mobile_prescriptions_filters-dx_select_box"
                [dataSource]="statusUngroupedData"
                valueExpr="key"
                [grouped]="true"
                displayExpr="name"
                [searchEnabled]="true"
                groupTemplate="statuses"
                [value]="prescriptionsFilters.status"
                (onValueChanged)="SelectStatus($event)"

            >
            
                <div *dxTemplate="let status of 'statuses'">
                    {{ status.name }} 
                </div>
            </dx-select-box>

        </div>
    </div>
</div> -->

<div *ngIf="isNurse" class="prescription_form-mobile_filter_input_container" >
    <input 
        appInputTrim
        [(ngModel)]="prescriptionsFilters.professionalFilter"
        class="input_nosubmit_white prescription_form-mobile_pacientes_input" 
        type="text" 
        [disabled]="inputsDisabled"
        (keyup)="StartTyping()"
        placeholder="Buscar por profesional"
        [ngClass]="{ 
            'border-blue': prescriptionsFilters.professionalFilter && prescriptionsFilters.professionalFilter.length > 0 
        }"
    >

    <div class="prescription_form-mobile_filter_icon_container">

        <fa-icon 
            *ngIf="prescriptionsFilters.professionalFilter && prescriptionsFilters.professionalFilter.length > 0  && !( inputsDisabled )"
            (click)="ClearProffesionalFilter()" 
            class="prescription_form-mobile_form-x-icons" 
            [icon]="faCircleXmark"
        ></fa-icon>

    </div>
</div>

<div 
    class="prescription_form-mobile_form-from_input_container" 
    [ngClass]="{ 
        'border-blue': prescriptionsFilters.from && prescriptionsFilters.from !== '' 
    }"
>
    
    <dx-date-box 
        class="prescription_form-mobile_form-date_box_from_input"
        [showClearButton]="true"
        [value]="prescriptionsFilters.from"
        [(ngModel)]="prescriptionsFilters.from" 
        [disabled]="inputsDisabled"
        max="{{ maxDate }}"
        type="date"
        displayFormat="dd/MM/yyyy"
        placeholder="Desde"
        (onValueChanged)="CheckFilters($event , 'from')"
    >
    </dx-date-box>
</div>

<div 
    class="prescription_form-mobile_form-to_input_container" 
    [ngClass]="{ 
        'border-blue': prescriptionsFilters.to && prescriptionsFilters.to !== '' 
    }"    
>
    <dx-date-box 
        class="prescription_form-mobile_form-date_box_to_input"
        [value]="prescriptionsFilters.to"
        [(ngModel)]="prescriptionsFilters.to"
        [disabled]="inputsDisabled"
        min="{{ minDate }}" 
        type="date"
        [showClearButton]="true"
        placeholder="Hasta"
        displayFormat="dd/MM/yyyy"
        (onValueChanged)="CheckFilters($event , 'to')"
        
    >
    </dx-date-box>
</div>

<div class="medicament_search_popup-banner_main_container">
   
    <div class="medicament_search_popup-banner_sub_container" >
        
        <div class="medicament_search_popup-banner_left_container" >
            <div class="medicament_search_popup-actions_input_container" >
                <input 
                    appInputTrim
                    type="text" 
                    placeholder="Medicamento" 
                    [(ngModel)]="params.medicamentoFiltrado"
                    class="input_nosubmit medicament_search_popup-search_input"
                >
            </div>
            <div class="medicament_search_popup-actions_containers" >
                <button 
                    (click)="SearchMedicaments('search_button')"
                    class="medicament_search_popup-button_search" 
                > Buscar </button>
            </div>
            
        </div>

        <div>
            <app-table-pagination (sendParams)="ReciveParams($event)"></app-table-pagination>
        </div>
    </div>

    <div class="medicament_search_popup-grid_main_container">
        <dx-data-grid  
            #grid
            [dataSource]="medicamentsArr"
            keyExpr="id"
            id="medicamentSearchGridContainer"
            [hoverStateEnabled]="true"
            (onRowUpdated)="OnRowUpdated($event)"
            [remoteOperations]="true"
            [showBorders]="true"
            [showRowLines]="true"
            (onRowClick)="OnRowClick($event)"
            class="medicament_search_popup-dataGrid_container"
        >
            <dxo-paging [enabled]="false" ></dxo-paging>
            <dxo-scrolling
                [useNative]="false"
                [scrollByContent]="true"
                [scrollByThumb]="true"
                showScrollbar="onHover"
                columnRenderingMode="virtual"
                rowRenderingMode="virtual"
            >
            </dxo-scrolling>
    
            <dxi-column class=""  caption="Nombre" dataField="name"></dxi-column>

            <dxi-column 
                class=""  
                caption="Psicotrópico o Estupefaciente" 
                dataField="psicotropicOrNarcotic"
                [width]="windowWidth < mobileViewBreakPoint ? 0 : 120"
                [alignment]="'center'"
            ></dxi-column>

            <!--------------------------------------------- FIN POSOLOGIA ------------------------------------------->

            <dxi-column 
                class="prescriptions_table-columns_header" 
                width="40" 
                caption="" 
                cellTemplate="AccionesTemplate" 
                dataField="acciones"
                [allowFiltering]="false"
            ></dxi-column>
            
            <div *dxTemplate="let acciones of 'AccionesTemplate'">
                <div class="prescriptions_table-acciones_container" >

                    <div 
                        class="medicament_search-plus_icons_container" 
                    >
                        <input
                            [disabled]="true"
                            type="checkbox" 
                            class="checkbox__input" 
                            [(ngModel)]="acciones.data.selected" 
                            
                        >
                        <svg class="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                            <rect width="21" height="21" x=".5" y=".5" fill="#FFF" stroke="#006F94" rx="3" />
                            <path class="tick" stroke="#6EA340" fill="none" stroke-linecap="round" stroke-width="4" d="M4 10l5 5 9-9" />
                        </svg>
                    </div>

                </div>
            </div>

        </dx-data-grid>
        
    </div>

    <div class="medicament_search_popup-button_close_container" >
        <button  
            class="medicament_search_popup-button_close"
            (click)="CloseMedicamentsSearchPopUp()"
        >
            Cerrar
        </button>
    </div>

    <div>
        <app-spinner></app-spinner>
    </div>
</div>

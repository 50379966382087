import { Component } from '@angular/core';
import { 
  faUser, fas , faScaleBalanced ,faUsers ,
  faList , faBookReader ,faCalendarDays , 
  faPhoneFlip , faGraduationCap , faChartColumn,
  faFolderOpen , faGear , faCircleQuestion , 
  faUserDoctor , faBuilding , faPrescriptionBottleMedical , 
  faAnglesRight, IconDefinition ,  
} from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary  } from '@fortawesome/angular-fontawesome';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {

  faUser:IconDefinition = faUser;
  faUsers:IconDefinition = faUsers;
  faList:IconDefinition = faList;
  faScaleBalanced:IconDefinition = faScaleBalanced; 
  faUserDoctor:IconDefinition = faUserDoctor; 
  faPrescriptionBottleMedical:IconDefinition = faPrescriptionBottleMedical; 
  faBuilding:IconDefinition = faBuilding; 
  faBookReader:IconDefinition = faBookReader;
  faCalendarDays:IconDefinition = faCalendarDays;
  faPhoneFlip:IconDefinition = faPhoneFlip;
  faGraduationCap:IconDefinition = faGraduationCap;
  faChartColumn:IconDefinition = faChartColumn;
  faFolderOpen:IconDefinition = faFolderOpen;
  faGear:IconDefinition = faGear;
  faCircleQuestion:IconDefinition = faCircleQuestion;
  faAnglesRight:IconDefinition = faAnglesRight;

  constructor(
    private route:                                   ActivatedRoute,
    private router:                                  Router,
    library: FaIconLibrary

  ){

    library.addIconPacks(fas);
    library.addIcons(faUser);
    library.addIcons(faUsers);
    library.addIcons(faList);
    library.addIcons(faScaleBalanced);
    library.addIcons(faBookReader);
    library.addIcons(faCalendarDays);
    library.addIcons(faPhoneFlip);
    library.addIcons(faGraduationCap);
    library.addIcons(faChartColumn);
    library.addIcons(faFolderOpen);
    library.addIcons(faGear);
    library.addIcons(faCircleQuestion);
    library.addIcons(faAnglesRight);
    
  }

  GoToPage( goTo : string ):void{
    

    const { location : { hostname } } = window || {};

    if( goTo !== 'prescriptions' && ( goTo === 'player' ) ){

      if(  hostname === 'localhost' ){
      
        window.location.href = environment.environmentDomain;

      } else {
  
        window.location.href = `${environment.environmentProtocol}${goTo}${environment.environmentDomain}`;

      }

    }else if ( goTo !== 'prescriptions' && ( goTo === 'goingUp' ) ){
      
      window.location.href = 'https://iris.clinicabaviera.com/#/going-up.es';
    }

   

  }

}

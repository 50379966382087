
/* customs */
export { MobileNewMedicamentButtonToMobileNewMedicamentContentService } from './customs/mobile-new-medicament-button-to-mobile-new-medicament-form.service';
export { FormToTableService } from './customs/form-to-table.service';
export { MedicamentSearchPopUpToPrescriptionNewTableService } from './customs/medicament-search-pop-up-to-prescription-new-table.service';
export { PresTemplateToNewPresTableService } from './customs/pres-template-to-new-pres-table.service';
export { PrescriptionNewToPatientInfoErrorsPopUpService } from './customs/prescription-new-to-patient-info-errors-pop-up.service';
export { PrescriptionNewToPrescriptionsNewTableService } from './customs/prescription-new-to-prescriptions-new-table.service';
export { PrescriptionsNewToMedicamentsTemplateService } from './customs/prescriptions-new-to-medicaments-template.service';
export { PrescriptionNewToPatientsPopUpService } from './customs/prescription-new-to-patients-pop-up.service';
export { ProtocolToIndicationTableService } from './customs/protocol-to-indication-table.service';
export { PrescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService } from './customs/prescriptions-filters-component-to-medicaments-templates-pop-up-component.service';
export { MedicamentsFavoritesPopUpToPrescriptionNewTableServiceService } from 'src/app/prescriptions/services/customs/medicaments-favorites-pop-up-to-prescription-new-table-service.service';
/* httpRequests */
export { ClinicsService } from './httpRequests/clinics.service';
export { DynPrescriptionsService } from './httpRequests/dyn-prescriptions.service';
export { HandleHTTPerrorsService } from '../../shared/services/httpRequests/handle-httperrors.service';
export { MedicinesService } from './httpRequests/medicines.service';
export { PatientsService } from './httpRequests/patients.service';
export { ProfessionalService } from './httpRequests/professional.service';
export { ProtocolsService } from './httpRequests/protocols.service';
export { IndicationsService } from './httpRequests/indications.service';
/* helpers */
export { PrescriptionDownloadHelperService } from './helpers/prescriptionNew/prescription-download-helper.service';
export { PrescriptionNewHelperService } from './helpers/prescriptionNew/prescription-new-helper.service';
export { PrescriptionNewValidatorsHelperService } from './helpers/prescriptionNew/prescription-new-validators-helper.service';
export { PrescriptionTablaService } from './helpers/translations/prescriptions-translation.service';
export { PrescriptionsAlertsService } from './helpers/alerts/prescriptions-alerts.service';
export { MedicamentValidatorsService } from './helpers/medicaments/medicament-validators.service';

<div class="protocol_medical_indication-inputs_container" >

    <div class="protocol_medical_indication-input_sub_container" >
        <input
            appInputTrim 
            type="text"
            [(ngModel)]="indicationParams.filterFamily"
            type="text"
            class="input_nosubmit_white protocol_medical_indication-search_family_input"
            (keyup)="StartTyping()"
            placeholder="Buscar familia "
        >
    </div>

    <div class="protocol_medical_indication-input_sub_container" >
        <input
            appInputTrim 
            type="text"
            [(ngModel)]="indicationParams.filter"
            type="text"
            class="input_nosubmit_white protocol_medical_indication-search_input"
            (keyup)="StartTyping()"
            placeholder="Buscar"
        >
    </div>
    
</div>

<div>
    <app-table-pagination (sendParams)="ReciveParams($event)"> </app-table-pagination>
</div>

<dx-data-grid  
    [dataSource]="indications"
    keyExpr="id"
    id="patientsGridContainer"
    [showRowLines]="true"
    [showColumnLines]="false"
    [showBorders]="false"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
    [columns]="[]"
    [hoverStateEnabled]="true"
    [loadPanel]="false"
    class="patients_table-table"
>

    <dxi-column 
        caption="Familia" 
        dataField="indicationFamilyDescription"
        [allowFiltering]="false"
    ></dxi-column>

    <dxi-column 
        caption="Indicacion" 
        dataField="description"
        [allowFiltering]="false"
    ></dxi-column>

    <dxi-column 
        caption="OjoId" 
        dataField="ojoStrId"
        [allowFiltering]="false"
    ></dxi-column>

    <dxi-column 
        class="prescriptions_table-columns_header" 
        width="100px" 
        caption="Seleccionar" 
        cellTemplate="AccionesTemplate" 
        dataField="acciones"
        [allowFiltering]="false"
        [alignment]="'center'"
    ></dxi-column>

    <div *dxTemplate="let acciones of 'AccionesTemplate'">
        <div class="prescriptions_table-acciones_container" >

            <div 
                class="medicament_search-plus_icons_container" 
            >
                <input
                    [disabled]="inputDisabled"
                    type="checkbox" 
                    class="checkbox__input" 
                    [(ngModel)]="acciones.data.selected" 
                    (change)="SelectIndication(acciones.data)"
                >
                <svg class="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <rect width="21" height="21" x=".5" y=".5" fill="#FFF" stroke="#006F94" rx="3" />
                    <path class="tick" stroke="#6EA340" fill="none" stroke-linecap="round" stroke-width="4" d="M4 10l5 5 9-9" />
                </svg>
            </div>

        </div>
    </div>

    

</dx-data-grid>

<div>
    <app-spinner></app-spinner>
</div>
    

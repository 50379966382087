<div class="prescription_new-main_container desktop_view" >
    
    <div>
        <app-alert></app-alert>
    </div>

    <div class="prescription_new-banner_main_container" >
        <div class="prescription_new-banner_containers prescription_new-banner_left_icon_container">
            <fa-icon id="backListPrescriptionsIcon" (click)="BackToPrescriptions()" [icon]="faArrowLeft"></fa-icon>
            <dx-popover
                target="#backListPrescriptionsIcon"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                position="top"
                [width]="150"
            >
                <div *dxTemplate="let data = model; of: 'content'">
                    Volver a listado de recetas
                </div>

            </dx-popover>
        </div>
        
        <div class="prescription_new-banner_containers">
            <fa-icon (click)="BackToPrescriptions()" [icon]="faEye"></fa-icon>
        </div>
        <div class="prescription_new-banner_containers">
            <span class="prescription_new-banner_span"> {{ pageTitle }} </span>
        </div>
        
    </div>

    <!----------------------------------------------------------------- WEB BUTTONS > 1192px resolution ----------------------------------------------------------->

    <div class="prescription_new-web_viewform_main_container">

        <div class="prescription_new-date_box_container" >
            <div class="prescription_new-crossed_labels">
                Fecha
            </div>
            <dx-date-box 
                min="{{ today }}"
                displayFormat="dd/MM/yyyy"
                [disabled]="prescriptionCreated"
                class="prescription_new-date_box_box"
                [value]="prescriptionDraft.createdOn" 
                type="date"
            >
            </dx-date-box>
        </div>

        <div class="dx-fieldset prescription_new-inputs_datafield_containers" >
            <div class="dx-field"  >
              
                <div class="dx-field-value prescription_new-selector_paciente_container">
                  <div class="dx-field-label prescription_new-crossed_labels">Clínicas</div>

                    <dx-select-box

                        [disabled]="prescriptionCreated"
                        class="prescription_new-dx_select_box"
                        [dataSource]="clinicsfromUngroupedData"
                        valueExpr="clinic.areaCode"
                        [grouped]="true"
                        displayExpr="clinic.areaName"
                        [searchEnabled]="true"
                        groupTemplate="clinicas"
                        [value]="prescriptionDraft.areaCode"
                        (onValueChanged)="SelectClinic($event)"

                    >
                    
                        <div *dxTemplate="let clinica of 'clinicas'">
                            {{ clinica.areaName }} 
                        </div>
                    </dx-select-box>

                </div>
            </div>
        </div>

        <div 
            *ngIf="prescriptionDraft.areaCode && !prescriptionDraft.id" 
            (click)="OpenClientsPopUp()"    
            class="prescription_new-buscar_paciente_container"
        >
            <fa-icon class="prescription_new-faSearch_icon" [icon]="faSearch"></fa-icon>
            <span>Buscar Paciente</span>
        </div>

        <div 
            *ngIf="prescriptionDraft.id && patientDoclineIsValid && isDoctor" 
            class="prescription_new-patient_name_container"    
        >
            <span class="prescription_new-patient_label" >
                Paciente
            </span>
            <span class="prescription_new-patient_name_content" >
                {{ prescriptionDraft.patient.name }}
            </span>
        </div>

    </div>
    <!----------------------------------------------------------------- END WEB BUTTONS > 1192px resolution ----------------------------------------------------------->
    
    <app-prescription-new-table 
        *ngIf=" windowWidth >= mobileViewBreakPoint "
        (SendRefreshOrder)="ReciveRefreshOrder($event)" 
        (SendIfMedicamentOrMasterFormulas)="SendIfMedicamentOrMasterFormulas($event)"
    ></app-prescription-new-table>

    <div 
        *ngIf="isDraft && patientDoclineIsValid && isDoctor && existsMedicamentOrMasterFormula" 
        class="prescription_new-guardar_button_containter" 
    >
        <button 
            class="prescription_new-guardar_descargar_button" 
            (click)="CheckProfessionalCollegiateNumberIsValid()" 
        >Formalizar Receta</button>
    </div>

    <div 
        *ngIf=" ( prescriptionDraft.status === 'Emitted' || prescriptionDraft.status === 'Expired' || prescriptionDraft.status === 'Finished' ) && prescriptionDraft.id"
        class="prescription_new-guardar_button_containter" 
    >
        <button 
            class="prescription_new-guardar_descargar_button" 
            (click)="OrderDownloadPrescription()" 
        >
            Descargar
        </button>
    </div>

    <div >
        <app-spinner></app-spinner>
    </div>

    <dx-popup
        height="100%"
        width="50%"
        minWidth="350px"
        [(visible)]="printClientsTablePopUp"
        [hideOnOutsideClick]="true"
        
    
    >

        <app-prescription-patients-table-popup></app-prescription-patients-table-popup>
    </dx-popup>

    <dx-popup
        height="30%"
        width="20%"
        minWidth="300px"
        [(visible)]="printConfirmManualPrescriptionCreatePopUp"
        [hideOnOutsideClick]="false"
    >
        <div class="prescription_new-prescription_manual_popup_confirmation_span_container" >
            Número de Colegiado no válido para DOCLINE ¿Desea crear la receta de forma manual?
        </div>
        <div class="prescription_new-prescription_manual_popup_confirmation_action_buttons_container" >
            <div (click)="CreateManualPrescription()" >
                <button class="prescription_new-prescription_manual_popup_confirmation_create_button" > Crear </button>
            </div>
            <div (click)="CancelCreateManualPrescription()" >
                <button class="prescription_new-prescription_manual_popup_confirmation_cancel_button" > Cancelar </button>
            </div>
        </div>
    </dx-popup>
    
</div>

<div class="mobile_view">

    <div>
        <app-spinner></app-spinner>
    </div>

    <div class="prescription_new-mobile_back_to_prescriptions_home_banner_main_container">

        <div class="prescription_new-mobile_back_to_prescriptions_home_icon_container">
            <div> <fa-icon class="prescription_new-mobile_receta_mobile_icon" (click)="BackToPrescriptions()" [icon]="faCircleChevronLeft"></fa-icon> </div>
            <div class="prescription_new-mobile_receta_mobile_title">Receta</div>
        </div>

        <div class="cell-estado" 
            [ngClass]="{
                'cell-danger':  prescriptionDraft.status === 'Canceled' || prescriptionDraft.status === 'Expired' , 
                'cell-grey':  prescriptionDraft.status === 'Manual' , 
                'cell-dark-masterFormula':  prescriptionDraft.status === 'MasterFormula' , 
                'cell-orange':  prescriptionDraft.status === 'RetryDraft' , 
                'cell-warning': prescriptionDraft.status === 'Draft', 
                'cell-success': prescriptionDraft.status === 'Emitted' || prescriptionDraft.status === 'Finished', 
                'cell-primary': prescriptionDraft.status === 'Pending', 
                'cell-dark-success': prescriptionDraft.status === 'Finished', 
                'cell-grey-grey': prescriptionDraft.status === 'F.Magistral elaboración' || prescriptionDraft.status === 'D.parcial con sustitucion', 
                'third': false
            }">{{ prescriptionDraft.statusTranslated }}
        </div>

    </div>
        
    <div class="prescription_new-mobile_clinics_combo_main_container" >

        <div class="prescription_new-mobile_clinics_combo_span_container">Clínicas</div>

        <dx-select-box
            class="prescription_new-mobile_clinics_combo"
            [disabled]="prescriptionCreated"
            [dataSource]="clinicsfromUngroupedData"
            valueExpr="clinic.areaCode"
            [grouped]="true"
            displayExpr="clinic.areaName"
            [showDropDownButton]="true"
            [searchEnabled]="false"
            groupTemplate="clinicas"
            [value]="prescriptionDraft.areaCode"
            (onValueChanged)="SelectClinic($event)"
        >
        
            <div *dxTemplate="let clinica of 'clinicas'">
                {{ clinica.areaName }} 
            </div>
            
        </dx-select-box>

    </div>

    
    <div class="prescription_new-mobile_buscar_paciente_parent_container">
        <div 
            *ngIf="prescriptionDraft.areaCode && !prescriptionDraft.id" 
            (click)="OpenClientsPopUp()"    
            class="prescription_new-mobile_buscar_paciente_container"
        >
            <fa-icon class="prescription_new-faSearch_icon" [icon]="faSearch"></fa-icon>
            <span>Buscar Paciente</span>
        </div>
    </div>
    

    <dx-popup
        height="100%"
        width="50%"
        minWidth="350px"
        [(visible)]="printClientsTablePopUp"
        [hideOnOutsideClick]="true"
        [showCloseButton]="true"
        [showTitle]="true"
    >

        <dxi-toolbar-item
            text="Buscar Paciente"
            location="before">
        </dxi-toolbar-item>

        <app-prescription-patients-table-popup></app-prescription-patients-table-popup>
    </dx-popup>

    <div class="prescription_new-buscar_paciente_main_container">

        <div 
            *ngIf="prescriptionDraft.id && patientDoclineIsValid && isDoctor" 
            class="prescription_new-patient_name_container"    
        >

            <span class="prescription_new-patient_label" >
                Paciente
            </span>

            <span class="prescription_new-patient_name_content" >
                {{ prescriptionDraft.patient.name }}
            </span>
            
        </div>

    </div>

    

    <app-prescription-new-table
        *ngIf=" windowWidth < mobileViewBreakPoint " 
        (SendRefreshOrder)="ReciveRefreshOrder($event)" 
        (SendIfMedicamentOrMasterFormulas)="SendIfMedicamentOrMasterFormulas($event)"
    ></app-prescription-new-table>

    <div  class="prescription_new-mobile_actions_main_container">

        <div class="prescription_new-mobile_actions_container">
            
            <div (click)="DeletePrescription()">

                <div class="prescription_new-mobile_icon_action">
                    <fa-icon  [icon]="faTrash"></fa-icon>
                </div>

                <div class="prescription_new-mobile_span_action" >
                    <span>Cancelar</span>
                </div>
            </div>

            <div *ngIf="
                    ( 
                    prescriptionDraft.status === 'Emitted' || 
                    prescriptionDraft.status === 'Manual' || 
                    prescriptionDraft.status === 'MasterFormula' || 
                    prescriptionDraft.status === 'Expired' || 
                    prescriptionDraft.status === 'Finished' 
                    )
                "
                (click)="SendPrescriptionByEmail()"
            >
                <div class="prescription_new-mobile_icon_action">
                    <fa-icon  [icon]="faEnvelope"></fa-icon>
                </div>
                <div  
                    class="prescription_new-mobile_span_action" 
                >
                    <span>Enviar</span>
                </div>
            </div>

            <div *ngIf="isDraft && patientDoclineIsValid && isDoctor && existsMedicamentOrMasterFormula ">
                <div (click)="CheckProfessionalCollegiateNumberIsValid()" class="prescription_new-mobile_icon_action">
                    <fa-icon  [icon]="faCheck"></fa-icon>
                </div>
                <div class="prescription_new-mobile_span_action" >
                    <span>Formalizar</span>
                </div>
            </div>
        </div>
    </div>

    <dx-popup
        height="60%"
        width="90%"
        minWidth="300px"
        [(visible)]="printDeletePopUp"
        [hideOnOutsideClick]="true"
        [showCloseButton]="true"
        [showTitle]="true"
    >
        <app-delete-warning-pop-up-content 
            (CloseDeleteDraftPopUp)="CloseDeleteDraftPopUp($event)" (CloseDeletePopUp)="CloseDeletePopUp($event)" (CloseDeleteFormalizedPrescriptionPopUp)="CloseDeleteFormalizedPrescriptionPopUp($event)">
        </app-delete-warning-pop-up-content>
    </dx-popup>

</div>



import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Prescription } from 'src/app/models';

@Injectable()

export class PrescriptionNewToPrescriptionsNewTableService {

  private subject = new Subject<Prescription>();

  sendMessage( message:Prescription) {
    this.subject.next( message );
  }

  clearMessages() {
    this.subject.next({id: '' , medicaments: [] , status : '' , masterFormulas : []});
  }

  onMessage(): Observable<Prescription> {
    return this.subject.asObservable();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Medicament } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MedicamentsFavoritesService {

  constructor(
    private http :                                      HttpClient
  ) { }

  /********************************************************* FAVORITES MEDICAMENTS *************************************************************/
  LoadFavoritesMedicaments( professionalId : string | null ):Observable<Medicament[]>{
    return this.http.get<Medicament[]>(`${environment.prescriptionsService.url}favorite/medicine/${professionalId}`);
  }

  SaveFavoriteMedicament( medicament : Medicament ):Observable<{ isOk: boolean , error:string | null , id:string }>{
    return this.http.post<{ isOk: boolean , error:string | null , id : string }>(`${environment.prescriptionsService.url}favorite/medicine` , medicament);
  }

  DeleteFavoriteMedicament( medicamentFavoritedId : string ):Observable<{ isOk: boolean , error:string | null }>{
    return this.http.delete<{ isOk: boolean , error:string | null  }>(`${environment.prescriptionsService.url}favorite/medicine?medicamentFavoriteId=${medicamentFavoritedId}`);
  }

  UpdateFavoriteMedicament( favoriteMedicament : Medicament ):Observable<{ isOk: boolean , error:string | null }>{
    return this.http.put<{ isOk: boolean , error:string | null }>( `${environment.prescriptionsService.url}favorite/medicine` , favoriteMedicament )
  }

  /********************************************************* FAVORITES MASTER FORMULAS *************************************************************/
  LoadFavoritesMasterFormulas( professionalId : string | null ):Observable<Medicament[]>{
    return this.http.get<Medicament[]>(`${environment.prescriptionsService.url}favorite/master-formula/${professionalId}`);
  }

  SaveFavoriteMasterFormula( masterFormula : Medicament ):Observable<{ isOk: boolean , error:string | null , id:string }>{
    return this.http.post<{ isOk: boolean , error:string | null , id : string }>(`${environment.prescriptionsService.url}favorite/master-formula` , masterFormula);
  }

  DeleteFavoriteMasterFormula( masterFormulaFavoritedId : string ):Observable<{ isOk: boolean , error:string | null }>{
    return this.http.delete<{ isOk: boolean , error:string | null  }>(`${environment.prescriptionsService.url}favorite/master-formula?masterFormulaFavoriteId=${masterFormulaFavoritedId}`);
  }

  UpdateFavoriteMasterFormula( favoriteMasterFormula : Medicament ):Observable<{ isOk: boolean , error:string | null }>{
    return this.http.put<{ isOk: boolean , error:string | null }>( `${environment.prescriptionsService.url}favorite/master-formula` , favoriteMasterFormula )
  }

  /********************************************************* FAVORITES PARAFARMACIES *************************************************************/
  LoadFavoritesParapharmacies( professionalId : string | null ):Observable<Medicament[]>{
    return this.http.get<Medicament[]>(`${environment.prescriptionsService.url}favorite/parapharmacy/${professionalId}`);
  }

  SaveFavoriteParapharmcy( parapharmacy : Medicament ):Observable<{ isOk: boolean , error:string | null , id:string }>{
    return this.http.post<{ isOk: boolean , error:string | null , id : string }>(`${environment.prescriptionsService.url}favorite/parapharmacy` , parapharmacy);
  }

  DeleteFavoriteParapharmcy( parapharmacyFavoritedId : string ):Observable<{ isOk: boolean , error:string | null }>{
    return this.http.delete<{ isOk: boolean , error:string | null  }>(`${environment.prescriptionsService.url}favorite/parapharmacy?medicamentFavoriteId=${parapharmacyFavoritedId}`);
  }

  UpdateFavoriteParapharmcy( favoriteParapharmacy : Medicament ):Observable<{ isOk: boolean , error:string | null }>{
    return this.http.put<{ isOk: boolean , error:string | null }>( `${environment.prescriptionsService.url}favorite/parapharmacy` , favoriteParapharmacy )
  }
}

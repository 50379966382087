import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { PrescriptionsReducer } from "./reducers/prescriptions/prescriptions.reducers";
import { UserReducer } from "./reducers/users/user.reducers";
import { AppState } from "../models/prescriptions/interfaces/appState";
import { ProtocolsReducer } from "./reducers/prescriptions/protocols.reducers";
import { PrescriptionsFiltersReducer } from "./reducers/prescriptions/prescriptionsFilters.reducers";
import { hydrationMetaReducer } from "./hydratation.reducer";
import { PrescriptionsPerPatientReducer } from "./reducers/prescriptions/prescriptionsPerPatient.reducers";
import { ProfessionalsJsonReducer } from "./reducers/professionals/professionals-reducers";
import { FavoritesMedicamentsReducers } from "./reducers/medicaments/favorites-medicaments.reducers";
import { FavoritesMasterFormulasReducers } from "./reducers/medicaments/favorites-masterFormulas.reducers";
import { MagickLinkHoldedReducers } from "./reducers/prescriptions/magickLinkHolded.reducers";
import { FavoritesParapharmacyReducers } from "./reducers/medicaments/favorites-paramedicaments.reducers";
import { PrescriptionReducers } from "./reducers/prescriptions/prescription.reducers";
// import { ParaPrescriptionReducer } from "./reducers/prescriptions/paraPrescriptionSelected.reducers";

export const reducers : ActionReducerMap<AppState> = {
  User : UserReducer,
  Prescription : PrescriptionsReducer,
  Protocols: ProtocolsReducer,
  PrescriptionsFilters : PrescriptionsFiltersReducer,
  PrescriptionsPerPatient: PrescriptionsPerPatientReducer,
  FavoritesMedicaments : FavoritesMedicamentsReducers,
  professionalsJSON:ProfessionalsJsonReducer,
  FavoritesMasterFormulas : FavoritesMasterFormulasReducers,
  MagicLinkHolded : MagickLinkHoldedReducers,
  FavoritesParapharmacy : FavoritesParapharmacyReducers,
  SinglePrescription : PrescriptionReducers
}

export function debug(reducer: ActionReducer<string>): ActionReducer<string> {
  return function(state, action) {
    return reducer(state, action);
  };
}

export const metaReducers : MetaReducer[] = [hydrationMetaReducer]
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()

export class PrescriptionNewToPatientsPopUpService {

  subject = new Subject<string>();
  patientIdSubject = new Subject<{id:string , name:string}>();
 
  SendMessage(clinicCode : string):void{
    this.subject.next(clinicCode);
  }

  OnMessage():Observable<string>{
    return this.subject.asObservable();
  }

  ClearMessage():void{
    this.subject.next('');
  }

  SendPatientIdMessage(patient:{id:string , name:string}):void{
    this.patientIdSubject.next(patient);
  }

  OnPatientIdMessage():Observable<{id:string , name:string}>{
    return this.patientIdSubject.asObservable();
  }

  ClearPatientIdMessage():void{
    this.patientIdSubject.next({id:'' , name:''});
  }
}

import { Component , enableProdMode , OnInit , OnDestroy } from '@angular/core';
import { IndicationsService } from '../../services/httpRequests/indications.service';
import { AppState, Indication, ProtocolPrescription, ProtocolsRes } from 'src/app/models';
import { ProtocolToIndicationTableService } from '../../services';
import { Subject, Subscription, takeUntil } from 'rxjs';
// import { FormatStringsService } from 'src/app/shared/services/helpers/format-strings.service';
import { IndicationParams, IndicationsRes } from 'src/app/models/prescriptions/interfaces/indications';
import { OrderResultsService, TableToTablePaginationService } from 'src/app/shared/services';
import { Store } from '@ngrx/store';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

@Component({
  selector: 'app-protocol-medical-indication-pop-up',
  templateUrl: './protocol-medical-indication-pop-up.component.html',
  styleUrls: ['./protocol-medical-indication-pop-up.component.scss']
})

export class ProtocolMedicalIndicationPopUpComponent implements OnInit , OnDestroy {

  indications :                                      Indication[] = [];
  indicationsAlreadySelected:                        Indication[] = [];
  protocolIndicationsParamas:                        Subscription;
  indicationsSelectedSubscription:                   Subscription;
  protocolId                                         = "" ;
  selectedIndications:                               Indication[] = []; 
  indicationParams:                                  IndicationParams = {};
  typingTimer                                        = 0;
  doneTypingInterval                                 = 2000;
  protocolsDestroyed$:                               Subject<void> = new Subject<void>();   
  protocols:                                         ProtocolPrescription[] = [];      
  protocolsRes:                                      ProtocolsRes = {};                              
 
  constructor(
    private store:                                   Store<AppState>,
    private indicationsService:                      IndicationsService,
    private protocolToIndicationTableService:        ProtocolToIndicationTableService,
    private tableToTablePaginationService :          TableToTablePaginationService,
    private OrderResultsService:                     OrderResultsService
  ){
    this.protocolIndicationsParamas = this.protocolToIndicationTableService.OnMessage().subscribe((paramsToIndication : { id: string , indications: Indication[] }) => {

      if(paramsToIndication){
        const { id , indications } = paramsToIndication || {};
        if(id)this.protocolId = id;
        if(indications)this.selectedIndications=indications;
        this.AddSelectedAttribute();
      }
    })
  }

  ngOnInit(): void {

    this.GetProtocols();
  }

  GetProtocols():void{

    this.store.select( AppState => AppState.Protocols ).pipe( takeUntil( this.protocolsDestroyed$ ) ).subscribe(( protocolsRes : ProtocolsRes ) => {

      const { items , stateStatus } = protocolsRes || {};

      if( stateStatus === 'real_assignement' ){

        if( items && items.length > 0 ){

          this.protocolsRes = { ...protocolsRes , items : [ ...items ].map(( p : ProtocolPrescription ) => { return { ...p } })  } ;
        }
  
        this.GetIndications();
      }
    })
  }

  GetIndications():void{

    this.indicationsService.LoadIndications( this.indicationParams ).subscribe(( indications : IndicationsRes ) => {

      const { items , totalCount } = indications;

      if( this.protocolsRes.items && this.protocolsRes.items.length > 0 ){

        const indicationsAlreadyUsed : Indication[] = this.GetIndicationsAlreadyUsed( this.protocolsRes.items );

        if( indicationsAlreadyUsed && indicationsAlreadyUsed.length > 0 ){

          this.indications = items.filter(( i : Indication ) => !indicationsAlreadyUsed.some(( iUsed : Indication ) => iUsed.indicationId === i.id ) );

          this.indications = this.OrderResultsService.OrderResultsByABC(this.indications , 'description') ;
        }

      } else {

        this.indications = this.OrderResultsService.OrderResultsByABC(items , 'description');
      }

      this.SendTotalElements( totalCount );

      this.AddSelectedAttribute();
    })
  }

  GetIndicationsAlreadyUsed( protocols : ProtocolPrescription[] ):Indication[]{

    const indicationsAlreadyUsed : Indication[] = [];

    for (let x = 0; x < protocols.length; x++) {
      
      const { indications } = protocols[x] || {};

      if( indications && indications.length > 0 ){

        for (let j = 0; j < indications.length; j++) {
          
          indicationsAlreadyUsed.push( indications[j] );          
        }
      }
    }

    return indicationsAlreadyUsed;
  }

  StartTyping():void{

    window.clearTimeout(this.typingTimer);

    this.typingTimer = window.setTimeout(() => {

      this.indicationParams.first = 1;
      this.indicationParams.size = 10;

      this.GetIndications();
      
    }, this.doneTypingInterval);
  }

  SendTotalElements(totalElementos:number):void{
    this.tableToTablePaginationService.sendTotalElementos(totalElementos);
  }

  ReciveParams(paginationParams:IndicationParams):void{

    const { first , size } = paginationParams;

    this.indicationParams.first = first;
    this.indicationParams.size = size;

    this.GetIndications();
  }

  AddSelectedAttribute():void{

    this.indications = this.indications.map(( i ) => {
      if( this.selectedIndications.find(( si ) => si.indicationId === i.id )) {
        i = { ...i , selected : true };
      }
      else 
      {
        i = { ...i , selected : false };
      }
      return i;
    })
    this.indications = this.indications.filter(( i ) => i.id && i.indicationFamilyOjoStrId && i.ojoStrId);
  }

  SelectIndication(indication : Indication):void{

    const { selected } = indication;

    if(selected){
      
      this.AssociateProtocolToIndication( indication );
    }

  }

  AssociateProtocolToIndication( indication : Indication ):void{

    const { indicationFamilyOjoStrId , id , ojoStrId } = indication;

    const indicationProtocolBody : Indication = {

      protocolId : this.protocolId,
      indicationId : id,
      indicationOjoId : ojoStrId,
      indicationFamilyOjoId : indicationFamilyOjoStrId
    }

    const { protocolId , indicationId , indicationOjoId , indicationFamilyOjoId } = indicationProtocolBody || {};

    if( protocolId && indicationId && indicationOjoId && indicationFamilyOjoId ){

      this.indicationsService.AddProtocolToIndication( indicationProtocolBody ).subscribe(( res : boolean ) => {
      
        if(res){

          this.indications = this.indications.filter(( i : Indication ) => i.id !== id);

          this.protocolToIndicationTableService.SendRefreshOrder('refresh');
        }
      })
    }
  }

  ngOnDestroy(): void {

    this.protocolsDestroyed$.next();
    this.protocolsDestroyed$.complete();
    this.protocolIndicationsParamas.unsubscribe();
  }

}

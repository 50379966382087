import { Location } from '@angular/common';
import { Component , OnInit , OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
/* services */
import { 
  DynPrescriptionsService , ProtocolsService , MedicamentSearchPopUpToPrescriptionNewTableService, PrescriptionsAlertsService, ProtocolToIndicationTableService, MedicamentValidatorsService, MedicamentsFavoritesPopUpToPrescriptionNewTableServiceService,
  PrescriptionNewHelperService
} from 'src/app/prescriptions/services';
import { CheckUserRoleService, ComponentToAlertComponentService, ComponentToDeletePopUpService, ComponentToSpinnerService, OrderResultsService } from 'src/app/shared/services';
/* models */
import { 
  AppState,
  Indication,
  Medicament , PrescriptionInsertRes, ProtocolMedicineUpdateBody, ProtocolNew, ProtocolNewRes, ProtocolsRes, UserInfo 
} from 'src/app/models';
/* rxjs */
import { Subject, Subscription, takeUntil } from 'rxjs';
/* fontawsome */
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas , faArrowLeft, IconDefinition , faTrash , faPrescriptionBottleMedical , faCapsules , faStar , faFlaskVial } from '@fortawesome/free-solid-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';
/* devextrem */
import { DxDataGridComponent } from 'devextreme-angular';
import { locale, loadMessages , formatMessage } from "devextreme/localization";
import esMessages from 'devextreme/localization/messages/es.json';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { ItemClickEvent } from 'devextreme/ui/multi_view';
import { IndicationsService } from '../../services/httpRequests/indications.service';
import { RowClickEvent } from 'devextreme/ui/data_grid';
import { MedicamentsFormalHelperService } from '../../services/helpers/medicaments/medicaments-formal-helper.service';
import { Store } from '@ngrx/store';
import { addFavoritesParapharmacies, deleteFavoritesParapharmacies } from 'src/app/store/actions/medicaments/favorites-parafarmacies.actions';
import { addFavoritesMedicaments, deleteFavoritesMedicaments } from 'src/app/store/actions/medicaments/favorites-medicaments.actions';
import { getFilteredProtocolAction } from 'src/app/store/actions/prescriptions/protocols.actions';
import { ProtocolHelperService } from '../../services/helpers/protocol-helper.service';
import { addFavoritesMasterFormulas, deleteFavoritesMasterFormulas } from 'src/app/store/actions/medicaments/favorites-masterFormulas.actions';
import { environment } from 'src/environments/environment';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';

@Component({
  selector: 'app-protocol',
  templateUrl: './protocol.component.html',
  styleUrls: ['./protocol.component.scss']
})

export class ProtocolComponent implements OnInit , OnDestroy {

  medicamentsSubscription:                                                                    Subscription;
  refreshSubscription:                                                                        Subscription;
  protocols:                                                                                  ProtocolsRes;
  medicamentdFavoritesDestroyed$:                                                             Subject<void> = new Subject<void>();
  parapharmaciesFavoritesDestroyed$:                                                          Subject<void> = new Subject<void>();
  protocolsDestroyed$:                                                                        Subject<void> = new Subject<void>();
  masterFormulasFavoritesDestroyed$:                                                          Subject<void> = new Subject<void>();
  protocolNew :                                                                               ProtocolNew = {name:"",description:"",medicaments:[],favorite:false};
  printMedicamentSearchPopUp                                                                  = false;
  protocolSaved                                                                               = true;
  protocolId:                                                                                 string | null;
  protocolCreated:                                                                            boolean;
  faArrowLeft:                                                                                IconDefinition=faArrowLeft;
  faFlaskVial:                                                                                IconDefinition=faFlaskVial;
  faTrash:                                                                                    IconDefinition=faTrash;
  faStar:                                                                                     IconDefinition=faStar;
  faPrescriptionBottleMedical:                                                                IconDefinition=faPrescriptionBottleMedical;
  faCapsules:                                                                                 IconDefinition=faCapsules;
  printDeletePopUp                                                                            = false;
  itemToDelete                                                                                = '';
  formatMessage ?  =                                                                          formatMessage;
  locale                                                                                      = "";
  printMedicalIndicationPopUp                                                                 = false;
  existsPsycotropic                                                                           = false;
  indicationsfromUngroupedData:                                                               DataSource;
  indications:                                                                                Indication[];
  indicationsComboTitle                                                                       = "Indicaciones Asociadas";
  hiddenRows                                                                                  = false;
  printParaMedicamentCreateFormPopUp                                                          = false;
  iAmParent:                                                                                  {from:string , parentId? : string | null , child : string} = { from : 'protocol' , child:'paraMedicament'}
  isMedicament                                                                                =false;
  isParaMedicament                                                                            =false;
  isMasterFormula                                                                             =false;
  isDoctor=                                                                                   false;
  isAdmin=                                                                                    false;
  isNurse=                                                                                    false;
  professionalDestroyed$:                                                                     Subject<void> = new Subject<void>();
  professionalLogged:                                                                         UserInfo;
  medicamentAction=                                                                           "";
  medicamentToInsert:                                                                         Medicament;
  medicamentsFavorites:                                                                       Medicament[];
  parapharmaciesFavorites:                                                                    Medicament[];
  masterFormulasFavorites:                                                                    Medicament[];
  favoriteMedicamentsLoaded=                                                                  false;
  favoriteParapharmaciesLoaded=                                                               false;
  favoriteMasterFormulasLoaded=                                                               false;
  favoritesMedicamentsPopUp=                                                                  false;
  protocolsLoaded=                                                                            false;
  protocolUsed=                                                                               false;
  showMagistralFormulaPopUp=                                                                  false;
  whoIsParent=                                                                                {from:'protocol' , child : 'master_formulas_form'};
  windowWidth=                                                                                0;
  mobileViewBreakPoint=                                                                       environment.mobileViewBreakPoint; 
  resizeSubscription:                                                                         Subscription;

  pharmaceuticalForms:                                                                        {name:string , formId:number}[] = [
    {"name":"Colirio" ,               "formId":1},
    {"name":"Comprimido" ,            "formId":2},
    {"name":"Cápsulas" ,              "formId":3},
    {"name":"Jarabe" ,                "formId":4},
    {"name":"Ampollas bebibles" ,     "formId":5},
    {"name":"Ampollas Inyectables" ,  "formId":6},
  ];

  unitForMagistralFormulas:                                                                   {name:string , frecuencyAmountId:number}[] = [
    {"name":"Gotas" ,      "frecuencyAmountId":1},
    {"name":"Comprimido" , "frecuencyAmountId":2},
    {"name":"Mililitros" , "frecuencyAmountId":3},
    {"name":"Miligramos" , "frecuencyAmountId":4},
    {"name":"Microgramos" ,"frecuencyAmountId":5},
    {"name":"Ampollas" ,   "frecuencyAmountId":6},
    {"name":"UI" ,         "frecuencyAmountId":7},
    {"name":"Gramos" ,     "frecuencyAmountId":8},
    {"name":"Cápsulas" ,   "frecuencyAmountId":9}
  ];

  pharmaceuticalFormsgroupedData = new DataSource({
    store: new ArrayStore({
      data: this.pharmaceuticalForms,
      key: 'name',
    }),
    group: 'Category',
  });

  unitgroupedData = new DataSource({
    store: new ArrayStore({
      data: this.unitForMagistralFormulas,
      key: 'name',
    }),
    group: 'Category',
  });

  @ViewChild(DxDataGridComponent, { static: false }) protocolMedicamentGridContainer:         DxDataGridComponent;

  protocolForm = new FormGroup({
    name : new FormControl('', [Validators.required]),
    description : new FormControl('', [/* Validators.required */]),
  });

  timeDuration:                                                                               {name:string , key:string}[]=[
    {name:'día' , key:'day'},
    {name:'semana' , key:'week'},
    {name:'mes' , key:'month'},
  ]

  frecuencyUnitTimeDuration:                                                                  {name:string , key:string}[]=[
    {name:'hora' , key:'hour'},
    {name:'día' , key:'day'},
    {name:'semana' , key:'week'},
    {name:'mes' , key:'month'},
  ]

  constructor(
    private store:                                                                             Store<AppState>,
    private checkUserRoleService:                                                              CheckUserRoleService,
    private medicamentSearchPopUpToPrescriptionNewTableService :                               MedicamentSearchPopUpToPrescriptionNewTableService,
    private protocolsService:                                                                  ProtocolsService,
    private protocolHelperService:                                                             ProtocolHelperService,
    private router:                                                                            Router,
    private route:                                                                             ActivatedRoute,
    private dynPrescriptionsService:                                                           DynPrescriptionsService,
    library:                                                                                   FaIconLibrary,
    private location:                                                                          Location,
    private componentToDeletePopUpService :                                                    ComponentToDeletePopUpService,
    private componentToSpinnerService:                                                         ComponentToSpinnerService,
    private prescriptionsAlertsService:                                                        PrescriptionsAlertsService,
    private protocolToIndicationTableService:                                                  ProtocolToIndicationTableService,
    private OrderResultsService:                                                               OrderResultsService ,
    private indicationsService:                                                                IndicationsService  ,
    private medicamentValidatorsService:                                                       MedicamentValidatorsService,    
    private medicamentsFormalHelperService:                                                    MedicamentsFormalHelperService  ,
    private componentToAlertComponentService:                                                  ComponentToAlertComponentService,
    private medicamentsFavoritesPopUpToPrescriptionNewTableServiceService:                     MedicamentsFavoritesPopUpToPrescriptionNewTableServiceService,
    private prescriptionNewHelperService:                                                      PrescriptionNewHelperService,
    private windowResizeService :                                                              WindowResizeService,
  ){

    this.locale = this.getLocale();

    this.initMessages();
    locale(this.locale);

    library.addIconPacks(fas);
    library.addIcons(faArrowLeft);
    library.addIcons(faTrash);
    library.addIcons(faPrescriptionBottleMedical);
    library.addIcons(faCapsules);
    library.addIcons(faFlaskVial);
    this.medicamentsSubscription = this.medicamentSearchPopUpToPrescriptionNewTableService.OnMessage().subscribe((message)=>{
      if(message){
        this.ReciveMedicaments(message);
      }
    })

    this.refreshSubscription = this.protocolToIndicationTableService
    .OnRefreshOrder().subscribe((message : string) =>{
      if(message){
        if(message === 'refresh')this.SearchProtocolById();
      }
    })

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {

      this.windowWidth = width;
    });
  }

  initMessages() {
    loadMessages(esMessages);
  }

  getLocale() {
    return 'es' ;
  }

  ngOnInit(): void {

    this.CheckParams();
    this.CheckUserRole();
    this.GetProfessionalInfo();
  }

  GetProfessionalInfo():void{
    
    this.store.select( AppState => AppState.User ).pipe( takeUntil( this.professionalDestroyed$ ) ).subscribe((professional : UserInfo) => {
      
      this.professionalLogged = { ...professional };

      if( this.professionalLogged.professionalLoggedId !== "first_assignement" ){
        
        if(this.isDoctor && !this.isAdmin ){
        
          this.protocolNew.favorite = true ;
        }

        this.GetProtocolsFromStore();
      }
    })
  }

  CheckUserRole():void{
    
    const roles = this.checkUserRoleService.CheckUserRole();
    
    const {isDoctor , isAdmin , isNurse} = roles || {};
    
    if( isDoctor ) this.isDoctor = isDoctor;
    
    if( isNurse ) this.isNurse = isNurse;
    
    if( isAdmin ) this.isAdmin = isAdmin;
  }

  CheckParams():void{
    
    this.route.paramMap.subscribe(params => {
      
      if(params.get('protocolId')){
       
        this.protocolCreated = true;
       
        this.protocolId = params.get('protocolId');
      
        this.iAmParent.parentId = this.protocolId;
     
        this.SearchProtocolById();
      }else{
       
        this.protocolCreated = false
      }
    });
  }

  SearchProtocolById():void{

    this.componentToSpinnerService.sendMessage('show');
    
    this.protocolsService.LoadProtocolById(this.protocolId).subscribe((res:ProtocolNew)=>{

      const { id , indications , professionalId } = res || {};

      if(indications)this.indications = this.OrderResultsService.OrderResultsByABC(indications , 'name');
      
      this.indications = this.indications.map((e) => { return {...e , icon : 'trash' , colors : [ '#e74c3c' ]} } );
      
      this.indicationsfromUngroupedData = new DataSource({
        store: new ArrayStore({
          data: this.indications,
          key: 'indicationId',
        }),
        group: 'Category',
      });

      if(id && indications){

        const paramsToIndicationsTable : { id: string , indications: Indication[] } = {id:id , indications:indications}

        if(this.isAdmin){
          this.protocolNew = { ...res , favorite : professionalId ? true : false };
        }

        if(this.isDoctor && !this.isAdmin){
          this.protocolNew = { ...res , favorite : true };
        }
        
        this.protocolToIndicationTableService.SendMessage(paramsToIndicationsTable);
      }
      
      const { medicaments , parapharmacyMedicaments , masterFormulas } = this.protocolNew;

      if( this.protocolNew.medicaments ){

        if( medicaments ){

          const myMedicaments = medicaments.map((m:Medicament) => { return { ...m , kind:'medicament' } });

          this.protocolNew.medicaments = myMedicaments;
  
          this.SearchPsycotropicInPrescription(myMedicaments);

          this.GetFavoritesMedicaments();
          
          this.SendMedicamentsSelectedToMedicamentsTable();
        }
  
        if( parapharmacyMedicaments ){
  
          const myparapharmacyMedicaments = parapharmacyMedicaments.map((pm) => { return { ...pm , fullName : pm.name , kind : 'paraMedicament' , unit : { name :  pm.takingUnit } }  });
  
          this.protocolNew.medicaments = [...this.protocolNew.medicaments , ...myparapharmacyMedicaments];

          this.GetFavoritesParapharmacy();

          this.SendMedicamentsSelectedToMedicamentsTable();
        }

        if( masterFormulas ){

          const myMasterFormulas = masterFormulas.map(( mas : Medicament ) => { return { ...mas , fullName : mas.composition , unit : { name : mas.takingUnit} , kind : 'masterFormula' } });
          
          this.protocolNew.medicaments = [...this.protocolNew.medicaments , ...myMasterFormulas];

          this.GetFavoritesMasterFormulas();

          this.SendMedicamentsSelectedToMedicamentsTable();
        }

      }

      if(this.protocolNew.medicaments)this.protocolNew.medicaments = this.medicamentsFormalHelperService.BuildPosologyField( this.protocolNew.medicaments );

      if(this.medicamentToInsert && this.protocolNew.medicaments){

        if( this.medicamentToInsert.nationalCode || this.medicamentToInsert.code ){

          const indexFound = this.medicamentToInsert.nationalCode ? this.protocolNew.medicaments.findIndex(m => m.code == this.medicamentToInsert.nationalCode) : this.protocolNew.medicaments.findIndex(m => m.code == this.medicamentToInsert.code);

          if(indexFound >= 0){

            if(this.medicamentAction === 'INSERT'){

              setTimeout(() => {
                this.printMedicamentSearchPopUp = false;
                this.protocolMedicamentGridContainer.instance.editRow( indexFound );
              }, 1000);
            
            }
          }
        }
      } 

      this.componentToSpinnerService.sendMessage('hide');
      
    },(error: HttpErrorResponse) => {

      console.log(error);
      
      this.componentToSpinnerService.sendMessage('hide');
    })
  }

  GetProtocolsFromStore():void{

    this.store.select( AppState => AppState.Protocols ).pipe( takeUntil( this.protocolsDestroyed$ ) ).subscribe(( protocols : ProtocolsRes ) => {

      const { stateStatus } = protocols || {};

      if( stateStatus === 'first_assignement' ){

        this.StoreProtocols();

      } else {

        this.protocols = { ...protocols };

        this.protocolsLoaded = true;
      }
    })
  }

  StoreProtocols():void{

    this.store.dispatch(getFilteredProtocolAction({ parameters: { first: 1 , size:100 , professionalId : this.professionalLogged.professionalLoggedId } }));
  }

  CheckFavoriteProtocol():void{
    this.protocolSaved = false;
  }

  CloseDeleteMasterFormulaPopUp( e : {name:string , id:string , action:string , prescriptionDoclineId ? : string , reasonId ? : string , comment ? : string , composition ? : string} ):void{
    const { id } = e || {};
    this.DeleteMasterFormula(id);  
  }

  CloseParapharmacyPopUp(medicament : Medicament):void{
    
    this.componentToSpinnerService.sendMessage('show');

    this.printParaMedicamentCreateFormPopUp = false

    medicament = {...medicament , protocolId : this.protocolNew.id};

    this.InsertParapharmacyInProtocol( medicament );
  }

  CloseFormulaMagistralPopUp( masterFormula : Medicament ):void{

    this.showMagistralFormulaPopUp = false;
   
    this.InsertMasterFormulaInDraft( masterFormula );
  }

  InsertMasterFormulaInDraft( masterFormula : Medicament ):void{
      
    let masterFormulaMapped : Medicament = this.prescriptionNewHelperService.MasterFormulaMapper( masterFormula );

    this.medicamentAction = 'INSERT_MASTERFORMULA';

    masterFormulaMapped = { ...masterFormulaMapped , protocolId : this.protocolNew.id };

    this.dynPrescriptionsService.InsertMasterFormulaInProtocol(masterFormulaMapped).subscribe((res:boolean) => {

      if(res){

        this.SearchProtocolById();
        
      }
    })
  }

  InsertParapharmacyInProtocol( medicament : Medicament ):void{

    this.dynPrescriptionsService.InsertParaMedicamentInProtocol( { ...medicament , protocolId : this.protocolNew.id } ).subscribe((res:boolean) => {

      if(res){

        this.medicamentAction = 'INSERT_PARAPHARMACY';

        this.SearchProtocolById();
      }
    }, (error : HttpErrorResponse) => {
      console.log(error);
    })
  }

  SearchPsycotropicInPrescription(medicaments : Medicament []):void{
    
    const psycotropics = medicaments.filter(( m ) => m.narcotic || m.psychotropic );
    
    if( psycotropics.length > 0 ){
      
      this.existsPsycotropic = true;
    }else{
     
      this.existsPsycotropic = false;
    }
  }

  SaveProtocolChanges():void{

    if( this.protocolForm.status === 'VALID' && !this.protocolSaved ){

      const result = this.protocolHelperService.CheckIfProtocolExistsByName( this.protocols.items , this.protocolForm.value.name );

      if( !result ){

        if(this.protocolNew.id){

          this.ModifyProtocol();
  
        } else {
  
          this.CreateNewProtocol();
        }
  
        this.protocolSaved = true;
      
      } else{

        this.componentToAlertComponentService.sendMessage(
          { 
            title : 'Atención!' , 
            content : 'Ya existe un protocolo con este nombre , cada protocolo debe tener un nombre único', 
            color:'rgb(252 , 244 , 232)',
            borderColor : '1px solid rgb(240 , 183 , 117)',
            icon: 'faTriangleExclamation',
          }
        );
      }
    }
  }

  SendMedicamentsSelectedToMedicamentsTable():void{

    if( this.protocolNew.medicaments ){

      this.medicamentSearchPopUpToPrescriptionNewTableService.prescriptionNewTableToMedicamentsTableSend(this.protocolNew.medicaments);
    
      this.medicamentsFavoritesPopUpToPrescriptionNewTableServiceService.PrescriptionNewTableToMedicamentsFavoritesSend(this.protocolNew.medicaments);

    }
  }

  CreateNewProtocol():void{
    
    this.componentToSpinnerService.sendMessage('show');

    const { favorite } = this.protocolNew || {};

    let myProtocol = favorite ? {...this.protocolForm.value , professionalId : this.professionalLogged.professionalLoggedId , favorite : true} : {...this.protocolForm.value , favorite : false} ;

    myProtocol = { ...myProtocol , description : "descripción del protocolo por defecto" }

    this.protocolsService.CreateProtocol(myProtocol).subscribe((res:ProtocolNewRes)=>{

      const { id } = res;

      this.router.navigate(['prescriptions/protocol' , {protocolId:id} ]);

    }, (error : HttpErrorResponse) => {

      this.componentToSpinnerService.sendMessage('hide');

      const { error : { status , errors } } = error || {};

      if( status === 400 ){

        if( errors.Name[0] === `Protocol with the name '${myProtocol.name}' already exists` ){

          this.componentToAlertComponentService.sendMessage(
            { 
              title : 'Atención!' , 
              content : 'Ya existe un protocolo con este nombre , cada protocolo debe tener un nombre único', 
              color:'rgb(252 , 244 , 232)',
              borderColor : '1px solid rgb(240 , 183 , 117)',
              icon: 'faTriangleExclamation',
            }
          );
        }
      }
    })
  }

  ModifyProtocol():void{

    if(this.protocolForm.status === 'VALID'){

      const { favorite } = this.protocolNew || {};

      const myProtocol = favorite ? {...this.protocolForm.value , id : this.protocolNew.id , professionalId : this.professionalLogged.professionalLoggedId , favorite : true} : {...this.protocolForm.value , id : this.protocolNew.id, favorite : false} ;

      this.protocolsService.ModifyProtocol(myProtocol).subscribe(()=>{

        this.SearchProtocolById();
      })
    }
  }

  OpenCreateMasterFormula():void{
    
    this.showMagistralFormulaPopUp = true;
  }

  OpenMedicamentSearchPopUp():void{
    
    this.printMedicamentSearchPopUp = true;
  }

  OpenMyFavoritesPopUp():void{
    
    this.favoritesMedicamentsPopUp = true;
  }

  OpenMedicalIndicationPopUp():void{
    
    this.printMedicalIndicationPopUp = true;
  }

  ProtocolNameChange(name : string):void{

    if( this.protocolNew.id && this.protocolNew.name ){

      this.protocolNew.name.replace(/\s/g, "").toLocaleLowerCase() === name.replace(/\s/g, "").toLocaleLowerCase() ? this.protocolSaved = true : this.protocolSaved = false; 

    } else {

      this.protocolSaved = false;
    }
  }

  ProtocolDescriptionChange():void{
    this.protocolSaved = false;
  }

  SendMedicamentFromPopUpFavoritetoAdd( medicament : Medicament[] ):void{

    const { kind , medicineId } = medicament[0] || {};

    if( kind === 'medicament' ){

      if( medicineId )this.AddMedicamentToProtocol( medicineId , medicament[0] );
    }

    if( kind === 'paraMedicament' ){

      this.InsertParapharmacyInProtocol( medicament[0] );
    }

    if( kind === 'masterFormula' ){

      this.InsertMasterFormulaInDraft( medicament[0] );
    }
  }

  SendMedicamentFavoriteIdArrToDelete(medicamentsIdArr : string[]):void{

    for (let i = 0; i < medicamentsIdArr.length; i++) {
      
      this.RemoveMedicamentFromProtocol( medicamentsIdArr[i] );
    }
  }

  ReciveMedicaments(medicaments : Medicament[]):void{

    if( this.CheckIfPsycotropicOrNarcoticMedicament( medicaments[0] ) && this.protocolNew/* .medicaments.length > 0 */ ){
      
      if( this.protocolNew.medicaments ){
      
        if( this.protocolNew.medicaments.length > 0 ){

          this.printMedicamentSearchPopUp = false;

          this.componentToSpinnerService.sendMessage('hide');

          this.prescriptionsAlertsService.BuildPrescriptionsAlert(
            `No es posible añadir un medicamento psycotrópico 
            o estupefaciente junto a otros medicamentos`,
            `ATENCIÓN , cree un nuevo protocolo para este medicamento`
          );
        }
      }
    }else{
      
      if(medicaments.length > 0){
      
        this.InsertMedicineDDBB(medicaments[0]);
      }
    }
  }

  CheckIfPsycotropicOrNarcoticMedicament( medicamentSelected : Medicament ):boolean{
    
    const { psychotropic , narcotic } = medicamentSelected;
    
    return psychotropic || narcotic ? true : false;
  }

  InsertMedicineDDBB(medicineToAddProtocol : Medicament):void{
    
    medicineToAddProtocol = { ...medicineToAddProtocol , patientInstructions : 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta' };
    
    this.dynPrescriptionsService.InsertMedicineDDBB(medicineToAddProtocol).subscribe((res:PrescriptionInsertRes)=>{
    
      const {id} = res;
    
      if(id)this.AddMedicamentToProtocol(id,medicineToAddProtocol);
    })
  }

  AddMedicamentToProtocol(id:string, medicineToAddProtocol : Medicament):void{
    const medicineToAdd = { 
      ...medicineToAddProtocol ,
      favoriteId : medicineToAddProtocol.favoriteId,
      protocolId : this.protocolNew.id , 
      medicineId:  id , 
      takingAmount:        1,
      packagesAmount:      1,
      frequencyAmount:     1,
      frequencyUnit:       'day',
      durationAmount:      1,
      durationUnit:        'day',
      status:              'DispensableInFuture',
      pharmacistWarnings:  medicineToAddProtocol.pharmaForm?.name,
      startDate:           medicineToAddProtocol.createdAt,
      endDate:             medicineToAddProtocol.createdAt
    }
    this.protocolsService.AddProtocolMedicament(medicineToAdd).subscribe((res:boolean) => {
      if(res){

        medicineToAdd.favoriteId ? this.medicamentAction = 'INSERT_FAVORITE' : this.medicamentAction = 'INSERT';

        this.medicamentToInsert = medicineToAdd;

        this.SearchProtocolById();
      }
    })
  }

  logEvent(e:any,action ? :string):void{

    if(action === 'onRowUpdated'){

      const { data } = e;

      const medicamentUpdated : ProtocolMedicineUpdateBody = {
        ...data , 
        protocolId : this.protocolId , 
        medicineId : data.id
      };

      if(data.kind === "medicament"){

        const { favorite } = data || {};

        let medicamentFavorite : Medicament | undefined = this.medicamentsFavorites.find(( mf : Medicament ) => mf.medicineId === data.id );

        if( medicamentFavorite && !favorite ){

          const { favoriteId }  = medicamentFavorite || {};

          if(favoriteId) this.RemoveFavoriteFromStore( favoriteId );
        }

        if( !medicamentFavorite && favorite ){
          
          this.StoreFavoriteMedicine( data );
        }

        this.UpdateProtocolMedicament(medicamentUpdated);
      }

      if(e.data.kind === 'masterFormula'){

        const { favorite } = data || {};

        let masterFormulaFavorite : Medicament | undefined = this.masterFormulasFavorites.find(( mf : Medicament ) => mf.composition === data.composition );

        const myMasterFormulaToUpdate = this.prescriptionNewHelperService.BuildMasterFormulaToUpdate( {
          ...data , 
          id : data.id , composition : data.fullName
        } );

        if( masterFormulaFavorite && !favorite ){

          const { favoriteId }  = masterFormulaFavorite || {};

          if(favoriteId) this.RemoveFavoriteMasterFormulaFromStore( favoriteId );
        }

        if( !masterFormulaFavorite && favorite ){
          
          this.StoreMasterFormulaFavorite( myMasterFormulaToUpdate );
        }

        this.UpdateMasterFormula( { ...myMasterFormulaToUpdate , protocolId : this.protocolNew.id } );
      }

      if(data.kind === "paraMedicament"){

        const { favorite } = data || {};

        let parapharmacyFavorite : Medicament | undefined = this.parapharmaciesFavorites.find(( mf : Medicament ) => mf.name === data.name );

        if( parapharmacyFavorite && !favorite ){

          const { favoriteId }  = parapharmacyFavorite || {};

          if(favoriteId) this.RemoveFavoriteParapharmacyFromStore( favoriteId );
        }

        if( !parapharmacyFavorite && favorite ){
          
          this.StoreParapharmacyFavorite( data );
        }

        this.UpdateParaMedicamentInProtocol(medicamentUpdated)
      }
      
    }
    if(action === 'onRowRemoved'){
      const {data:{id}} = e;
      this.RemoveMedicamentFromProtocol(id);
    }
    if(action === "onEditorPreparing"){

      const {  row : { data : { kind } } } = e || {};

      if(kind === 'medicament'){
        this.isMedicament=true;
        this.isParaMedicament=false;
        this.isMasterFormula=false;
      }

      if(kind === 'masterFormula'){
        this.isMedicament=false;
        this.isParaMedicament=false;
        this.isMasterFormula=true
      }

      if(kind === 'paraMedicament'){
        this.isMedicament=false;
        this.isParaMedicament=true;
        this.isMasterFormula=false;
      }

      if(e.dataField === "fullName" /* && composition */){
        
        if(kind === 'medicament') e.editorOptions.disabled = true;
        if(kind === 'paraMedicament') e.editorOptions.disabled = false;
        if(kind === 'masterFormula') e.editorOptions.disabled = false;

      }

      if(e.dataField === "unit.name" /* && composition */){
        
        if(kind === 'medicament') e.editorOptions.disabled = true;
        if(kind === 'paraMedicament') e.editorOptions.disabled = false;
        if(kind === 'masterFormula') e.editorOptions.disabled = false;

      }

      if(e.dataField === "unitAmount"){

        if(kind === 'medicament') e.editorOptions.disabled = true;
        if(kind === 'paraMedicament') e.editorOptions.disabled = false;
        if(kind === 'masterFormula') e.editorOptions.disabled = false;
      }

      if(e.dataField === "pharmacistWarnings"){
        if(kind === 'medicament') e.editorOptions.disabled = true;
        if(kind === 'paraMedicament') e.editorOptions.disabled = false;
        if(kind === 'masterFormula') e.editorOptions.disabled = false;
      }
    }
  }

  ReciveMedicamentIdArrToDelete(ids:string[]):void{
    
    this.RemoveMedicamentFromProtocol(ids[0]);
  }

  DeleteMedicament(linea:RowClickEvent):void{

    this.itemToDelete = 'medicament';
    const { data : { id , name , kind } } = linea ;
    const deleteMessage = {id:id , name:name , action:'show',kind:kind};
    this.printDeletePopUp = true;
    this.componentToDeletePopUpService.sendMessage(deleteMessage); 
  }

  DeleteParapharmacy(id : string){

    this.CloseDeleteParaMedicamentPopUp( id );
  }

  StoreFavoriteMedicine( medicament : Medicament ):void{
    
    const favoriteMedicamentToStore : Medicament = { ...medicament , medicineId : medicament.id , professionalId : this.professionalLogged.professionalLoggedId };

    this.store.dispatch( addFavoritesMedicaments({ medicament : favoriteMedicamentToStore }) );
  }

  StoreParapharmacyFavorite( parapharmacyFavorite : Medicament ):void{
    
    const favoriteParapharmacy = { ...parapharmacyFavorite , professionalId : this.professionalLogged.professionalLoggedId };

    this.store.dispatch( addFavoritesParapharmacies({ parapharmacy : favoriteParapharmacy }) );
  }

  StoreMasterFormulaFavorite( masterFormulaFavorite : Medicament ):void{
    
    const favoriteMasterFormula = { ...masterFormulaFavorite , professionalId : this.professionalLogged.professionalLoggedId };
    this.store.dispatch( addFavoritesMasterFormulas({ masterFormula : favoriteMasterFormula }) );
  }

  RemoveFavoriteParapharmacyFromStore ( favoriteId : string ):void{

    this.store.dispatch( deleteFavoritesParapharmacies({ parapharmacyFavoritedId : favoriteId }) );
  }

  RemoveFavoriteMasterFormulaFromStore ( favoriteId : string ):void{
   
    this.store.dispatch( deleteFavoritesMasterFormulas({ masterFormulaFavoritedId : favoriteId }) );
  }

  RemoveFavoriteFromStore( favoriteId : string ){

    this.store.dispatch( deleteFavoritesMedicaments({ medicamentFavoritedId : favoriteId }) );
  }

  RemoveMedicamentFromProtocol(id:string):void{

    if(this.protocolNew.id){
    
      this.protocolsService.DeleteMedicamentFromProtocol(this.protocolNew.id , id).subscribe((res:boolean)=>{
    
        if(res){

          this.medicamentAction = 'DELETE_MEDICAMENT';

          this.itemToDelete = '';
    
          this.SearchProtocolById();
        }
      })
    }
  }

  UpdateProtocolMedicament( medicamentUpdated : ProtocolMedicineUpdateBody ):void{

    this.protocolsService.ModyfyProtocolMedicament(medicamentUpdated).subscribe((res:boolean) => {

      this.medicamentAction = 'UPDATE_MEDICAMENT';

      if(res)this.SearchProtocolById();
    })
  }

  UpdateMasterFormula( masterFormulaUpdated : Medicament ):void{

    this.dynPrescriptionsService.UpdateMasterFormulaInProtocol( masterFormulaUpdated ).subscribe((res : boolean) => {

      if(res){
      
        this.SearchProtocolById();
      }
    })
  }

  UpdateParaMedicamentInProtocol( medicamentUpdated : Medicament ):void{

    const { 
      status , patientInstructions , pharmacistWarnings , startDate , endDate ,  id , fullName , protocolId , posology
    } = medicamentUpdated || {};

     const myParaMedicament = {
      status: status,
      posology : posology,
      patientInstructions: patientInstructions,
      pharmacistWarnings: pharmacistWarnings,
      startDate: startDate,
      endDate: endDate,
      id: id,
      name: fullName,
      protocolId: protocolId
    } 
    
    this.protocolsService.UpdateParaMedicamentInProtocol( myParaMedicament ).subscribe((res:boolean) => {
      this.printParaMedicamentCreateFormPopUp = false;
      if(res)this.SearchProtocolById();
    })  
  }

  DeleteProtocolById():void{
    
    const { id , name } = this.protocolNew;

    if( id && name){

      const message : {name:string , id:string , action:string , kind?:string} = {id:id , name: name , action:'show' , kind:'protocol'};
     
      this.itemToDelete = 'protocol'
     
      this.printDeletePopUp = true;
      
      this.componentToDeletePopUpService.sendMessage(message);
    }
  }

  DeleteMasterFormula( masterFormulaId : string ):void{

    this.componentToSpinnerService.sendMessage('show');
    
    this.medicamentAction = 'DELETE';
    
    this.printDeletePopUp = false;

    if( this.protocolNew.id ) {

      this.dynPrescriptionsService.DeleteMasterFormulaInProtocol( masterFormulaId , this.protocolNew.id ).subscribe((res : boolean) => {
      
        if(res){
  
          this.SearchProtocolById();
        }
      })
    }
  }

  CloseDeleteParaMedicamentPopUp(parapharmacyId : string):void{

    this.printDeletePopUp = false;

    if(this.protocolNew.id){

      this.protocolsService.DeleteParaMedicamentInProtocol( this.protocolNew.id , parapharmacyId ).subscribe((res:boolean) => {
       
        if(res)this.SearchProtocolById();
      
      } , ( error : HttpErrorResponse ) => {

        this.componentToSpinnerService.sendMessage('hide');
      })
    }
  }

  CloseDeletePopUp(message:string):void{
    
    this.printDeletePopUp = false;
    
    if(message !== 'cancel'){

      if(this.itemToDelete === 'protocol')this.ConfirmDeleteProtocol();
    
      if(this.itemToDelete === 'indication')this.ConfirmDeleteIndication(message);
    
      if(this.itemToDelete === 'medicament')this.RemoveMedicamentFromProtocol(message);
    }
  }

  CloseDeleteProtocolPopUp(message:string):void{
    this.printDeletePopUp = false;

    if(message !== 'cancel'){
    
      this.ConfirmDeleteProtocol();
    }
  }

  CloseDeleteIndicationPopUp(message:string):void{

    this.printDeletePopUp = false;

    if(message !== 'cancel'){
    
      if(this.itemToDelete === 'indication')this.ConfirmDeleteIndication(message);
    }
  }

  ConfirmDeleteProtocol():void{
    
    if(this.protocolNew.id){
    
      this.protocolsService.DeleteProtocol(this.protocolNew.id).subscribe((res : boolean)=>{
    
        if(res)this.router.navigate(['prescriptions']);
      })
    }
  }

  ConfirmDeleteIndication( indicationId : string ):void{

    if( this.protocolNew.id ){

      this.indicationsService.RemoveIndication( indicationId , this.protocolNew.id ).subscribe((res:boolean) => {
        
        if( res ){

          this.SearchProtocolById();

          this.StoreProtocols();
        }
      })
    }
  }

  CancelDeleteProtocol():void{
    
    this.printDeletePopUp = false;
  }

  BackToPrescriptions():void{
    
    this.router.navigate(['prescriptions']);
  }

  OnIndicationClick(e:ItemClickEvent):void{
    
    const { itemData : { indicationId , name } } = e;

    this.itemToDelete = 'indication';

    const deleteMessage = {id:indicationId , name:name , action:'show' , kind:'indication'};

    this.printDeletePopUp = true;

    this.componentToDeletePopUpService.sendMessage(deleteMessage);
  }

  OpenParaMedicamentoCreateFormPopUp():void{

    this.printParaMedicamentCreateFormPopUp = true;
  }

  //#region ___________________________________________ PACKAGES AMOUNT CALCULATE _______________________________________________
  setPackageAmountFromTakinAmount = ( rowData : Medicament , value:number , currentRowData : Medicament ) => {
    rowData.takingAmount = value;

    if(currentRowData.kind === "medicament") this.SetFinalPackageAmount(rowData , currentRowData);
  }

  setPackageAmountFromFrecuencyAmount  = ( rowData : Medicament , value:number , currentRowData : Medicament ) => {
    rowData.frequencyAmount = value;

    if(currentRowData.kind === "medicament")this.SetFinalPackageAmount(rowData , currentRowData);
  }

  setPackageAmountFromFrecuencyUnit = ( rowData : Medicament , value:string , currentRowData : Medicament ) => {
    rowData.frequencyUnit = value;

    if(currentRowData.kind === "medicament")this.SetFinalPackageAmount(rowData , currentRowData);
  }

  setPackageAmountdurationAmount = ( rowData : Medicament , value:number , currentRowData : Medicament ) => {
    rowData.durationAmount = value;

    if(currentRowData.kind === "medicament")this.SetFinalPackageAmount(rowData , currentRowData);
  }

  setPackageAmountdurationUnit = ( rowData : Medicament , value:string , currentRowData : Medicament ) => {
    rowData.durationUnit = value;

    if(currentRowData.kind === "medicament")this.SetFinalPackageAmount(rowData , currentRowData);
  }

  SetFinalPackageAmount(rowData : Medicament , currentRowData : Medicament):void{

    rowData.packagesAmount = this.medicamentValidatorsService.CalculatePackageForProtocols(rowData , currentRowData);
  }
  //#endregion

  CloseMedicamentsSearchPopUp(message:string):void{
    if(message === 'close_medicaments_search_pop_up'){
      this.printMedicamentSearchPopUp = false;
    }
  }

  //#region ___________________________________________________ FAVORITES SUBSCRIPTIONS _____________________________________________

  GetFavoritesMedicaments():void{

    this.store.select( AppState => AppState.FavoritesMedicaments ).pipe( takeUntil(this.medicamentdFavoritesDestroyed$) ).subscribe(( medicamentsFavorites : Medicament[] ) => {

      if( this.protocolNew.medicaments ){

        if( medicamentsFavorites.length > 0 ){

          if( medicamentsFavorites[0].name !== 'initial_value' ){
    
            this.medicamentsFavorites = [ ...medicamentsFavorites ];
            
            this.protocolNew.medicaments = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.protocolNew.medicaments , this.medicamentsFavorites , 'medicament' );
            
          }
  
          this.medicamentsFavorites = [ ...medicamentsFavorites ];
  
        } else {
  
          this.medicamentsFavorites = [];
  
          this.protocolNew.medicaments = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.protocolNew.medicaments , this.medicamentsFavorites , 'medicament' );
        }

      }

      this.favoriteMedicamentsLoaded = true;
    })
  }
  
  GetFavoritesParapharmacy():void{

    this.store.select( AppState => AppState.FavoritesParapharmacy ).pipe( takeUntil( this.parapharmaciesFavoritesDestroyed$ ) ).subscribe(( parapharmaciesFavorites : Medicament [] ) => {

      if( this.protocolNew.medicaments ){

        if( parapharmaciesFavorites.length > 0 ){

          if( parapharmaciesFavorites[0].name !== 'initial_value' ){
    
            this.parapharmaciesFavorites = [ ...parapharmaciesFavorites ];
  
            this.protocolNew.medicaments = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.protocolNew.medicaments , this.parapharmaciesFavorites , 'paraMedicament' );
          }
  
          this.parapharmaciesFavorites = [ ...parapharmaciesFavorites ];
  
        } else {
  
          this.parapharmaciesFavorites = [];
  
          this.protocolNew.medicaments = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.protocolNew.medicaments , this.parapharmaciesFavorites , 'paraMedicament' );
        }
        this.favoriteParapharmaciesLoaded = true;
      }
    })
  }

  GetFavoritesMasterFormulas():void{

    this.store.select( AppState => AppState.FavoritesMasterFormulas ).pipe( takeUntil( this.masterFormulasFavoritesDestroyed$ ) ).subscribe(( masterFormulasFavorites : Medicament [] ) => {

      if( masterFormulasFavorites.length > 0 ){

        if( masterFormulasFavorites[0].name !== 'initial_value' ){
  
          this.masterFormulasFavorites = [ ...masterFormulasFavorites ];
         
          this.protocolNew.medicaments = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.protocolNew.medicaments , this.masterFormulasFavorites , 'masterFormula' );

        }

        this.masterFormulasFavorites = [ ...masterFormulasFavorites ];

      } else {

        this.masterFormulasFavorites = [];

        this.protocolNew.medicaments = this.medicamentsFormalHelperService.AddFavoritePropertyToMedicament( this.protocolNew.medicaments , this.masterFormulasFavorites , 'masterFormula' );
      }

      this.favoriteMasterFormulasLoaded = true;
    })
  }

  //#endregion

  ngOnDestroy(): void {

    this.resizeSubscription.unsubscribe();
    this.medicamentsSubscription.unsubscribe();
    this.refreshSubscription.unsubscribe();
    this.professionalDestroyed$.next();
    this.professionalDestroyed$.complete();
    this.medicamentdFavoritesDestroyed$.next();
    this.medicamentdFavoritesDestroyed$.complete();
    this.parapharmaciesFavoritesDestroyed$.next();
    this.parapharmaciesFavoritesDestroyed$.complete();
    this.protocolsDestroyed$.next();
    this.protocolsDestroyed$.complete();
    this.masterFormulasFavoritesDestroyed$.next();
    this.masterFormulasFavoritesDestroyed$.complete();
  }
}

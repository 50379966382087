import { Component , Inject, OnInit , EventEmitter , Output , OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest} from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { Subject , Subscription, takeUntil } from 'rxjs';
import { addUser, removeUser} from '../store/actions/users/user.actions';
import { Store } from '@ngrx/store';
import { faEye, fas ,faLock, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FaIconLibrary  } from '@fortawesome/angular-fontawesome';
import { AppState } from 'src/app/models/prescriptions/interfaces/appState';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../models';
import { WindowResizeService } from '../shared/services/customs/window-resize.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent implements OnInit , OnDestroy {

  resizeSubscription:                 Subscription;
  isIframe                            = false;
  loginDisplay                        = false;
  username ! :                        string;
  faEye:                              IconDefinition = faEye;
  faLock:                             IconDefinition = faLock;
  user_name                           = "";
  private readonly _destroying$ =     new Subject<string>();
  userDestroyed$:                     Subject<void> = new Subject<void>();
  windowWidth=                        0;
  avatar                              = '';
  mobileViewBreakPoint=               environment.mobileViewBreakPoint;

  @Output() iframe :                  EventEmitter<boolean> = new EventEmitter;
  @Output() isLogged :                EventEmitter<boolean> = new EventEmitter;

  constructor(
    private store:                    Store<AppState>,
    private authService:              MsalService , 
    private msalBroadcastService:     MsalBroadcastService,
    private windowResizeService :     WindowResizeService,
    private router:                   Router,
    library:                          FaIconLibrary,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ){
    library.addIconPacks(fas);
    library.addIcons(faEye);
    library.addIcons(faLock);

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {
      this.windowWidth = width;

      // console.log(this.windowWidth);
      // this.updateColumnVisibility(width);
    });
  }

  ngOnInit(): void {
    if(window.localStorage.getItem('idToken')){
      this.loginDisplay=true;
    }

    let userInfo: AccountInfo = this.authService.instance.getAllAccounts()[0];

    

    // userInfo = this.AccountInfoToUserInfoMapper( userInfo )
    // userInfo = { ...userInfo , professionalLoggedId : 'first_assignement' };

    const myUserInfo : UserInfo = {...userInfo , professionalLoggedId : 'first_assignement'}; 

    this.store.dispatch(addUser(myUserInfo));
    
    this.store.select(appState => appState.User).pipe(takeUntil( this.userDestroyed$ )).subscribe((user) => {

      const { name , avatar } = user || {};

      if(avatar)this.avatar = avatar;

      if(name)this.user_name = name.split(' ')[0];
    })
    this.CheckLogin();
  }

  CheckLogin(){

    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.msalSubject$.pipe(

      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    
    ).subscribe((result: EventMessage) => {

      const payload = result.payload as AuthenticationResult;

      if( payload.account ){
        let userInfo : UserInfo =  this.AccountInfoToUserInfoMapper( payload.account );

        userInfo = { ...userInfo , professionalLoggedId : 'first_assignement' };

        this.store.dispatch(addUser(userInfo));
        
        const idToken = payload.idToken;
        const accessToken = payload.accessToken;
        const user_id = payload.account.localAccountId;
        
        if(idToken){
          window.localStorage.setItem('idToken',idToken);
        }
        window.localStorage.setItem('accessToken',accessToken);
        window.localStorage.setItem('user_id',user_id);
        
        this.isLogged.emit(true);
        this.setLoginDisplay();
      }
    });
    this.iframe.emit(this.isIframe);
  }

  Login() {
    this.msalBroadcastService.inProgress$.pipe(
      filter((status : InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(()=>{
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    })
  }
 
  Logout() {
    /* LIMPIAMOS EL STATE */
    this.store.dispatch(removeUser({}));
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('state');
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('user_id');
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.prescriptionsService.logoutRedirectUri
    });
  }

  GoToPrescriptions():void{
    this.router.navigate(['prescriptions']);
  }
  
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  AccountInfoToUserInfoMapper( accountInfo : AccountInfo ):UserInfo{
    const { idToken , homeAccountId , idTokenClaims , localAccountId , name , tenantId , username } = accountInfo || {};
    const userInfo : UserInfo = {
      homeAccountId : homeAccountId,
      idTokenClaims : idTokenClaims,
      localAccountId : localAccountId,
      professionalLoggedId : 'first_assignement',
      name : name,
      tenantId : tenantId,
      username : username,
      idToken : idToken
    }
    return userInfo;
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
    this._destroying$.next('');
    this._destroying$.complete();
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
  }
}

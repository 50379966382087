import { Component , Input , AfterViewInit , OnInit } from '@angular/core';
/* devextrem */
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';


@Component({
  selector: 'app-prescriptions-detail',
  templateUrl: './prescriptions-detail.component.html',
  styleUrls: ['./prescriptions-detail.component.scss']
})

export class PrescriptionsDetailComponent implements AfterViewInit , OnInit {
  @Input() key ? :                                       number;
  @Input() Detalles ? :                                  [];
  medicamentDataSource :                                 DataSource;
  
  ngOnInit(): void {
    this.medicamentDataSource = new DataSource({
      store: new ArrayStore({
        data: [],
        key : ''
      }),
      filter: []
    });
  }
 
  ngAfterViewInit(): void {

    this.medicamentDataSource = new DataSource({
      store: new ArrayStore({
        data: this.Detalles,
        key : 'id'
      }),
      filter: ['id_medicament','=',this.key]
    })
  }
}

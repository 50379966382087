import { Component , EventEmitter, OnInit, Output , OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
/* fontawsome */
import { faPen, fas, faPlus,  IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
/* models */
import { ProtocolPrescription, ProtocolParamsToInsert, AppState, UserInfo } from 'src/app/models';
/* services */
import { ProtocolsService  , 
  PrescriptionsNewToMedicamentsTemplateService, 
  PresTemplateToNewPresTableService,
  PrescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService
} from 'src/app/prescriptions/services/index';
import { CheckUserRoleService, ComponentToSpinnerService, HandleHTTPerrorsService } from 'src/app/shared/services/index';
/* rxjs */
import { Subscription , Subject , takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { getFilteredProtocolAction } from 'src/app/store/actions/prescriptions/protocols.actions';
import { ProtocolHelperService } from '../../services/helpers/protocol-helper.service';
import { environment } from 'src/environments/environment';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';

@Component({
  selector: 'app-medicaments-templates-pop-up',
  templateUrl: './medicaments-templates-pop-up.component.html',
  styleUrls: ['./medicaments-templates-pop-up.component.scss']
})

export class MedicamentsTemplatesPopUpComponent implements OnInit , OnDestroy {

  protocolsPrescriptions :                                                            ProtocolPrescription[];
  prescriptionId:                                                                     string;
  prescriptionIdSubscription:                                                         Subscription;
  openPopUpSubscription:                                                              Subscription;
  openPopUpFromTableSubscription:                                                     Subscription;
  resizeSubscription:                                                                 Subscription;
  protocolId:                                                                         string;
  isDoctor:                                                                           boolean;
  isAdmin:                                                                            boolean;
  isNurse:                                                                            boolean;
  faPen:                                                                              IconDefinition=faPen;
  faPlus:                                                                             IconDefinition=faPlus;
  isDraft:                                                                            boolean;
  params:                                                                             {filter:string , size:number} = {filter:"" , size:100};
  typingTimer                                                                         = 0;
  protocolsDestroyed$:                                                                Subject<void> = new Subject<void>();
  professionalDestroyed$:                                                             Subject<void> = new Subject<void>();
  doneTypingInterval                                                                   = 2000;
  professionalLogged:                                                                 UserInfo;
  windowWidth=                                                                        0;
  mobileViewBreakPoint=                                                               environment.mobileViewBreakPoint;
  
  constructor( 
    private store:                                                                    Store<AppState>,
    private protocolService :                                                         ProtocolsService ,
    private prescriptionsNewToMedicamentsTemplateService:                             PrescriptionsNewToMedicamentsTemplateService,
    private presTemplateToNewPresTableService:                                        PresTemplateToNewPresTableService,
    private router :                                                                  Router,
    library:                                                                          FaIconLibrary,   
    private checkUserRoleService:                                                     CheckUserRoleService,
    private handleHTTPerrorsService:                                                  HandleHTTPerrorsService,
    private componentToSpinnerService:                                                ComponentToSpinnerService,
    private protocolHelperService:                                                    ProtocolHelperService,
    private windowResizeService :                                                     WindowResizeService,
    private prescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService: PrescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService
  ){
    library.addIconPacks(fas);
    library.addIcons(faPen);
    library.addIcons(faPlus);

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {

      this.windowWidth = width;
    });

    this.prescriptionIdSubscription = this.prescriptionsNewToMedicamentsTemplateService.OnMessage()
    .subscribe(message=>{

      if(message){

        const {status , id} = message;
        status === 'Draft' ? this.isDraft = true : this.isDraft = false;

        if(id)this.prescriptionId = id;
      }
    })

    this.openPopUpSubscription = this.presTemplateToNewPresTableService.sendOpenModalOnMessage()
    .subscribe( message => {
      
      if(message){

        if(message === 'open')/* this.GetProtocols(); */this.StoreProtocols();
      }
    })

    this.openPopUpFromTableSubscription = this.prescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService.onMessage()
    .subscribe((message)=>{

      if(message){
        if(message === 'open')/* this.GetProtocols(); */this.StoreProtocols();
      }
    })

  } 

  @Output() ClosePopUp : EventEmitter<string> = new EventEmitter;

  ngOnInit(): void {
    this.CheckUserRole();
    this.GetProfessionalInfo();
    
  }

  GetProfessionalInfo():void{
    this.store.select( AppState => AppState.User ).pipe( takeUntil( this.professionalDestroyed$ ) ).subscribe((professional : UserInfo) => {

      this.professionalLogged = { ...professional };
    })
  }

  CheckUserRole():void{
    const roles = this.checkUserRoleService.CheckUserRole();
    const {isDoctor , isAdmin , isNurse} = roles || {};
    if( isDoctor ) this.isDoctor = isDoctor;
    if( isNurse ) this.isNurse = isNurse;
    if( isAdmin ) this.isAdmin = isAdmin;
  }

  StartTyping():void{

    window.clearTimeout(this.typingTimer);

    this.typingTimer = window.setTimeout(() => {

      this.StoreProtocols();

    }, this.doneTypingInterval);
  }

  StoreProtocols():void{

    if( this.professionalLogged.professionalLoggedId !== 'first_assignement' ){

      if( ( this.isDoctor && this.isAdmin ) || ( this.isNurse && this.isAdmin ) || this.isDoctor ){

        const myParams = { ...this.params , professionalId : this.professionalLogged.professionalLoggedId }/* Object.assign({} , this.params) */;
  
        this.store.dispatch(getFilteredProtocolAction({ parameters: myParams }));
  
        this.GetProtocols();
      }
    }
  }

  GetProtocols(){

    if( ( this.isDoctor && this.isAdmin ) || ( this.isNurse && this.isAdmin ) || this.isDoctor){

      if( this.professionalLogged.professionalLoggedId !== 'first_assignement' ){

        this.store.select(AppState => AppState.Protocols).pipe(takeUntil( this.protocolsDestroyed$ )).subscribe((protocols)=>{

          if( protocols.stateStatus === 'real_assignement' ){

            if(protocols?.error){
    
              this.componentToSpinnerService.sendMessage('hide');
            
            }else {
            
              if( protocols?.items ){

                const { professionalLoggedId } = this.professionalLogged || {};
    
                if( /* professionalLoggedId && */ professionalLoggedId !== 'first_assignement' ){

                  //this.protocolsPrescriptions = this.protocolHelperService.FilterProtocolWithProfessionalLoggedId( protocols , professionalLoggedId );

                  this.protocolsPrescriptions = this.protocolHelperService.AddMedicamentsToProtocol( protocols.items );

                  this.protocolsPrescriptions = this.protocolHelperService.OrderProtocolsArrayFavoriteFirst ( this.protocolsPrescriptions );
                
                }
              } 
            }
          }
        })
      }
    } 
  }

  SelectProtocol(protocol:ProtocolPrescription):void{

    const {id} = protocol || {};
    if(id)this.protocolId = id;
    const protocolInsertParams : ProtocolParamsToInsert = {
      protocolId : id,
      prescriptionId : this.prescriptionId
    }
    this.InsertProtocol(protocolInsertParams);
  }

  InsertProtocol(protocolInsertParams:ProtocolParamsToInsert):void{

    this.protocolService.InsertProtocol(protocolInsertParams).subscribe((res:ArrayBuffer)=>{

      if(res)this.ClosePopUpMethod();

    },(error: HttpErrorResponse) => {
      this.handleHTTPerrorsService.HandleHTTPerrors( error );
    })
  }

  EditProtocol(protocolId:string):void{
    this.router.navigate(['prescriptions/protocol' , {protocolId:protocolId}]);
  }

  ClosePopUpMethod():void{
    this.ClosePopUp.emit('close_pop_up');
  }

  GoToProtocol():void{
    this.router.navigate(['prescriptions/protocol']);
  }

  ngOnDestroy(): void {

    this.resizeSubscription.unsubscribe();
    this.prescriptionIdSubscription.unsubscribe();
    this.protocolsDestroyed$.next();
    this.protocolsDestroyed$.complete();
    this.openPopUpSubscription.unsubscribe();
    this.openPopUpFromTableSubscription.unsubscribe();
    this.professionalDestroyed$.next();
    this.professionalDestroyed$.complete();
  }

}

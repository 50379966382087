<div class="protocols_table-main_container" >
    
    <div>
        <span class="protocols_table-tittle_container" >
            Protocolos
        </span>
    </div>

    <div class="protocols_table-protocol_new_button_container" >
        <div 
            (click)="GoProtocolNew()" 
            class="protocols_table-protocol_new_button" 
        >
            <div>
                <fa-icon [icon]="faPlus"></fa-icon>
            </div>
            <div>
                <span>PROTOCOLO NUEVO</span>
            </div>
        </div>
    </div>
    
    <dx-data-grid  
        [dataSource]="protocols"
        keyExpr="id"
        id="protocolsGridContainer"
        [showRowLines]="true"
        [showColumnLines]="false"
        [showBorders]="false"
        [allowColumnResizing]="true"
        [hoverStateEnabled]="true"
        [columnAutoWidth]="true"
        [columns]="['Id','Nombre','Descripción']"
        [loadPanel]="false"
        class="prescription_table-table"
        
    >
    <!-- (onInitialized)="GetEvent($event)" -->
        <dxo-scrolling
            [useNative]="false"
            [scrollByContent]="true"
            [scrollByThumb]="true"
            showScrollbar="onHover"
            columnRenderingMode="virtual"
        >
        </dxo-scrolling>

        <dxo-filter-builder
            [allowHierarchicalFields]="true"
        ></dxo-filter-builder>

        <dxo-filter-row 
            [visible]="showFilterRow"
            [applyFilter]="currentFilter"
        >
        </dxo-filter-row>

        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>

        <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>

        <dxi-column 
            class="protocol_table-table_header" 
            caption="Nombre" 
            dataField="name"
            [allowFiltering]="false"
        ></dxi-column>

        <dxi-column 
            class="protocol_table-table_header" 
            caption="Descripcción" 
            dataField="description"
            [allowFiltering]="false"
        ></dxi-column>

        <dxi-column 
            class="protocol_table-table_header" 
            caption="Indicaciones del protocolo" 
            dataField=""
            cellTemplate="indications"
            [allowFiltering]="false"
        ></dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="edit" 
            dataField="edit_action"
            [allowFiltering]="false"
        ></dxi-column>

        <dxi-column 
            class="prescriptions_table-columns_header" 
            width="30 px" 
            caption="" 
            cellTemplate="delete" 
            dataField="delete_action"
            [allowFiltering]="false"
        ></dxi-column>

        <div *dxTemplate="let acciones of 'edit'">
            <div (click)="EditProtocol(acciones.data)" class="" >
                <fa-icon 
                    class="protocols_table-acciones-edit_icon actions_icons" 
                    [icon]="faPen"
                ></fa-icon>
            </div>
        </div>

        <div *dxTemplate="let acciones of 'delete'">

            <div (click)="DeleteProtocol(acciones)" class="" >
                <fa-icon 
                    class="protocols_table-acciones-delete_icon actions_icons" 
                    [icon]="faTrash"
                ></fa-icon>
            </div>
            
        </div>

        <div *dxTemplate="let indication of 'indications'">
            <div class="master-detail-caption"></div>

            <div *ngFor="let indication_name of indication.data.indications" >
                <span>
                    {{ indication_name.name }} ,
                </span>
            </div>

        </div>

        <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
        
        <div *dxTemplate="let medicamento of 'detail'">
            <div class="master-detail-caption"></div>
            <app-prescriptions-detail [Detalles]="medicaments" [key]="medicamento.key" ></app-prescriptions-detail>
        </div>

        
    </dx-data-grid>

    <div>
        <app-table-pagination (sendParams)="ReciveParams($event)" ></app-table-pagination>
    </div>

    <dx-popup
        height="300px"
        width="30%"
        minWidth="300px"
        [(visible)]="printDeletePopUp"
        [hideOnOutsideClick]="true"
    >
        <app-delete-warning-pop-up-content 
            (CloseDeletePopUp)="CloseDeletePopUp($event)">
        </app-delete-warning-pop-up-content>
    </dx-popup>
</div>
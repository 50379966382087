import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { NgrxPrescriptionsRes, Prescription, PrescriptionsFilters } from 'src/app/models';

export const getFilteredPrescriptionsAction = createAction(
    '[Prescriptions] Get prescriptions' , 
    props<{ parameters:PrescriptionsFilters }>()
);

export const getPrescriptionsSuccess = createAction(
    '[Prescriptions] Get prescriptions success',
    ( prescriptions : NgrxPrescriptionsRes ) => ( prescriptions )
)


export const updatePrescription = createAction(
    '[Prescriptions] Updata prescription',
    props<{ prescription : Prescription }>()
)

export const getPrescriptionsFailure = createAction(
    '[Prescriptions] Get prescriptions failure',
    props<{ error: HttpErrorResponse }>()
);

export const addLastPrintPropertyToPrescription = createAction(
    '[Prescriptions] add lastPrint property to prescription',
    props<{ prescription: Prescription }>()
);
export const modifyPrescriptionStatusToCanceled = createAction(
    '[Prescriptions] Modify prescription status when canceled',
    props<{ message : {prescriptionDoclineId : string , reasonId : string , comment : string , id:string , prescriptionStatus?:string} }>()
)

export const modifyPrescriptionStatusToCanceledSuccess = createAction(
    '[Prescriptions] Modify prescription status when canceled success',
    props<{ message ? : {prescriptionDoclineId : string , reasonId : string , comment : string , id:string} }>()
)

export const modifyPrescriptionStatusToFailure = createAction(
    '[Prescriptions] Modify prescription status when canceled failure',
    props<{ error: HttpErrorResponse }>()
)



/* export const addUser = createAction('Add User' , props<UserInfo>());
export const removeUser = createAction('Remove User' , props<UserInfo>())
export const clearUser = createAction('Clear User');
export const returnUsers = createAction('Return User'); */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DoclineGetRes, UserRes } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root' //injected in app-component
})
export class ProfessionalService {

  constructor(private http : HttpClient) {}

  LoadProfessionalDocLineByIdProfessional(params : {professionalId:string}):Observable<DoclineGetRes>{
    return this.http.post<DoclineGetRes>(`${environment.prescriptionsService.url}professionaldocline` , params);
  }

  LoadUserById(professionalId:string):Observable<UserRes>{
    return this.http.get<UserRes>(`${environment.usersService.url}professional?Id=${professionalId}`);
  }

  LoadUserByEmail(professionalEmail:string):Observable<UserRes>{
    return this.http.get<UserRes>(`${environment.usersService.url}professional?Email=${professionalEmail}`);
  }

  CheckProfessinalCollegiateNumberIsValid( professionalId : string ):Observable<{ valid : boolean }>{
    return this.http.get<{valid:boolean}>(`${environment.prescriptionsService.url}professionaldocline/check-collegiate-number?professionalId=${professionalId}`);
  }
}

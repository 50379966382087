import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HandleHTTPerrorsService {

  constructor(
    private router:                                          Router
  ){}

  HandleHTTPerrors(error:HttpErrorResponse){
    const { status } = error
    switch (status) {
      case 409:
        this.Manage409(error);  
        break;
      case 500:
        this.Manage500(error);  
        break;
      case 400:
        this.Manage400(error);  
        break;
      default:
        break;
    }
    
  }

  Manage400(error : HttpErrorResponse):void{
    console.log(error);
    //this.prescriptionsAlertsService.BuildPrescriptionsAlert( error );
  }

  Manage409( error : HttpErrorResponse ):void{
    console.log(error);
    /* Conflict */
    // if( statusText === 'Conflict' ){};
  }

  Manage500( error : HttpErrorResponse ):void{
    console.log(error);
    /* deshabilita redirección en servidor local para depurar el error */

    // const { location : { hostname } } = window;
    /* if( hostname !== 'localhost') */this.router.navigate(['serviceErrors/serverError']);
  }
}

import { /* User,  */UserInfo } from '../../../models';
import { createReducer , on } from '@ngrx/store';
import { addProfessionalAvatar, addUser, removeFirstAssignementId, removeUser } from '../../actions/users/user.actions';

export interface userState {
    User: UserInfo
}

export const initialUserEntries : UserInfo = {} ;

export const UserReducer = createReducer(
    initialUserEntries,
    //on(clearUser, _ => {}),

    on(addUser , (state , User)=>{

        state = User/* JSON.parse(JSON.stringify(entries)) */;
        return state;
    }),
    
    on(removeUser , (EmptyObject) => {
        const entriesClone : UserInfo = EmptyObject;
        return entriesClone;
    }),

    on( addProfessionalAvatar , ( state , { avatar } ) =>{
        
        return { ...state , avatar : avatar };
    }),

    on( removeFirstAssignementId , (state , { order }) =>{
        if(order === 'remove_first_assignement'){
            return {...state , professionalLoggedId:undefined};
        } else {
            return state;
        }
    })
) 
<div *ngIf="showSpinner" class="spinner_main_container" >
    <div class="spinner_sub_container">
        <div class="indicators">
            <dx-load-indicator
                id="large-indicator"
                height="60"
                width="60"
            >
            </dx-load-indicator>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, MagickLinkHolded, Patient, PrescriptionsFilters, UserInfo } from 'src/app/models';
import { CheckUserRoleService, ComponentToSpinnerService, HandleHTTPerrorsService } from 'src/app/shared/services';
import { modifyPrescriptionsFilters } from 'src/app/store/actions/prescriptions/prescriptionsFilters.actions';
import { ValueChangedEvent } from 'devextreme/ui/html_editor';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormToTableService, PatientsService, PrescriptionsAlertsService, PrescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowResizeService } from 'src/app/shared/services/customs/window-resize.service';
import { HttpErrorResponse } from '@angular/common/http';
import { removeMagicLinkHolded } from 'src/app/store/actions/prescriptions/magickLinkHolded.actions';
/* fontawsome */
import { IconDefinition , faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-mobile-prescriptions-filters-pop-up',
  templateUrl: './mobile-prescriptions-filters-pop-up.component.html',
  styleUrls: ['./mobile-prescriptions-filters-pop-up.component.scss']
})

export class MobilePrescriptionsFiltersPopUpComponent implements OnInit {

  prescriptionsFilters :                                                               PrescriptionsFilters = {};
  currentUserCode                                                                      = '';
  currentProfessionalCode                                                              = '';
  patientIdFromParams                                                                  :string | null= '';
  inputsDisabled                                                                       = false;
  typingTimer                                                                          = 0;
  isDoctor:                                                                            boolean;
  isNurse:                                                                             boolean;
  isAdmin:                                                                             boolean;
  inputsDisableSubscription:                                                           Subscription;
  minDate:                                                                             string = new Date().toString();
  maxDate:                                                                             string = new Date().toString();
  printPopUpProtocol                                                                   = false;
  doneTypingInterval                                                                   = 2000;
  subscriptionCounter                                                                  = 0;
  patientAlertaAbierta                                                                 = false;
  userDestroyed$:                                                                      Subject<void> = new Subject<void>();
  prescriptionsFiltersDestroyed$:                                                      Subject<void> = new Subject<void>();
  showAlert                                                                            = false;
  magickLinkHoldedDestroyed$:                                                          Subject<void> = new Subject<void>();
  prescriptionEmittedAlertPopUp =                                                      false;
  magickLinkHolded :                                                                   MagickLinkHolded = new MagickLinkHolded();
  showNewPrescriptionButton =                                                          false;
  resizeSubscription:                                                                  Subscription;
  windowWidth=                                                                         0;
  mobileViewBreakPoint=                                                                environment.mobileViewBreakPoint;
  faCircleXmark:                                                                       IconDefinition=faCircleXmark;

  prescriptionsStatus:                                                                 { name : string , key : string }[] = [
    {"name" : "Borrador" , "key" : "Draft"},
    {"name" : "Pendiente de Emisión" , "key" : "Pending"},
    {"name" : "Emitida" , "key" : "Emitted"},
    {"name" : "Canceleda" , "key" : "Canceled"},
    {"name" : "Expirada" , "key" : "Expired"},
    {"name" : "Dispensada" , "key" : "Finished"},
    {"name" : "Reintentando emisión" , "key" : "RetryDraft"},
    {"name" : "Manual" , "key" : "Manual"},
    {"name" : "Fórmula Magistral" , "key" : "MasterFormula"},
    {"name" : "Todos" , "key" : "All"}
  ]

  statusUngroupedData:                                                                 DataSource = new DataSource({
    store: new ArrayStore({
      data: this.prescriptionsStatus,
      key: 'key',
    }),
    group: 'Category',
  });

  constructor(
    private store:                                                                     Store<AppState>,
    private formToTableService :                                                       FormToTableService,
    private checkUserRoleService:                                                      CheckUserRoleService,
    private route:                                                                     ActivatedRoute,
    private patientsService:                                                           PatientsService,
    private windowResizeService :                                                      WindowResizeService,
    private router:                                                                    Router,
    private componentToSpinnerService:                                                 ComponentToSpinnerService,
    private prescriptionsAlertsService:                                                PrescriptionsAlertsService,
    private handleHTTPerrorsService:                                                   HandleHTTPerrorsService,
    private prescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService : PrescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService
  ){

    this.inputsDisableSubscription = formToTableService.onMessageToForm()
    .subscribe((message:string)=>{
      if(message){
        
        if( message === 'http_start' )this.inputsDisabled = true;

        if(message === 'http_done'){
          if(this.isDoctor){
            this.inputsDisabled = false
          } else {

            this.patientIdFromParams ? this.inputsDisabled = true : this.inputsDisabled = false;
          }
        }
      }
    })

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {
      this.windowWidth = width;

      // this.updateColumnVisibility(width);
    });
  }

  ngOnInit(): void {
    this.CheckInitialFilters();
    this.GetProfessionalId();
  }

  GetProfessionalId():void{
    this.store.select(appState => appState.User).pipe(takeUntil( this.userDestroyed$ )).subscribe((user : UserInfo) => {

      const { professionalLoggedId , /* username */ } = user || {};

      if( professionalLoggedId /* username */ ){

        if( professionalLoggedId === 'first_assignement' ){

          this.showNewPrescriptionButton = false;

          this.prescriptionsFilters.professionalId = undefined;

        } else {

          this.showNewPrescriptionButton = true;

          this.prescriptionsFilters.professionalId = professionalLoggedId;
          
        }
        /* this.prescriptionsFilters.userName = username; */
      }else{
        this.showNewPrescriptionButton = true;
        this.prescriptionsFilters.professionalId = undefined;
      }

      this.CheckUserRole();
    })
  }

  CheckInitialFilters():void{

    this.store.select( AppState => AppState.PrescriptionsFilters ).pipe(takeUntil(this.prescriptionsFiltersDestroyed$)).subscribe( prescriptionsFilters => {

      const myPrescriptionsFilters = { ...prescriptionsFilters };

      this.prescriptionsFilters = myPrescriptionsFilters;
    })
  }

  CheckUserRole():void{

    const roles = this.checkUserRoleService.CheckUserRole();

    const {isDoctor , isAdmin , isNurse} = roles || {};

    if( isDoctor )this.isDoctor = isDoctor;
    if( isNurse )this.isNurse = isNurse;
    if( isAdmin )this.isAdmin = isAdmin;

    if( isNurse && (!isDoctor) ){

      this.CheckParams();

    } else if (isDoctor) {

      this.CheckParams();
      
      this.StoreFilters();
    }
  }

  SelectStatus(selectedStatus:ValueChangedEvent):void{
    
    const { value } = selectedStatus || {};

    this.prescriptionsFilters.status = value;

    this.StoreFilters();
  }

  CheckParams():void{
    this.route.paramMap.subscribe(params => {

      if(params.get('patientId')){

        if(params.get('patientId') !== 'patient_not_found'){
         
          this.patientIdFromParams = params.get('patientId');

          /* params.get('date') === 'curDate' ? this.GetPatientById(new Date().toString()) : */ this.GetPatientById();
          
        } else {
          this.showAlert = true;
          setTimeout(() => {
            this.showAlert = false;
          }, 10000);
          // this.formToTableService.sendMessagePatientNotFound('patient_not_found');
          this.prescriptionsFilters.patientFilter = undefined;
          // this.prescriptionsFilters.from = new Date().toString();
          this.StoreFilters();
          
        }
        
      } else {
        //this.formToTableService.sendMessage(this.filters_obj);
        this.StoreFilters();
      }
    },(error: HttpErrorResponse) => {
      this.handleHTTPerrorsService.HandleHTTPerrors( error );
    });
  }

  GetPatientById(from ? : string):void{
    this.patientsService.LoadPatientById(this.patientIdFromParams).subscribe((res: Patient) => {
      const { name , id } = res;
      this.prescriptionsFilters.patientFilter = name;
      this.prescriptionsFilters.patientId = id;
      this.prescriptionsFilters.from = from ? from : undefined;
      this.StoreFilters();
    })
  }

  StartTyping( filter?:string ):void{

    if(filter && filter === 'patientFilter'){
      this.prescriptionsFilters.patientId = undefined;
    }

    window.clearTimeout(this.typingTimer);
    this.typingTimer = window.setTimeout(() => {
      this.StoreFilters();
    }, this.doneTypingInterval);
  }

  ClearPatientFilter():void{
    this.prescriptionsFilters.patientFilter = undefined;
    this.prescriptionsFilters.patientId = undefined;
    this.StoreFilters();
  }

  ClearClinicFilter():void{
    this.prescriptionsFilters.clinic = undefined;
    this.StoreFilters();
  }

  ClearProffesionalFilter():void{
    this.prescriptionsFilters.professionalFilter = undefined;
    this.StoreFilters();
  }

  GoNewParaPrescription():void{
    this.router.navigate([
      'paraPrescription/' , {professionalId:this.prescriptionsFilters.professionalId}
    ]);
  }

  GoNewPrescription():void{

    /* Antes de ir a la nueva prescription verificamos que si hay un magicLinkHolded */
    this.store.select( AppState => AppState.MagicLinkHolded ).pipe(takeUntil( this.magickLinkHoldedDestroyed$ )).subscribe(( magickLiknHolded : MagickLinkHolded ) => {
      
      if( magickLiknHolded.professionalCode ){
        this.magickLinkHolded = magickLiknHolded
        this.prescriptionEmittedAlertPopUp = true;
      }else {
        this.router.navigate([
          'prescriptions/new/' , 
          {professionalId:this.prescriptionsFilters.professionalId} 
        ]);
      }
      
    })
    
  }

  CreatePrescriptionWithMagickLinkHolded():void{

    this.store.dispatch(removeMagicLinkHolded({order : 'remove'}));

    const { userCode , clinicCode , professionalCode , patientCode , holdingReasong , indi } = this.magickLinkHolded;
    this.router.navigate([
      'prescriptions/new/' , 
      {userCode : userCode , professionalCode : professionalCode , clinicCode : clinicCode , patientCode : patientCode , indi : indi} 
    ]);
    /* http://localhost:4201/prescriptions/new;userCode=MOYA;professionalCode=MOY;clinicCode=46C07;patientCode=BDBR08505250;indi=LAS2,LAS8,0002, */
  }

  CancelCreatePrescriptionWithMagickLinkHolded():void{
    this.prescriptionEmittedAlertPopUp = false;
  }

  OpenProtocolPopUp():void{
    this.printPopUpProtocol = true;
    /* conectamos al nuevo servicio para enviar la orden de open */
    this.prescriptionsFiltersComponentToMedicamentsTemplatesPopUpComponentService.sendMessage('open');
  }
  
  CheckFilters(e:ValueChangedEvent , target : string):void{

    if(target === 'from'){

      const { event } = e;
      if(event){
        const { prescriptionsFilters : { from } } = this
        if( from )this.minDate = from;
        
        this.StoreFilters();
      }

    }else if(target === 'to'){

      const { event } = e;
      if(event){
        const { prescriptionsFilters : { to } } = this
        if( !to ){
          this.prescriptionsFilters.to = undefined;
        }
        
        this.StoreFilters();
      }
    }
  }

  StoreFilters():void{
    this.inputsDisabled = true;
    this.componentToSpinnerService.sendMessage('show');
    
    /* 
    * cuando se usan los filtros configuramos first a 1 y size a 10
    */
    
    const myFilters = { ...this.prescriptionsFilters , first: 1 , size:10 };
    this.store.dispatch( modifyPrescriptionsFilters({ prescriptionsFilters : myFilters }) );
  }

  OrderCloseAlert():void{
    this.showAlert = false;
  }

  GoToFavoritesPage():void{
    this.router.navigate(['medicamentsFavorites']);
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
    this.inputsDisableSubscription.unsubscribe();
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
    this.prescriptionsFiltersDestroyed$.next();
    this.prescriptionsFiltersDestroyed$.complete();
    this.magickLinkHoldedDestroyed$.next();
    this.magickLinkHoldedDestroyed$.complete();
  }
}

<div class="prescriptions_patients_table-search_container" >
    <div class="prescriptions_patients_table-input_container">
        <input 
            appInputTrim
            class="input_nosubmit prescriptions_patients_table-search_input"
            type="text" 
            placeholder="busca paciente" 
            
            [(ngModel)]="patientsTableFilters.filter"    
        >
        <!-- (keyup)="StartTyping('patients')" -->
    </div>

    <div class="prescriptions_patients_table-search-actions_containers" >
        <button 
            (click)="GetPatientByPatientsFilters()"
            class="prescriptions_patients_table-search-button_search" 
        > Buscar </button>
    </div>

    <div class="prescriptions_patients_table-search-pagination_container" >
        <app-table-pagination (sendParams)="ReciveParams($event)" ></app-table-pagination>
    </div>
</div>

<dx-data-grid  
    [dataSource]="patients"
    keyExpr="id"
    id="patientsGridContainer"
    [showRowLines]="true"
    [showColumnLines]="false"
    [showBorders]="false"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
    [columns]="[]"
    [loadPanel]="false"
    class="patients_table-table"
>
    <dxi-column 
        class="patients_table-columns_header" 
        caption="Paciente" 
        dataField="name"
        [allowFiltering]="false"
    ></dxi-column>

    <dxi-column 
        class="prescriptions_table-columns_header" 
        width="100px" 
        caption="Seleccionar" 
        cellTemplate="AccionesTemplate" 
        dataField="acciones"
        [allowFiltering]="false"
    ></dxi-column>

    <div *dxTemplate="let acciones of 'AccionesTemplate'">
        <div class="prescriptions_table-acciones_container" >

            <div 
                class="medicament_search-plus_icons_container" 
            >
                <input
                    [disabled]="inputDisabled"
                    type="checkbox" 
                    class="checkbox__input" 
                    [(ngModel)]="acciones.data.selected" 
                    (change)="SelectPatient(acciones.data)"
                >
                <svg class="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <rect width="21" height="21" x=".5" y=".5" fill="#FFF" stroke="#006F94" rx="3" />
                    <path class="tick" stroke="#6EA340" fill="none" stroke-linecap="round" stroke-width="4" d="M4 10l5 5 9-9" />
                </svg>
            </div>

        </div>
    </div>

    

</dx-data-grid>

<div>
    <app-spinner></app-spinner>
</div>
    

import { createReducer, on } from "@ngrx/store";
import { Medicament } from "src/app/models";
import * as FavoritesMasterFormulasActions from 'src/app/store/actions/medicaments/favorites-masterFormulas.actions';

export interface FavoriteMasterFormulasState {
    masterFormulas : Medicament[]
}

const initialState : Medicament[] = [{name:'initial_value'}];

export const FavoritesMasterFormulasReducers = createReducer(
    initialState,

    on( FavoritesMasterFormulasActions.getFavoritesMasterFormulasSuccess , ( state , {medicaments} ) => {
       state = medicaments;
       return state;
    }),

    on( FavoritesMasterFormulasActions.getFavoritesMasterFormulasFailure , ( state , {error} ) => {
        return [];
    }),

    on( FavoritesMasterFormulasActions.addFavoritesMasterFormulasSuccess , ( state , {myMasterFormulatWithId} ) =>{
        if( myMasterFormulatWithId ){
            return [ ...state , myMasterFormulatWithId ];
        }else {
            return state;
        }
    }),

    on( FavoritesMasterFormulasActions.addFavoritesMasterFormulasFailure , ( state , { error } ) => {
        return state;
    }),

    on( FavoritesMasterFormulasActions.deleteFavoritesMasterFormulasSuccess , ( state , { masterFormulaFavoritedId } ) =>{
        if( masterFormulaFavoritedId ){
            return state.filter( ( mf ) => mf.favoriteId !== masterFormulaFavoritedId );
        } else {
            return state;
        }
    }),

    on( FavoritesMasterFormulasActions.updateFavoritesMasterFormulasSuccess , ( state , { myMasterFormulaToUpdate }) => {
       
        if( myMasterFormulaToUpdate ){
            
            const { favoriteId } = myMasterFormulaToUpdate || {};

            return state.map(( mf ) => {
                return mf.favoriteId === favoriteId ? myMasterFormulaToUpdate : mf;
            });

        } else {
          return state;
        }
    })
)
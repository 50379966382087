import { createAction, props } from "@ngrx/store";
import { Prescription } from "src/app/models";

export const addClinicToPrescription = createAction(
    '[SinglePrescription] Add Clinic AreaCode to prescriptionDraft',
    props<{ areaCode : string }>()
)

export const addPatientInfoToPrescription = createAction(
    '[SinglePrescription] Add Patient Info to prescriptionDraft',
    props<{ patientInfo : { id: string , name: string } }>()
)

export const cleanSinglePrescriptionState = createAction(
    '[SinglePrescrition] Clean SinglePrescription state',
    props<{ order : string }>()
)

export const storeSinglePrescription = createAction(
    '[SinglePrescrition] Store prescriptionDraft in state',
    props<{ prescription : Prescription }>()
)
import { createReducer , on } from '@ngrx/store';
import { AddSingleProtocol, addMedicamentToProtocolSuccess, 
    deleteMedicamentToProtocolSuccess, getFilteredProtocolSuccess, getProtocolsFailure, storeProtocolSuccess 
} from '../../actions/prescriptions/protocols.actions'; 
import { ProtocolsRes } from '../../../models';

export interface ProtocolsState {
    protocols : ProtocolsRes
}

const initialState : ProtocolsRes = {
   
    stateStatus:  "first_assignement"
};

export const ProtocolsReducer = createReducer(
    initialState,
    
    on(getFilteredProtocolSuccess , (state , {protocols}  ) => {
        
        return  {...state , ...protocols , stateStatus : 'real_assignement'} ;
        
    }),

    on(addMedicamentToProtocolSuccess , (state , { medicament }) => {

        const { protocolId } = medicament;
        const stateWriteAble = Object.assign( {} , state )


        if( stateWriteAble.items ){
            if(stateWriteAble.items.length > 0)

            stateWriteAble.items = stateWriteAble.items.map(( e ) => {
                const protocolWriteAble = Object.assign( {} , e );
                if( protocolWriteAble.id === protocolId ){

                    if(protocolWriteAble.medicaments){
                        protocolWriteAble.medicaments = [ ...protocolWriteAble.medicaments , medicament ]
                    }

                    
                }
                return protocolWriteAble;
            })

            return stateWriteAble;
        }

        
        return state;
        
    }),

    on(getProtocolsFailure , ( state , {error}  )  => {
        return  {
            error:         error,
            first :        1,
            size :         10,
            totalPages :   0,
            totalCount :   0,
            items :        []
        } ;
    }),

    on(deleteMedicamentToProtocolSuccess , (state , params ) => {
        return state;
    }),

    on(storeProtocolSuccess , (state , {myProtocol}) => {

        if( state.items){

            const myItems = [...state.items];

            myItems.push(myProtocol);

            const myState = { ...state , items : myItems} ;
            
            return myState;
        }else {

            return state;
        }
    }),

    on(AddSingleProtocol , ( state , {protocol}  ) => {
        
        if( state.items ){
            const protocols = [ ...state.items , protocol ];
            return {...state , items : protocols};
        }

        return state;
    })
)




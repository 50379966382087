import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfessionalsRes } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UsuariosService {

  constructor(private http : HttpClient) { }

  GetUsers(){
    /* 
    * busca generica de usuarios
    */
    return this.http.get<ProfessionalsRes>(environment.usersService.url + 'professional/search')
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Prescription } from 'src/app/models';

export const addParaPrescriptionAction = createAction(
    '[ParaPrescription] Add paraPrescription' , 
    props<{ paraPrescription:Prescription }>()
);

export const addParaPrescriptionActionSuccess = createAction(
    '[ParaPrescription] Add paraPrescription success',
    props<{ myParaPrescription ? :Prescription }>()
)

export const storeIfIsNotInStore = createAction(
    '[ParaPrescription] Add paraPrescription if does not exists',
    props<{ paraPrescription ? :Prescription }>()
)

/* export const storeParaPrescriptionSelected = createAction(
    '[ParaPrescription] store paraPrescriptionSelected',
    props<{ paraPrescriptionSelected : Prescription }>()
)

export const cleanPrescriptionSelected = createAction(
    '[ParaPrescription] clean paraPrescriptionSelected',
    props<{ order : 'clean' }>()
) */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Prescription } from 'src/app/models';

@Injectable()

export class PrescriptionsNewToMedicamentsTemplateService {
  subject = new Subject<Prescription>();
 
  SendMessage(message:Prescription):void{
    this.subject.next(message);
  }

  OnMessage():Observable<Prescription>{
    return this.subject.asObservable();
  }

  ClearMessage():void{
    this.subject.next({});
  }
}

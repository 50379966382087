import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PrescriptionTablaService {

  FormatPrescriptionStatus(status:string):string{
    let mystatus = "";
    switch (status) {
      case 'Draft': 
      mystatus = 'Borrador';
        break;
      case 'Pending': 
      mystatus = 'Pendiente de Emisión';
        break;
      case 'Emitted': 
      mystatus = 'Emitida';
        break;
      case 'Expired': 
      mystatus = 'Expirada';
        break;
      case 'Canceled': 
      mystatus = 'Cancelada';
        break;
      case 'Finished': 
      mystatus = 'Dispensada';
        break;
      case 'RetryDraft': 
      mystatus = 'Reintentando emisión';
        break;
      case 'MasterFormula': 
      mystatus = 'Fórmula Magistral';
          break;
      default: mystatus = status; // en castellano desde el store de redux puede que llegue traducido 
        break;
    }
    return mystatus;   
  }

  TranslateMedicamentStatus(medicamentStatus:string):string{
    let translatedStatus = "";
    switch (medicamentStatus) {
      /* 0 */case "DispensableInFuture":
        translatedStatus = 'Dispensable a futuro'
        break;
      /* 1 */case "Dispensable":
        translatedStatus = 'Dispensable'
        break;
      /* 2 */case "Locked":
        translatedStatus = 'Bloqueado Cautelarmente'
        break;
      /* 3 */case "Dispensed":
        translatedStatus = 'Dispensado'
        break;
      /* 4 */case "Replaced_OnlyMedicament":
        translatedStatus = 'Dispensado con Sustitucion'
        break;
      /* 5 */case "Expired":
        translatedStatus = 'Caducado'
        break;
      /* 6 */case "VisaPending_OnlyMasterFormula":
        translatedStatus = 'Pendiente de Visado'
        break;
      /* 7 */case "VisaReject_OnlyMasterFormula":
        translatedStatus = 'Visado Rechazado'
        break;
      /* 8 */case "PartialDispensed":
        translatedStatus = 'Dispensado parcialmente'
        break;
      /* 9 */case "ElaboratingMasterFormula_OnlyMasterFormula":
        // translatedStatus = 'Fórmula Magistral / Vacuna Individualizada en elaboración'
        translatedStatus = 'F.Magistral elaboración'
        break;
      /* 10 */case "PartialDispensedWithReplaced_OnlyMedicament":
        // translatedStatus = 'Dispensado parcialmente con sustitucion'
        translatedStatus = 'D.parcial con sustitucion'
        break;
      /* 11 */case "Deleted":
        translatedStatus = 'Anulado'
        break;
        /* 12 *//* case "RetryDraft":
        translatedStatus = 'Segundo Intento de Emisión de Docline'
        break; */
        default: translatedStatus = medicamentStatus; // en castellano desde el store de redux puede que llegue traducido 
        break;
    }

    return translatedStatus;
  }

  TranslateMedicamentsFrequencyUnit(frequencyUnit:string):string{

    let translatedFrequencyUnit = "";
    switch (frequencyUnit) {
      case 'week':
        translatedFrequencyUnit = 'Semana';
        break;
      case 'hour':
        translatedFrequencyUnit = 'Hora';
        break;
      case 'day':
        translatedFrequencyUnit = 'Día';
        break;
      case 'month':
        translatedFrequencyUnit = 'Mes';
        break;
      default: translatedFrequencyUnit = frequencyUnit
        break;
    }
    return translatedFrequencyUnit;
  }

  TranslateToEnglishMedicamentsFrequencyUnit(frequencyUnit:string):string{

    let translatedFrequencyUnit = "";
    switch (frequencyUnit) {
      case 'Semana':
        translatedFrequencyUnit = 'week';
        break;
      case 'Hora':
        translatedFrequencyUnit = 'hour';
        break;
      case 'Día':
        translatedFrequencyUnit = 'day';
        break;
      case 'Mes':
        translatedFrequencyUnit = 'month';
        break;
      default: translatedFrequencyUnit = frequencyUnit
        break;
    }
    return translatedFrequencyUnit;
  }

  TranslateToEnglishMedicamentsDurationUnit( durationUnit : string ):string{


    let translatedDurationUnit = "";

    switch (durationUnit) {
      case 'Semana':
        translatedDurationUnit = 'week';
        break;
      case 'Día':
        translatedDurationUnit = 'day';
        break;
      case 'Mes':
        translatedDurationUnit = 'month';
        break;
      default: translatedDurationUnit = durationUnit
        break;
    }

    return translatedDurationUnit;
  }
}



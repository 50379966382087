import { Component , OnInit , OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
/* services */
import { ProtocolsService , PrescriptionTablaService } from 'src/app/prescriptions/services/index';
import { 
  TableToTablePaginationService , 
  CheckUserRoleService, ComponentToDeletePopUpService 
} from 'src/app/shared/services'
/* models */
import { ProtocolPrescription, AppState , ProtocolsFilters, Medicament , PaginationParams } from 'src/app/models';
/* fontawesome */
import { faPen, fas, faPlus , faTrash , IconDefinition 
} from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary  } from '@fortawesome/angular-fontawesome';
/* ngrx */
import { Store } from '@ngrx/store';
import { getFilteredProtocolAction } from 'src/app/store/actions/prescriptions/protocols.actions';
import { Subject, takeUntil } from 'rxjs';
import { CellClickEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-protocols-table',
  templateUrl: './protocols-table.component.html',
  styleUrls: ['./protocols-table.component.scss']
})
export class ProtocolsTableComponent implements OnInit , OnDestroy {

  protocolParams:                                          ProtocolsFilters = {filter:"" , size:10 , first:1};
  protocols:                                               ProtocolPrescription[];    
  showFilterRow:                                           boolean;  
  currentFilter                                            = "";
  showHeaderFilter:                                        boolean;
  applyFilterTypes:                                        {key:string , name:string}[]; 
  medicaments :                                            Medicament[] = []; 
  faPen:                                                   IconDefinition=faPen;                      
  faPlus:                                                  IconDefinition=faPlus;
  faTrash:                                                 IconDefinition=faTrash;
  printDeletePopUp                                         = false;
  protocolDestroyed$:                                      Subject<void> = new Subject<void>();
                      
  constructor(
    private store:                                         Store<AppState>,
    private protocolsService :                             ProtocolsService,
    library:                                               FaIconLibrary,
    private router:                                        Router,
    private tableToTablePaginationService :                TableToTablePaginationService,
    private prescriptionTablaService:                      PrescriptionTablaService,
    private checkUserRoleService:                          CheckUserRoleService,
    private componentToDeletePopUpService :                ComponentToDeletePopUpService
  ){
    library.addIconPacks(fas);
    library.addIcons(faPen);
    library.addIcons(faPlus);
    library.addIcons(faTrash);
    this.showHeaderFilter = true;
    this.showFilterRow = true;
    
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];

    this.currentFilter = this.applyFilterTypes[0].key;
  }

  ngOnInit(): void {
    this.CheckUserRole();
  }

  CheckUserRole():void{
    const roles = this.checkUserRoleService.CheckUserRole();
    const {isDoctor , isAdmin , isNurse} = roles || {};
    if( isAdmin && ( !isDoctor && !isNurse ) ){
      this.StoreProtocols();
    }
  }

  StoreProtocols():void{
    const parameters = Object.assign({} , this.protocolParams)
    this.store.dispatch(getFilteredProtocolAction({ parameters : parameters }));
    this.GetProtocols();
  }

  GetProtocols(){

    this.store.select(AppState => AppState.Protocols).pipe(takeUntil( this.protocolDestroyed$ )).subscribe(protocols => {
      const { items , totalCount} = protocols || {};
      if(totalCount)this.SendTotalElements(totalCount);
      if(items){

        this.OrderProtocol(items);
      }
    })
  }

  OrderProtocol(original_protocols : ProtocolPrescription[]):void{

    if(original_protocols){

      const protocols : ProtocolPrescription[] = [];
      const medicaments : Medicament[] = [];

      for (let i = 0; i < original_protocols.length; i++) {
        
        const { id } = original_protocols[i] ;
  
        const protocol_id = id;
  
        const prescription_clone : ProtocolPrescription = (({ medicaments , ...o }) => o)(original_protocols[i]);
        protocols.push(prescription_clone)

        const protocol_medicaments = original_protocols[i]?.medicaments; 

        if(protocol_medicaments){

          const myOriginalProtocol : ProtocolPrescription = original_protocols[i];

          for (let x = 0; x < protocol_medicaments.length; x++) {
            
            if(myOriginalProtocol.medicaments){
              const medicament = Object.assign({} , myOriginalProtocol.medicaments[x]);
  
              if(medicament.status)medicament.status = this.prescriptionTablaService.TranslateMedicamentStatus(medicament.status);
              if(medicament.frequencyUnit)medicament.frequencyUnit = this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(medicament.frequencyUnit);
              if(medicament.durationUnit)medicament.durationUnit = this.prescriptionTablaService.TranslateMedicamentsFrequencyUnit(medicament.durationUnit);
              medicament.id_medicament = protocol_id;
              medicaments.push(medicament);
            }

            
    
          }
        }
        
      }
      this.medicaments = medicaments;
      this.protocols=protocols;
    }
  }

  SendTotalElements(totalElementos:number):void{
    this.tableToTablePaginationService.sendTotalElementos(totalElementos);
  }

  ReciveParams( paginationParams : PaginationParams ):void{
    const { first , size } = paginationParams;
    this.protocolParams.first = first;
    this.protocolParams.size = size;
    
    const parameters = Object.assign({} , this.protocolParams)
    this.store.dispatch(getFilteredProtocolAction({ parameters : parameters }));
  }

  GoProtocolNew():void{
    this.router.navigate(['/prescriptions/protocol']);
  }

  EditProtocol(e : {id:string , name: string , description: string} ):void{
    const { id } = e;
    this.router.navigate(['/prescriptions/protocol' , { protocolId : id }])
  }

  DeleteProtocol(linea:CellClickEvent):void{
    const { data : { id , name } } = linea;
    const deleteMessage = {id:id,name:name,action:'show'};
    this.componentToDeletePopUpService.sendMessage(deleteMessage);
    this.printDeletePopUp = true;
  }

  CloseDeletePopUp(message:string):void{
    this.printDeletePopUp = false;
    if( message !== 'cancel' ){
      this.ConfirmDeleteProtocol( message );
    }
  }

  ConfirmDeleteProtocol( id:string ):void{
    this.protocolsService.DeleteProtocol(id).subscribe((res:boolean)=>{
      if(res){
        this.StoreProtocols();
      }
    })
  }

  ngOnDestroy(): void {
    this.protocolDestroyed$.next();
    this.protocolDestroyed$.complete();
  }
}

import { Injectable } from '@angular/core';
import { Medicament, ProtocolNew, ProtocolPrescription, ProtocolsRes } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})

export class ProtocolHelperService {

  constructor() { }

  FilterProtocolWithProfessionalLoggedId( protocols : ProtocolsRes , professionalLoggedId ? : string ):ProtocolPrescription[]{

    if(protocols.items){
      
      return protocols.items?.filter((p) => { 
                  
        if( p.professionalId ){
  
          return p.professionalId === professionalLoggedId;
  
        } else {
  
          return p; 
        }

      });

    } else {

      return [];

    }
  }

  AddMedicamentsToProtocol( protocols : ProtocolPrescription[] ):ProtocolPrescription[]{

    return protocols.map((pp) => {
    
      if( pp.medicaments && pp.parapharmacyMedicaments && pp.masterFormulas ){

        return { 

          ...pp , medicaments : [ ...pp.medicaments , ...pp.parapharmacyMedicaments , ...pp.masterFormulas.map((mas : Medicament) => { return {...mas , name : mas.composition} }) ] 
        };
      }else {

        return pp;
      }
    })
  }

  OrderProtocolsArrayFavoriteFirst( protocols : ProtocolPrescription[] ):ProtocolPrescription[]{

    return protocols.sort((b, a) => {

      if (b.professionalId !== null && a.professionalId === null) {
        
        return -1;

      } else if (b.professionalId === null && a.professionalId !== null) {
        
        return 1;

      } else {
        
        return 0;
      }
    });
  }

  CheckIfProtocolExistsByName( protocols : ProtocolPrescription[] | undefined , name : string | null | undefined ):boolean{

    if( protocols && name ){

      return protocols.find(( p : ProtocolPrescription ) => p.name?.replace(/\s/g, "").toLocaleLowerCase() === name.replace(/\s/g, "").toLocaleLowerCase() ) ? true : false;
      
    } else {

      return false;
    }
  }
}

<div class="protocol-main_container" >
    <!-- <input type="color" name="" id=""> -->
    <div>
        <app-alert></app-alert>
    </div>
    <div class="protocol-banner_container" >
        <div class="protocol-title_container" >

            <div class="protocol-arrow_left_container">
                <fa-icon 
                    (click)="BackToPrescriptions()" 
                    [icon]="faArrowLeft"
                ></fa-icon>
            </div>

            <span class="protocol-span_protocol" >
                Protocolo
            </span>

        </div>

        <div class="protocol-actions_main_container" >

            <div *ngIf="protocolCreated" class="dx-fieldset protocol-inputs_datafield_containers">
                <div 
                    class="dx-field-value protocol-selector_paciente_container"
                    style="width:100%"    
                >
                    <dx-drop-down-button
                        width="100%"
                        textAlign="start"
                        [splitButton]="true"
                        [useSelectMode]="false"
                        [items]="indications"
                        text="{{ indicationsComboTitle }}"
                        displayExpr="name"
                        keyExpr="indicationId"
                        (onItemClick)="OnIndicationClick($event)"
                    ></dx-drop-down-button>
                </div>
            </div>
    
            <div>
                
                <button
                    *ngIf="protocolCreated" 
                    (click)="OpenMedicalIndicationPopUp()"
                    class="protocol-search_medicament_popup_button"
                >
                    Asociar a indicación
                </button>

                <button
                    *ngIf="protocolCreated && !existsPsycotropic" 
                    (click)="OpenMedicamentSearchPopUp()"
                    class="protocol-search_medicament_popup_button"
                >
                    <fa-icon [icon]="faPrescriptionBottleMedical"></fa-icon>
                    Añadir Medicamento
                </button>

                <button
                    *ngIf="protocolCreated && !existsPsycotropic" 
                    (click)="OpenCreateMasterFormula()"
                    class="protocol-search_medicament_popup_button"
                >
                    <fa-icon [icon]="faFlaskVial"></fa-icon>
                    Fórmula Magistral
                </button>

                <button
                    *ngIf="protocolCreated" 
                    (click)="OpenParaMedicamentoCreateFormPopUp()"
                    class="protocol-search_medicament_popup_button"
                >
                    <fa-icon [icon]="faCapsules"></fa-icon>
                    Parafarmacia
                </button>
    
                 

                <button
                    *ngIf="protocolCreated && !existsPsycotropic" 
                    (click)="OpenMyFavoritesPopUp()"
                    class="protocol-search_medicament_popup_button"
                >
                    <fa-icon [icon]="faStar"></fa-icon>
                    Desde mis favoritos
                </button> 
    
                <button 
                    *ngIf="protocolCreated" 
                    class="protocol-delete_protocol"
                    (click)="DeleteProtocolById()"
                >
                    Eliminar Protocolo
                </button>
            </div>
        </div>

    </div>

    <div class="protocol-form_container" >

        <div class="protocol-favorite_checkbox_container" *ngIf="isDoctor && !protocolNew.id" >

            <div>
                <span>
                    Favorito
                </span>
            </div>
            <div class="protocol-favorite_checkbox">
                <input
                    [disabled] = "isDoctor && !isAdmin"
                    type="checkbox" 
                    class="checkbox__input" 
                    [(ngModel)]="protocolNew.favorite"
                    (change)="CheckFavoriteProtocol()"
                >
                <svg class="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <rect width="21" height="21" x=".5" y=".5" fill="#FFF" stroke="#006F94" rx="3" />
                    <path class="tick" stroke="#6EA340" fill="none" stroke-linecap="round" stroke-width="4" d="M4 10l5 5 9-9" />
                </svg>
            </div>

        </div>

        <form 
            *ngIf="protocolsLoaded"
            [formGroup]="protocolForm" 
            class="protocol-form" 
            (ngSubmit)="SaveProtocolChanges()"
        >

            <div class="protocol-form_container">

                <div class="protocol-inputs_container">
                
                    <label for="Nombre" class="protocol-labels" >Nombre *</label>
                    <input 
                        type="text" 
                        [ngModel] = "protocolNew.name" 
                        formControlName="name"
                        class="protocol-inputs" 
                        (input)="ProtocolNameChange($event.target.value)"
                    >

                    <div>
                        <span 
                            class="protocol-form_required_spans" 
                            style="color : red;" 
                            *ngIf="this.protocolForm.get('name')?.errors?.['required']"
                        >
                        Nombre es requerido *
                    </span>

                    <span 
                        class="protocol-form_required_spans" 
                        style="color : red;" 
                        *ngIf="protocolUsed"
                    >
                        Este nombre ya es usado por otro protocolo
                    </span>
                    </div>

                </div>
        
                <!-- <div class="protocol-inputs_container protocol-input_description_container">

                    <label for="Nombre" class="protocol-label_description" >Descripción *</label>
                    <input 
                        type="text" 
                        [ngModel] = "protocolNew.description" 
                        class="protocol-inputs protocol-input_description" 
                        formControlName="description"
                        (input)="ProtocolDescriptionChange()" 
                    >
                    <span 
                        class="protocol-form_required_spans" 
                        style="color : red;" 
                        *ngIf="this.protocolForm.get('description')?.errors?.['required']"
                    >
                        Descripción es requerida *
                    </span>

                </div> -->

                <div *ngIf=" isDoctor || isAdmin " class="protocol-search_button_container" >
                    <div class="protocol-favorite_actions_container" style="display:flex;" >
                        <div>
                            <button 
                                [ngClass]="{ 'protocol-not_saved': !protocolSaved }"
                                class="protocol-search_medicament_popup_button"
                                type="submit"
                            >
                                Guardar Cambios
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="protocolCreated" >
        <dx-data-grid
            id="protocolNewMedicamentsGridContainer"
            #protocolMedicamentGridContainer
            class="protocol-table_container"
            [dataSource]="protocolNew.medicaments"
            [allowColumnReordering]="true"
            [showBorders]="true"
            [showRowLines]="false"
            [hoverStateEnabled]="true"
            [showColumnLines]="true"
            [columnAutoWidth]="true"
            (onEditingStart)="logEvent($event,'onEditingStart')"
            (onEditorPreparing)="logEvent($event,'onEditorPreparing')"
            (onInitNewRow)="logEvent($event , 'onInitNewRow')"
            (onToolbarPreparing)="logEvent($event , 'onToolbarPreparing')"
            (onRowInserted)="logEvent($event , 'onRowInserted')"
            (onRowUpdating)="logEvent($event , 'onRowUpdating')"
            (onRowUpdated)="logEvent($event,'onRowUpdated')"
            (onRowRemoving)="logEvent($event , 'onRowRemoving')"
            (onRowRemoved)="logEvent( $event , 'onRowRemoved')"
            (onSaving)="logEvent($event , 'onSaving')"
            (onSaved)="logEvent($event , 'onSaved')"
            (onEditCanceling)="logEvent($event , 'onEditCanceling')"
            (onEditCanceled)="logEvent($event , 'onEditCanceled')"
            (onCellPrepared)="logEvent($event , 'onCellPrepared')"
            (onRowInserting)="OnInsert($event , 'onRowInserting')"
        >
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        
            <dxo-scrolling
                [useNative]="false"
                [scrollByContent]="true"
                [scrollByThumb]="true"
                showScrollbar="onHover"
                columnRenderingMode="virtual"
                
            ></dxo-scrolling>
            
            <dxo-editing
                *ngIf="favoriteParapharmaciesLoaded && favoriteMedicamentsLoaded"
                mode="popup"
                [allowAdding] = "false"
                [allowUpdating]="true"
                [useIcons]="true"
                [allowFixing]="true"
                [hideOnOutsideClick]="true"
            >

                <dxo-popup
                    [showTitle]="false"
                    [width]="1000"
                    [height]="500"
                >
                </dxo-popup>
                
                <dxo-texts
                    saveRowChanges="Guardar"
                >
                </dxo-texts>

                <dxo-texts
                    cancelRowChanges="Cancelar"
                >
                </dxo-texts>

                <dxo-texts confirmDeleteMessage="¿Desea sacar este medicamento de la receta? "></dxo-texts>
                <dxo-texts
                    deleteRow="Si">
                </dxo-texts>
                <dxo-texts 
                    undeleteRow="No">
                </dxo-texts>

                <dxo-form [colCount]="1">

                    <dxi-item *ngIf="!caption && isMedicament" [colCount]="3" [colSpan]="2" itemType="group">
                
                        <dxi-item [colSpan]="2" [label]="{text: 'Medicamento'}"  dataField="fullName" [editorOptions]="{ disabled: true }"></dxi-item>
        
                        <dxi-item 
                            [colSpan]="1"
                            editorType="dxCheckBox" 
                            dataField="favorite"
                        >
                            <dxo-label 
                                location="left"
                                alignment="right"
                                [text]="'Favorito'"
                            >
                            </dxo-label>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item *ngIf="!caption && isParaMedicament " [colCount]="3" [colSpan]="2" itemType="group">
                
                        <dxi-item [colSpan]="2" [label]="{text: 'Medicamento'}"  dataField="fullName" [editorOptions]="{ disabled: false }"></dxi-item>
        
                        <dxi-item 
                            [colSpan]="1"
                            editorType="dxCheckBox" 
                            dataField="favorite"
                        >
                            <dxo-label 
                                location="left"
                                alignment="right"
                                [text]="'Favorito'"
                            >
                            </dxo-label>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item *ngIf="!caption && isMasterFormula " [colCount]="3" [colSpan]="2" itemType="group">
                
                        <dxi-item [colSpan]="1"  dataField="fullName" [editorOptions]="{ disabled: true }">
                            <dxo-label 
                                location="top"
                                alignment="left"
                                [text]="'Composición'"
                            >
                            </dxo-label>
                        </dxi-item>
        
                        <dxi-item 
                            [colSpan]="1" 
                            dataField="pharmaceuticalForm" 
                            editorType="dxSelectBox"
                            [editorOptions]="{
                                items: pharmaceuticalForms,  
                                displayExpr: 'name',  
                                valueExpr: 'name'
                            }"
                        >
                            <dxo-label 
                                location="top"
                                alignment="left"
                                [text]="'Forma Farmacéutica'"
                            >
                            </dxo-label>
                        </dxi-item>
        
                        <dxi-item 
                            [colSpan]="1"
                            editorType="dxCheckBox" 
                            dataField="favorite"
                        >
                            <dxo-label 
                                location="left"
                                alignment="right"
                                [text]="'Favorito'"
                            >
                            </dxo-label>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item *ngIf="!caption && isMedicament" [colSpan]="2" itemType="group">
                        <dxi-item  dataField="pharmacistWarnings" [editorOptions]="{ disabled: true }"></dxi-item>
                    </dxi-item>

                    <dxi-item [colSpan]="2" itemType="group">
                        <dxi-item  dataField="patientInstructions" [editorOptions]="{ disabled: false }"></dxi-item>
                    </dxi-item>
                
                    <dxi-item *ngIf="!caption && (isMedicament || isMasterFormula)" [colCount]="6" [colSpan]="2" itemType="group" caption="Posología">

                        <dxi-item  dataField="takingAmount">
                            <dxo-label [visible]="false" ></dxo-label>
                        </dxi-item>

                        <dxi-item *ngIf="isMedicament" [editorOptions]="{ disabled: true }"  dataField="unit.name">
                            <dxo-label [visible]="false" ></dxo-label>
                        </dxi-item>

                        <dxi-item 
                            *ngIf="isMasterFormula"  
                            dataField="unit.name"
                            editorType="dxSelectBox"
                            [editorOptions]="{
                                items: unitForMagistralFormulas,  
                                displayExpr: 'name',  
                                valueExpr: 'name'
                            }"    
                        > 
                            <dxo-label [visible]="false" ></dxo-label> 
                        </dxi-item>

                        <dxi-item [label]="{ text : 'Cada' , location : 'left' }" dataField="frequencyAmount">

                        </dxi-item>

                        <dxi-item  dataField="frequencyUnit">
                            <dxo-label [visible]="false" ></dxo-label>
                        </dxi-item>

                        <dxi-item [label]="{ text : 'Durante' , location : 'left' }" dataField="durationAmount">

                        </dxi-item>

                        <dxi-item  dataField="durationUnit">
                            <dxo-label [visible]="false" ></dxo-label>
                        </dxi-item>

                    </dxi-item>

                    <dxi-item *ngIf="!caption && isParaMedicament" [colCount]="1" [colSpan]="2" itemType="group" caption="Posología">

                        <dxi-item  dataField="posology">
                            <dxo-label [visible]="false" ></dxo-label>
                        </dxi-item>

                    </dxi-item>

                    <dxi-item *ngIf="!caption && ( isMedicament || isMasterFormula )" [colCount]="2" [colSpan]="2" itemType="group" caption="Detalle del medicamento">
                        
                        <dxi-item  dataField="packagesAmount"></dxi-item>

                        <dxi-item *ngIf="isMedicament"  dataField="unitAmount" [label]="{ text : 'Unidades Por Envase' }" ></dxi-item>

                    </dxi-item>

                </dxo-form>
                
            </dxo-editing>

            <!-- COLUMNS -->

            <dxi-column 
                cellTemplate="kinds"
                [allowFixing]="false" 
                width="50" 
                alignment='left' 
                dataField="kind" 
                caption=""
            >
            </dxi-column>

            <dxi-column 
                width="80" 
                alignment='left' 
                dataField="packagesAmount" 
                caption="Núm.envases/unidades"
            >

                <dxi-validation-rule
                    type="stringLength"
                    [min]="1"
                    message="Especifique cantidad de cajas" >
                </dxi-validation-rule>
            
            </dxi-column>

            <dxi-column dataField="fullName" caption="Medicamento" width="620">
                <dxi-validation-rule
                    type="stringLength"
                    [min]="1"
                    message="Especifique Medicamento" >
                </dxi-validation-rule>
            </dxi-column>

            <dxi-column 
                [allowFixing]="false"
                dataField="favorite" 
                alignment='left' 
                [(visible)]="hiddenRows"
                [allowEditing]="true"
                caption="" 
                width="75"
            >
            </dxi-column>

            <dxi-column 
                [allowFixing]="false"
                dataField="pharmaceuticalForm" 
                alignment='left' 
                [(visible)]="hiddenRows"
                [allowEditing]="true" 
                width="160"
            >
            </dxi-column>

            <dxi-column 
                [allowFixing]="false" 
                dataField="unit.name" 
                alignment='left' 
                caption="Unidad" 
                [(visible)]="hiddenRows"
                [allowEditing]="true"
                width="110"
            ></dxi-column>

            <dxi-column 
                [allowFixing]="false"
                dataField="posology" 
                alignment='left' 
                caption="Posología" 
                width="400"
            >
            </dxi-column>

            <dxi-column 
                [allowFixing]="false"
                dataField="patientInstructions" 
                alignment='left' 
                caption="Instrucciones" 
                width="190"
            ></dxi-column>

            <dxi-column 
                dataField="pharmacistWarnings" 
                alignment='left' 
                caption="Avisos Farmacéuticos" 
                width="190"
            ></dxi-column>

            <dxi-column 
                dataField="takingAmount" 
                alignment='left' 
                caption="Cantidad"
                [(visible)]="hiddenRows"
                [allowEditing]="true"
                [setCellValue]="setPackageAmountFromTakinAmount"
                width="100"
            >
                <dxi-validation-rule
                    type="stringLength"
                    [min]="1"
                    message="Especifique Cantidad" >
                </dxi-validation-rule>
            </dxi-column>

            <dxi-column 
                dataField="unitAmount" 
                alignment='left' 
                caption=""
                [(visible)]="hiddenRows"
                [allowEditing]="true"
                width="100"
            >
            </dxi-column>

            <dxi-column 
                dataField="frequencyAmount" 
                alignment='left' 
                caption="Frecuencia" 
                width="110"
                [setCellValue]="setPackageAmountFromFrecuencyAmount"
                [(visible)]="hiddenRows"
                [allowEditing]="true"
            >
                <dxi-validation-rule
                    type="stringLength"
                    [min]="1"
                    message="Especifique las unidades" >
                </dxi-validation-rule>
            </dxi-column>

            <dxi-column 
                dataField="frequencyUnit" 
                alignment='left' 
                caption="Unidad de Frecuencia" 
                width="160"
                [setCellValue]="setPackageAmountFromFrecuencyUnit"
                [(visible)]="hiddenRows"
                [allowEditing]="true"
            >
                <dxi-validation-rule
                    type="stringLength"
                    [min]="1"
                    message="Especifique cada cuanto tiempo" >
                </dxi-validation-rule>

                <dxo-lookup 
                    [dataSource]="frecuencyUnitTimeDuration"   
                    displayExpr="name" 
                    valueExpr="key"
                >  
                </dxo-lookup>
            </dxi-column>

            <dxi-column 
                dataField="durationAmount" 
                alignment='left' 
                caption="Duración" 
                width="105"
                [(visible)]="hiddenRows"
                [allowEditing]="true"
                [setCellValue]="setPackageAmountdurationAmount"
            >
                <dxi-validation-rule
                    type="stringLength"
                    [min]="1"
                    message="Especifique las unidades" >
                </dxi-validation-rule>
            </dxi-column>

            <dxi-column 
                dataField="durationUnit" 
                alignment='left' 
                caption="Unidad de Duración" 
                [(visible)]="hiddenRows"
                [allowEditing]="true"
                width="160"
                [setCellValue]="setPackageAmountdurationUnit"
            >
                <dxi-validation-rule
                    type="stringLength"
                    [min]="3"
                    message="Especifique franja temporal" >
                </dxi-validation-rule>

                <dxo-lookup 
                    [dataSource]="timeDuration"   
                    displayExpr="name" 
                    valueExpr="key"
                >  
                </dxo-lookup>
            </dxi-column>

            <dxi-column 
                class="protocol-columns_header" 
                caption="" 
                fixed="true"
                fixedPosition="right"
                cellTemplate="delete" 
                dataField="delete_action"
                [allowFiltering]="false"
                width="70"
            ></dxi-column>

            <div *dxTemplate="let acciones of 'delete'">
                <div (click)="DeleteMedicament(acciones)" class="protocol-acciones_icons_container" >
                    <fa-icon class="protocol-acciones-icons" [icon]="faTrash"></fa-icon>
                </div>
            </div>

            <div *dxTemplate="let action of 'kinds'" class="protocol-kind_icons_container" >
                <div *ngIf="action.data.kind === 'paraMedicament'" >
                    <fa-icon [icon]="faCapsules" class="kind_icon"></fa-icon>
                </div>
                <div *ngIf="action.data.kind === 'medicament'" >
                    <fa-icon [icon]="faPrescriptionBottleMedical" class="kind_icon"></fa-icon>
                </div>
                <div *ngIf="action.data.kind === 'masterFormula'" >
                    <fa-icon [icon]="faFlaskVial" class="kind_icon"></fa-icon>
                </div>
            </div>

        </dx-data-grid>
    </div>

    <div>
        <dx-popup
            height="100%"
            width="60%"
            id="medicamentForProtocolPopUp"
            [(visible)]="printMedicamentSearchPopUp"
            [hideOnOutsideClick]="true"
        >
            <app-medicament-search-pop-up
                (SendMedicamentIdArrToDelete)="ReciveMedicamentIdArrToDelete($event)"
                (CloseMedicamentsSearchPopUpEmitter)="CloseMedicamentsSearchPopUp($event)"
            >
            </app-medicament-search-pop-up>  
        </dx-popup>
    </div>

    <dx-popup
        [width]="1000"
        [height]="550"
        [(visible)]="showMagistralFormulaPopUp"
        [hideOnOutsideClick]="true"
    >

        <dxi-toolbar-item
            text="Añadir fórmula magistral"
            location="before">
        </dxi-toolbar-item>

        <app-magistral-formula-pop-up 
            (CloseFormulaMagistralPopUp)="CloseFormulaMagistralPopUp($event)" 
            [whoIsParent]="whoIsParent"
        >
        </app-magistral-formula-pop-up>
    </dx-popup>

    <dx-popup
        [width]="1000"
        [height]="400"
        id="paramedicamentCreateFormPopUp"
        [(visible)]="printParaMedicamentCreateFormPopUp"
        [hideOnOutsideClick]="true"
        
    >   <!-- (onHiding)="onHiding($event)" -->
        <dxi-toolbar-item
            text="Añadir Paramedicamento"
            location="before">
        </dxi-toolbar-item>

        <app-parapharmacy-medicament-popup 
            (CloseParapharmacyPopUp)="CloseParapharmacyPopUp($event)" 
            [whoIsParent]="iAmParent"
        >
        </app-parapharmacy-medicament-popup>

    </dx-popup>
    
    <dx-popup
        height="300px"
        width="30%"
        minWidth="300px"
        [(visible)]="printDeletePopUp"
        [hideOnOutsideClick]="true"
    >
        <app-delete-warning-pop-up-content 
            (CloseDeleteProtocolPopUp)="CloseDeleteProtocolPopUp($event)"
            (CloseDeleteIndicationPopUp)="CloseDeleteIndicationPopUp($event)"
            (CloseDeletePopUp)="CloseDeletePopUp($event)"  
            (CloseDeleteParaMedicamentPopUp)="CloseDeleteParaMedicamentPopUp($event.id)"
            (CloseDeleteMasterFormulaPopUp)="CloseDeleteMasterFormulaPopUp($event)"
        >
        </app-delete-warning-pop-up-content>
    </dx-popup>

    <dx-popup
        height="700px"
        width="50%"
        minWidth="300px"
        [(visible)]="printMedicalIndicationPopUp"
        [hideOnOutsideClick]="true"
    >
       <app-protocol-medical-indication-pop-up></app-protocol-medical-indication-pop-up>
    </dx-popup>

    <!-- [width]="1200"
        [height]="800" -->
    
    <div *ngIf="windowWidth > mobileViewBreakPoint">
        <dx-popup
            width="60%"
            height="100%"
            [(visible)]="favoritesMedicamentsPopUp"
            [hideOnOutsideClick]="true"
        >
            <dxi-toolbar-item
                text="Mis favoritos"
                location="before">
            </dxi-toolbar-item>
            <app-favorites-medicaments-pop-up 
                [whoIsParent]="{from:'protocol' , parentId:protocolNew.id}"
                (SendMedicamentFromPopUpFavoritetoAdd)="SendMedicamentFromPopUpFavoritetoAdd($event)" 
                (SendMedicamentFavoriteIdArrToDelete)="SendMedicamentFavoriteIdArrToDelete($event)"
                (SendParapharmacyFavoriteIdArrToDelete)="DeleteParapharmacy($event)"
                (SendMasterFormulaFavoriteIdArrToDelete)="DeleteMasterFormula($event)"
            >
            </app-favorites-medicaments-pop-up>
        </dx-popup>
    </div>

    <div *ngIf="windowWidth < mobileViewBreakPoint">
        <dx-popup
            width="95%"
            maxWidth="700px"
            height="100%"
            [(visible)]="favoritesMedicamentsPopUp"
            [hideOnOutsideClick]="true"
        >
            <dxi-toolbar-item
                text="Mis favoritos"
                location="before">
            </dxi-toolbar-item>
            <app-favorites-medicaments-pop-up 
                [whoIsParent]="{from:'protocol' , parentId:protocolNew.id}"
                (SendMedicamentFromPopUpFavoritetoAdd)="SendMedicamentFromPopUpFavoritetoAdd($event)" 
                (SendMedicamentFavoriteIdArrToDelete)="SendMedicamentFavoriteIdArrToDelete($event)"
                (SendParapharmacyFavoriteIdArrToDelete)="DeleteParapharmacy($event)"
                (SendMasterFormulaFavoriteIdArrToDelete)="DeleteMasterFormula($event)"
            >
            </app-favorites-medicaments-pop-up>
        </dx-popup>
    </div>

    <app-spinner></app-spinner>
    
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/* fontawesome */
import { faFilter , faPlus , IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppState, PrescriptionsFilters, UserInfo } from 'src/app/models';
import { CheckUserRoleService } from 'src/app/shared/services';

@Component({
  selector: 'app-mobile-prescriptions-filters',
  templateUrl: './mobile-prescriptions-filters.component.html',
  styleUrls: ['./mobile-prescriptions-filters.component.scss']
})

export class MobilePrescriptionsFiltersComponent implements OnInit , OnDestroy {

  faFilter:                                                   IconDefinition=faFilter;
  faPlus:                                                     IconDefinition=faPlus;
  printMobilePrescriptionsPopUp=                              false;
  showNewPrescriptionButton=                                  false;
  userDestroyed$:                                             Subject<void> = new Subject<void>();
  prescriptionsFiltersDestroyed$:                             Subject<void> = new Subject<void>();
  isDoctor=                                                   false;
  isAdmin=                                                    false;
  isNurse=                                                    false;
  professionalId:                                             string | undefined = "";
  prescriptionsFilters:                                       PrescriptionsFilters = {} 

  constructor(
    private router:                                           Router,
    private store:                                            Store<AppState>,
    private checkUserRoleService:                             CheckUserRoleService
  ){}

  ngOnInit(): void {
    
    this.GetProfessionalId();
    this.GetPrescriptionsFilters();
  }

  GetPrescriptionsFilters():void{

    this.store.select( AppState => AppState.PrescriptionsFilters ).pipe( takeUntil( this.prescriptionsFiltersDestroyed$ ) ).subscribe(( filters : PrescriptionsFilters ) => {

      this.prescriptionsFilters = { ...filters };

    })
  }

  GetProfessionalId():void{

    this.store.select(appState => appState.User).pipe(takeUntil( this.userDestroyed$ )).subscribe((user : UserInfo) => {
      const { professionalLoggedId } = user || {};

      if( professionalLoggedId ){

        if( professionalLoggedId === 'first_assignement' ){

          this.showNewPrescriptionButton = false;

          this.professionalId = undefined;

        } else {

          this.showNewPrescriptionButton = true;

          this.professionalId = professionalLoggedId;
          
        }

      }else{
        this.showNewPrescriptionButton = true;
        this.professionalId = undefined;
      }

      this.CheckUserRole();
    })
  }

  CheckUserRole():void{

    const roles = this.checkUserRoleService.CheckUserRole();

    const {isDoctor , isAdmin , isNurse} = roles || {};

    if( isDoctor )this.isDoctor = isDoctor;
    if( isNurse )this.isNurse = isNurse;
    if( isAdmin )this.isAdmin = isAdmin;
  }

  OpenMobileFiltersPopUp():void{

    this.printMobilePrescriptionsPopUp = true;
  }

  GoToPrescriptionNew():void{

    this.router.navigate([
      'prescriptions/new/' , {professionalId:this.professionalId} 
    ]);
  }

  ngOnDestroy(): void {
    
    this.prescriptionsFiltersDestroyed$.next();
    this.prescriptionsFiltersDestroyed$.complete();
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
  }
}


<div class="prescriptions_new_table-desktop_view_main_container">
    
    <!----------------------------------------------------------------- WEB BUTTONS > 1192px resolution ----------------------------------------------------------->
    <div class="prescription_new_table-web_view_buttons_actions_main_container">

        <div *ngIf="isDoctor && isDraft && !existsPsycotropic" class="prescription_new_table-buscar_main_container" >
            <div (click)="OpenMedicamentSearchPopUp()" class="prescription_new_table-buscar_button" >
                <fa-icon [icon]="faPrescriptionBottleMedical"></fa-icon>
                <button 
                    class="prescription_new_table-buscar_button"
                >
                    Añadir Medicamento
                </button>
            </div>
        </div>

        <div 
            *ngIf=" isDoctor && isDraft && existsPsycotropic" 
            class="prescription_new_table-buscar_main_container"
            (click)="ShowPsicotropicAlert()"
        >
            <div class="prescription_new_table-buscar_button_disabled" >
                <fa-icon [icon]="faPrescriptionBottleMedical"></fa-icon>
                <button 
                    class="prescription_new_table-buscar_button_disabled"
                >
                    Añadir Medicamento
                </button>
            </div>
        </div>

        <div *ngIf="isDoctor && isDraft && !existsPsycotropic" class="prescription_new_table-buscar_main_container" >
            <div (click)="OpenCreateMasterFormula()" class="prescription_new_table-buscar_button" >
                <fa-icon [icon]="faFlaskVial"></fa-icon>
                <button 
                    class="prescription_new_table-buscar_button"
                >
                    Añadir Fórmula magistral
                </button>
            </div>
        </div>

        <div *ngIf="isDoctor && isDraft && existsPsycotropic" class="prescription_new_table-buscar_main_container" (click)="ShowPsicotropicAlert()" >
            <div class="prescription_new_table-buscar_button_disabled" >
                <fa-icon [icon]="faFlaskVial"></fa-icon>
                <button 
                    class="prescription_new_table-buscar_button_disabled"
                >
                    Añadir Fórmula magistral
                </button>
            </div>
        </div> 

        <div *ngIf="isDoctor && isDraft && !existsPsycotropic" class="prescription_new_table-buscar_main_container" >
            <div (click)="OpenParaMedicamentSearchPopUp()" class="prescription_new_table-buscar_button" >
                <fa-icon [icon]="faCapsules"></fa-icon>
                <button 
                    class="prescription_new_table-buscar_button"
                >
                    Añadir Parafarmacia
                </button>
            </div>
        </div>

        <div 
            *ngIf=" isDoctor && isDraft && existsPsycotropic" 
            class="prescription_new_table-buscar_main_container"
            (click)="ShowPsicotropicAlert()"
        >
            <div class="prescription_new_table-buscar_button_disabled" >
                <fa-icon [icon]="faCapsules"></fa-icon>
                <button 
                    class="prescription_new_table-buscar_button_disabled"
                >
                    Añadir Parafarmacia
                </button>
            </div>
        </div>

        <div *ngIf="isDoctor && isDraft && !existsPsycotropic" class="prescription_new_table-buscar_main_container" >
            <div (click)="OpenMyFavoritesPopUp()" class="prescription_new_table-buscar_button" >
                <fa-icon [icon]="faStar"></fa-icon>
                <button 
                    class="prescription_new_table-buscar_button"
                >
                    Desde mis favoritos
                </button>
            </div>
        </div>

        <div 
            *ngIf=" isDoctor && isDraft && existsPsycotropic" 
            class="prescription_new_table-buscar_main_container"
            (click)="ShowPsicotropicAlert()"
        >
            <div class="prescription_new_table-buscar_button_disabled" >
                <fa-icon [icon]="faStar"></fa-icon>
                <button 
                    class="prescription_new_table-buscar_button_disabled"
                >
                    Desde mis favoritos
                </button>
            </div>
        </div>
        
        <div *ngIf="isDraft && ( isDoctor || isAdmin ) && !existsPsycotropic " (click)="ShowMedicamentTemplate()" 
            class=" prescription_new_table-template_button_container " >
            <div>
                <fa-icon [icon]="faPlus"></fa-icon>
            </div>
            <div>
                <span class="prescription_new_table-template_span" >
                    Aplicar plantilla de medicamentos
                </span>
            </div>
        </div>

        <div *ngIf="isDraft && ( isDoctor || isAdmin ) && existsPsycotropic "
            (click)="ShowPsicotropicAlert()" 
            class=" prescription_new_table-template_button_container_disabled " >
            <div>
                <fa-icon [icon]="faPlus"></fa-icon>
            </div>
            <div>
                <span class="prescription_new_table-template_span" >
                    Aplicar plantilla de medicamentos
                </span>
            </div>
        </div>

        <dx-popup
            height="100%"
            width="60%"
            id="protocolPopUp"
            [(visible)]="printMedicamentSearchPopUp"
            [hideOnOutsideClick]="true"
            (onHiding)="onHiding($event)"
            [showCloseButton]="true"
            [showTitle]="true"
        >
            <dxi-toolbar-item
                text="Buscar Medicamento"
                location="before">
            </dxi-toolbar-item>

            <app-medicament-search-pop-up 
                (SendMedicamentIdArrToDelete)="ReciveMedicamentIdArrToDelete($event)"
                (CloseMedicamentsSearchPopUpEmitter)="CloseMedicamentsSearchPopUp($event)" 
                class="medicament-popup-component" 
            ></app-medicament-search-pop-up>  
        </dx-popup>

        <dx-popup
            height="100%"
            width="30%"
            minWidth="300px"
            id="protocolPopUp"
            [(visible)]="printPopUp"
            [hideOnOutsideClick]="true"
            [showCloseButton]="true"
            [showTitle]="true"
            [hideOnOutsideClick]="true"
        >

            <dxo-position
                my="right top"
                at="right top"
            >
            </dxo-position>

            <dxi-toolbar-item
                text="Protocolos"
                location="before">
            </dxi-toolbar-item>

            <div *ngIf="windowWidth > mobileViewBreakPoint">
                <app-medicaments-templates-pop-up 
                    (ClosePopUp)="ClosePopUp($event)"
                ></app-medicaments-templates-pop-up>
            </div>

              
        </dx-popup>

        <dx-popup
            height="300px"
            width="30%"
            minWidth="300px"
            [(visible)]="printDeletePopUp"
            [hideOnOutsideClick]="true"
        >
            <app-delete-warning-pop-up-content 
                (CloseDeletePopUp)="CloseDeletePopUp($event)"  
                (CloseDeleteMasterFormulaPopUp)="CloseDeleteMasterFormulaPopUp($event)"
                (CloseDeleteParaMedicamentPopUp)="CloseDeleteParaMedicamentPopUp($event)"
            >
            </app-delete-warning-pop-up-content>
        </dx-popup>

        
        <dx-popup
            [width]="1000"
            [height]="550"
            [(visible)]="showMagistralFormulaPopUp"
            [hideOnOutsideClick]="true"
        >

            <dxi-toolbar-item
                text="Añadir fórmula magistral"
                location="before">
            </dxi-toolbar-item>

            <app-magistral-formula-pop-up 
                (CloseFormulaMagistralPopUp)="CloseFormulaMagistralPopUp($event)" 
                [whoIsParent]="whoIsParent"
            >
            </app-magistral-formula-pop-up>
        </dx-popup>

        
        <dx-popup
            [width]="1200"
            [height]="800"
            [(visible)]="favoritesMedicamentsPopUp"
            [hideOnOutsideClick]="true"
        >
            <dxi-toolbar-item
                text="Mis favoritos"
                location="before">
            </dxi-toolbar-item>
            <app-favorites-medicaments-pop-up 
                [whoIsParent]="{from:'prescription' , parentId:prescriptionId}" 
                (SendMasterFormulaFavoriteIdArrToDelete)="DeleteMasterFormula($event)"
                (SendMedicamentFavoriteIdArrToDelete)="SendMedicamentFavoriteIdArrToDelete($event)"
                (SendMedicamentFromPopUpFavoritetoAdd)="SendMedicamentFromPopUpFavoritetoAdd($event)" 
                (SendParapharmacyFavoriteIdArrToDelete)="DeleteParapharmacy($event)"
            >
            </app-favorites-medicaments-pop-up>
        </dx-popup>

        <dx-popup
            [width]="1000"
            [height]="600"
            [(visible)]="showParapharmacyPopUp"
            [hideOnOutsideClick]="true"
        >

            <dxi-toolbar-item
                text="Añadir Paramedicamento"
                location="before">
            </dxi-toolbar-item>

            <app-parapharmacy-medicament-popup 
                (CloseParapharmacyPopUp)="CloseParapharmacyPopUp($event)" 
                [whoIsParent]="iAmParent"
            >
            </app-parapharmacy-medicament-popup>
        </dx-popup>

    </div>

    <dx-data-grid
        #prescriptionNewGridContainer
        id="prescriptionNewGridContainer"
        class="prescription_new_table-table_container"
        [hoverStateEnabled]="true"
        [dataSource]="dataSource"
        [allowColumnReordering]="true"
        [showRowLines]="false"
        [showColumnLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        (onEditorPreparing)="logEvent($event,'onEditorPreparing')"
        (onContentReady)="logEvent($event,'onContentReady')"
        (onEditingStart)="logEvent($event,'onEditingStart')"
        (onInitNewRow)="logEvent($event , 'onInitNewRow')"
        (onRowInserted)="logEvent($event , 'onRowInserted')"
        (onRowUpdating)="logEvent($event , 'onRowUpdating')"
        (onRowUpdated)="logEvent($event,'onRowUpdated')"
        (onRowRemoving)="logEvent($event , 'onRowRemoving')"
        (onToolbarPreparing)="logEvent($event , 'onToolbarPreparing')"
        (onRowRemoved)="logEvent( $event , 'onRowRemoved')"
        (onSaving)="logEvent($event , 'onSaving')"
        (onSaved)="logEvent($event , 'onSaved')"
        (onEditCanceling)="logEvent($event , 'onEditCanceling')"
        (onEditCanceled)="logEvent($event , 'onEditCanceled')"
        (onCellPrepared)="logEvent($event , 'onCellPrepared')"
        width="100%"
    >

        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxo-paging [pageSize]="7"> </dxo-paging>

        <dxo-scrolling
            [useNative]="false"
            [scrollByContent]="true"
            [scrollByThumb]="true"
            showScrollbar="onHover"
            columnRenderingMode="virtual"
        ></dxo-scrolling>

        <dxi-column 
            [cssClass]="'prescription_new_table-icon_column'"
            cellTemplate="kinds"
            [allowFixing]="false" 
            width="50" 
            alignment='left' 
            dataField="kind" 
            caption=""
        >
        </dxi-column>

        <dxi-column 
            *ngIf="!existsPsycotropic"
            [allowFixing]="false" 
            width="45" 
            alignment='left' 
            dataField="packagesAmount" 
            caption="Envases"
        >
            
        </dxi-column>

        <dxi-column 
            *ngIf="existsPsycotropic"
            [allowFixing]="false" 
            width="165" 
            alignment='left' 
            dataField="packagesAmount" 
            caption="Núm.envases/unidades"
        >
            <dxi-validation-rule
                type="range"
                [max]="4"
                message="La cantidad máxima de envases para medicamento psicotrópico o estupefaciente es de 4" >
            </dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="fullName" 
            alignment='left' 
            caption="Medicamento / Composición" 
            width="400"
        >
            <dxi-validation-rule
                type="stringLength"
                [min]="1"
                message="Especifique Medicamento" >
            </dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="posology" 
            alignment='left' 
            caption="Posología" 
            width="350"
        >
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="patientInstructions" 
            alignment='left' 
            caption="Instrucciones" 
            width="190"
        ></dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="pharmacistWarnings" 
            alignment='left' 
            caption="Avisos Farmacéuticos" 
            width="190"
        ></dxi-column>

        <dxi-column
            [allowFixing]="false" 
            dataField="takingAmount" 
            alignment='left' 
            caption="Cantidad"
            [(visible)]="hiddenRows"
            [allowEditing]="true"
            [setCellValue]="setPackageAmountFromTakinAmount" 
            width="75"
        >
            <dxi-validation-rule
                type="stringLength"
                [min]="1"
                message="Especifique Cantidad" >
            </dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            [allowFixing]="false" 
            dataField="unit.name" 
            alignment='left' 
            caption="Unidad" 
            [(visible)]="hiddenRows"
            [allowEditing]="true"
            width="110"
        ></dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="frequencyAmount" 
            alignment='left' 
            caption="Frecuencia"
            [(visible)]="hiddenRows"
            [allowEditing]="true" 
            [setCellValue]="setPackageAmountFromFrecuencyAmount"
            width="90"
        >
            <dxi-validation-rule
                type="stringLength"
                [min]="1"
                message="Especifique las unidades" >
            </dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="frequencyUnit" 
            alignment='left' 
            caption="Unidad de Frecuencia"
            [(visible)]="hiddenRows"
            [allowEditing]="true"
            [setCellValue]="setPackageAmountFromFrecuencyUnit" 
            width="160"
        >
            <dxi-validation-rule
                type="stringLength"
                [min]="1"
                message="Especifique cada cuanto tiempo" >
            </dxi-validation-rule>

            <dxo-lookup 
                [dataSource]="frecuencyUnitTimeDuration"   
                displayExpr="name" 
                valueExpr="key"
            >  
            </dxo-lookup>
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="durationAmount" 
            alignment='left' 
            [setCellValue]="setEndDateDependingAmount"
            [(visible)]="hiddenRows"
            [allowEditing]="true"
            caption="Duración" 
            width="75"
        >
            <dxi-validation-rule
                type="stringLength"
                [min]="1"
                message="Especifique las unidades" >
            </dxi-validation-rule>
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="unitAmount" 
            alignment='left' 
            [(visible)]="hiddenRows"
            [allowEditing]="true"
            caption="" 
            width="75"
        >
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="favorite" 
            alignment='left' 
            [(visible)]="hiddenRows"
            [allowEditing]="true"
            caption="" 
            width="75"
        >
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="durationUnit" 
            alignment='left' 
            [setCellValue]="setEndDateDependingDurationUnit"
            [(visible)]="hiddenRows"
            [allowEditing]="true"
            caption="Unidad de Duración" 
            width="160"
        >
            <dxi-validation-rule
                type="stringLength"
                [min]="3"
                message="Especifique franja temporal" >
            </dxi-validation-rule>

            <dxo-lookup 
                [dataSource]="timeDuration"   
                displayExpr="name" 
                valueExpr="key"
            >  
            </dxo-lookup>
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="administrationWay" 
            alignment='left' 
            [(visible)]="hiddenRows"
            [allowEditing]="true" 
            width="160"
        >
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="pharmaceuticalForm" 
            alignment='left' 
            [(visible)]="hiddenRows"
            [allowEditing]="true" 
            width="160"
        >
        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="startDate" 
            [setCellValue]="setEndDateValue"
            alignment='left' 
            [width]="100" 
            caption="Inicio" 
            dataType="date" 
            format="dd/MM/yyyy"
            [editorOptions]="{ min : today }"
        >

            <dxi-validation-rule
                type="stringLength"
                [min]="9"
                message="Seleccione Fecha de Inicio" >
            </dxi-validation-rule>

        </dxi-column>

        <dxi-column 
            [allowFixing]="false"
            dataField="endDate"  
            alignment='left' 
            [width]="100"
            caption="Fin"
            [setCellValue]="setPackageAmountAndDurationAmountFromEndDate" 
            dataType="date" 
            format="dd/MM/yyyy"
            [editorOptions]="{ min : selectedStartDate , max : limitEndDate }"
        >
            <dxi-validation-rule
                [reevaluate]="true"
                type="stringLength"
                [min]="9"
                message="Seleccione Fecha de Fin" >
            </dxi-validation-rule>
        </dxi-column>

        <dxi-column
            *ngIf="isDraft"
            class="prescriptions_table-columns_header" 
            fixed="true"
            width="35"
            fixedPosition="right"
            caption="" 
            cellTemplate="delete" 
            dataField="delete_action"
            [allowFiltering]="false"
        ></dxi-column>

        <dxi-column
            *ngIf="!isDraft"
            class="prescriptions_table-columns_header" 
            fixed="true"
            width="145"
            fixedPosition="right"
            caption="" 
            cellTemplate="delete" 
            dataField="delete_action"
            [allowFiltering]="false"
        ></dxi-column>
        
        <!-- favoriteMedicamentsLoaded &&  -->
        
        <dxo-editing
            *ngIf="isDoctor && patientPrescriptionsRecordLoaded &&  favoriteParapharmaciesLoaded && favoriteMasterFormulasLoaded "
            mode="popup"
            [allowUpdating]="isDraft"
            [allowAdding]="false"
            fixed="true"
            fixedPosition="right"
            [useIcons]="true"
        >

            <dxo-popup
                [showTitle]="false"
                [width]="1000"
                [height]="600"
            >
            </dxo-popup>
            
            <dxo-form colCount="2" labelLocation="top" [showColonAfterLabel]="true" [labelMode]="floating" >
                
                <app-alert></app-alert>
                    <dxi-item *ngIf="isMedicament || isMasterFormula" [colSpan]="2" itemType="group">
                        <dxo-label 
                            class="prescriptions_new_table-limitDaysMessages" 
                            [text]=" 'Días disponibles para prescribir este medicamento : ' + limitDaysMessage.toString()"
                        >
                        </dxo-label>
                    </dxi-item>
        
                    <dxi-item *ngIf="ShowWrongEndDateAlert" [colSpan]="2" itemType="group">
                        <dxo-label 
                            class="prescriptions_new_table-limitDaysMessages" 
                            [text]="' ATENCIÓN , CANTIDAD SELECCIONADA SUPERA EN DÍAS EL LÍMITE PARA ESTE MEDICAMENTO '"
                            backgroundColor="blue"
                        >
                        </dxo-label>
                    </dxi-item>
        
                    <dxi-item *ngIf="!caption && isMedicament" [colCount]="3" [colSpan]="2" itemType="group">
                        
                        <dxi-item [colSpan]="2" [label]="{text: 'Medicamento'}"  dataField="fullName" [editorOptions]="{ disabled: true }"></dxi-item>
        
                        <dxi-item 
                            [colSpan]="1"
                            editorType="dxCheckBox" 
                            dataField="favorite"
                        >
                            <dxo-label 
                                location="left"
                                alignment="right"
                                [text]="'Favorito'"
                            >
                            </dxo-label>
                        </dxi-item>
                    </dxi-item>
        
                    <dxi-item *ngIf="!caption && isParaMedicament " [colCount]="3" [colSpan]="2" itemType="group">
                        
                        <dxi-item [colSpan]="2" [label]="{text: 'Medicamento'}"  dataField="fullName" [editorOptions]="{ disabled: false }"></dxi-item>
        
                        <dxi-item 
                            [colSpan]="1"
                            editorType="dxCheckBox" 
                            dataField="favorite"
                        >
                            <dxo-label 
                                location="left"
                                alignment="right"
                                [text]="'Favorito'"
                            >
                            </dxo-label>
                        </dxi-item>
                    </dxi-item>
        
                    <dxi-item *ngIf="!caption && isMasterFormula " [colCount]="3" [colSpan]="2" itemType="group">
                        
                        <dxi-item [colSpan]="1" [label]="{text: 'Composición'}"  dataField="fullName" [editorOptions]="{ disabled: true }"></dxi-item>
        
                        <dxi-item 
                            [colSpan]="1" [label]="{text: 'Forma Farmacéutica'}"  
                            dataField="pharmaceuticalForm" 
                            editorType="dxSelectBox"
                            [editorOptions]="{
                                items: pharmaceuticalForms,  
                                displayExpr: 'name',  
                                valueExpr: 'name'
                            }"
                        >
                        </dxi-item>
        
                        <dxi-item 
                            [colSpan]="1"
                            editorType="dxCheckBox" 
                            dataField="favorite"
                        >
                            <dxo-label 
                                location="left"
                                alignment="right"
                                [text]="'Favorito'"
                            >
                            </dxo-label>
                        </dxi-item>
                    </dxi-item>
        
                    <!-- <dxi-item *ngIf="!caption && isParaMedicament" [colCount]="1" [colSpan]="2" itemType="group">
                        <dxi-item [colSpan]="2"  dataField="fullName" [editorOptions]="{ disabled: true }"></dxi-item>
                    </dxi-item> -->
        
                    <dxi-item *ngIf="!caption && isMedicament" [colSpan]="2" itemType="group">
                        <dxi-item  dataField="pharmacistWarnings" [editorOptions]="{ disabled: true }"></dxi-item>
                    </dxi-item>
        
                    <dxi-item [colSpan]="2" itemType="group">
                        <dxi-item  dataField="patientInstructions" [editorOptions]="{ disabled: false }"></dxi-item>
                    </dxi-item>
        
                    <dxi-item *ngIf="isMedicament || isMasterFormula" [colCount]="6" [colSpan]="2" itemType="group" caption="Posología">
        
                        <dxi-item  dataField="takingAmount"> 
                            <dxo-label [visible]="false" ></dxo-label> 
                        </dxi-item>
        
                        <dxi-item *ngIf="isMedicament"  dataField="unit.name"> 
                            <dxo-label [visible]="false" ></dxo-label> 
                        </dxi-item>
        
                        <dxi-item 
                            *ngIf="isMasterFormula"  
                            dataField="unit.name"
                            editorType="dxSelectBox"
                            [editorOptions]="{
                                items: unitForMagistralFormulas,  
                                displayExpr: 'name',  
                                valueExpr: 'name'
                            }"    
                        > 
                            <dxo-label [visible]="false" ></dxo-label> 
                        </dxi-item>
        
                        <dxi-item [label]="{ text : 'Cada' , location : 'left' }"  dataField="frequencyAmount"></dxi-item>
        
                        <dxi-item  dataField="frequencyUnit">
                            <dxo-label [visible]="false" ></dxo-label>
                        </dxi-item>
        
                        <dxi-item [label]="{ text : 'Durante' , location : 'left' }" dataField="durationAmount">
        
                        </dxi-item>
        
                        <dxi-item  dataField="durationUnit">
                            <dxo-label [visible]="false" ></dxo-label>
                        </dxi-item>
        
                    </dxi-item>
        
                    <dxi-item *ngIf="isParaMedicament" [colCount]="1" [colSpan]="2" itemType="group" caption="Posología">
        
                        <dxi-item  dataField="posology"></dxi-item>
        
                    </dxi-item>
        
                    <dxi-item *ngIf="isMedicament || isMasterFormula" [colCount]="3" [colSpan]="2" itemType="group" caption="Detalle del medicamento">
                        <dxi-item  dataField="packagesAmount"></dxi-item>
                        <dxi-item  dataField="startDate"></dxi-item>
                        <dxi-item  dataField="endDate"></dxi-item>
                    </dxi-item>
        
                    <dxi-item *ngIf="isParaMedicament" [colCount]="2" [colSpan]="2" itemType="group" caption="Detalle del medicamento">
                        <dxi-item  dataField="startDate"></dxi-item>
                        <dxi-item  dataField="endDate"></dxi-item>
                    </dxi-item>
            
            </dxo-form>

            <dxo-texts
                saveRowChanges="Guardar"
            >
            </dxo-texts>

            <dxo-texts
                cancelRowChanges="Cancelar"
            >
            </dxo-texts>

        </dxo-editing>

        <!-- kinds -->
        <div *dxTemplate="let action of 'kinds'" class="prescriptions_new_table-kind_icons_container" >
            <div *ngIf="action.data.kind === 'paraMedicament'" >
                <fa-icon [icon]="faCapsules" class="kind_icon"></fa-icon>
            </div>
            <div *ngIf="action.data.kind === 'medicament'" >
                <fa-icon [icon]="faPrescriptionBottleMedical" class="kind_icon"></fa-icon>
            </div>
            <div *ngIf="action.data.kind === 'masterFormula'" >
                <fa-icon [icon]="faFlaskVial" class="kind_icon"></fa-icon>
            </div>
        </div>

        <div *dxTemplate="let acciones of 'delete'">
        
            <div 
                *ngIf=" ( prescriptionStatus === 'draft' || prescriptionStatus === 'Draft' || prescriptionStatus === 'manual'  || prescriptionStatus === 'Manual' || prescriptionStatus === 'MasterFormula' ) &&
                ( acciones.data.status === 'DispensableInFuture' || acciones.data.status === 'dispensableInFuture' || acciones.data.status === 'Dispensable' || acciones.data.status === 'dispensable' )" 
                (click)="DeletePrescriptionMedicament(acciones)" 
                class="prescriptions_new_table-acciones_icons_container" 
            >
                <fa-icon class="prescriptions_new_table-acciones-icons" [icon]="faTrash"></fa-icon>
            </div>

            <div 
                *ngIf="
                ( prescriptionStatus !== 'draft' && prescriptionStatus !== 'Draft' && prescriptionStatus !== 'manual' && prescriptionStatus !== 'Manual' && prescriptionStatus !== 'MasterFormula' ) && 
                ( acciones.data.status !== 'Dispensed' && acciones.data.status !== 'dispensed' ) &&
                ( acciones.data.status !== 'Deleted' && acciones.data.status !== 'deleted' ) " 
                class="prescriptions_new_table-acciones_icons_container" 
            >
                <dx-button
                    stylingMode="contained"
                    text="Anular"
                    type="danger"
                    [width]="120"
                    (onClick)="DeletePrescriptionMedicament(acciones)"
                >
                </dx-button>
            
            </div>

            <div 
                *ngIf="acciones.data.status === 'Dispensed' || acciones.data.status === 'dispensed'" 
                class="prescriptions_new_table-acciones_icons_container"
            >
                <dx-button
                    disabled="true"
                    stylingMode="contained"
                    text="Dispensado"
                    type="success"
                    [width]="120"
                >
                </dx-button>
            </div>

            <div 
                *ngIf="acciones.data.status === 'Deleted' || acciones.data.status === 'deleted'" 
                class="prescriptions_new_table-acciones_icons_container" 
            >
                <dx-button
                    disabled="true"
                    stylingMode="contained"
                    text="Anulado"
                    type="danger"
                    [width]="120"
                    (onClick)="DeletePrescriptionMedicament(acciones)"
                >
                </dx-button>
            </div>
        </div>

    </dx-data-grid>
</div>

<div *ngIf="prescriptionId && isDoctor" class="prescriptions_new_table-mobile_view_main_container">

    <div *ngIf="isDraft">
        <div class="dx-fieldset" >
    
            <div class="dx-field"  >
                
                <div  class="dx-field-value prescriptions_new_table-mobile_selector_paciente_container">
    
                    <div class="dx-field-label prescriptions_new_table-mobile_crossed_labels">Nuevo medicamento</div>
    
                    <dx-select-box
                        
                        class="prescriptions_new_table-mobile_dx_select_box "
                        [dataSource]="medicamentKindfromUngroupedData"
                        valueExpr="key"
                        [grouped]="true"
                        displayExpr="name"
                        [showDropDownButton]="true"
                        [searchEnabled]="false"
                        groupTemplate="medicamentKinds"
                        [value]="medicamentKindSelected"
                        (onValueChanged)="SelectMedicamentKind($event)"
                    >
                    
                        <div *dxTemplate="let medicamentKind of 'medicamentKinds'">
                            {{ medicamentKind.name }} 
                        </div>
                        
                    </dx-select-box>
    
                </div>
            </div>
        </div>
    </div>
    
    <div 
        *ngIf="isDoctor && isDraft && !existsPsycotropic && showMedicamentSearchButton" 
        (click)="OpenMedicamentSearchPopUp()" 
        class="prescriptions_new_table-mobile_search_medicament_popup_open_button"
    >
        Buscar Medicamento
    </div>

    <div 
        *ngIf="isDoctor && isDraft && !existsPsycotropic && showFavoriteSearchButton" 
        (click)="OpenFavoriteSearchPopUp()" 
        class="prescriptions_new_table-mobile_search_medicament_popup_open_button"
    >
        Buscar Favorito
    </div>

    <div 
        *ngIf="isDoctor && isDraft && !existsPsycotropic && showProtocolSearchButton" 
        (click)="ShowMedicamentTemplate()" 
        class="prescriptions_new_table-mobile_search_medicament_popup_open_button"
    >
        Buscar Protocolo
    </div>

    <dx-popup
        height="100%"
        width="90%"
        id="protocolPopUp"
        [(visible)]="printMedicamentSearchPopUp"
        [hideOnOutsideClick]="true"
        (onHiding)="onHiding($event)"
        [showCloseButton]="true"
        [showTitle]="true"
    >
        <dxi-toolbar-item
            text="Buscar Medicamento"
            location="before">
        </dxi-toolbar-item>

        <app-medicament-search-pop-up 
            (SendMedicamentIdArrToDelete)="ReciveMedicamentIdArrToDelete($event)"
            (CloseMedicamentsSearchPopUpEmitter)="CloseMedicamentsSearchPopUp($event)" 
            class="medicament-popup-component" 
        ></app-medicament-search-pop-up>  
    </dx-popup>

    <dx-popup
        height="100%"
        width="70%"
        minWidth="300px"
        [showTitle]="true"
        id="protocolPopUp"
        [(visible)]="printPopUp"
        [showCloseButton]="true"
        [hideOnOutsideClick]="true"
    >

        <dxo-position
            my="right top"
            at="right top"
        >
        </dxo-position>

        <dxi-toolbar-item
            text="Protocolos"
            location="before">
        </dxi-toolbar-item>

        <div *ngIf="windowWidth <= mobileViewBreakPoint">
            <app-medicaments-templates-pop-up 
                (ClosePopUp)="ClosePopUp($event)"
            ></app-medicaments-templates-pop-up>
        </div>

          
    </dx-popup>

    <dx-popup
        width="90%"
        height="90%"
        [(visible)]="favoritesMedicamentsPopUp"
        [hideOnOutsideClick]="true"
        [showCloseButton]=" windowWidth <= mobileViewBreakPoint ? true : false"
        [showTitle]=" windowWidth <= mobileViewBreakPoint ? true : false "
    >
        <dxi-toolbar-item
            text="Mis favoritos"
            location="before">
        </dxi-toolbar-item>
        <app-favorites-medicaments-pop-up 
            [whoIsParent]="{from:'prescription' , parentId:prescriptionId}" 
            (SendMasterFormulaFavoriteIdArrToDelete)="DeleteMasterFormula($event)"
            (SendMedicamentFavoriteIdArrToDelete)="SendMedicamentFavoriteIdArrToDelete($event)"
            (SendMedicamentFromPopUpFavoritetoAdd)="SendMedicamentFromPopUpFavoritetoAdd($event)" 
            (SendParapharmacyFavoriteIdArrToDelete)="DeleteParapharmacy($event)"
        >
        </app-favorites-medicaments-pop-up>
    </dx-popup>

    <div *ngIf="showParaPharmacyCreateMobileForm" class="prescriptions_new_table-mobile_parapharmacy_create_container">
        
        <div>
            <span>Nueva Parafarmacia</span>
        </div>

        <!-- paraPharmacyMobile -->

        <div class="prescriptions_new_table-mobile_accordion_producto_banner_main_container">

            <span class="prescriptions_new_table-mobile_accordion_producto_banner_span"> Producto </span>

            <span class="prescriptions_new_table-mobile_accordion_producto_banner_span_required"> * </span>
            
        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
            
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Medicamento

                </span>

            </div>
            
            <div 
                class="prescriptions_new_table-mobile_medicamento_name_input_container">

                <input 
                    class="prescriptions_new_table-mobile_inputs" 
                    type="text" 
                    [(ngModel)]="paraPharmacyMobile.name"
                >

            </div>

        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
                
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Instrucciones

                </span>

            </div>
            
            <div class="prescriptions_new_table-mobile_medicamento_name_input_container">

                <input [disabled]="!isDraft" class="prescriptions_new_table-mobile_inputs" type="text" [(ngModel)]="paraPharmacyMobile.patientInstructions">

            </div>

        </div>

        <div  class="prescriptions_new_table-mobile_accordion_full_name_container">
                
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Posología

                </span>

            </div>
            
            <div class="prescriptions_new_table-mobile_medicamento_name_input_container">
                <input [disabled]="!isDraft" class="prescriptions_new_table-mobile_inputs" type="text" [(ngModel)]="paraPharmacyMobile.posology">
            </div>

        </div>

        <div class="prescriptions_new_table-mobile_accordion_producto_banner_main_container">

            <span class="prescriptions_new_table-mobile_accordion_producto_banner_span"> Detalles </span>

            <span class="prescriptions_new_table-mobile_accordion_producto_banner_span_required"> * </span>
            
        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
                
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Fecha inicio

                </span>

            </div>
            
            <dx-date-box 
                
                class="prescriptions_new_table-mobile_date_inputs"
                [value]="paraPharmacyMobile.startDate"
                [(ngModel)]="paraPharmacyMobile.startDate"
                [disabled]="inputsDisabled || !isDraft"
                type="date"
                [showClearButton]="true"
                placeholder="Inicio"
                displayFormat="dd/MM/yyyy"
            >
            </dx-date-box>

        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
            
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Fecha fin

                </span>

            </div>
            
            <dx-date-box 
                class="prescriptions_new_table-mobile_date_inputs"
                [value]="paraPharmacyMobile.endDate"
                [(ngModel)]="paraPharmacyMobile.endDate"
                [disabled]="inputsDisabled || !isDraft"
                type="date"
                [showClearButton]="true"
                placeholder="Fin"
                displayFormat="dd/MM/yyyy"
            >
            </dx-date-box>

        </div>

        <div class="magistral_formula_pop_up-save_alert_form">
            <div  *ngIf="masterFormulaValidatorOpen" >
                <div 
                
                    class="alert-main_container" 
                >
                    <div>
                        <div class="alert-title_span_container" >
                            <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                            <span class="alert-title_span" > Complete campos incompletos o valores a 0 </span>
                        </div>
                    </div>
                    
                    <div>
                        <div>
                            <fa-icon class="alert-x_button" (click)="OrderCloseAlert()" [icon]="faXmark"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div (click)="ValidateParamedicament( paraPharmacyMobile , 'create' )" class="prescriptions_new_table-mobile_save_medicament_button">
            GUARDAR
        </div>

    </div>

    <div *ngIf="showMasterFormulaCreateMobileForm" class="prescriptions_new_table-mobile_parapharmacy_create_container">
        
        <div>
            <span> Nueva Fórmula Magistral </span>
        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
                
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Medicamento

                </span>

            </div>
            
            <div 
                class="prescriptions_new_table-mobile_medicamento_name_input_container">

                <input 
                    class="prescriptions_new_table-mobile_inputs" 
                    type="text" 
                    [(ngModel)]="masterFormulaMobile.composition"
                >

            </div>

        </div>

        <div class="dx-fieldset prescriptions_new_table-mobile_inputs_datafield_containers_pharmaForm" >
            
            <div class="dx-field"  >
                
                <div class="dx-field-value prescriptions_new_table-mobile_selector_paciente_container">
    
                    <div class="dx-field-label prescriptions_new_table-mobile_crossed_labels">F.Farmacéutica</div>
    
                    <dx-select-box
                        [disabled]="!isDraft"
                        class="prescriptions_new_table-mobile_dx_select_box_pharma_form"
                        [dataSource]="pharmaceuticalFormsgroupedData"
                        valueExpr="name"
                        [grouped]="true"
                        displayExpr="name"
                        [searchEnabled]="true"
                        groupTemplate="pharmaForms"
                        [value]="masterFormulaMobile.pharmaceuticalForm"
                        [(ngModel)]="masterFormulaMobile.pharmaceuticalForm"
                    >
                    
                        <div *dxTemplate="let pharmaForm of 'pharmaForms'">
                            {{ pharmaForm.name }} 
                        </div>
                        
                    </dx-select-box>
    
                </div>
            </div>
        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
                
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Instrucciones

                </span>

            </div>
            
            <div class="prescriptions_new_table-mobile_medicamento_name_input_container">

                <input [disabled]="!isDraft" class="prescriptions_new_table-mobile_inputs" type="text" [(ngModel)]="masterFormulaMobile.patientInstructions">

            </div>

        </div>

        <div class="prescriptions_new_table-mobile_accordion_producto_banner_main_container">

            <span class="prescriptions_new_table-mobile_accordion_producto_banner_span"> Posología </span>

            <span class="prescriptions_new_table-mobile_accordion_producto_banner_span_required"> * </span>
            
        </div>

        <div class="prescriptions_new_table-mobile_accordion_posology_row_container">
            
            <div class="prescriptions_new_table-mobile_posology_first_column"></div>
           
            <div class="prescriptions_new_table-mobile_accordion_posology_number_input_container">

                <input [disabled]="!isDraft" type="text" [(ngModel)]="masterFormulaMobile.takingAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input">

            </div>
        
            <div>

                <dx-select-box
                    class="prescriptions_new_table-mobile_drop_edit_medicament_downs"
                    [dataSource]="unitgroupedData"
                    valueExpr="name"
                    [grouped]="true"
                    displayExpr="name"
                    [searchEnabled]="true"
                    groupTemplate="takingUnits"
                    [value]="masterFormulaMobile.takingUnit"
                    [(ngModel)]="masterFormulaMobile.takingUnit"
                >
                    <div *dxTemplate="let takingUnit of 'takingUnits'">
                        {{ takingUnit.name }} 
                    </div>
                    
                </dx-select-box>
            </div>
        </div>

        <div class="prescriptions_new_table-mobile_accordion_posology_row_container">
            
            <div class="prescriptions_new_table-mobile_posology_first_column">Cada</div>

            <div class="prescriptions_new_table-mobile_accordion_posology_number_container">
                <input type="text" [(ngModel)]="masterFormulaMobile.frequencyAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input">
            </div>

            <div>
                <dx-select-box
                    class="prescriptions_new_table-mobile_drop_edit_medicament_downs"
                    [dataSource]="frecuencyUnitTimeDurationGroupedData"
                    valueExpr="key"
                    [grouped]="true"
                    displayExpr="name"
                    [searchEnabled]="true"
                    groupTemplate="frequencyUnits"
                    [value]="masterFormulaMobile.frequencyUnit"
                    [(ngModel)]="masterFormulaMobile.frequencyUnit"
                >
                    <div *dxTemplate="let frequencyUnit of 'frequencyUnits'">
                        {{ frequencyUnit.name }} 
                    </div>
                    
                </dx-select-box>
            </div>
        
        </div>

        <div class="prescriptions_new_table-mobile_accordion_posology_row_container">
        
            <div class="prescriptions_new_table-mobile_posology_first_column">Durante</div>
        
            <div class="prescriptions_new_table-mobile_accordion_posology_number_container">
                <input type="text" [(ngModel)]="masterFormulaMobile.durationAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input">
            </div>
        
            <div>
                <dx-select-box
                    class="prescriptions_new_table-mobile_drop_edit_medicament_downs"
                    [dataSource]="timeDurationGroupedData"
                    valueExpr="key"
                    [grouped]="true"
                    displayExpr="name"
                    [searchEnabled]="true"
                    groupTemplate="durationUnits"
                    [value]="masterFormulaMobile.durationUnit"
                    [(ngModel)]="masterFormulaMobile.durationUnit"
                >
                    <div *dxTemplate="let durationUnit of 'durationUnits'">
                        {{ durationUnit.name }} 
                    </div>
                    
                </dx-select-box>
            </div>
        
        </div>

        <div class="prescriptions_new_table-mobile_accordion_producto_banner_main_container">

            <span class="prescriptions_new_table-mobile_accordion_producto_banner_span"> Detalles </span>

            <span class="prescriptions_new_table-mobile_accordion_producto_banner_span_required"> * </span>
            
        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
            
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    N envases

                </span>

            </div>
            
            <div class="prescriptions_new_table-mobile_medicamento_name_input_container">

                <input class="prescriptions_new_table-mobile_inputs" type="text" [(ngModel)]="masterFormulaMobile.packagesAmount">

            </div>

        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
            
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Fecha inicio

                </span>

            </div>
            
            <dx-date-box 
                
                class="prescriptions_new_table-mobile_date_inputs"
                [value]="masterFormulaMobile.startDate"
                [(ngModel)]="masterFormulaMobile.startDate"
                [disabled]="inputsDisabled || !isDraft"
                type="date"
                [showClearButton]="true"
                placeholder="Inicio"
                format="dd/MM/yyyy"
            >
            </dx-date-box>

        </div>

        <div class="prescriptions_new_table-mobile_accordion_full_name_container">
            
            <div class="prescriptions_new_table-mobile_medicamento_span_container">
                
                <span>

                    Fecha fin

                </span>

            </div>
            
            <dx-date-box 
                class="prescriptions_new_table-mobile_date_inputs"
                [value]="masterFormulaMobile.endDate"
                [(ngModel)]="masterFormulaMobile.endDate"
                [disabled]="inputsDisabled || !isDraft"
                type="date"
                [showClearButton]="true"
                placeholder="Fin"
                format="dd/MM/yyyy"
            >
            </dx-date-box>

        </div>

        <div class="magistral_formula_pop_up-save_alert_form">
            <div  *ngIf="masterFormulaValidatorOpen" >
                <div 
                
                    class="alert-main_container" 
                >
                    <div>
                        <div class="alert-title_span_container" >
                            <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                            <span class="alert-title_span" > Complete campos incompletos o valores a 0 </span>
                        </div>
                    </div>
                    
                    <div>
                        <div>
                            <fa-icon class="alert-x_button" (click)="OrderCloseAlert()" [icon]="faXmark"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div (click)="AddMobileMasterFormula( masterFormulaMobile )" class="prescriptions_new_table-mobile_save_medicament_button">
            GUARDAR
        </div>

    </div>

    <dx-accordion
        *ngIf="dataSource && dataSource.length > 0"
        [dataSource]="dataSource"
        itemTemplate="medicaments"
        itemTitleTemplate="titles" 
        [selectedIndex]="-1"
        [collapsible]="true"
        [multiple]="false"
        [animationDuration]="450"
        class="prescriptions_new_table-mobile_accordion_main_container"
    >

    <div>
        <app-spinner></app-spinner>
    </div>

        <div *dxTemplate="let data of 'titles'">
            
            <div class="prescriptions_new_table-mobile_title_container">

                <div class="prescriptions_new_table-mobile_accordion_title_spans_main_container">

                    <div *ngIf="data.kind === 'paraMedicament'" >
                        <fa-icon [icon]="faCapsules" class="kind_icon_mobile"></fa-icon>
                    </div>
                    <div *ngIf="data.kind === 'medicament'" >
                        <fa-icon [icon]="faPrescriptionBottleMedical" class="kind_icon_mobile"></fa-icon>
                    </div>
                    <div *ngIf="data.kind === 'masterFormula'" >
                        <fa-icon [icon]="faFlaskVial" class="kind_icon_mobile"></fa-icon>
                    </div>

                    <div>
                        <span class="prescriptions_new_table-mobile_accordion_title_spans">
    
                            {{ data.name.split(' ')[0] }}
                        </span>
                    </div>

                    
                </div>

                <div *ngIf="isDraft">
                    <span class="prescriptions_new_table-mobile_available_days_spans"> Días disponibles : {{data.availableDays}} </span>
                </div>

                <div class="prescriptions_new_table-mobile_deleted_warning_container" *ngIf="!isDraft && data.status === 'Deleted'">

                    <span class="prescriptions_new_table-mobile_accordion_posology_spans"> Anulado </span>
    
                </div>

            </div>

            <div>

                <span class="prescriptions_new_table-mobile_accordion_posology_spans"> {{ data.posology }} </span>

            </div>

        </div>
       

        <div *dxTemplate="let data of 'medicaments'">

            <div class="prescriptions_new_table-mobile_accordion_producto_body_main_container">

                <div class="prescriptions_new_table-mobile_accordion_producto_banner_main_container">

                    <span class="prescriptions_new_table-mobile_accordion_producto_banner_span"> Producto </span>
    
                    <span class="prescriptions_new_table-mobile_accordion_producto_banner_span_required"> * </span>
                    
                </div>
    
                <div class="prescriptions_new_table-mobile_accordion_full_name_container">
                    
                    <div class="prescriptions_new_table-mobile_medicamento_span_container">
                        
                        <span>
    
                            Medicamento
    
                        </span>
    
                    </div>
                    
                    <div 
                        class="prescriptions_new_table-mobile_medicamento_name_input_container">
    
                        <input 
                            class="prescriptions_new_table-mobile_inputs" 
                            type="text" 
                            [(ngModel)]="data.fullName"
                            [disabled]="data.kind === 'medicament' || !isDraft"
                        >
    
                    </div>
    
                </div>
    
                <div 
                    *ngIf="data.kind === 'medicament'" 
                    class="prescriptions_new_table-mobile_accordion_full_name_container"
                >
                    
                    <div class="prescriptions_new_table-mobile_medicamento_span_container">
                        
                        <span>
    
                            Avisos
    
                        </span>
    
                    </div>
                    
                    <div 
                        class="prescriptions_new_table-mobile_medicamento_name_input_container"
                    >
                        <input  
                            [disabled]="!isDraft"
                            class="prescriptions_new_table-mobile_inputs" 
                            type="text" 
                            [(ngModel)]="data.pharmacistWarnings"
                        >
                    </div>
    
                </div>
    
                <div *ngIf="data.kind === 'masterFormula'">
                    
                    <div class="dx-fieldset prescriptions_new_table-mobile_inputs_datafield_containers_pharmaForm" >
                
                        <div class="dx-field"  >
                            
                            <div class="dx-field-value prescriptions_new_table-mobile_selector_paciente_container">
                
                                <div class="dx-field-label prescriptions_new_table-mobile_crossed_labels">F.Farmacéutica</div>
                
                                <dx-select-box
                                    [disabled]="!isDraft"
                                    class="prescriptions_new_table-mobile_dx_select_box_pharma_form"
                                    [dataSource]="pharmaceuticalFormsgroupedData"
                                    valueExpr="name"
                                    [grouped]="true"
                                    displayExpr="name"
                                    [showDropDownButton]="true"
                                    [searchEnabled]="false"
                                    groupTemplate="pharmaForms"
                                    [value]="data.pharmaceuticalForm"
                                    [(ngModel)]="data.pharmaceuticalForm"
                                >
                                
                                    <div *dxTemplate="let pharmaForm of 'pharmaForms'">
                                        {{ pharmaForm.name }} 
                                    </div>
                                    
                                </dx-select-box>
                
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="prescriptions_new_table-mobile_accordion_full_name_container">
                    
                    <div class="prescriptions_new_table-mobile_medicamento_span_container">
                        
                        <span>
    
                            Instrucciones
    
                        </span>
    
                    </div>
                    
                    <div class="prescriptions_new_table-mobile_medicamento_name_input_container">
    
                        <input [disabled]="!isDraft" class="prescriptions_new_table-mobile_inputs" type="text" [(ngModel)]="data.patientInstructions">
    
                    </div>
    
                </div>
    
                <div class="prescriptions_new_table-mobile_accordion_producto_banner_main_container">
    
                    <span class="prescriptions_new_table-mobile_accordion_producto_banner_span"> Posología </span>
    
                    <span class="prescriptions_new_table-mobile_accordion_producto_banner_span_required"> * </span>
                    
                </div>
    
                <div *ngIf="data.kind === 'medicament' || data.kind === 'masterFormula'" class="prescriptions_new_table-mobile_accordion_posology_row_container">
                    
                    <div class="prescriptions_new_table-mobile_posology_first_column"></div>
                   
                    <div class="prescriptions_new_table-mobile_accordion_posology_number_input_container">
    
                        <input *ngIf="data.kind === 'medicament'" [disabled]="!isDraft" type="text" (input)="SetFinalPackageAmountForMobileForm( data , $event.target.value , 'takingAmount' )" [(ngModel)]="data.takingAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input">
                        
                        <input *ngIf="data.kind === 'masterFormula'" [disabled]="!isDraft" type="text"  [(ngModel)]="data.takingAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input">
    
                    </div>
                    
                   
                    <div *ngIf="data.kind === 'medicament'" class="prescriptions_new_table-mobile_accordion_posology_text_container">
    
                        <input [disabled]="data.kind === 'medicament' || !isDraft" type="text" [(ngModel)]="data.unit.name" class="prescriptions_new_table-mobile_accordion_posology_text_input">
    
                    </div>
                
                    <div *ngIf="data.kind === 'masterFormula'">
    
                        <dx-select-box
                            class="prescriptions_new_table-mobile_drop_edit_medicament_downs"
                            [dataSource]="unitgroupedData"
                            [disabled]="!isDraft"
                            valueExpr="name"
                            [grouped]="true"
                            displayExpr="name"
                            [showDropDownButton]="true"
                            [searchEnabled]="false"
                            groupTemplate="takingUnits"
                            [value]="data.unit.name"
                            [(ngModel)]="data.unit.name"
                        >
                            <div *dxTemplate="let takingUnit of 'takingUnits'">
                                {{ takingUnit.name }} 
                            </div>
                            
                        </dx-select-box>
                    </div>
                </div>
    
                <!-- {
                    "composition": "nueva fm",
                    "dosage": "Comprimido",
                    "pharmaceuticalForm": "Comprimido",
                    "administrationWay": "Comprimido",
                    "takingAmount": 11,
                    "takingUnit": "Comprimido",
                    "frequencyAmount": 1,
                    "frequencyUnit": "day",
                    "durationAmount": 1,
                    "durationUnit": "week",
                    "narcotic": false,
                    "psychotropic": false,
                    "patientInstructions": "Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta",
                    "pharmacistWarnings": "Avisos de carácter farmacéutico",
                    "packagesAmount": 12,
                    "startDate": "2024-05-29T00:00:00.000Z",
                    "endDate": "2024-06-04T00:00:00.000Z",
                    "status": "DispensableInFuture",
                    "unitAmount": 10,
                    "prescriptionId": "aab913f0-a721-47b0-8a29-573d0bd4ef6b"
                } -->
    
                <div *ngIf="data.kind === 'medicament' || data.kind === 'masterFormula'" class="prescriptions_new_table-mobile_accordion_posology_row_container">
                    
                    <div class="prescriptions_new_table-mobile_posology_first_column">Cada</div>
    
                    <div class="prescriptions_new_table-mobile_accordion_posology_number_container">
                        <input 
                            *ngIf="data.kind === 'medicament'"
                            [disabled]="!isDraft"
                            type="text" (input)="SetFinalPackageAmountForMobileForm( data , $event.target.value , 'frequencyAmount' )" 
                            [(ngModel)]="data.frequencyAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input"
                        >

                        <input 
                            *ngIf="data.kind === 'masterFormula'"
                            type="text" [disabled]="!isDraft"
                            [(ngModel)]="data.frequencyAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input"
                        >
                    </div>
    
                    <div>
                        <dx-select-box
                            class="prescriptions_new_table-mobile_drop_edit_medicament_downs"
                            [dataSource]="frecuencyUnitTimeDurationGroupedData"
                            [disabled]="!isDraft"
                            valueExpr="key"
                            [grouped]="true"
                            displayExpr="name"
                            [showDropDownButton]="true"
                            [searchEnabled]="false"
                            groupTemplate="frequencyUnits"
                            [value]="data.frequencyUnit"
                            [(ngModel)]="data.frequencyUnit"
                            (onValueChanged)="SetFinalPackageAmountForMobileFormFromSelector( data , $event , 'frequencyUnit' )"
                        >
                            <div *dxTemplate="let frequencyUnit of 'frequencyUnits'">
                                {{ frequencyUnit.name }} 
                            </div>
                            
                        </dx-select-box>
                    </div>
                
                </div>
    
                <div *ngIf="data.kind === 'medicament' || data.kind === 'masterFormula'" class="prescriptions_new_table-mobile_accordion_posology_row_container">
                
                    <div class="prescriptions_new_table-mobile_posology_first_column">Durante</div>
                
                    <div class="prescriptions_new_table-mobile_accordion_posology_number_container">

                        <input
                            *ngIf="data.kind === 'medicament'" [disabled]="!isDraft"
                            type="text" (input)="SetEndDateDependingAmountMobileForm(data , $event.target.value , 'durationAmount')" 
                            [(ngModel)]="data.durationAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input"
                        >

                        <input
                            *ngIf="data.kind === 'masterFormula'" 
                            type="text" [disabled]="!isDraft"
                            [(ngModel)]="data.durationAmount" class="prescriptions_new_table-mobile_accordion_posology_number_input"
                        >

                    </div>
                
                    <div>
                        <dx-select-box
                            class="prescriptions_new_table-mobile_drop_edit_medicament_downs"
                            [dataSource]="timeDurationGroupedData"
                            valueExpr="key"
                            [grouped]="true"
                            displayExpr="name"
                            [showDropDownButton]="true"
                            [searchEnabled]="false"
                            groupTemplate="durationUnits"
                            [value]="data.durationUnit"
                            [disabled]="!isDraft"
                            [(ngModel)]="data.durationUnit"
                            (onValueChanged)="SetEndDateDependingDurationUnitMobileForm(data , $event)"
                        >
                            <div *dxTemplate="let durationUnit of 'durationUnits'">
                                {{ durationUnit.name }} 
                            </div>
                            
                        </dx-select-box>
                    </div>
                
                </div>
    
                <div *ngIf="data.kind === 'paraMedicament'" class="prescriptions_new_table-mobile_accordion_full_name_container">
                    
                    <div class="prescriptions_new_table-mobile_medicamento_span_container">
                        
                        <span>
    
                            Posología
    
                        </span>
    
                    </div>
                    
                    <div class="prescriptions_new_table-mobile_medicamento_name_input_container">
                        <input [disabled]="!isDraft" class="prescriptions_new_table-mobile_inputs" type="text" [(ngModel)]="data.posology">
                    </div>
    
                </div>
    
                <div class="prescriptions_new_table-mobile_accordion_producto_banner_main_container">
    
                    <span class="prescriptions_new_table-mobile_accordion_producto_banner_span"> Detalles </span>
    
                    <span class="prescriptions_new_table-mobile_accordion_producto_banner_span_required"> * </span>
                    
                </div>
    
                <div *ngIf="data.kind === 'medicament' || data.kind === 'masterFormula'" class="prescriptions_new_table-mobile_accordion_full_name_container">
                    
                    <div class="prescriptions_new_table-mobile_medicamento_span_container">
                        
                        <span>
    
                            N envases
    
                        </span>
    
                    </div>
                    
                    <div class="prescriptions_new_table-mobile_medicamento_name_input_container">
                        <input class="prescriptions_new_table-mobile_inputs" type="text" [disabled]="!isDraft" [(ngModel)]="data.packagesAmount">
                    </div>
    
                </div>
    
                <div class="prescriptions_new_table-mobile_accordion_full_name_container">
                    
                    <div class="prescriptions_new_table-mobile_medicamento_span_container">
                        
                        <span>
    
                            Fecha inicio
    
                        </span>
    
                    </div>
                    
                    <dx-date-box 
                        
                        class="prescriptions_new_table-mobile_date_inputs"
                        min="{{ today }}"
                        [value]="data.startDate"
                        [(ngModel)]="data.startDate"
                        [disabled]="inputsDisabled || !isDraft"
                        type="date"
                        [showClearButton]="true"
                        placeholder="Inicio"
                        displayFormat="dd/MM/yyyy"
                        (onValueChanged)="setEndDateValueMobileForm( {} , $event , data )"
                    >
                    </dx-date-box>
    
                </div>
    
                <div class="prescriptions_new_table-mobile_accordion_full_name_container">
                    
                    <div class="prescriptions_new_table-mobile_medicamento_span_container">
                        
                        <span>
    
                            Fecha fin
    
                        </span>
    
                    </div>
                    
                    <dx-date-box 
                        class="prescriptions_new_table-mobile_date_inputs"
                        [value]="data.endDate"
                        min="{{ data.startDate }}"
                        [(ngModel)]="data.endDate"
                        [disabled]="inputsDisabled || !isDraft"
                        type="date"
                        [showClearButton]="true"
                        placeholder="Fin"
                        displayFormat="dd/MM/yyyy"
                        (onValueChanged)="setPackageAmountAndDurationAmountFromEndDateFormMobile( {} , $event , data )"
                    >
                    </dx-date-box>
    
                </div>
            </div>

            <div class="prescriptions_new_table-mobile_medicament_edit_action_buttons" *ngIf="data.kind === 'medicament'">
                
                <div class="prescriptions_new_table-mobile_medicament_edit_action_buttons_container" *ngIf="isDraft" (click)="DeleteMedicine( '' ,  data.id )">
                    <fa-icon class="prescriptions_new_table-mobile_medicament_edit_action_buttons_x" [icon]="faTrash"></fa-icon>
                </div>

                <div 
                    *ngIf="
                    ( prescriptionStatus !== 'draft' && prescriptionStatus !== 'Draft' && prescriptionStatus !== 'manual' && prescriptionStatus !== 'Manual' && prescriptionStatus !== 'MasterFormula' ) && 
                    ( data.status !== 'Dispensed' && data.status !== 'dispensed' ) &&
                    ( data.status !== 'Deleted' && data.status !== 'deleted' ) " 
                    class="prescriptions_new_table-mobile_acciones_icons_container" 
                >
                    <dx-button
                        stylingMode="contained"
                        text="Anular"
                        type="danger"
                        [width]="120"
                        (onClick)="DeletePrescriptionMedicamentMobileView(data)"
                    >
                    </dx-button>
                
                </div>
                
                <div (click)="UpdateMedicament( data )" *ngIf="isDraft" class="prescriptions_new_table-mobile_medicament_edit_action_buttons_container">
                    <fa-icon class="prescriptions_new_table-mobile_medicament_edit_action_buttons_save" [icon]="faFloppyDisk"></fa-icon>
                </div>

            </div>

            <div class="magistral_formula_pop_up-save_alert_form">
                <div  *ngIf="masterFormulaValidatorOpen" >
                    <div 
                    
                        class="alert-main_container" 
                    >
                        <div>
                            <div class="alert-title_span_container" >
                                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                                <span class="alert-title_span" > Complete campos incompletos o valores a 0 </span>
                            </div>
                        </div>
                        
                        <div>
                            <div>
                                <fa-icon class="alert-x_button" (click)="OrderCloseAlert()" [icon]="faXmark"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="prescriptions_new_table-mobile_medicament_edit_action_buttons" *ngIf="data.kind === 'masterFormula'">
                
                <div class="prescriptions_new_table-mobile_medicament_edit_action_buttons_container" (click)="DeleteMasterFormula( data.id )">
                    <fa-icon class="prescriptions_new_table-mobile_medicament_edit_action_buttons_x" [icon]="faTrash"></fa-icon>
                </div>
                
                <div (click)="BuildMasterFormulaToUpdate( data )" *ngIf="isDraft" class="prescriptions_new_table-mobile_medicament_edit_action_buttons_container">
                    <fa-icon class="prescriptions_new_table-mobile_medicament_edit_action_buttons_save" [icon]="faFloppyDisk"></fa-icon>
                </div>

            </div>

            <div class="prescriptions_new_table-mobile_medicament_edit_action_buttons" *ngIf="data.kind === 'paraMedicament'">
                
                <div class="prescriptions_new_table-mobile_medicament_edit_action_buttons_container" (click)="DeleteParapharmacy( data.id )">
                    <fa-icon class="prescriptions_new_table-mobile_medicament_edit_action_buttons_x" [icon]="faTrash"></fa-icon>
                </div>
                
                <div (click)="ValidateParamedicament( data , 'update' )" *ngIf="isDraft" class="prescriptions_new_table-mobile_medicament_edit_action_buttons_container">
                    <fa-icon class="prescriptions_new_table-mobile_medicament_edit_action_buttons_save" [icon]="faFloppyDisk"></fa-icon>
                </div>

            </div>

            <dx-popup
                height="50%"
                width="90%"
                [(visible)]="printDeletePopUp"
                [hideOnOutsideClick]="true"
            >
                <app-delete-warning-pop-up-content 
                    (CloseDeletePopUp)="CloseDeletePopUp($event)"  
                    (CloseDeleteMasterFormulaPopUp)="CloseDeleteMasterFormulaPopUp($event)"
                    (CloseDeleteParaMedicamentPopUp)="CloseDeleteParaMedicamentPopUp($event)"
                >
                </app-delete-warning-pop-up-content>
            </dx-popup>

            <!-- <div *ngIf="data.kind === 'medicament'" (click)="UpdateMedicament( data )" class="prescriptions_new_table-mobile_save_medicament_button">
                GUARDAR
            </div>

            <div *ngIf="data.kind === 'masterFormula'" (click)="BuildMasterFormulaToUpdate( data )" class="prescriptions_new_table-mobile_save_medicament_button">
                GUARDAR
            </div>

            <div *ngIf="data.kind === 'paraMedicament'" (click)="UpdateParaMedicament( data )" class="prescriptions_new_table-mobile_save_medicament_button">
                GUARDAR
            </div>

            <div *ngIf="data.kind === 'paraMedicament' && isDraft" (click)="DeleteParapharmacy( data.id )" class="prescriptions_new_table-mobile_delete_medicament_button">
                Borrar
            </div>

            <div *ngIf="data.kind === 'masterFormula' && isDraft" (click)="DeleteMasterFormula( data.id )" class="prescriptions_new_table-mobile_delete_medicament_button">
                Borrar
            </div>

            <div *ngIf="data.kind === 'medicament' && isDraft" (click)="DeleteMedicine( '' ,  data.id )" class="prescriptions_new_table-mobile_delete_medicament_button">
                Borrar
            </div> -->

        </div>
    
    </dx-accordion>

    

</div>

    
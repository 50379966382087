import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of , mergeMap } from 'rxjs';
import { catchError , map, switchMap } from 'rxjs/operators';
import * as FavoritesMedicamentsActions from 'src/app/store/actions/medicaments/favorites-medicaments.actions';
import { MedicamentsFavoritesService } from 'src/app/medicaments-favorites/services';
import { HttpErrorResponse } from '@angular/common/http';
import { Medicament } from 'src/app/models';

@Injectable()

export class favoritesMedicamentsEffects {

    constructor( 
        private action$ : Actions,
        private medicamentsFavoritesService: MedicamentsFavoritesService 
    ){}

    loadMedicamentsFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(FavoritesMedicamentsActions.getFavoritesMedicaments),
            switchMap(action =>
                {
                    const { professionalId } = action || {};
                    return this.medicamentsFavoritesService.LoadFavoritesMedicaments( professionalId )
                    .pipe(
                        map(medicaments => {
                            return FavoritesMedicamentsActions.getFavoritesMedicamentsSuccess ( {medicaments} )
                        }),
                        catchError((error : HttpErrorResponse) => {
                            return of( FavoritesMedicamentsActions.getFavoritesMedicamentsFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })

    addMedicamentsFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( FavoritesMedicamentsActions.addFavoritesMedicaments ),
            mergeMap(( action ) => {
                
                const { medicament } = action || {};

                console.log(medicament);

                const myMedicament = {
                    name : medicament.name,
                    professionalId : medicament.professionalId,
                    medicineId : medicament.medicineId,
                    takingAmount : medicament.takingAmount ? medicament.takingAmount : 1,
                    packagesAmount : medicament.packagesAmount,
                    code : medicament.nationalCode?.toString(),
                    frequencyAmount : medicament.frequencyAmount,
                    frequencyUnit : medicament.frequencyUnit,
                    durationAmount : medicament.durationAmount,
                    durationUnit : medicament.durationUnit,
                    patientInstructions: 'Siga las instrucciones de posología que le hayan indicado en clínica, independientemente de la escrita en esta receta',
                    pharmacistWarnings : 'avisos farmacéuticos',
                    startDate : new Date(),
                    endDate : new Date(),
                    narcotic : medicament.narcotic,
                    psychotropic : medicament.psychotropic,
                    unitAmount : medicament.unitAmount,
                    fullName : medicament.name,
                    unit : { name : medicament.unit?.name ,  id : medicament.unit?.id }
                };

                return this.medicamentsFavoritesService.SaveFavoriteMedicament( myMedicament )
                .pipe(
                    map(( data ) =>{
                        if( data.isOk ){
                            
                            const myMedicamentWithId = { ...myMedicament , favoriteId : data.id }

                            return FavoritesMedicamentsActions.addFavoritesMedicamentsSuccess({ myMedicamentWithId });
                        }else{
                            return FavoritesMedicamentsActions.addFavoritesMedicamentsSuccess({  });
                        }
                    }),

                    catchError(error => {
                        return of( FavoritesMedicamentsActions.addFavoritesMedicamentsFailure({ error } ) )
                    })
                )
            })
        )
    })

    deleteMedicamentsFavorites$ = createEffect(() =>
        this.action$.pipe(
            ofType( FavoritesMedicamentsActions.deleteFavoritesMedicaments ),
            mergeMap(( action ) => {
                const { medicamentFavoritedId } = action;
                return this.medicamentsFavoritesService.DeleteFavoriteMedicament( medicamentFavoritedId )
                .pipe(
                    map(( data ) =>{
                        if( data.isOk ){
                            return FavoritesMedicamentsActions.deleteFavoritesMedicamentsSuccess({ medicamentFavoritedId })
                        } else {
                            return FavoritesMedicamentsActions.deleteFavoritesMedicamentsSuccess({  })
                        }
                    }),

                    catchError(error => {
                        return of( FavoritesMedicamentsActions.deleteFavoritesMedicamentsFailure({ error } ) )
                    })
                )
            })
        )
    );

    updateMedicamentsFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( FavoritesMedicamentsActions.updateFavoritesMedicaments ),
            mergeMap(( action ) => {


                const { 
                    medicamentUpdate : { favoriteId , professionalId , medicineId , takingAmount , packagesAmount , frequencyAmount , frequencyUnit , 
                    durationAmount , durationUnit , patientInstructions , pharmacistWarnings , startDate , endDate , narcotic , psychotropic , unitAmount ,
                    name 
                } } = action || {};


                const myMedicamentToUpdate : Medicament = {
                    name : name,
                    favoriteId: favoriteId,
                    professionalId: professionalId,
                    medicineId: medicineId,
                    takingAmount: takingAmount,
                    packagesAmount: packagesAmount,
                    frequencyAmount: frequencyAmount,
                    frequencyUnit: frequencyUnit,
                    durationAmount: durationAmount,
                    durationUnit: durationUnit,
                    patientInstructions: patientInstructions,
                    pharmacistWarnings: pharmacistWarnings,
                    startDate: startDate,
                    endDate: endDate,
                    narcotic: narcotic,
                    psychotropic: psychotropic,
                    unitAmount: unitAmount, 
                    unit : action.medicamentUpdate.unit?.name
                }


                return this.medicamentsFavoritesService.UpdateFavoriteMedicament( myMedicamentToUpdate )
                .pipe(
                    map(( data ) => {
                        if( data.isOk ){
                            return FavoritesMedicamentsActions.updateFavoritesMedicamentsSuccess({ myMedicamentToUpdate });
                        } else {
                            return FavoritesMedicamentsActions.updateFavoritesMedicamentsSuccess({})
                        }
                    }),

                    catchError(error => {
                        return of( FavoritesMedicamentsActions.updateFavoritesMedicamentsFailure({ error } ) )
                    })
                )
            })
        )
    })
}

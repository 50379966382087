import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Medicament, ProtocolMedicineUpdateBody, ProtocolNew, ProtocolNewRes, ProtocolsRes, Indication } from 'src/app/models';
import { ProtocolParamsToInsert, ProtocolPrescription, ProtocolsFilters } from 'src/app/models/prescriptions/interfaces/protocols';
import { environment } from 'src/environments/environment';
import { DynPrescriptionsService } from './dyn-prescriptions.service';

@Injectable({
  providedIn: 'root' //injected in protocols effects
})
export class ProtocolsService {

  constructor(
    private http :                                  HttpClient,
    private dynPrescriptionsService :               DynPrescriptionsService
  ) {}

  LoadProtocols(params:ProtocolsFilters):Observable<ProtocolsRes>{

    const url = `${environment.prescriptionsService.url}protocol`;

    const FilledUrl = this.dynPrescriptionsService.BuildUrl(url,params);
    
    return this.http.get<ProtocolsRes>(`${FilledUrl}`);
  }

  InsertProtocol(body:ProtocolParamsToInsert):Observable<ArrayBuffer>{
    const { prescriptionId , protocolId} = body;
    return this.http.post<ArrayBuffer>(
      `${environment.prescriptionsService.url}prescription/protocol?prescriptionId=${prescriptionId}&protocolId=${protocolId}`,{}
    );
  }

  InsertProtocols(body:ProtocolParamsToInsert):Observable<ArrayBuffer>{
    const { protocolArrayIds , prescriptionId } = body;

    const url = `${environment.prescriptionsService.url}prescription/protocols?prescriptionId=${prescriptionId}&`; 
    const builtUrl = protocolArrayIds ? this.BuildInsertProtocolUrl(url , protocolArrayIds) : url;
    return this.http.post<ArrayBuffer>(
      `${builtUrl}`,{}
    );
  }

  CreateProtocol(body:object):Observable<ProtocolNewRes>{
    return this.http.post<ProtocolNewRes>(`${environment.prescriptionsService.url}protocol`, body);
  }

  LoadProtocolById(id:string | null):Observable<ProtocolNew>{
    return this.http.get<ProtocolNew>(`${environment.prescriptionsService.url}protocol/${id}`);
  }

  ModifyProtocol(body:object):Observable<ProtocolPrescription>{
    return this.http.put<ProtocolPrescription>(`${environment.prescriptionsService.url}protocol`, body)
  }

  ModyfyProtocolMedicament(medicamentToUpdate:ProtocolMedicineUpdateBody):Observable<boolean>{
    return this.http.put<boolean>(`${environment.prescriptionsService.url}protocol/medicine` , medicamentToUpdate);
  }

  AddProtocolMedicament(medicamentToUpdate:Medicament):Observable<boolean>{
    medicamentToUpdate.endDate = new Date();
    medicamentToUpdate.startDate = new Date();
    return this.http.post<boolean>(`${environment.prescriptionsService.url}protocol/medicine` , medicamentToUpdate);
  }

  DeleteProtocol(protocolId : string):Observable<boolean>{
    return this.http.delete<boolean>(`${environment.prescriptionsService.url}protocol/${protocolId}`);
  }

  DeleteMedicamentFromProtocol(protocolId:string,medicineId:string):Observable<boolean>{
    return this.http.delete<boolean>(
      `${environment.prescriptionsService.url}protocol/medicine?protocolId=${protocolId}&medicineId=${medicineId}`
    );
  }

  /*********************************************** PARAMEDICAMENTS *************************************************/

  DeleteParaMedicamentInProtocol(protocolId:string , paraMedicamentId: string):Observable<boolean>{
    return this.http.delete<boolean>(
      `${environment.prescriptionsService.url}protocol/parapharmacy?protocolId=${protocolId}&id=${paraMedicamentId}`
    );
  }

  CreateParaMedicamentInProtocol(paraMedicament:Medicament){
    return this.http.post<boolean>(
      `${environment.prescriptionsService.url}protocol/parapharmacy`,
      paraMedicament
    );
  }

  UpdateParaMedicamentInProtocol(paraMedicament: Medicament):Observable<boolean>{
    return this.http.put<boolean>(
      `${environment.prescriptionsService.url}protocol/parapharmacy`,
      paraMedicament
    );
  }

  /*********************************************** end PARAMEDICAMENTS *************************************************/

  GetIndicationIdByOjoId(indi:string , professionalId:string):Observable<Indication[]>{

    const url = `${environment.prescriptionsService.url}protocol/indication/by-array-ojoids`;
    
    const buildUrl = this.BuildProtocolsByIndicationsUrl( url , indi , professionalId );

    return this.http.get<Indication[]>(buildUrl);
  }

  GetProtocolByIndicationId(indicationId:string){
    return this.http.get(`${environment.prescriptionsService.url}protocol/indication/${indicationId}`);
  }

  BuildProtocolsByIndicationsUrl(url: string , indi: string , professionalId : string):string{
  
    const indiArr = indi.split(',');

    if(indi.length > 0)url += `?`;

    for (let i = 0; i < indiArr.length; i++) {

      if(indiArr[i]) url += `ojoIds=${indiArr[i]}&`;
    }

    url += `professionalId=${professionalId}`;

    return url;
  }

  BuildInsertProtocolUrl(url:string , protocolArrayIds:string[]):string {
    for (let i = 0; i < protocolArrayIds.length; i++) {
      url += `protocolIds=${protocolArrayIds[i]}&`;
    }
    if(url.slice(-1) === '&')url = url.replace(/.$/,"");
    return url;
    /* https://app-prescriptions-dev-westeu.azurewebsites.net/api/prescription/protocol?prescriptionId=27a3299f-8542-4723-9531-45f21d3bc062&protocolId=e874045b-632d-4adf-880a-2fd1c6784269&protocolId=95e6bc1f-a787-445a-8cc4-03093fb2cf0f */
  }

  GetMedicamentsFromIndication():Medicament[]{
    const mockedIndications = [
      {
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "protocolId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "ojoId": "string",
        "familyOjoId": "string",
        "name": "string",
        "familyName": "string",
        "protocol": {
          "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "name": "mocked protocol",
          "description": "mocked protocol description",
          "medicaments": [
            {
              "id": 1,
              "nationalCode": 600000,
              "codeDcsa": "89519005",
              "codeDcp": "2431000140106",
              "codeDcpf": "2441000140100",
              "name": "AMOXICILINA /ACIDO CLAVULANICO SALA 500/50 mg POLVO PARA SOLUCION INYECTABLE Y PARA PERFUSION EFG",
              "dosage": "500 mg/50 mg",
              "unitAmount": 100,
              "unit": {
                  "id": 34,
                  "name": "Vial para inyección"
              },
              "laboratoryOriginal": {
                  "id": 2367,
                  "name": "Laboratorio Reig Jofre, S.a."
              },
              "laboratoryProvider": {
                  "id": 2367,
                  "name": "Laboratorio Reig Jofre, S.a."
              },
              "generic": true,
              "narcotic": false,
              "prescriptionRequired": true,
              "psychotropic": false,
              "pharmaForm": {
                  "id": 288,
                  "name": "Polvo Para SoluciÓn Inyectable Y Para PerfusiÓn"
              },
              "administrationWay": {
                  "id": 49,
                  "name": "VÍa Intravenosa"
              },
              "active": true,
              "createdAt": "2020-06-01T11:38:23",
              "updatedAt": "2023-05-16T14:13:23",
              "psicotropicOrNarcotic": "no",
              "selected": true,
              "durationUnit": "day",
              "frecuencyUnit": "hour",
              "startDate": "2023-08-03T01:48:47.205Z",
              "endDate": "2023-08-03T01:48:47.205Z"
            },
            {
              "id": 14,
              "nationalCode": 600028,
              "codeDcsa": "89519005",
              "codeDcp": "323568005",
              "codeDcpf": "2451000140103",
              "name": "AMOXICILINA/ACIDO CLAVULANICO NORMON 1.000 mg/200 mg POLVO PARA SOLUCION INYECTABLE Y PARA PERFUSION EFG",
              "dosage": "1.000 mg/200 mg",
              "unitAmount": 100,
              "unit": {
                  "id": 34,
                  "name": "Vial para inyección"
              },
              "laboratoryOriginal": {
                  "id": 6638,
                  "name": "Laboratorios Normon, S.a."
              },
              "laboratoryProvider": {
                  "id": 6638,
                  "name": "Laboratorios Normon, S.a."
              },
              "generic": true,
              "narcotic": false,
              "prescriptionRequired": true,
              "psychotropic": false,
              "pharmaForm": {
                  "id": 288,
                  "name": "Polvo Para SoluciÓn Inyectable Y Para PerfusiÓn"
              },
              "administrationWay": {
                  "id": 49,
                  "name": "VÍa Intravenosa"
              },
              "active": true,
              "createdAt": "2020-06-01T11:38:23",
              "updatedAt": "2023-05-16T14:13:23",
              "psicotropicOrNarcotic": "no",
              "selected": true,
              "durationUnit": "day",
              "frecuencyUnit": "hour",
              "startDate": "2023-07-10T10:44:47.667Z",
              "endDate": "2023-07-10T10:44:47.667Z"
          }
          ],
          "indications": [
            "string"
          ]
        }
      }
    ]

    const medicamentsArr : Medicament[] = [];

    for (let i = 0; i < mockedIndications.length; i++) {
      
      const { protocol : { medicaments } } = mockedIndications[i];

      for (let x = 0; x < medicaments.length; x++) {
        
        medicamentsArr.push(medicaments[x]);
      }
    }

    return medicamentsArr;
  }

  GetMockedIndications():string[]/* :indication[] */{
    
    const mockedIndications = [
      {
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "protocolId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "ojoId": "string",
        "familyOjoId": "string",
        "name": "string",
        "familyName": "string",
        "protocol": {
          "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "name": "mocked protocol",
          "description": "mocked protocol description",
          "medicaments": [
            {
              "id": 1,
              "nationalCode": 600000,
              "codeDcsa": "89519005",
              "codeDcp": "2431000140106",
              "codeDcpf": "2441000140100",
              "name": "AMOXICILINA /ACIDO CLAVULANICO SALA 500/50 mg POLVO PARA SOLUCION INYECTABLE Y PARA PERFUSION EFG",
              "dosage": "500 mg/50 mg",
              "unitAmount": 100,
              "unit": {
                  "id": 34,
                  "name": "Vial para inyección"
              },
              "laboratoryOriginal": {
                  "id": 2367,
                  "name": "Laboratorio Reig Jofre, S.a."
              },
              "laboratoryProvider": {
                  "id": 2367,
                  "name": "Laboratorio Reig Jofre, S.a."
              },
              "generic": true,
              "narcotic": false,
              "prescriptionRequired": true,
              "psychotropic": false,
              "pharmaForm": {
                  "id": 288,
                  "name": "Polvo Para SoluciÓn Inyectable Y Para PerfusiÓn"
              },
              "administrationWay": {
                  "id": 49,
                  "name": "VÍa Intravenosa"
              },
              "active": true,
              "createdAt": "2020-06-01T11:38:23",
              "updatedAt": "2023-05-16T14:13:23",
              "psicotropicOrNarcotic": "no",
              "selected": true,
              "durationUnit": "day",
              "frecuencyUnit": "hour",
              "startDate": "2023-08-03T01:48:47.205Z",
              "endDate": "2023-08-03T01:48:47.205Z"
            },
            {
              "id": 14,
              "nationalCode": 600028,
              "codeDcsa": "89519005",
              "codeDcp": "323568005",
              "codeDcpf": "2451000140103",
              "name": "AMOXICILINA/ACIDO CLAVULANICO NORMON 1.000 mg/200 mg POLVO PARA SOLUCION INYECTABLE Y PARA PERFUSION EFG",
              "dosage": "1.000 mg/200 mg",
              "unitAmount": 100,
              "unit": {
                  "id": 34,
                  "name": "Vial para inyección"
              },
              "laboratoryOriginal": {
                  "id": 6638,
                  "name": "Laboratorios Normon, S.a."
              },
              "laboratoryProvider": {
                  "id": 6638,
                  "name": "Laboratorios Normon, S.a."
              },
              "generic": true,
              "narcotic": false,
              "prescriptionRequired": true,
              "psychotropic": false,
              "pharmaForm": {
                  "id": 288,
                  "name": "Polvo Para SoluciÓn Inyectable Y Para PerfusiÓn"
              },
              "administrationWay": {
                  "id": 49,
                  "name": "VÍa Intravenosa"
              },
              "active": true,
              "createdAt": "2020-06-01T11:38:23",
              "updatedAt": "2023-05-16T14:13:23",
              "psicotropicOrNarcotic": "no",
              "selected": true,
              "durationUnit": "day",
              "frecuencyUnit": "hour",
              "startDate": "2023-07-10T10:44:47.667Z",
              "endDate": "2023-07-10T10:44:47.667Z"
          }
          ],
          "indications": [
            "string"
          ]
        }
      }
    ]

    const protocolsStringArr : string [] = [];

    for (let i = 0; i < mockedIndications.length; i++) {
      
      const { protocol : { id } } = mockedIndications[i];
      protocolsStringArr.push(id);
      
    }

    return protocolsStringArr;
    // return mockedIndications;
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { 
  Prescription, PrescriptionByProfessionalIdPatientIdRes, 
  PrescriptionDoclineRes, PrescriptionInsertRes,
  PrescriptionsFilters, 
  NgrxPrescriptionsRes,
} from 'src/app/models';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Medicament } from 'src/app/models';
import { FormatDatesService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root' //injected in effects
})
export class DynPrescriptionsService {

  constructor(
    private http :                                          HttpClient,
    private formatDatesService:                             FormatDatesService
  ) { }

  TestMsal(){
    return this.http.get("https://graph.microsoft.com/v1/me")
  }

  LoadPrescriptionByProfessionalIdAndPatientId(
    params:PrescriptionByProfessionalIdPatientIdRes
  ):Observable<PrescriptionByProfessionalIdPatientIdRes>
  {

    const { professionalId , patientId } = params;
    let url = `${environment.prescriptionsService.url}prescription?`;
    if(professionalId)url += `professionalId=${professionalId}`;
    if(patientId)url += `&patientId=${patientId}`;
    return this.http.get<PrescriptionByProfessionalIdPatientIdRes>(url);
  }

  LoadLastDraftByProfessionalIdAndPatientId(
    params:PrescriptionByProfessionalIdPatientIdRes
  ):Observable<Prescription>
  {

    const { professionalId , patientId } = params;
    let url = `${environment.prescriptionsService.url}prescription/draft?`;

    if(professionalId)url += `professionalId=${professionalId}`;
    if(patientId)url += `&patientId=${patientId}`;

    return this.http.get<Prescription>(url);
  }

  /* GetTodayPrescriptions( professionalId : string , patientId : string , date : string ){

  } */

  CreatePrescriptionDraft(body:Prescription):Observable<{id:string,isOk:boolean,error:null}>{
    return this.http.post<{id:string,isOk:boolean,error:null}>(`${environment.prescriptionsService.url}prescription/draft`, body);
  }

  CreatePrescriptionDraftFromEffects(body:Prescription){
    return this.http.post(`${environment.prescriptionsService.url}prescription/draft`, body);
  }

  CreatePrescriptionManual( prescriptionId : string ): Observable<{StatusCode: number,Message: string,InternalCode: string}>{
    return this.http.post<{StatusCode: number,Message: string,InternalCode: string}>(`${environment.prescriptionsService.url}prescription/manual`, { prescriptionId : prescriptionId });
  }

  GetPrescriptionById(id:string):Observable<Prescription>{
    return this.http.get<Prescription>(`${environment.prescriptionsService.url}prescription/${id}`);
  }

  InsertMedicineDDBB(body:Medicament):Observable<PrescriptionInsertRes>{
    return this.http.post<PrescriptionInsertRes>(`${environment.prescriptionsService.url}medicin`,body);
  }

  InsertNewMedicineToDraft(body:Medicament){
    return this.http.post(`${environment.prescriptionsService.url}prescription/medicine`,body );
  }

  InsertParaMedicamentInDraft(body:Medicament):Observable<{isOk:string , error:null}>{
    return this.http.post<{isOk:string , error:null}>(`${environment.prescriptionsService.url}prescription/parapharmacy`,body);
  }

  InsertMasterFormula( masterFormula : Medicament ):Observable<{ isOk:boolean , error?:'' }>{
    return this.http.post<{ isOk:boolean , error?:'' }>(`${environment.prescriptionsService.url}prescription/master-formula` , masterFormula);
  }

  InsertMasterFormulaInProtocol( masterFormula : Medicament ):Observable<boolean>{
    return this.http.post<boolean>(`${environment.prescriptionsService.url}protocol/master-formula` , masterFormula);
  }

  InsertParamedicament( paramedicament : Medicament ):Observable<{ isOk:boolean , error?:'' }>{
    return this.http.post<{ isOk:boolean , error?:'' }>(`${environment.prescriptionsService.url}prescription/parapharmacy` , paramedicament);
  }

  InsertParaMedicamentInProtocol( medicament : Medicament ):Observable<boolean>{
    return this.http.post<boolean>( `${environment.prescriptionsService.url}protocol/parapharmacy` , medicament );
  }

  UpdateParaMedicamentInProtocol( medicament : Medicament ):Observable<{isOk: boolean,error: string}>{
    return this.http.put<{isOk: boolean,error: string}>( `${environment.prescriptionsService.url}protocol/parapharmacy` , medicament );
  }

  UpdateParaMedicamentInPrescription( medicament : Medicament ):Observable<{isOk: boolean,error: string}>{
    return this.http.put<{isOk: boolean,error: string}>( `${environment.prescriptionsService.url}prescription/parapharmacy` , medicament );
  }

  DeleteMasterFormula( masterFormulaId : string ):Observable<boolean>{
    return this.http.delete<boolean>(`${environment.prescriptionsService.url}prescription/master-formula?masterFormulaId=${masterFormulaId}`);
  }

  DeleteMasterFormulaInProtocol( masterFormulaId : string , protocolId : string ):Observable<boolean>{
    return this.http.delete<boolean>(`${environment.prescriptionsService.url}protocol/master-formula?protocolId=${protocolId}&id=${masterFormulaId}`);
  }

  /* DeleteParapharmacy( parapharmacyId : string ):Observable<boolean>{
    return this.http.delete<boolean>(`${environment.prescriptionsService.url}prescription/parapharmacy?masterFormulaId=${parapharmacyId}`);
  } */

  ForceReloadPrescriptionsPut( doclineId : string ):Observable<boolean>{
    return this.http.put<boolean>(`${environment.prescriptionsService.url}webhook/force-sync/${doclineId}` , {});
  }

  UpdateMedicine(body:Medicament):Observable<{ isOk: boolean , error:string }>{
    return this.http.put<{ isOk: boolean , error:string }>(`${environment.prescriptionsService.url}prescription/medicine`,body);
  }

  UpdateMasterFormula(body:Medicament):Observable<{ isOk: boolean , error:string }>{
    return this.http.put<{ isOk: boolean , error:string }>(`${environment.prescriptionsService.url}prescription/master-formula`,body);
  }

  UpdateMasterFormulaInProtocol(body:Medicament):Observable<boolean>{

    return this.http.put<boolean>(`${environment.prescriptionsService.url}protocol/master-formula`,body);
  }

  DeleteMedicine(prescriptionId:string , medicineId:string):Observable<boolean>{
    return this.http.delete<boolean>(`
      ${environment.prescriptionsService.url}prescription/medicine?prescriptionId=${prescriptionId}&medicineId=${medicineId}
    `);
  }

  DeleteParaMedicament( prescriptionId:string , paraMedicamentId : string ):Observable<boolean>{
    return this.http.delete<boolean>(`${environment.prescriptionsService.url}prescription/parapharmacy?prescriptionId=${prescriptionId}&id=${paraMedicamentId}`);
  }

  DeletePrescriptionById(id:string):Observable<boolean>{
    return this.http.delete<boolean>(`${environment.prescriptionsService.url}prescription/${id}`);
  }

  CancelPrescriptionById(paramsToCancelPrescription : { prescriptionDoclineId : string , reasonId : string , comment : string , id : string }):Observable<{ isOk : boolean , error : string }> {

    const myBody : { prescriptionDoclineId : string , reasonId : string , comment : string } = { 
      prescriptionDoclineId : paramsToCancelPrescription.prescriptionDoclineId ,
      reasonId : paramsToCancelPrescription.reasonId,
      comment : paramsToCancelPrescription.comment  
    }

    return this.http.delete<{ isOk : boolean , error : string }>(`${environment.prescriptionsService.url}prescription/cancel/${paramsToCancelPrescription.id}` , {headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
      body: myBody 
    });
  }

  FormalizePrescription(prescriptionId:{prescriptionId:string}):Observable<PrescriptionDoclineRes>{
    return this.http.post<PrescriptionDoclineRes>(
      `${environment.prescriptionsService.url}prescription/v2/docline` , prescriptionId
    );
  }

  ReemitPrescription( filters : { prescriptionId : string , createOnlyDraft : boolean } ):Observable<{ isOk : boolean , error : string , id?:string }>{
    return this.http.post<{ isOk : boolean , error : string , id?:string }>(`${environment.prescriptionsService.url}prescription/re-emit-docline` , filters);
  }

  GetDownloadPrescriptionUrlById(prescriptionId:string){
    return this.http.get(
      `${environment.prescriptionsService.url}prescription/download/${prescriptionId}` ,
      {responseType : 'text'}
    );
  }

  DownloadPrescriptionByUrl(dirUrl:string){
    return this.http.get(dirUrl, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders().append('Content-Type', 'application/pdf'),
    });
  }

  DownloadPrescriptionByUrlToPreview(dirUrl:string){
    return this.http.get(dirUrl, {responseType : 'arraybuffer'});
  }

  SendPrescriptionByEmail(prescriptionId : string){
    const prescriptionIdBody : {prescriptionId : string} = { prescriptionId : prescriptionId };
    return this.http.post(`${environment.prescriptionsService.url}prescription/send-email-to-patient` , prescriptionIdBody);
  }
  
  
  /********************************************* SERVICES FOR EFFECTS *******************************************/  
  GetPrescriptionByEffects(params ? : PrescriptionsFilters):Observable<NgrxPrescriptionsRes>{
    
    if(params?.from) params = { ...params , from : this.formatDatesService.FormatPrescriptionDateYearMonthDay(params?.from) };
    if(params?.to) params = { ...params , to : this.formatDatesService.FormatPrescriptionDateYearMonthDay(params?.to) };

    const url = `${environment.prescriptionsService.url}prescription`;
    const builtUrl = params ? this.BuildUrlPrescriptions(url , params) : url;


    /* let url = `${environment.url}prescription`;
    let FilledUrl = this.BuildUrl(url,params); */
    return this.http.get<NgrxPrescriptionsRes>(builtUrl);
  }

  LoadPrescriptionsByPatientId(params : PrescriptionsFilters):Observable<NgrxPrescriptionsRes>{
    const url = `${environment.prescriptionsService.url}prescription`;
    const builtUrl = this.BuildUrlPrescriptions( url , params );

    return this.http.get<NgrxPrescriptionsRes>(builtUrl);
  }

  /***********************************  URL BUILDERS ********************************************/

  BuildUrlPrescriptions(url :string , params : PrescriptionsFilters):string{
    if( Object.values( params ).length > 0 )url += `?`;

    const { 
      Filter , patientId , professionalId , first , size , from , to , doctorName , 
      patientFilter , professionalFilter , type , init , clinic , status
    } = params;

    if(Filter)url += `Filter=${Filter}&`;
    if(clinic)url += `clinic=${clinic}&`;
    if(status && status !== 'All')url += `status=${status}&`;
    // if(patientId)url += `patientId=${patientId}&`;
    if(professionalId)url += `professionalId=${professionalId}&`;
    // if(userName)url += `userName=${userName}&`;
    if(first)url += `first=${first}&`;
    if(size)url += `size=${size}&`;
    if(from)url += `from=${from}&`;
    if(to)url += `to=${to}&`;
    if(doctorName)url += `doctorName=${doctorName}&`;

    if( patientId ){
      url += `patientId=${patientId}&`;
    } else {
      if(patientFilter)url += `patientFilter=${patientFilter}&`;
    }

    
    if(professionalFilter)url += `professionalFilter=${professionalFilter}&`;
    if(type)url += `type=${type}&`;
    if(init)url += `init=${init}&`;

    if(url.slice(-1) === '&')url = url.replace(/.$/,"");
    return url;
  }

  BuildUrl(url:string,params?:PrescriptionsFilters):string{

    url += '?';
    
    if(params){

      const keys = Object.keys(params);
      const values = Object.values(params);

      for (let i = 0; i < keys.length; i++) {

        if(values[i]){

          url += `${keys[i]}=${values[i]}`;
          
          if(i < (keys.length - 1))url += `&`;
        }
      }
    } else {
      url += `size=10&`;
      url += `first=1`;
    }

    if(url.slice(-1) === '&')url = url.replace(/.$/,"");
    return url;
  }
}





/****************************** disabled requests *******************************/
/* 

  formatEndDateRecoverOneDay(date):string{
    date = new Date(date);
    const dateStr = (date.getMonth()+1) + '/' + (date.getDate()+1) + '/' + date.getFullYear();
    return dateStr;
  }

  YearMonthDayFormat(date):string{
    date = new Date(date);
    // const dateStr = (date.getMonth()+1) + '/' + (date.getDate()+1) + '/' + date.getFullYear();
    const dateStr = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + (date.getDate()+1) ;
    return dateStr;
  }

  formatStartDateRecoverOneDay(date):string{
    date = new Date(date);
    const dateStr = (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();
    return dateStr;
  }

  GetPrescription(params ? : PrescriptionsFilters){

    let url = `${environment.url}prescription?`;
    let FilledUrl = this.BuildUrl(url , params);
    const status = new ReplaySubject<Status>();
    const request = this.http.get<NgrxPrescriptionsRes>(FilledUrl).pipe(
      retry(2),
      catchError(error => {
        status.next(Status.ERROR);
        throw 'error loading prescriptions';
      }),
      tap(() => status.next(Status.SUCCESS))
    );

    const data = defer(() => {
      status.next(Status.LOADING);
      return request;
    });

    return { data, status };
  }

  GetPrescriptionsByParams(params ? : PrescriptionsFilters){
    
    let url = `${environment.url}prescription`;
    
    let FilledUrl = this.BuildUrl(url , params);

    return this.http.get<NgrxPrescriptionsRes>(FilledUrl);
  }

  GetDraftPrescriptionByProId(params){
    let url = `${environment.url}prescription`;
    let FilledUrl = this.BuildUrl(url,params);
    return this.http.get(FilledUrl);
  }

  ModifyPrescriptionDraf(body:any):Observable<Prescription>{
    return this.http.put<Prescription>(`${environment.url}prescription/draft`,body);
  }

*/
import { AdministrationWay } from "../../prescriptions/interfaces/prescriptionsRes";

export class MasterFormula {
    prescriptionId      ='';
    composition         ='';
    dosage              ='Gotas';
    pharmaceuticalForm  ='Colirio';
    administrationWay   ='Gotas';
    takingAmount        = 1;
    takingUnit          ='Gotas';
    frequencyAmount     = 8;
    frequencyUnit       ='Hora';
    unit?: AdministrationWay | string             ;
    durationAmount      = 1;
    durationUnit        ='Día';
    narcotic            =false;
    psychotropic        =false;
    patientInstructions ='';
    pharmacistWarnings  ='';
    startDate           = new Date();
    endDate             = new Date();
}
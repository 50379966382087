<!-- {{ whoIsParent?.from }}
{{ whoIsParent?.parentId }}
{{ whoIsParent?.child }} -->

<!-- <dx-scroll-view
    id="scrollviewFavoriteMedicamentsPopUp"
    reachBottomText="Updating..."
> -->

    <div  class="favorite_medicaments_table-main_container">

        <dx-data-grid
            #prescriptionNewGridContainer
            id="prescriptionmedicamentsFavoriteGridContainer"
            class="favorite_medicaments_table-table_container"
            [hoverStateEnabled]="true"
            [dataSource]="mixFavoritesMedicamentsMasterFormulas"
            [allowColumnReordering]="true"
            [showRowLines]="false"
            [showColumnLines]="true"
            [showBorders]="true"
            [columnAutoWidth]="true"
            (onRowClick)="OnRowClick($event)"
        >
            <dxo-paging [pageSize]=" windowWidth < mobileViewBreakPoint ? 10 : 15"> </dxo-paging>
            
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

            <dxo-filter-builder
                [allowHierarchicalFields]="true"
            ></dxo-filter-builder>

            <dxo-filter-row 
                [visible]="showFilterRow"
                [applyFilter]="currentFilter"
            >
            </dxo-filter-row>

            <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>

            <!-- <dxo-scrolling
                [useNative]="false"
                [scrollByContent]="true"
                [scrollByThumb]="true"
                showScrollbar="onHover"
                columnRenderingMode="virtual"
            ></dxo-scrolling> -->

            <dxi-column 
                cellTemplate="kinds"
                [allowFixing]="false" 
                width="50" 
                alignment='left' 
                dataField="kind" 
                caption=""
            >
            </dxi-column>

            <dxi-column
                [allowFixing]="false"
                [width]="windowWidth < mobileViewBreakPoint ? 0 : 80" 
                alignment='left' 
                dataField="packagesAmount" 
                caption="Envases"
            >
            </dxi-column>

            <dxi-column 
                [allowFixing]="false"
                dataField="fullName" 
                alignment='left' 
                caption="Medicamento / Composición" 
                [width]="windowWidth < mobileViewBreakPoint ? 250 : 400"
            >
            </dxi-column>

            <dxi-column
                alignment='left' 
                dataField="posology" 
                caption="Posologia" 
                width="370"
                [width]="windowWidth < mobileViewBreakPoint ? 0 : 300"
                dataType="string"
            ></dxi-column>

            <dxi-column 
                [allowFixing]="false"
                dataField="patientInstructions" 
                alignment='left' 
                caption="Instrucciones" 
                [width]="windowWidth < mobileViewBreakPoint ? 0 : 190"
            ></dxi-column>

            <dxi-column 
                [allowFixing]="false"
                dataField="pharmacistWarnings" 
                alignment='left' 
                caption="Avisos Farmacéuticos"
                [width]="windowWidth < mobileViewBreakPoint ? 0 : 190"
            ></dxi-column>
            
            <dxi-column 
                [allowFixing]="false"
                dataField="startDate" 
                alignment='left'
                [width]="windowWidth < mobileViewBreakPoint ? 0 : 100"
                caption="Inicio" 
                dataType="date" 
                format="dd/MM/yyyy"
            >
            </dxi-column>

            <dxi-column 
                [allowFixing]="false"
                dataField="endDate"  
                alignment='left' 
                [width]="windowWidth < mobileViewBreakPoint ? 0 : 100"
                caption="Fin"
                dataType="date" 
                format="dd/MM/yyyy"
                
            >
            </dxi-column>

            <dxi-column
                class="prescriptions_table-columns_header" 
                fixed="true"
                width="40"
                fixedPosition="right"
                caption="" 
                cellTemplate="modify_selection" 
                dataField="modify_selection_action"
                [allowFiltering]="false"
            ></dxi-column>

            <div *dxTemplate="let action of 'kinds'" class="favorites_medicaments-kind_icons_container" >
                <div *ngIf="action.data.kind === 'medicament'" >
                    <fa-icon [icon]="faPrescriptionBottleMedical" class="kind_icon"></fa-icon>
                </div>
                <div *ngIf="action.data.kind === 'masterFormula'" >
                    <fa-icon [icon]="faFlaskVial" class="kind_icon"></fa-icon>
                </div>
                <div *ngIf="action.data.kind === 'paraMedicament'" >
                    <fa-icon [icon]="faCapsules" class="kind_icon"></fa-icon>
                </div>
            </div>

            <div *dxTemplate="let acciones of 'modify_selection'">
            
                <div
                    class="medicament_search-plus_icons_container" 
                >
                    <input
                        [disabled]="true"
                        type="checkbox" 
                        class="checkbox__input" 
                        [(ngModel)]="acciones.data.selected" 
                        
                    >

                    <svg class="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                        <rect width="21" height="21" x=".5" y=".5" fill="#FFF" stroke="#006F94" rx="3" />
                        <path class="tick" stroke="#6EA340" fill="none" stroke-linecap="round" stroke-width="4" d="M4 10l5 5 9-9" />
                    </svg>
                </div>
            </div>

        </dx-data-grid>

        <div>
            <app-spinner></app-spinner>
        </div>
    </div>

<!-- </dx-scroll-view> -->



    
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of , mergeMap } from 'rxjs';
import { catchError , map, switchMap } from 'rxjs/operators';
import * as ParapharmacyFormulasActions from 'src/app/store/actions/medicaments/favorites-parafarmacies.actions';
import { MedicamentsFavoritesService } from 'src/app/medicaments-favorites/services';
import { HttpErrorResponse } from '@angular/common/http';
import { Medicament } from 'src/app/models';
import { ComponentToSpinnerService } from 'src/app/shared/services';

@Injectable()

export class favoritesParapharmaciesEffects {

    constructor( 
        private action$ : Actions,
        private medicamentsFavoritesService: MedicamentsFavoritesService ,
        private componentToSpinnerService:  ComponentToSpinnerService
    ){}

    loadParapharmaciesFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(ParapharmacyFormulasActions.getFavoritesParapharmacies),
            switchMap(action =>
                {
                    const { professionalId } = action || {};

                    return this.medicamentsFavoritesService.LoadFavoritesParapharmacies( professionalId )
                    .pipe(
                        map(medicaments => {
                            return ParapharmacyFormulasActions.getFavoritesParapharmaciesSuccess ( {medicaments} )
                        }),
                        catchError((error : HttpErrorResponse) => {
                            this.componentToSpinnerService.sendMessage('hide');
                            return of( ParapharmacyFormulasActions.getFavoritesParapharmaciesFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })

    addParapharmaciesFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( ParapharmacyFormulasActions.addFavoritesParapharmacies ),
            switchMap(( action ) => {
                
                const { parapharmacy } = action || {}

                return this.medicamentsFavoritesService.SaveFavoriteParapharmcy( parapharmacy )
                .pipe(
                    map(( data ) =>{
                        if( data.isOk ){
                            const myparapharmacyWithId = { ...parapharmacy , favoriteId : data.id }

                            return ParapharmacyFormulasActions.addFavoritesParapharmaciesSuccess({ myparapharmacyWithId  });
                        }else{
                            return ParapharmacyFormulasActions.addFavoritesParapharmaciesSuccess({  });
                        }
                    }),

                    catchError(error => {
                        this.componentToSpinnerService.sendMessage('hide');
                        return of( ParapharmacyFormulasActions.addFavoritesParapharmaciesFailure({ error } ) )
                    })
                )
            })
        )
    })

    deleteParapharmaciesFavorites$ = createEffect(() =>
        this.action$.pipe(
            ofType( ParapharmacyFormulasActions.deleteFavoritesParapharmacies ),
            mergeMap(( action ) => {
                const { parapharmacyFavoritedId } = action;
                return this.medicamentsFavoritesService.DeleteFavoriteParapharmcy( parapharmacyFavoritedId )
                .pipe(
                    map(( data ) =>{
                        if( data.isOk ){
                            return ParapharmacyFormulasActions.deleteFavoritesParapharmaciesSuccess({ parapharmacyFavoritedId })
                        } else {
                            return ParapharmacyFormulasActions.deleteFavoritesParapharmaciesSuccess({  })
                        }
                    }),

                    catchError(error => {
                        this.componentToSpinnerService.sendMessage('hide');
                        return of( ParapharmacyFormulasActions.deleteFavoritesParapharmaciesFailure({ error } ) )
                    })
                )
            })
        )
    );

    updateParapharmaciesFavorites$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( ParapharmacyFormulasActions.updateFavoritesParapharmacies ),
            mergeMap(( action ) => {

                const { parapharmacyUpdate : { 
                    favoriteId , professionalId , fullName , posology, patientInstructions  ,
                    startDate , endDate , status } 
                } = action || {};

                const myParapharmacyToUpdate : Medicament = {
                    favoriteId : favoriteId ,
                    professionalId : professionalId ,
                    name : fullName ,
                    posology : posology ,
                    status : status,
                    patientInstructions : patientInstructions,
                    startDate : startDate , 
                    endDate : endDate
                }

                return this.medicamentsFavoritesService.UpdateFavoriteParapharmcy( myParapharmacyToUpdate )
                .pipe(
                    map(( data ) => {
                        if( data.isOk ){
                            return ParapharmacyFormulasActions.updateFavoritesParapharmaciesSuccess({ myParapharmacyToUpdate });
                        } else {
                            return ParapharmacyFormulasActions.updateFavoritesParapharmaciesSuccess({})
                        }
                    }),

                    catchError(error => {
                        this.componentToSpinnerService.sendMessage('hide');
                        return of( ParapharmacyFormulasActions.updateFavoritesParapharmaciesFailure({ error } ) )
                    })
                )
            })
        )
    })
}
